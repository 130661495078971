import PropTypes from "prop-types"

const airportsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    isCodeShare: PropTypes.bool,
    thesaurus: PropTypes.arrayOf(PropTypes.string),
    cityName: PropTypes.string,
    region: PropTypes.string,
    operationDate: PropTypes.string,
  })
)

const flightsDataPropTypes = PropTypes.shape({
  origin: PropTypes.shape({
    code: PropTypes.string,
    city: PropTypes.string,
  }),
  destination: PropTypes.shape({
    code: PropTypes.string,
    city: PropTypes.string,
  }),
  date: PropTypes.string,
  isCheck: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
})

// const flightsDataPropTypes = PropTypes.shape({
//   origin: {
//     code: PropTypes.string,
//     city: PropTypes.string,
//   },
//   destination: {
//     code: PropTypes.string,
//     city: PropTypes.string,
//   },
//   date: PropTypes.string.isRequired,
//   isCheck: PropTypes.bool.isRequired,
//   index: PropTypes.number.isRequired,
// })

export { airportsPropTypes, flightsDataPropTypes }
