import React, { useRef } from "react"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import clsx from "clsx"
import PropTypes from "prop-types"
import Hidden from "@material-ui/core/Hidden"
import isEmpty from "lodash/isEmpty"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"

import { generalActions } from "../../../../../store/general/generalSlice"
import Modal from "../languageSelector"
import MembersMenuModal from "../../../../../ToLibrary/menuModal/membersMenuModal"
import MembersLoginModal from "../../../../../ToLibrary/menuModal/loginBoxModal"
import ReturnButton from "./returnButton"

import { ReactComponent as CopaIcon } from "../../../../../ToLibrary/icons/CopaAirlines_LogoSm.svg"
import useStyles from "./styles"
import { getCookie } from "../../../../../utils/auth"
import FeatureFlags from "../../../../../featureFlags"

const MobileTopbar = (props) => {
  const {
    // searchDone,
    // origin,
    // destination,
    // departureDate,
    // returnDate,
    editIcon,
    // currencyIcon,
    carrotDown,
    loginIcon,
    formatMessage,
    loggedIn,
    tripType,
    // tripTypeText,
    countries,
    country,
    language,
    languages,
    onChangeCountryAndLanguage,
    userInfo,
    onEditClick,
    showEditButton,
    isOpenLoginBox,
    setloginBox,
    reservation,
    showInfo,
    isEditSearch,
    lastName,
  } = props
  const classes = useStyles({
    tripType: tripType === 2 ? "multicity" : "other",
  })
  const isMobile = useMediaQuery(`${theme.breakpoints.down("xs")}`)
  const refLoginButton = useRef()

  const handleOpenLoginBox = () => {
    setloginBox(!isOpenLoginBox)
  }
  const cookieOrigin = getCookie("origin")

  // const onKeyPress = (e) => {
  //   if ((e.keyCode === 32 || e.keyCode === 13) && showEditButton) onEditClick()
  // }

  return (
    <>
      <div className={classes.containerMobil}>
        <ButtonCo
          data-cy="mobileTopBar-CopaIcon"
          className={classes.copaIcon}
          color="primary"
          variant="linkInverted"
          aria-label={formatMessage({ id: "topBarWCAG.logo" })}
          start={CopaIcon}
          disabled={cookieOrigin?.toLowerCase() === "app"}
          href="https://www.copaair.com/"
        />
        {showInfo && !isEmpty(reservation) && (
          <div className={classes.textMobileContainer}>
            <TypographyCo
              data-cy="searchParams-route"
              variant="body1"
              className={clsx(classes.multicityTitle, classes.toolbarText)}
            >
              {formatMessage({ id: "topBar.description" })}
            </TypographyCo>
            <ButtonCo
              data-cy="mobileTopBar-editButton-tripType1"
              className={classes.editButton}
              color="primary"
              variant="linkInverted"
              size="small"
              end={showEditButton ? editIcon : null}
              onClick={onEditClick}
              aria-label={formatMessage({ id: "topBarWCAG.editButton" })}
            >
              <TypographyCo
                data-cy="searchParams-date"
                className={clsx(classes.editText, classes.toolbarText)}
                variant="body2"
              >
                {formatMessage(
                  {
                    id: isMobile
                      ? "topBar.reservationXS"
                      : "topBar.reservation",
                  },
                  { reservationCode: reservation }
                )}
              </TypographyCo>
            </ButtonCo>
          </div>
        )}
      </div>
      {/* {searchDone && (
        <Grid
          item
          sm={9}
          xs={7}
          className={classes.displayFlex}
          style={{ justifyContent: "left" }}
        >
          <div
            id="edit"
            className={classes.flightInfo}
            role="button"
            onClick={showEditButton ? onEditClick : null}
            tabIndex="0"
            onKeyDown={onKeyPress}
          >
            {tripType === 2 ? (
              <TypographyCo
                variant="body2"
                className={classes.multicityTitleMobile}
              >
                <FormattedMessage id={tripTypeText()} />
              </TypographyCo>
            ) : (
              <TypographyCo
                variant="body1"
                className={clsx(classes.font, classes.displayTextFlex)}
              >
                {`${origin}`}
                <ArrowIcon className={clsx(classes.icon, classes.arrowIcon)} />
                {`${destination}`}
              </TypographyCo>
            )}
            {tripType === 1 ? (
              <ButtonCo
                data-cy="mobileTopBar-editButton-tripType1"
                className={classes.editButton}
                color="primary"
                variant="linkInverted"
                size="small"
                end={showEditButton ? editIcon : null}
                aria-label={formatMessage({ id: "topBarWCAG.editButton" })}
              >
                <span className={classes.dates}>{`${departureDate} - `}</span>
                <FormattedMessage id={tripTypeText()} />
              </ButtonCo>
            ) : (
              <ButtonCo
                data-cy="mobileTopBar-editButton"
                className={classes.editButton}
                color="primary"
                variant="linkInverted"
                size="small"
                end={showEditButton ? editIcon : null}
                aria-label={formatMessage({ id: "topBarWCAG.editButton" })}
              >
                <span
                  className={classes.dates}
                >{`${departureDate} - ${returnDate}`}</span>
              </ButtonCo>
            )}
          </div>
        </Grid>
      )} */}
      <Grid
        item
        xs={4}
        sm={isEditSearch ? 3 : 7}
        className={clsx(classes.displayFlex, classes.displayFlexMobil)}
      >
        <Hidden xsDown>
          <ReturnButton
            toShow={isEditSearch}
            pnr={reservation}
            lastName={lastName}
            language={language.id}
          />
        </Hidden>
        <Modal
          data={countries}
          language={language}
          country={country}
          onApply={onChangeCountryAndLanguage}
          languages={languages}
        >
          <ButtonCo
            data-cy="mobileTopBar-languageSelector"
            color="primary"
            variant="linkInverted"
            size="small"
            className={classes.currencyButton}
            end={carrotDown}
            aria-label={formatMessage(
              {
                id: "topBarWCAG.languageSelector",
              },
              {
                language: language.option,
              }
            )}
          >
            {isMobile ? language.id : language.option}
          </ButtonCo>
        </Modal>
        {FeatureFlags.TURN_ON_LOGIN && (
          <>
            {loggedIn ? (
              <MembersMenuModal userData={userInfo} refElement={refLoginButton}>
                <div
                  ref={refLoginButton}
                  className={classes.displayFlex}
                  aria-haspopup="true"
                  aria-controls="simple-menu"
                  aria-describedby="customized-menu"
                >
                  <ButtonCo
                    data-cy="mobileTopBar-userNameButton"
                    id="btnMembersLoginBox"
                    size="small"
                    color="primary"
                    variant="linkInverted"
                    className={classes.userNameButton}
                    aria-label={formatMessage({ id: "topBarWCAG.login" })}
                    onClick={handleOpenLoginBox}
                  >
                    {`${userInfo?.givenName[0]}${userInfo?.surname[0]} `}
                  </ButtonCo>
                </div>
              </MembersMenuModal>
            ) : (
              <MembersLoginModal
                userData={userInfo}
                refElement={refLoginButton}
              >
                <ButtonCo
                  data-cy="mobileTopBar-loginButton"
                  ref={refLoginButton}
                  id="btnLoginBox"
                  color="primary"
                  variant="linkInverted"
                  size="small"
                  className={classes.loginButton}
                  start={loginIcon}
                  onClick={handleOpenLoginBox}
                />
              </MembersLoginModal>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

MobileTopbar.propTypes = {
  // origin: PropTypes.string,
  // destination: PropTypes.string,
  // departureDate: PropTypes.string,
  // returnDate: PropTypes.string,
  // currencyIcon: PropTypes.func.isRequired,
  carrotDown: PropTypes.func.isRequired,
  loginIcon: PropTypes.func,
  editIcon: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  // searchDone: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  tripType: PropTypes.number.isRequired,
  // tripTypeText: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      countryCode: PropTypes.string.isRequired,
      countryKey: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      languageCode: PropTypes.string.isRequired,
      languageKey: PropTypes.string.isRequired,
    })
  ),
  onChangeCountryAndLanguage: PropTypes.func.isRequired,
  country: PropTypes.string,
  language: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
    })
  ),
  userInfo: PropTypes.shape({
    givenName: PropTypes.string,
    surname: PropTypes.string,
    loyalty: PropTypes.objectOf(PropTypes.any),
  }),
  onEditClick: PropTypes.func.isRequired,
  setloginBox: PropTypes.func.isRequired,
  isOpenLoginBox: PropTypes.bool.isRequired,
  showEditButton: PropTypes.bool,
  reservation: PropTypes.string.isRequired,
  showInfo: PropTypes.bool,
  lastName: PropTypes.string.isRequired,
  isEditSearch: PropTypes.bool.isRequired,
}

MobileTopbar.defaultProps = {
  // origin: "",
  // destination: "",
  // departureDate: "",
  // returnDate: "",
  loginIcon: null,
  countries: [],
  country: "",
  language: "",
  languages: [],
  userInfo: {},
  showEditButton: true,
  showInfo: true,
}

const mapStateToProps = (state) => ({
  isOpenLoginBox: state.general.openLoginBox,
})

const mapDispatchToProps = (dispatch) => ({
  setloginBox: (open) => dispatch(generalActions.setModalMemberLogin(open)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileTopbar)
