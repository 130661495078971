// import moment from "moment"
// import flatten from "lodash/flatten"
import findLastIndex from "lodash/findLastIndex"
import {
  EVENT_ACTION_TYPES,
  ROUTE_TYPE,
  FARE_FAMILY_MAP,
} from "./analyticsConstants"
import { getErrorStackTrace } from "./generalUtils"

export const pushAnalyticsCustomEvent = (event) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push(event)
}

/**
 * Push the pageView event with the enqueued path
 * and clear the timeout
 */
export const pushPageView = (pathname) => {
  pushAnalyticsCustomEvent({
    event: "pageview",
    page: {
      path: pathname,
    },
  })
}

export const pushAnalyticsCategoryEvent = (
  category,
  action,
  label,
  event = "regularEvent",
  extraData = {}
) => {
  pushAnalyticsCustomEvent({ event, category, action, label, ...extraData })
}

const pushEnhancedECommerceEvent = (action, label, extraData) =>
  pushAnalyticsCategoryEvent(
    "Enhanced E-commerce",
    action,
    label,
    "enhanced ecommerce",
    extraData
  )

const masterDataLayerEvents = [
  "customDimension",
  "pageview",
  "masterDataLayerLoad",
  "enhanced ecommerce",
  "regularEvent",
]
// const pushAnalyticsEECevent = (...event) => {
//   /**
//    * Must be pushed before every event
//    */
//   pushAnalyticsCustomEvent({ event: "eec clear", ecommerce: undefined })
//   pushAnalyticsCategoryEvent(...event)
// }

// const pushAnalyticsTransaction = (event) => {
//   pushAnalyticsCustomEvent({ event: "eec clear", ecommerce: undefined })
//   const dataLayer = window.dataLayer || []
//   const index = dataLayer.findIndex((e) => e.action === "Transaction")
//   if (index >= 0) {
//     dataLayer.splice(index, 1, event)
//   } else {
//     dataLayer.push(event)
//   }
// }

export const pushAnalyticsEvent = (event, payload, usePayload = true) => {
  const variableName = usePayload ? "payload" : event
  pushAnalyticsCustomEvent({ event, [variableName]: payload })
}

const getLabel = (label) => (label.split("|") || [""])[0]

const eventIsRemoved = (layer, label) => {
  const lastAddToCar = findLastIndex(
    layer,
    (item) =>
      getLabel(item.label || "") === label && item.action === "Add to cart"
  )
  const lastRemoved = findLastIndex(
    layer,
    (item) =>
      getLabel(item.label || "") === label && item.action === "Remove from cart"
  )
  return lastRemoved > lastAddToCar
}

// const getLastInsuranceAdded = () => {
//   const dataLayer = window.dataLayer || []
//   const filterAdded = dataLayer.filter(
//     (item) =>
//       item.action === "Add to cart" &&
//       getLabel(item.label) === "Insurance" &&
//       item.event === "enhanced ecommerce"
//   )
//   return filterAdded[filterAdded.length - 1]
//     ? filterAdded[filterAdded.length - 1].ecommerce.add.products
//     : []
// }

const countPassengers = (passengers, typeCode) =>
  passengers.filter((passenger) => passenger.typeCode === typeCode).length

export const pushMasterDataLayer = (reservationInfo, language) => {
  if (reservationInfo && language) {
    const {
      pnr,
      passengers: passengersList,
      storefront,
      currency: currencyCode,
      flights,
      tripType,
      airlineConfirmation: operatingAirlines,
    } = reservationInfo
    const passengersString = `ADT-${countPassengers(
      passengersList,
      "ADT"
    )}|CHD-${countPassengers(passengersList, "CHD")}|INF-${countPassengers(
      passengersList,
      "INF"
    )}`
    const route = flights
      .map((flight) => `${flight.locationDepart}-${flight.locationArrive}`)
      .join("|")
    const departureDateTimes = flights
      .map(
        (flight) =>
          `${flight.scheduledDepart.date}T${flight.scheduledDepart.time}`
      )
      .toString()
    const arrivalDateTimes = flights
      .map(
        (flight) =>
          `${flight.scheduledArrive.date}T${flight.scheduledArrive.time}`
      )
      .toString()
    const flightClass = flights
      .map((flight) => flight.classOfService)
      .toString()
    const fareFamily = flights
      .map((flight) => FARE_FAMILY_MAP[flight.selectedFareFamily])
      .toString()

    const dataLayer = window.dataLayer || []
    const masterDataLayerEvent = {
      event: "masterDataLayerLoad",
      BookingFlow: "RBK",
      pnr,
      passengers: passengersString,
      language,
      storefront,
      // country: "",
      currencyCode,
      // currencyConversionRate: "",
      // ffpLevel: "",
      ffpId: passengersList.find((passenger) => passenger.typeCode === "ADT")
        ?.ffn,
      route,
      routeType: ROUTE_TYPE[tripType],
      departureDateTimes,
      arrivalDateTimes,
      flightClass,
      fareFamily,
      operatingAirlines,
    }
    const masterDataLayerEventIndex = dataLayer.findIndex(
      (dataLayerEvent) => dataLayerEvent.event === "masterDataLayerLoad"
    )
    if (masterDataLayerEventIndex !== -1) {
      dataLayer[masterDataLayerEventIndex] = masterDataLayerEvent
    } else {
      pushAnalyticsCustomEvent(masterDataLayerEvent)
    }
  }
}

const takeLastEventEEC = (newLayer = [], layer, item) => {
  const specialEvents = ["Pricelock", "Insurance"]
  let eventFound = []
  let isRemoved = false
  if (specialEvents.indexOf(getLabel(item.label)) >= 0) {
    isRemoved = eventIsRemoved(layer, getLabel(item.label))
  }
  if (!isRemoved) {
    eventFound = layer.filter((layerItem) => {
      if (item.action === "Add to cart" && getLabel(item.label) === "Flight") {
        return (
          layerItem.action === "Add to cart" &&
          getLabel(layerItem.label) === "Flight" &&
          layerItem.ecommerce?.add?.products[0]?.name ===
            item.ecommerce?.add?.products[0]?.name &&
          newLayer &&
          !newLayer.some(
            (newItem) =>
              newItem?.action === item.action &&
              getLabel(newItem.label) === getLabel(item.label) &&
              newItem.ecommerce?.add?.products[0]?.name ===
                item.ecommerce?.add?.products[0]?.name
          )
        )
      }
      return (
        layerItem.action === item.action &&
        getLabel(layerItem.label) === getLabel(item.label) &&
        newLayer &&
        !newLayer.some(
          (newItem) =>
            newItem?.action === item.action &&
            getLabel(newItem.label) === getLabel(item.label)
        )
      )
    })
  }
  if (eventFound && eventFound.length <= 0) return null

  return eventFound[eventFound.length - 1]
}

const getGASession = () => {
  try {
    const dataPayments = JSON.parse(sessionStorage.getItem("dataLayer"))
    return dataPayments || []
  } catch (error) {
    return []
  }
}

const getEECSession = () => {
  try {
    const dataProducts = JSON.parse(sessionStorage.getItem("productsEEC"))
    return dataProducts || []
  } catch (error) {
    return []
  }
}

export const getProductsEEC = (dataLayer) => {
  const productsEEC = getEECSession()
  const filteredData = dataLayer.filter(
    (data) =>
      data.event === "enhanced ecommerce" && data.action === "Add to cart"
  )
  const productsData = filteredData
    .reduce((layer, item) => {
      const lastEvent = takeLastEventEEC(layer, filteredData, item)
      return layer.concat(lastEvent || { event: "none" })
    }, [])
    .reduce((products, item) => {
      const productEvent =
        item.ecommerce?.add?.products || item.ecommerce?.click?.products || []
      return products.concat(productEvent)
    }, [])
  return productsEEC.concat(productsData)
}

const takeLastEvent = (newLayer = [], layer, item) => {
  const eventFound = layer.filter(
    (layerItem) =>
      layerItem.event === item.event &&
      newLayer &&
      !newLayer.some((newItem) => newItem?.event === item.event)
  )
  if (eventFound && eventFound.length <= 0) return null

  return eventFound[eventFound.length - 1]
}

export const filterGAEvents = (dataLayer) => {
  try {
    const filteredDataLayer = dataLayer
      .filter((dataLayerArray) =>
        masterDataLayerEvents.some((event) => event === dataLayerArray.event)
      )
      .reduce((layer, item) => {
        const lastEvent = takeLastEvent(layer, dataLayer, item)
        return layer.concat(lastEvent || { event: "none" })
      }, [])
      .filter((layer) => layer.event !== "none")
    return filteredDataLayer
  } catch (error) {
    return []
  }
}

export const concatDataLayer = () => {
  const dataLayer = window.dataLayer || []
  const dataPayments = getGASession()
  const products = JSON.stringify(getProductsEEC(dataPayments))
  sessionStorage.setItem("productsEEC", products)
  const repeatedData = [
    "gtm.js",
    "gtm.dom",
    "gtm.load",
    "BookingFlow",
    "Country:",
    "Currency:",
    "Language:",
    "Storefront:",
    "eec clear",
    "enhanced ecommerce",
  ]
  const dataLayerFiltered = dataPayments.filter(
    (data) => !repeatedData.includes(data.event)
  )
  dataLayer.push(...dataLayerFiltered)
}

// const getDiffHours = (dateObject) => {
//   const date = moment(`${dateObject.date} ${dateObject.hour}`)
//   return date.diff(moment(), "hours")
// }

// const getPricelockEventObject = (
//   state,
//   flightsSelected,
//   pricelockData,
//   isRoundtrip,
//   isProduct
// ) => {
//   const { roundtrip, segments } = state.flight.bookingParams
//   const { exchangeRates } = state.catalog
//   const [firstFlightSelected] = flightsSelected
//   const { origin, destination, solutions } = firstFlightSelected
//   const isDomestic = origin.countryCode === destination.countryCode
//   const flightRouteType = isDomestic ? "Domestic" : "International"
//   const isMulticity = !roundtrip && segments.length >= 2

//   const {
//     price,
//     timeLimit: { numberOfDays },
//   } = pricelockData
//   const position = {
//     1: 1,
//     3: 2,
//     7: 3,
//   }[numberOfDays.toString()]
//   const flightsSize = flightsSelected.length
//   let flightType = ""
//   const flightRoute = []
//   let flightFares = []
//   let classOfServices = []
//   flightsSelected.forEach((flight) => {
//     flightRoute.push(`${flight.origin.code}-${flight.destination.code}`)
//     if (!isMulticity) {
//       flightFares.push(
//         `${CABIN_CATEGORY[flight.solutions?.offers?.fareFamily?.code]} ${
//           FARE_FAMILY_CATEGORY[flight.solutions?.offers?.fareFamily?.code]
//         }`
//       )
//     }
//     classOfServices.push(flight.solutions?.offers?.classOfService)
//   })
//   classOfServices = flatten(classOfServices)

//   if (isMulticity) {
//     // Convert classOfService into fare families for multicity
//     flightFares = classOfServices.map((classOfService) => {
//       const currentFareFamily = FARE_FAMILY_LETTER[classOfService]
//       const CATEGORY = FARE_FAMILY_CODE_CATEGORY[currentFareFamily]
//       return `${CABIN_CATEGORY[CATEGORY]} ${FARE_FAMILY_CATEGORY[CATEGORY]}`
//     })
//   }

//   classOfServices = classOfServices.map(
//     (classOfService) => CLASS_OF_SERVICES[classOfService]
//   )
//   if (isRoundtrip) {
//     flightType = "RT"
//   } else {
//     flightType = flightsSize > 1 ? "MC" : "OW"
//   }
//   const resultObject = {
//     name: "Pricelock",
//     brand: "CM",
//     category: `${flightRouteType}/${flightType}/${flightRoute.join(
//       "|"
//     )}/${flightFares.join(",")}/${classOfServices.join(",")}`,
//     variant: numberOfDays,
//     position,
//     price: parseFloat((price * exchangeRates.rate).toFixed(2)),
//   }
//   if (isProduct) {
//     const departureDate = {
//       date: origin.departureDate,
//       hour: solutions?.flights[0]?.departure?.flightTime,
//     }
//     resultObject.quantity = 1
//     resultObject.dimension8 = (!pricelockData.noPriceLock)
//       .toString()
//       .toUpperCase()
//     resultObject.dimension9 = flightType
//     resultObject.metric3 = getDiffHours(departureDate)
//     resultObject.metric8 = price
//   }
//   return resultObject
// }

// const getCurrentFlight = (flightList, flightsSelected, flightKey) =>
//   flightList.find(({ originDestinationKey: odKey }) => odKey === flightKey) ||
//   flightsSelected.find(
//     ({ originDestinationKey }) => originDestinationKey === flightKey
//   )

// const getCurrentSolution = (currentFlight, solutionId) =>
//   (currentFlight?.solutions.length &&
//     currentFlight?.solutions.find(({ key }) => key === solutionId)) ||
//   currentFlight?.solutions

// const getInsuranceEventObject = (state, isProduct, listType) => {
//   const flightRoute = []
//   const {
//     passengers: { list: passengersList = [] },
//     seatsSelection: { typeInfoSeats },
//     catalog: { exchangeRates },
//     flight,
//   } = state
//   const { bookingParams, flightsSelected, priceLockSelected } = flight
//   const { adults, children, infants } = bookingParams
//   const [firstFlightSelected] = flightsSelected
//   const { origin, solutions } = firstFlightSelected

//   const passengerAges = passengersList.map((pax) =>
//     moment().diff(moment(pax.birthDate, "DD/MM/YYYY"), "years")
//   )
//   const price = typeInfoSeats?.seatsSelected.find(
//     (item) => item.typeCode === "insurance"
//   )
//   flightsSelected.forEach((fl) => {
//     flightRoute.push(`${fl.origin.code}-${fl.destination.code}`)
//   })

//   const departureTime = moment(
//     `${flightsSelected[0]?.solutions?.flights[0]?.departure?.flightDate} ${flightsSelected[0]?.solutions?.flights[0]?.departure?.flightTime}`
//   )
//   const lastFlight =
//     flightsSelected[flightsSelected.length - 1]?.solutions?.flights
//   const arrivalTime = moment(
//     `${lastFlight[lastFlight.length - 1]?.departure?.flightDate} ${
//       lastFlight[lastFlight.length - 1]?.departure?.flightTime
//     }`
//   )
//   const duration = moment
//     .duration(moment(arrivalTime).diff(departureTime))
//     .asHours()
//   const resultObject = {
//     name: "Insurance",
//     brand: "CM",
//     category: [
//       passengerAges.join("|"),
//       flightRoute.join("|"),
//       duration,
//       passengersList.length,
//       [`ADT-${adults}`, `CHD-${children}`, `INF-${infants}`].join("|"),
//     ].join("/"),
//     price: Number(((price?.sumPrice || 0) * exchangeRates.rate).toFixed(2)),
//   }
//   if (isProduct) {
//     resultObject.quantity = 1
//     resultObject.dimension8 = (!priceLockSelected.noPriceLock)
//       .toString()
//       .toUpperCase()
//     resultObject.metric3 = getDiffHours({
//       date: origin.departureDate,
//       hour: solutions?.flights[0]?.departure?.flightTime,
//     })
//     resultObject.metric8 = price?.sumPrice
//   }
//   if (listType) {
//     resultObject.list = listType
//   }

//   return resultObject
// }
// const getCurrentOffer = (currentSolution, offerId) =>
//   (currentSolution?.offers.length &&
//     currentSolution?.offers.find(({ id }) => id === offerId)) ||
//   currentSolution?.offers

// const pushFlightRouteType = (airportsState) => {
//   const { originSelected, destinationSelected } = airportsState
//   const isDomestic =
//     originSelected?.countryCode === destinationSelected?.countryCode
//   const flightRouteType = isDomestic ? "Domestic" : "International"
//   pushAnalyticsEvent("FlightRouteType:", flightRouteType, false)
// }

// export const getAnalyticsProduct = (state, flightKey, solutionId, offerId) => {
//   const { airports, flight, catalog } = state
//   const {
//     list: flightList,
//     bookingParams,
//     flightsSelected,
//     priceLockSelected,
//   } = flight
//   const { adults, children, infants, roundtrip } = bookingParams
//   const { exchangeRates } = catalog

//   const currentFlight = getCurrentFlight(flightList, flightsSelected, flightKey)
//   const currentSolution = getCurrentSolution(currentFlight, solutionId)

//   const [originFlight] = currentSolution?.flights
//   const [destinationFlight] = currentSolution?.flights.slice(-1)
//   const {
//     departure: {
//       airportCode: originCode,
//       flightDate: departureFlightDate,
//       flightTime: departureFlightTime,
//     } = {},
//   } = originFlight || {}
//   const { arrival: { airportCode: destinationCode } = {} } =
//     destinationFlight || {}
//   const origin = airports.origins.find(({ code }) => code === originCode) || {}
//   const destination =
//     airports.destinations.find(({ code }) => code === destinationCode) || {}

//   const currentOffer = getCurrentOffer(currentSolution, offerId)

//   const flightsDetail =
//     currentSolution?.flights.map(
//       ({ marketingCarrier, operatingCarrier, departure, layoverTime }) => {
//         if (!operatingCarrier) operatingCarrier = {}
//         return {
//           airlineCode:
//             operatingCarrier.airlineCode || marketingCarrier.airlineCode,
//           flightNumber:
//             operatingCarrier.flightNumber || marketingCarrier.flightNumber,
//           departure,
//           layoverTime,
//         }
//       }
//     ) || []
//   const internationalFlag = !!localStorage.getItem("isInternational")
//   const isDomestic =
//     origin.countryCode === destination.countryCode && !internationalFlag
//   const flightType = isDomestic ? "National" : "International"

//   const name = `${originCode}-${destinationCode}`
//   const brand = flightsDetail.map(({ airlineCode }) => airlineCode).join(",")
//   const category = [
//     flightType,
//     departureFlightDate,
//     `${CABIN_CATEGORY[currentOffer?.fareFamily?.code]} ${
//       FARE_FAMILY_CATEGORY[currentOffer?.fareFamily?.code]
//     }`,
//     currentOffer?.classOfService
//       .map((service) => CLASS_OF_SERVICES[service] || "Pending")
//       .join(","),
//     [`ADT-${adults}`, `CHD-${children}`, `INF-${infants}`].join("|"),
//   ].join("/")
//   const [firstFlightDetail] = flightsDetail
//   const variant = `${firstFlightDetail?.flightNumber}|${firstFlightDetail?.departure.flightTime}`
//   const position =
//     FARE_FAMILY_POSITION_BY_CATEGORY[currentOffer?.fareFamily?.code]
//   const metric8 = currentOffer?.totalPrice

//   const price = (metric8 * exchangeRates?.rate).toFixed(2)
//   const dimension8 = (!priceLockSelected.noPriceLock).toString().toUpperCase()
//   const dimension9 = roundtrip ? "RT" : "OW"
//   const dimension11 = currentSolution?.flights
//     // eslint-disable-next-line no-useless-escape
//     .map(({ aircraftName }) => aircraftName.replace(/[^0-9\-]/g, ""))
//     .join(",")
//   const metric3 = getDiffHours({
//     date: departureFlightDate,
//     hour: departureFlightTime,
//   })

//   const metric6 = flightsDetail
//     .map(({ layoverTime }) => {
//       const hours = parseInt(layoverTime.match(/[0-9]+(?=h)/g), 10)
//       const mins = parseInt(layoverTime.match(/[0-9]+(?=m)/g), 10)
//       return hours + mins / 60
//     })
//     .reduce((a, b) => a + b, 0)

//   const { promocode } = bookingParams
//   const coupon = promocode
//   return {
//     brand,
//     category,
//     dimension10: brand,
//     dimension11,
//     dimension8,
//     dimension9,
//     metric3,
//     metric6,
//     metric8,
//     name,
//     position,
//     price,
//     coupon,
//     variant,
//   }
// }

// export const getAnalyticsMulticityProduct = (payload, state) => {
//   const { bookingParams, priceLockSelected } = state.flight
//   const { exchangeRates } = state.catalog

//   const { adults, children, infants, promocode: coupon } = bookingParams

//   const [firstSolution] = payload.offer.solutionKeys
//   const [firstFlight] = firstSolution.flights

//   const { flightDate: departureDate } = firstFlight.departure

//   const name = []
//   for (let i = 0; i < 5; i += 1) {
//     const areaIndex = i * 2 + 1
//     const area1 = bookingParams[`area${areaIndex}`]
//     const area2 = bookingParams[`area${areaIndex + 1}`]

//     if (!area1 || !area2) break
//     name.push(`${area1}-${area2}`)
//   }

//   const brand = []
//   const variant = []
//   const metric3 = []
//   const metric6 = []
//   payload.offer.solutionKeys.forEach(({ flights }) => {
//     const flightsDetail = flights.map(
//       ({ marketingCarrier, operatingCarrier, departure, layoverTime }) => {
//         if (!operatingCarrier) operatingCarrier = {}
//         return {
//           layoverTime,
//           airlineCode:
//             operatingCarrier.airlineCode || marketingCarrier.airlineCode,
//           flightNumber:
//             operatingCarrier.flightNumber || marketingCarrier.flightNumber,
//           departureDate: departure.flightDate,
//           departureTime: departure.flightTime,
//         }
//       }
//     )
//     const [firstFlightDetail] = flightsDetail
//     brand.push(flightsDetail.map(({ airlineCode }) => airlineCode).join(","))
//     variant.push(
//       `${firstFlightDetail.flightNumber}|${firstFlightDetail.departureTime}`
//     )
//     const diffHours = getDiffHours({
//       date: firstFlightDetail.departureDate,
//       hour: firstFlightDetail.departureTime,
//     })
//     metric3.push(diffHours)

//     const flightConnectionTime = flightsDetail
//       .map(({ layoverTime }) => {
//         const hours = parseInt(layoverTime.match(/[0-9]+(?=h)/g), 10)
//         const mins = parseInt(layoverTime.match(/[0-9]+(?=m)/g), 10)
//         return hours + mins / 60
//       })
//       .reduce((a, b) => a + b, 0)
//     metric6.push(flightConnectionTime)
//   })

//   const isDomesticByFlight = []
//   const flightTypes = []
//   payload.originsAirportsData.forEach((origin, i) => {
//     const destination = payload.destinationsAirportsData[i]
//     const isDomestic = origin.countryCode === destination.countryCode
//     isDomesticByFlight.push(isDomestic)
//     flightTypes.push(isDomestic ? "Domestic" : "International")
//   })

//   const fareFamilies = []
//   const classOfServices = []
//   payload.offer.classOfService.forEach((classOfService) => {
//     const currentFareFamily = FARE_FAMILY_LETTER[classOfService]
//     classOfServices.push(CLASS_OF_SERVICES[classOfService])
//     if (currentFareFamily) {
//       fareFamilies.push(currentFareFamily)
//     }
//   })

//   const category = [
//     // Flight types: Domestic | International
//     flightTypes.join(","),
//     // Departure Date
//     departureDate,
//     // Fare families: Business Promo | Business Full | Economy Classic...
//     fareFamilies
//       .map((code) => {
//         const CATEGORY = FARE_FAMILY_CODE_CATEGORY[code]
//         return `${CABIN_CATEGORY[CATEGORY]} ${FARE_FAMILY_CATEGORY[CATEGORY]}`
//       })
//       .join(","),
//     // Class of service: Alpha, Bravo, Charlie...
//     classOfServices.join(","),
//     // Passengers count
//     [`ADT-${adults}`, `CHD-${children}`, `INF-${infants}`].join("|"),
//   ].join("/")

//   const metric8 = payload.offer.totalPrice
//   const price = (metric8 * exchangeRates.rate).toFixed(2)

//   const dimension8 = (!priceLockSelected.noPriceLock).toString().toUpperCase()
//   const dimension11 = payload.offer.solutionKeys
//     .map(({ flights }) => {
//       return (
//         flights
//           // eslint-disable-next-line no-useless-escape
//           .map(({ aircraftName }) => aircraftName.replace(/[^0-9\-]/g, ""))
//           .join(",")
//       )
//     })
//     .join("|")

//   return {
//     name: name.join("|"),
//     brand: brand.join("|"),
//     category,
//     variant: variant.join(","),
//     position: 1,
//     price: parseFloat(price),
//     quantity: 1,
//     coupon,
//     dimension8,
//     dimension9: "MC",
//     dimension10: brand.join(","),
//     dimension11,
//     metric3: metric3[0],
//     metric6: metric6[0],
//     metric8,
//   }
// }

// export const getMulticityOriginDestinationAirports = ({ airports, flight }) => {
//   const { bookingParams } = flight
//   const originsAirportsData = []
//   const destinationsAirportsData = []
//   for (let i = 0; i < 5; i += 1) {
//     const position = i * 2 + 1
//     const nextPosition = position + 1
//     const [areaOrigin, areaDestination] = [
//       bookingParams[`area${position}`],
//       bookingParams[`area${nextPosition}`],
//     ]

//     if (!areaOrigin || !areaDestination) break

//     const [origin, destination] = airports.origins.filter(({ code }) =>
//       [areaOrigin, areaDestination].includes(code)
//     )
//     originsAirportsData.push(origin)
//     destinationsAirportsData.push(destination)
//   }

//   return [originsAirportsData, destinationsAirportsData]
// }

// export const mapMulticityOfferSolutions = (initialOffer, { flight }) => {
//   const { mcSolutions } = flight
//   const offer = {
//     ...initialOffer,
//     solutionKeys: [],
//   }
//   offer.solutionKeys = mcSolutions.filter(({ key }) =>
//     initialOffer.solutionKeys.includes(key)
//   )

//   return offer
// }

export const handleAnalyticsEvent = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case EVENT_ACTION_TYPES.SET_LANG:
      pushAnalyticsCategoryEvent(
        "Exchanges",
        "Language change",
        action.payload,
        "customDimension",
        { language: action.payload }
      )
      break
    case EVENT_ACTION_TYPES.SET_CHANGE_FARE_MODAL_OPTION_SELECTED:
      pushAnalyticsCategoryEvent(
        "Exchanges",
        action.payload,
        "Old Itinerary/New Itinerary",
        "regularEvent"
      )
      break
    case EVENT_ACTION_TYPES.SHOW_NOT_AVAILABLE_PAGE:
      pushAnalyticsCategoryEvent(
        "RBK - Unavailable",
        "Unavailable Itinerary",
        "Old Itinerary/New Itinerary",
        "regularEvent"
      )
      break
    case EVENT_ACTION_TYPES.AUTH_LOGIN_FAILURE:
      pushAnalyticsCategoryEvent(
        "Exchanges",
        "ConnectMiles login",
        `Error|${payload}`,
        "regularEvent"
      )
      break
    case EVENT_ACTION_TYPES.GENERAL_LOG_ERROR:
      const err = payload
      let errorType = ""
      let errorMessage = ""
      let errorContext = ""
      const errorStackTrace =
        getErrorStackTrace(err.stack).substring(0, 350) || "" // truncate string due to GA action label limit
      if (err.response) {
        errorType = err.response.status
        errorMessage = err.response.data.message
        errorContext = `${err.response.config.baseURL}|${err.response.config.url}|${err.response.config.data}|${errorStackTrace}`
      } else {
        errorType = err.name
        errorMessage = err.message
        errorContext = errorStackTrace
      }

      pushAnalyticsCategoryEvent(
        "Exchanges", // Category
        "Error", // Action
        `${errorType}|${errorMessage}|${errorContext}`, // Labal
        "regularEvent" // Event
      )
      break
    case EVENT_ACTION_TYPES.AUTH_LOGIN_SUCCESS:
      pushAnalyticsCategoryEvent(
        "Exchanges",
        "ConnectMiles login",
        `Success`,
        "regularEvent"
      )
      break
    case EVENT_ACTION_TYPES.SET_FLIGHTS_SELECTED:
      const { fareFamily, solutionsData } = payload
      const { reservation } = state
      if (fareFamily && solutionsData) {
        const newItineraryInfo = solutionsData
          .map(
            (solutionData) =>
              `${solutionData.originDestination?.origin?.code}-${
                solutionData.originDestination?.destination?.code
              }_${solutionData.flights[0]?.departure?.flightDate}T${
                solutionData.flights[0]?.departure?.flightTime
              },${
                solutionData.flights[solutionData.flights.length - 1]?.arrival
                  ?.flightDate
              }T${
                solutionData.flights[solutionData.flights.length - 1]?.arrival
                  ?.flightTime
              }_${FARE_FAMILY_MAP[fareFamily.code]}_${
                fareFamily.classOfService
              }_${solutionData.flights
                .map(
                  (flight) =>
                    flight.operatingCarrier?.airlineCode ||
                    flight.marketingCarrier?.airlineCode
                )
                .toString()}`
          )
          .join("|")

        const oldItineraryInfo = `${reservation.flightOrigin?.locationDepart}-${
          reservation.flightOrigin?.locationArrive
        }_${reservation.flightOrigin?.scheduledDepart?.date}T${
          reservation.flightOrigin?.scheduledDepart?.time
        },${reservation.flightOrigin?.scheduledArrive?.date}T${
          reservation.flightOrigin?.scheduledArrive?.time
        }_${FARE_FAMILY_MAP[reservation.flightOrigin?.selectedFareFamily]}_${
          reservation.flightOrigin?.classOfService
        }_${
          reservation.flightOrigin?.airlineOperating ||
          reservation.flightOrigin?.airlineMarketing
        }|${reservation.flightDestination?.locationDepart}-${
          reservation.flightDestination?.locationArrive
        }_${reservation.flightDestination?.scheduledDepart?.date}T${
          reservation.flightDestination?.scheduledDepart?.time
        },${reservation.flightDestination?.scheduledArrive?.date}T${
          reservation.flightDestination?.scheduledArrive?.time
        }_${
          FARE_FAMILY_MAP[reservation.flightDestination?.selectedFareFamily]
        }_${reservation.flightDestination?.classOfService}_${
          reservation.flightDestination?.airlineOperating ||
          reservation.flightDestination?.airlineMarketing
        }`

        pushEnhancedECommerceEvent(
          "Add to cart",
          `Exchange|${payload.fareFamily?.finalTotalPrice}`,
          {
            ecommerce: {
              add: {
                products: {
                  Product: "Exchange",
                  Name: "Exchange",
                  Brand: "CM",
                  // Category1:,
                  Category2: newItineraryInfo,
                  Category3: oldItineraryInfo,
                  Position: 1,
                  price: fareFamily?.finalTotalPrice,
                  Quantity: 1,
                  metric8: fareFamily?.finalTotalPrice,
                  metric17:
                    fareFamily?.travelerFaresDetails[0]?.penalty?.amount,
                  metric18:
                    fareFamily?.travelerFaresDetails[0]?.penalty?.amount,
                  metric4: fareFamily?.totalTaxes,
                },
              },
            },
          }
        )

        pushEnhancedECommerceEvent(
          "Product Click",
          `Exchange|${payload.fareFamily?.finalTotalPrice}`,
          {
            ecommerce: {
              click: {
                products: {
                  Product: "Exchange",
                  Name: "Exchange",
                  Brand: "CM",
                  // Category1:,
                  Category2: newItineraryInfo,
                  Category3: oldItineraryInfo,
                  Position: 1,
                  price: fareFamily?.finalTotalPrice,
                  Quantity: 1,
                  metric8: fareFamily?.finalTotalPrice,
                  metric17:
                    fareFamily?.travelerFaresDetails[0]?.penalty?.amount,
                  metric18:
                    fareFamily?.travelerFaresDetails[0]?.penalty?.amount,
                  metric4: fareFamily?.totalTaxes,
                },
              },
            },
          }
        )
      }
      break
    default:
      pushAnalyticsEvent(type, payload)
  }
}
