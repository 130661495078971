import { makeStyles } from "@material-ui/core/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles(() => ({
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  spinner: {
    width: ({ width }) => width,
    height: 20,
    borderRadius: "100%",
    animation: "$spin 700ms infinite linear",
    borderTop: `2px solid ${theme.palette.transparent.main}`,
    borderLeft: ({ color }) => `2px solid ${color}`,
    borderRight: ({ color }) => `2px solid ${color}`,
    borderBottom: ({ color }) => `2px solid ${color}`,
  },
}))

export default useStyles
