import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Grid } from "@material-ui/core"
import { useFormik } from "formik"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import moment from "moment"

/**
 * @components
 */
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import SearchPanelAutocomplete from "../searchPanelAutocomplete/SearchPanelAutocomplete"
import CalendarContainer from "../../../../../../ToLibrary/bookingCalendar/calendarContainer"
import AlertText from "../alertText"
/**
 * @utils
 */
import fieldsValidation from "./validations"
import {
  airportsPropTypes,
  flightsDataPropTypes,
} from "../../../propTypesSchema"

/**
 * @icons
 */
import { ReactComponent as ArrowRight } from "../../../../../../ToLibrary/icons/Carrot-Down.svg"
import { ReactComponent as ArrowIcon } from "../../../../../../ToLibrary/icons/Arrow-Right.svg"

/**
 * @styles
 */
import useStyles from "./styles"

const DatesSelector = ({
  flightData,
  isOnlyDates,
  originAirports,
  destinationAirports,
  getChange,
  dateChange,
  isEdidt,
  checkSegment,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(`${theme.breakpoints.down("xs")}`)
  const [swapClicked, setSwapClick] = useState(false)
  const [fields, setFields] = useState(flightData)
  const [didMount, setDidMount] = useState(false)
  const [destinationAux, setDestination] = useState(
    flightData?.destination?.code
  )
  const [originAux, setOrigin] = useState(flightData?.origin?.code)
  const [checkFields, setCheckFiels] = useState(true)
  const [prevDate, setPrevDate] = useState(null)
  const [originOptions, setOriginOptions] = useState([[], []])
  const [destinationOptions, setDestinationOptions] = useState([[], []])
  const { formatMessage } = useIntl()

  const { setFieldValue, values, touched, setFieldTouched, errors } = useFormik(
    {
      initialValues: {
        area1: flightData?.origin?.code || "",
        area2: flightData?.destination?.code || "",
        date1: flightData?.date || "",
        startDate: flightData?.startDate || "",
      },
      validate: (val) => fieldsValidation(val, formatMessage),
      enableReinitialize: true,
    }
  )

  useEffect(() => {
    setFields((prevState) => {
      return { ...prevState, isCheck: flightData.isCheck }
    })
  }, [flightData.isCheck])

  // reset values of form
  const handleResetValues = () => {
    if (didMount) {
      setFieldValue("area1", flightData?.origin?.code || "")
      setFieldValue("area2", flightData?.destination?.code || "")
      setFieldValue("date1", flightData?.date || null)
      setFieldTouched("area1", false)
      setFieldTouched("area2", false)
      setFieldTouched("date1", false)
      setFields(flightData)
    }
  }

  // Sets area1 value
  const handleOriginFieldChange = (data) => {
    if (values.area2 === data) {
      setFieldValue("area2", values.area1)
    }
    setFieldValue("area1", data)
    setOrigin(data)
  }

  // Sets area2 value
  const handleDestinationFieldChange = (data) => {
    if (data.length <= 0) {
      handleResetValues()
    } else {
      setFieldValue("area2", data)
    }
  }

  // Reload if the values have not been loaded into the DOM
  const handleValidationRestar = (data) => {
    if (isEdidt && checkFields) {
      setCheckFiels(false)
    }
    if ((!touched.area2 && !touched.area1) || (isEdidt && checkFields)) {
      handleDestinationFieldChange(data)
    } else {
      setFieldValue("area2", data)
    }
  }

  // Handles any origin changes
  const handleOriginChange = (data) => {
    setFields((prevState) => ({ ...prevState, origin: data || "" }))
    getChange({ ...fields, origin: data || "" })
    handleOriginFieldChange((data && data?.code) || "")
  }

  // Handle any destination changes
  const handleDestinationChange = (data) => {
    if (
      (values.area2 === "" || values.area2 !== data?.code) &&
      data !== undefined
    ) {
      handleValidationRestar(data?.code || "")
      setFields((prevState) => ({ ...prevState, destination: data || "" }))
      setDestination(data?.code || "")
      getChange({ ...fields, destination: data || "" })
    }
  }

  // Handle startDate changes
  const handleStartDate = (prop, data) => {
    setFields((prevState) => ({ ...prevState, date: data }))
    dateChange(prop, data)
    setFieldValue("date1", data || null)
  }

  // Handles origin list for each segment
  const handleOriginAirportChanges = (id, data) => {
    // eslint-disable-next-line no-undef
    const newOptions = fill(originOptions, data, [id], [id + 1])
    setOriginOptions([...newOptions])
  }

  // Handles destination list for ecah segment
  const handleDestinationAirportChanges = (id, data) => {
    const newData = data[id].destinations
    // eslint-disable-next-line no-undef
    const newOptions = fill(destinationOptions, newData, [id], [id + 1])
    setDestinationOptions([...newOptions])
  }

  // handles date1 touched
  const handleDateTouched = () => {
    setFieldTouched("date1", true)
  }

  // handles origin touched
  const handleOriginTouched = () => {
    setFieldTouched("area1", true)
    if (originAux === "") {
      setFieldValue("area1", originAux)
    }
  }

  // handles destination touched
  const handleDestinationTouched = () => {
    setFieldTouched("area2", true)
    if (destinationAux === "") {
      setFieldValue("area2", destinationAux)
    }
  }

  const isSameDate = (date) => {
    const time1 = moment(date).format("YYYY-MM-DD")
    const time2 = moment().format("YYYY-MM-DD")
    return moment(time1).isSame(time2)
  }

  const handlePrevDate = useCallback(() => {
    if (
      moment(flightData.initialAvailableDate).format("YYYY-MM-DD") <
      moment().format("YYYY-MM-DD")
    ) {
      setPrevDate(moment().format("YYYY-MM-DD"))
    } else if (!flightData.initialAvailableDate) {
      setPrevDate(moment().add(1, "days").format("YYYY-MM-DD"))
    } else if (isSameDate(flightData.date) && flightData.index === 1) {
      const flightDate = moment(flightData.date)
        .add(1, "days")
        .format("YYYY-MM-DD")
      setPrevDate(flightDate)
    } else if (
      isSameDate(flightData.initialAvailableDate) &&
      flightData.index === 2
    ) {
      const flightDate = moment(flightData.initialAvailableDate)
        .add(1, "days")
        .format("YYYY-MM-DD")
      setPrevDate(flightDate)
    } else {
      const initialAvailableDate = moment(
        flightData.initialAvailableDate
      ).format("YYYY-MM-DD")
      setPrevDate(initialAvailableDate)
    }
  }, [flightData.date, flightData.initialAvailableDate, flightData.index])

  useEffect(handleResetValues, [isOnlyDates, flightData])

  // checks form on submit
  const checkForm = () => {}

  useEffect(() => setDidMount(true), [])

  useEffect(() => {
    handlePrevDate()
  }, [handlePrevDate])

  return (
    <>
      {(flightData.isCheck || !isMobile) && (
        <form
          data-cy="dataSelector-form"
          onSubmit={checkForm}
          className={classes.container}
        >
          <Grid data-cy="dataSelector-container" container alignItems="center">
            <Grid
              data-cy="dataSelector-item"
              item
              md={8}
              sm={12}
              xs={12}
              className={classes.dateSelectorItem}
            >
              <SearchPanelAutocomplete
                id={flightData.index}
                onOriginChange={handleOriginChange}
                onDestinationChange={handleDestinationChange}
                onOriginTouched={handleOriginTouched}
                onDestinationTouched={handleDestinationTouched}
                originFieldValue={values.area1}
                destinationFieldValue={values.area2}
                errorOriginField={errors.area1}
                errorDestinationField={errors.area2}
                touchedOrigin={touched.area1}
                touchedDestination={touched.area2}
                origin={fields.origin}
                destination={fields.destination}
                originOptions={originAirports}
                destinationOptions={
                  destinationAirports[flightData.index - 1].destinations
                }
                originAirports={originAirports}
                destinationAirports={
                  destinationAirports[flightData.index - 1].destinations
                }
                setSwap={setSwapClick}
                handleSwapChanges={() => {}}
                handleOriginClear={() => {}}
                showIcons={false}
                disableStopoversegment={!(flightData.isCheck && !isOnlyDates)}
                isOnlyDates={isOnlyDates}
                checkSegment={checkSegment}
                handleDestinationAirportChanges={
                  handleDestinationAirportChanges
                }
                handleOriginAirportChanges={handleOriginAirportChanges}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12} className={classes.calendarGrid}>
              <CalendarContainer
                id={flightData.index}
                fieldName1="date1"
                fieldName2="date2"
                typeIndex={2}
                onStartDateChange={handleStartDate}
                onEndDateChange={() => {}}
                onDateTouched={handleDateTouched}
                handleFlexibleDate={() => {}}
                errorStartDate={errors.date1}
                errorEndDate=""
                touchedStartDate={touched.date1 || values.date1 === null}
                touchedEndDate={false}
                startDateField={values.date1}
                endDateField=""
                area2={values.area2}
                date1={values.date1}
                roundtrip={values.roundtrip}
                swapped={swapClicked}
                setSwap={setSwapClick}
                prevDate={prevDate}
                finalAvailableDate={fields.finalAvailableDate}
                calendarDisable={!flightData.isCheck}
              />
            </Grid>
            {isSameDate(flightData.date) && (
              <AlertText
                message={formatMessage({
                  id: "searchPage.datesSelector.sameDay",
                })}
              />
            )}
          </Grid>
        </form>
      )}
      {!flightData.isCheck && (
        <Hidden smUp>
          <div className={classes.containerMobil}>
            <div className={classes.flightsMobil}>
              <TypographyCo data-cy="containerDateSelector-origin" variant="h3">
                {values.area1}
              </TypographyCo>
              {isOnlyDates && <ArrowIcon className={classes.arrowOnlyDate} />}
              {!isOnlyDates && <ArrowRight className={classes.arrawRight} />}
              <TypographyCo data-cy="containerDateSelector-origin" variant="h3">
                {values.area2}
              </TypographyCo>
            </div>
            <div className={classes.dateMobil}>
              <TypographyCo data-cy="containerDateSelector-origin" variant="h3">
                {moment(values.date1).format("ddd, D MMM")}
              </TypographyCo>
            </div>
          </div>
        </Hidden>
      )}
    </>
  )
}

DatesSelector.propTypes = {
  flightData: flightsDataPropTypes.isRequired,
  isOnlyDates: PropTypes.bool,
  originAirports: airportsPropTypes,
  destinationAirports: airportsPropTypes,
  getChange: PropTypes.func.isRequired,
  dateChange: PropTypes.func,
  isEdidt: PropTypes.bool.isRequired,
  checkSegment: PropTypes.func.isRequired,
}

DatesSelector.defaultProps = {
  isOnlyDates: false,
  originAirports: [],
  destinationAirports: [],
  dateChange: () => {},
}

export default DatesSelector
