import { call, put, takeEvery } from "redux-saga/effects"

import { fareRulesActions } from "../../store/fareRules/fareRulesSlice"
import { generalActions } from "../../store/general/generalSlice"
import api from "../../api"
import TYPES from "./fareRulesTypes"

export function* fetchFareRules({ payload }) {
  try {
    const { data } = yield call(api.fareRules.getFareRules, {
      payload,
    })

    yield put(fareRulesActions.setFareRulesInfo(data))
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(fareRulesActions.setFareRulesInfo({ error: true }))
  }
}

function* fareRulesSagas() {
  yield takeEvery(TYPES.FETCH_FARE_RULES, fetchFareRules)
}

export default fareRulesSagas
