import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(-90deg)" },
  },
  "@keyframes spinOpen": {
    from: { transform: "rotate(-90deg)" },
    to: { transform: "rotate(0deg)" },
  },
  root: ({ open }) => ({
    background: theme.palette.alternative.blueBackground,
    paddingBottom: 80,
    marginTop: 72,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 72,
      marginTop: 64,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 64,
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: open ? 57 : 0,
      marginTop: 56,
    },
  }),
  rootElements: ({ open }) => ({
    "&.MuiContainer-root": {
      paddingLeft: 42,
      maxWidth: 1280,
      [theme.breakpoints.down("md")]: {
        maxWidth: 1008,
        paddingLeft: 24,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 648,
        paddingLeft: 24,
        paddingBottom: open ? 57 : 0,
      },
    },
  }),
  containerText: ({ open }) => ({
    display: "flex",
    alignItems: "center",
    paddingTop: 80,
    paddingBottom: 48,
    background: theme.palette.transparent.main,
    border: "inherit",
    outline: "none",
    textAlign: "initial",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: 77,
      paddingBottom: 51,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 64,
      paddingBottom: 64,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
      paddingBottom: open ? 40 : 56,
    },
  }),
  title: {
    fontSize: 20,
    color: theme.palette.alternative.blueDark,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  arrowOpen: {
    fill: theme.palette.alternative.blueDark,
    animation: "$spinOpen 300ms linear",
  },
  arrowClose: {
    fill: theme.palette.alternative.blueDark,
    animation: "$spin 300ms linear",
    transform: "rotate(-90deg)",
  },
  item: {
    display: "flex",
    paddingBottom: 40,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  iconItem: {
    marginRight: 24,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 8,
    },
  },
  textItem: {
    color: theme.palette.grey[600],
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  containerLink: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
    textDecoration: "none",
  },
  arrowLink: {
    fill: theme.palette.primary.main,
    marginLeft: 10,
  },
  textLink: {
    color: theme.palette.alternative.blueDark,
  },
})

export default useStyles
