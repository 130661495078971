import moment from "moment"
import { setCookie, getDomain, getCookie } from "./auth"
import { redirectToMyTrips, processRedirect } from "./redirectFlow"
import FeatureFlags from "../featureFlags"

const SpanishDates = {
  monthsShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
}

const getLanguageCountry = (lang) =>
  ({
    es: "es-PA",
    en: "en-US",
    pt: "pt-BR",
    fr: "fr-CA",
  }[lang])

const LANGUAGES = [
  { id: "es", label: "ESPAÑOL", option: "Español" },
  { id: "en", label: "ENGLISH", option: "English" },
  { id: "pt", label: "PORTUGUÊS", option: "Português" },
  { id: "fr", label: "FRANCÊS", option: "Francês" },
]

export const getSupportedLanguages = () => [
  "es",
  "en",
  "pt",
  ...(FeatureFlags.ENABLE_FRENCH_TRASNLATION ? ["fr"] : []),
]

export const getLanguages = () =>
  LANGUAGES.filter((lang) => getSupportedLanguages().includes(lang.id)).reduce(
    (languages, lang) => {
      languages[lang.id] = { ...lang }
      return languages
    },
    {}
  )

const setMomentLocal = (lang) => {
  moment.updateLocale(lang, lang === "es" ? SpanishDates : {})
}

const getParameterFromCurrentURLByName = (parameter) => {
  const url = window.location.href
  const parameterCharacterEspecialEscaped = parameter.replace(/[[\]]/g, "\\$&")
  const regex = new RegExp(
    `[?&]${parameterCharacterEspecialEscaped}(=([^&#]*)|&|#|$)`
  )
  const getParamValue = regex.exec(url)

  if (!getParamValue) return null
  if (!getParamValue[2]) return ""
  return decodeURIComponent(getParamValue[2].replace(/\+/g, " "))
}

const getDate = () => moment().format("YYYY-DD-MM")

const setCookieUUID = (uuidValue) => {
  setCookie("uuid", uuidValue, 1, getDomain())
}

const setCookieAttemptsDone = (value, time = 1) => {
  setCookie("attemptsDone", value, time, getDomain())
}

const convertMinsToHrsMins = (minutes) => {
  let hour = Math.floor(minutes / 60)
  let mins = minutes % 60
  hour = hour < 10 ? `${hour}h` : `${hour}h`
  mins = mins < 10 ? `${mins}m` : `${mins}m`
  return `${hour} ${mins}`
}

const backToOriginApplication = (pnr, lastName) => {
  const getOriginUrl = getCookie("originURL")
  const getOrigin = getCookie("origin")
  if (getOriginUrl === "" || getOriginUrl === null) {
    redirectToMyTrips(pnr, lastName).runWith(window.location)
  } else if (getOrigin === "APP")
    processRedirect(getOriginUrl, pnr, lastName).runWith(window.location)
}

const sustrapElement = (offers) => {
  const newArrayOfObj = offers.map(
    ({
      newFare: totalPrice,
      totalRefund,
      taxesCharges: totalTaxes,
      originalFare: totalBookedFare,
      total: finalTotalPrice,
      ...rest
    }) => ({
      totalPrice,
      totalRefund,
      totalTaxes,
      totalBookedFare,
      finalTotalPrice,
      ...rest,
    })
  )

  return newArrayOfObj
}

const tranformFareData = (offerGroups = []) => {
  const newItem = offerGroups.map(({ offers, ...rest }) => ({
    offers: sustrapElement(offers),
    ...rest,
  }))

  return newItem
}

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

const getErrorStackTrace = (stack) =>
  stack
    .split("\n")
    .map((line) => line.trim())
    .splice(stack[0] === "Error" ? 2 : 1)
    .join(" ")

export {
  getLanguageCountry,
  setMomentLocal,
  getParameterFromCurrentURLByName,
  getDate,
  setCookieUUID,
  convertMinsToHrsMins,
  backToOriginApplication,
  tranformFareData,
  capitalize,
  getErrorStackTrace,
  setCookieAttemptsDone,
}
