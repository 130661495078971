import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  root: {
    marginTop: 57,
    marginBottom: 55,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      marginBottom: 32,
      flexDirection: "column-reverse",
    },
  },
  title: {
    color: theme.palette.alternative.blueDark,
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: -1,
    lineHeight: "40px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  ctaEdit: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.transparent.main,
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
      paddingLeft: 0,
    },
  },
  swapedIcon: {
    fill: theme.palette.alternative.blueDark,
    width: 30,
    height: 30,
    transform: "rotate(180deg)",
    marginRight: 17,
    [theme.breakpoints.down("xs")]: {
      width: 22,
      height: 20,
    },
  },
  textEdit: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "32px",
    color: theme.palette.alternative.blueDark,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
})

export default useStyles
