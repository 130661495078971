import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import moment from "moment"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { connect } from "react-redux"
import RadioBox from "../../../../../../ToLibrary/radioBox"
import Mode from "./mode"

import useStyles from "./styles"

import { ReactComponent as CalendarIcon } from "../../../../../../ToLibrary/icons/Calendar.svg"
import { ReactComponent as FlightIcon } from "../../../../../../ToLibrary/icons/Flight.svg"

const ModeSelector = ({
  handleChange,
  flightOrigin,
  flightDestination,
  tripType,
  onlyDate,
  isEditSearch,
  isChangeOfRoute,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const onlyDateGlobal = process.env.REACT_APP_ONLY_DATE.includes("true")
  const initialValue = onlyDate === "true" ? "onlyDates" : "flightAndDates"

  const modes = [
    {
      id: "onlyDates",
      value: "onlyDates",
      component: (
        <Mode
          icon={<CalendarIcon />}
          label={formatMessage({ id: "searchPage.modeSelector.option1Text" })}
          description={
            tripType === "ROUNDTRIP"
              ? formatMessage(
                  { id: "searchPage.modeSelector.option1Info" },
                  {
                    departureDate: moment(
                      flightOrigin?.scheduledDepart?.date
                    ).format("DD MMM"),
                    arrivalDate: moment(
                      flightDestination?.scheduledDepart?.date
                    ).format("DD MMM"),
                  }
                )
              : formatMessage(
                  { id: "searchPage.modeSelector.option3Info" },
                  {
                    departureDate: moment(
                      flightOrigin?.scheduledDepart?.date
                    ).format("DD MMM"),
                  }
                )
          }
        />
      ),
      wcag: formatMessage({ id: "searchPage.modeSelectorWCAG.changeDates" }),
      dataCy: "radio-box-change-dates",
    },
  ]

  if (isChangeOfRoute)
    modes.push({
      id: "flightAndDates",
      value: "flightAndDates",
      component: (
        <Mode
          icon={<FlightIcon />}
          label={formatMessage({ id: "searchPage.modeSelector.option2Text" })}
          description={
            tripType === "ROUNDTRIP"
              ? formatMessage(
                  { id: "searchPage.modeSelector.option2Info" },
                  {
                    departureCode: flightOrigin?.locationDepart,
                    arrivalCode: flightDestination?.locationDepart,
                    departureDate: moment(
                      flightOrigin?.scheduledDepart?.date
                    ).format("DD MMM"),
                    arrivalDate: moment(
                      flightDestination?.scheduledDepart?.date
                    ).format("DD MMM"),
                  }
                )
              : formatMessage(
                  { id: "searchPage.modeSelector.option4Info" },
                  {
                    departureCode: flightOrigin?.locationDepart,
                    arrivalCode: flightDestination?.locationArrive,
                    departureDate: moment(
                      flightOrigin?.scheduledDepart?.date
                    ).format("DD MMM"),
                  }
                )
          }
        />
      ),
      wcag: formatMessage({ id: "searchPage.modeSelectorWCAG.changeRoutes" }),
      dataCy: "radio-box-change-routes",
    })

  const finalModes = onlyDateGlobal ? modes.splice(0, 1) : modes
  return (
    <>
      <TypographyCo variant="h3" className={classes.title}>
        {formatMessage({ id: "searchPage.modeSelector.title" })}
      </TypographyCo>
      <RadioBox
        handleChange={(value) => handleChange(value === "onlyDates")}
        initialValue={isEditSearch ? initialValue : null}
      >
        {finalModes}
      </RadioBox>
    </>
  )
}

const mapStateToProps = ({ reservation, flight }) => ({
  flightOrigin: reservation.flightOrigin,
  flightDestination: reservation.flightDestination,
  tripType: reservation.ticket.tripType,
  onlyDate: flight.onlyDate,
})

const mapDispatchToProps = () => ({})

ModeSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  flightOrigin: PropTypes.objectOf(PropTypes.any).isRequired,
  flightDestination: PropTypes.objectOf(PropTypes.any).isRequired,
  tripType: PropTypes.string,
  onlyDate: PropTypes.string,
  isEditSearch: PropTypes.bool.isRequired,
  isChangeOfRoute: PropTypes.bool.isRequired,
}

ModeSelector.defaultProps = {
  onlyDate: "",
  tripType: "",
}

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelector)
