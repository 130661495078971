/* eslint-disable no-plusplus */
import moment from "moment"

export const FARE_FAMILY_CATEGORY = {
  BAS: "basic",
  CLS: "classic",
  CLF: "flex",
  EFU: "full",
  PRO: "businessPromo",
  BFU: "businessFull",
}

const CABIN_DICTIONARY = {
  A: "Y",
  T: "Y",
  L: "Y",
  E: "Y",
  W: "Y",
  O: "Y",
  S: "Y",
  U: "Y",
  V: "Y",
  K: "Y",
  Q: "Y",
  H: "Y",
  M: "Y",
  B: "Y",
  Y: "Y",
  R: "C",
  D: "C",
  J: "C",
  C: "C",
  X: "Y",
  N: "Y",
  G: "Y",
  I: "C",
  P: "C",
}

export const getCabinFromClassofService = (classOfService) => {
  return CABIN_DICTIONARY[classOfService]
}

export const getTravelers = (passengers = []) => {
  const newPassengers = []

  let count = 0
  passengers.forEach((item) => {
    if (item.typeCode !== "INF") {
      count++
    }

    newPassengers.push({
      dateOfBirth: item.dateOfBirth,
      givenname: item.firstName,
      gender: item.gender === "Female" ? "F" : "M",
      id:
        item.typeCode === "INF" ? `${count.toString()}.${1}` : count.toString(),
      surname: item.surname,
      paxType: item.typeCode,
    })
  })
  return newPassengers
}

const findCity = (codeDestinarion, originAirports) =>
  originAirports.find((airport) => airport.code === codeDestinarion)

export const formatData = (
  ticket,
  flightDestination,
  flightOrigin,
  originAirports,
  reEntry
) => {
  const originDestination = flightDestination
  const firstFlight = flightOrigin

  if (ticket?.tripType === "ROUNDTRIP") {
    return [
      {
        origin: {
          code: firstFlight?.locationDepart,
          city: findCity(firstFlight?.locationDepart, originAirports)?.city,
        },
        destination: {
          code: originDestination?.locationDepart,
          city: findCity(originDestination?.locationDepart, originAirports)
            ?.city,
        },
        date: firstFlight?.scheduledDepart?.date,
        startDate: moment().format("YYYY-MM-DD"),
        initialAvailableDate: ticket?.initialAvailableDate,
        finalAvailableDate: ticket?.finalAvailableDate,
        isCheck: reEntry?.isCheck1 ? reEntry?.isCheck1 : false,
        index: 1,
      },
      {
        origin: {
          code: originDestination?.locationDepart,
          city: findCity(originDestination?.locationDepart, originAirports)
            ?.city,
        },
        destination: {
          code: firstFlight?.locationDepart,
          city: findCity(firstFlight?.locationDepart, originAirports)?.city,
        },
        date: originDestination?.scheduledDepart?.date,
        startDate: firstFlight?.scheduledDepart?.date,
        initialAvailableDate: firstFlight?.scheduledDepart?.date,
        finalAvailableDate: ticket?.finalAvailableDate,
        isCheck: reEntry?.isCheck2 ? reEntry?.isCheck2 : false,
        index: 2,
      },
    ]
  }
  return [
    {
      origin: {
        code: firstFlight?.locationDepart,
        city: findCity(firstFlight?.locationDepart, originAirports)?.city,
      },
      destination: {
        code: originDestination?.locationArrive,
        city: findCity(originDestination?.locationArrive, originAirports)?.city,
      },
      date: firstFlight?.scheduledDepart?.date,
      startDate: moment().format("YYYY-MM-DD"),
      initialAvailableDate: ticket?.initialAvailableDate,
      finalAvailableDate: ticket?.finalAvailableDate,
      isCheck: !!reEntry,
      index: 1,
    },
  ]
}

export const formatDataReEntry = (ods, originAirports, reEntry) => {
  const originDestinations = ods.map((flight, index) => ({
    origin: {
      code: flight?.departure,
      city: findCity(flight?.departure, originAirports)?.city,
    },
    destination: {
      code: flight?.arrival,
      city: findCity(flight?.arrival, originAirports)?.city,
    },
    date: flight?.date,
    startDate: index === 0 ? moment().format("YYYY-MM-DD") : ods[0]?.date,
    isCheck: index === 0 ? reEntry?.isCheck1 : reEntry?.isCheck2,
    index: index + 1,
  }))
  return originDestinations
}

export const getRetainedItinerary = (newSearch, ticket) => {
  // find the OD that has not been selected
  const notSeletect = newSearch.find((flight) => flight.isCheck === false)
  // validate the position of OD for take the first or lasts
  if (notSeletect === undefined) {
    return null
  }
  const reservation =
    notSeletect?.index === 1
      ? ticket.flights.slice(0, ticket.flights.length / 2)
      : ticket.flights.slice(ticket.flights.length / 2, ticket.flights.length)

  const retainedItinerary = {
    flight: reservation.map((flight) => ({
      departure: {
        airportCode: flight.locationDepart,
        date: flight.scheduledDepart.date,
        time: flight.scheduledDepart.time,
      },
      arrival: {
        airportCode: flight.locationArrive,
      },
      airlineCode: flight.airlineMarketing,
      flightNumber: flight.flightNumber,
      classOfService: flight.classOfService,
      cabin: getCabinFromClassofService(flight.classOfService),
    })),
  }
  return { retainedItinerary }
}

export const getODs = (newSearch) => {
  // filter the OD that has been selected
  const selected = newSearch.filter((flight) => flight.isCheck === true)

  const originDestinations = selected.map((flight) => ({
    departure: flight.origin.code,
    arrival: flight.destination.code,
    date: flight.date,
  }))

  return originDestinations
}
