import { useState, useEffect } from "react"
import moment from "moment"

const useCountDownTimer = (expTime, minutesToDownTime = 5, delay = 1000) => {
  const [timer, setTimer] = useState({ isDownTime: false, isEndTime: false })

  useEffect(() => {
    const idTimer = setInterval(() => {
      let duration = moment.duration(moment(expTime).diff(moment()))
      duration = moment.duration(duration.asSeconds() - 1, "seconds")
      const min = duration.minutes()
      const sec = duration.seconds()
      const isDownTime =
        (min === minutesToDownTime && sec === 0) || min < minutesToDownTime
      const isEndTime = min <= 0 && sec <= 0
      setTimer({ min, sec, isDownTime, isEndTime })

      if (min <= 0 && sec <= 0) {
        clearInterval(idTimer)
      }
    }, delay)
    return () => clearInterval(idTimer)
  }, [expTime, delay, minutesToDownTime])

  return timer
}

export default useCountDownTimer
