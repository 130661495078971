import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  iconContainer: {
    textAlign: "center",
  },
  icon: {
    width: 48,
    height: 48,
  },
  caption: {
    color: theme.palette.grey["600"],
  },
  title: {
    marginTop: 39,
    marginBottom: 32,
  },
  box: {
    height: "100%",
    paddingTop: 8,
  },
})

export default useStyles
