import { makeStyles, createStyles } from "@material-ui/core/styles"

const useContentStyle = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      background: theme.palette.gradients[100],
      position: "relative",
      "& > div": {
        width: 400,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      "& .gray--text, a": {
        color: theme.palette.grey[300],
        fontSize: theme.typography.body2,
      },
      "& form .MuiTypography-body2": {
        textAlign: "left",
        color: theme.palette.common.white,
      },
      "& .logo-connect-miles": {
        width: 150,
        marginTop: 20,
        marginBottom: 20,
      },
      "& .forgotPass--container": {
        position: "relative",
        paddingTop: 20,
        "& > a": {
          position: "absolute",
          top: 18,
          right: 0,
        },
      },
      "& button": {
        width: "100%",
        marginBottom: 30,
        marginTop: 20,
      },
      "& Label.MuiFormLabel-root": {
        color: theme.palette.common.white,
        textAlign: "left",
        lineHeight: "initial",
        fontSize: theme.typography.body2.fontSize,
      },
      "& .MuiFormControl-root": {
        width: "100%",
        marginTop: 5,
        height: 55,
        "&>.MuiFilledInput-root": {
          background: theme.palette.common.white,
          borderRadius: 5,
        },
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.warning.main,
        marginTop: 5,
        "& svg": {
          marginRight: 5,
          fontSize: "0.7rem",
        },
        "&.Mui-error": {
          color: theme.palette.warning.main,
        },
      },
      "& input": {
        padding: "15px 10px",
        borderRadius: 5,
        width: "100%",
        boxShadow: "0 0 0 30px white inset !important",
      },
      "& .Mui-error": {
        "& input": {
          borderColor: theme.palette.warning.main,
          borderWidth: 2,
          borderStyle: "solid",
        },
        "&:after": {
          content: "none",
        },
        "&:before": {
          content: "none",
        },
      },
      "& #message-alert": {
        marginBottom: 20,
        textAlign: "left",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        paddingTop: 32,
        "& > div": {
          margin: "0 auto",
          height: "100%",
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "stretch",
        height: "auto !important",
        minHeight: "100%",
        margin: 0,
      },
    },
  })
)

export default useContentStyle
