import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  container: ({ maxWidthContainer }) => ({
    display: "flex",
    padding: "40px 0",
    justifyContent: "space-between",
    maxWidth: maxWidthContainer,
    [theme.breakpoints.down("md")]: {
      maxWidth: 960,
      padding: "32px 0",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 648,
      padding: "24px 0",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }),
  border: ({ noBorderBottom }) => ({
    borderBottom: !noBorderBottom
      ? `1px solid ${theme.palette.grey["300"]}`
      : "inherit",
    paddingRight: 0,
    paddingLeft: 0,
  }),
  shadow: {
    boxShadow: theme.palette.shadows.medium,
  },
  content: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 30,
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 30,
      flexDirection: "row",
    },
  },
  itinerary: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  contentSummary: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
    "&.contentSummaryModal": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
      [theme.breakpoints.up("md")]: {
        width: "80%",
      },
    },
  },
  generalFlex: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTypography-root": {
        fontSize: "0.75rem",
      },
    },
  },
  contentActions: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  contentRules: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 20,
    },
  },
  link: {
    minWidth: "inherit",
    padding: 4,
  },
  linkColor: {
    color: theme.palette.primary.light,
  },
  viewMore: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "initial",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  svgIcon: {
    color: theme.palette.grey["400"],
    margin: "0 34px",
    [theme.breakpoints.down("md")]: {
      margin: "0 11px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 30px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 16px",
      flex: 1,
    },
  },
  firstLineText: {
    marginLeft: 14,
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey["600"],
    [theme.breakpoints.up("md")]: {
      flexShrink: 0,
    },
  },
  button: {
    alignSelf: "center",
    padding: "4px 16px",
    height: 24,
    [theme.breakpoints.down("md")]: {
      marginRight: 10,
    },
    [theme.breakpoints.down("xs")]: {
      alignSelf: "auto",
      margin: "0px 10px 0px 10px",
    },
  },
  textItinerary: {
    color: theme.palette.grey["700"],
    [theme.breakpoints.down("xs")]: {
      flex: 2,
    },
  },
  flightIcon: {
    fontSize: 18,
    marginRight: 5,
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "flex-start",
      marginTop: 2,
    },
  },
  hour: {
    fontWeight: 500,
    "&::before": {
      content: '" · "',
      [theme.breakpoints.down("sm")]: {
        content: '" "',
      },
    },
  },
  classOfServiceFormat: {
    marginLeft: "5px",
    color: theme.palette.primary.light,
    "& > button": {
      padding: "0px",
    },
  },
  stopOverStyle: {
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 15,
    },
  },
  stopOver: {
    color: theme.palette.primary.main,
    marginLeft: 27,
    [theme.breakpoints.down("md")]: {
      marginLeft: 27,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 19,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -1,
    },
  },
  mapPinIcon: {
    marginBottom: -3,
    [theme.breakpoints.down("md")]: {
      marginBottom: -1,
      height: 12,
      with: 10,
    },
    [theme.breakpoints.down("sm")]: {
      height: 12,
      with: 10,
    },
    [theme.breakpoints.down("xs")]: {
      height: 12,
      with: 8.57,
    },
  },
  iconAdd: {
    fill: theme.palette.secondary.dark,
    width: 14,
    height: 16,
    marginRight: 4,
  },
  dividerHalf: {
    width: "100%",
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 15,
    },
  },
  fareFamilyName: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "initial",
    },
  },
}))

export default useStyles
