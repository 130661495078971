import React from "react"
import { useIntl } from "react-intl"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { useHistory } from "react-router-dom"
import useStyles from "./styles"
import { ReactComponent as SwapIcon } from "../../../../../ToLibrary/icons/Arrow-Right.svg"

const EditSearch = () => {
  const { formatMessage } = useIntl()
  const { goForward } = useHistory()
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      data-cy="editSearch"
      aria-label={formatMessage({ id: "searchPage.editHeading.wcagContent" })}
    >
      <TypographyCo data-cy="editSearch-title" className={classes.title}>
        {formatMessage({ id: "searchPage.editHeading.title" })}
      </TypographyCo>
      <button
        className={classes.ctaEdit}
        type="button"
        data-cy="editSearch-bton"
        onClick={() => {
          goForward()
        }}
        aria-label={formatMessage({
          id: "searchPage.editHeading.wcagBton",
        })}
      >
        <SwapIcon className={classes.swapedIcon} />
        <TypographyCo
          data-cy="editSearch-btonTitle"
          className={classes.textEdit}
        >
          {formatMessage({ id: "searchPage.editHeading.ctaEdit" })}
        </TypographyCo>
      </button>
    </div>
  )
}

export default EditSearch
