/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

class Seo extends React.Component {
  componentDidMount() {
    // When the component mounts, set focus onto the container in order
    if (this.container) {
      this.container.focus()
    }
  }

  render() {
    const {
      children,
      ariaText,
      metaTypeSEO,
      id,
      lang,
      title,
      keywords,
      meta,
    } = this.props
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | Copa Airlines `}
          meta={[
            {
              name: `description`,
              content: metaTypeSEO,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaTypeSEO,
            },
          ]
            .concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                : []
            )
            .concat(meta)}
        />

        <div
          id={id}
          tabIndex="0"
          aria-label={ariaText}
          ref={(container) => {
            this.container = container
          }}
          role="contentinfo"
        >
          {children}
        </div>
      </>
    )
  }
}

Seo.propTypes = {
  children: PropTypes.node.isRequired,
  ariaText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.shape),
  keywords: PropTypes.arrayOf(PropTypes.string),
  metaTypeSEO: PropTypes.string,
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: ["flights", "copaAir"],
  metaTypeSEO: null,
}

const mapStateToProps = ({ general }) => ({
  lang: general.lang,
})

export default connect(mapStateToProps)(Seo)
