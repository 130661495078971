import { createSlice } from "@reduxjs/toolkit"
import initialState from "./sessionInitialState"
import {
  getConfRespCookie,
  getAprovalNumber,
  getInstallmentsCookie,
} from "../../utils/confirmationUtils"

const flightSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    fetchSession: (state) => {
      state.fetching = true
      state.error = ""
      state.successful = false
    },
    setSession: (state) => {
      // const { payload } = action // use it when back confirm
      state.fetching = false
      state.error = ""
      state.successful = true
    },
    errorSession: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = payload
      state.successful = false
    },
    fetchSessionInformation: (state) => {
      state.fetching = true
      state.error = ""
    },
    setConfirmationSession: (state, action) => {
      const { payload } = action
      state.sessionInfo = payload
      state.fetching = false
      state.error = ""
    },
    resetSession: (state) => {
      state.fetching = false
      state.error = ""
      state.successful = false
    },
    getConfResp: (state, { payload }) => ({
      ...state,
      confResp: getConfRespCookie(payload),
    }),
    getAprovalNumber: (state, { payload }) => ({
      ...state,
      aprovalNumber: getAprovalNumber(payload),
    }),
    getPaymentsInfo: (state, { payload }) => {
      state.paymentsInfo = getInstallmentsCookie(payload)
    },
  },
})

export default flightSlice.reducer

export const { actions: sessionActions } = flightSlice
