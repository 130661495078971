import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
// import LogRocket from "logrocket"

import { handleAnalyticsEvent } from "../utils/analytics"
import saga from "../sagas"
import general from "./general/generalSlice"
import auth from "./auth/authSlice"
import airports from "./airports/airportsSlice"
import flight from "./flight/flightSlice"
import reservation from "./reservation/reservationSlice"
import session from "./session/sessionSlice"
import fareRules from "./fareRules/fareRulesSlice"

const reducer = {
  general,
  auth,
  airports,
  flight,
  reservation,
  session,
  fareRules,
}

const analytics = (store) => (next) => (action) => {
  const result = next(action)
  try {
    if (window.self === window.top)
      handleAnalyticsEvent(store.getState(), action)
  } catch (error) {
    return error
  }
  return result
}

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: { ignoredActions: ["general/logError"] },
  }),
  // LogRocket.reduxMiddleware(),
  analytics,
  sagaMiddleware,
]

const store = configureStore({
  reducer,
  middleware,
})

sagaMiddleware.run(saga)

export default store
