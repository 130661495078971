import { createSlice } from "@reduxjs/toolkit"

import initialState from "./fareRulesInitialState"

const fareRulesSlice = createSlice({
  name: "fareRules",
  initialState,
  reducers: {
    loadFareRulesInfo: (state) => {
      state.airlineCode = ""
      state.flightNumber = ""
      state.rules = ""
    },
    setFareRulesInfo: (state, { payload }) => {
      const { airlineCode, flightNumber, fareRules, error } = payload

      if (!error) {
        state.airlineCode = airlineCode
        state.flightNumber = flightNumber
        state.error = false
        state.rules =
          fareRules &&
          fareRules
            .map((info) =>
              info.texts
                .map((text) => {
                  if (text.split("").every((dash) => dash === "-")) {
                    return "<br /><br />"
                  }
                  return text.concat("<br />")
                })
                .join("")
            )
            .join()
      } else {
        state.error = error
      }
    },
  },
})

export default fareRulesSlice.reducer

export const { actions: fareRulesActions } = fareRulesSlice
