import React from "react"
import PropTypes from "prop-types"
import Lottie from "react-lottie"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    zIndex: -1,
  },
}))

const AnimationDisplayer = ({
  config,
  autoplay,
  loop,
  wcagDescription,
  ...props
}) => {
  const defaultOptions = {
    autoplay,
    loop,
    animationData: config,
  }
  const classes = useStyles()
  return (
    <div role="alert" aria-live="assertive">
      <span className={classes.root}>{wcagDescription}</span>
      <Lottie options={defaultOptions} {...props} />
    </div>
  )
}

AnimationDisplayer.propTypes = {
  config: PropTypes.shape().isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  wcagDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

AnimationDisplayer.defaultProps = {
  autoplay: true,
  loop: true,
  wcagDescription: "",
}

export default AnimationDisplayer
