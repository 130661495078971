import { useState, useEffect, useCallback } from "react"

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

const useObserveWindowResize = (onClose) => {
  const [currentWidth] = useState(getWidth())

  useEffect(() => {
    const resizeListener = () => {
      if (currentWidth > 960 && getWidth() < 960) {
        onClose()
      }
      if (currentWidth < 958 && getWidth() > 958) {
        onClose()
      }
    }
    window.addEventListener("resize", resizeListener)
    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [currentWidth, onClose])
}

const useActiveFocusElement = () =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback((element) => {
    if (element !== null) {
      setTimeout(() => {
        element.focus()
      }, 0)
    }
  })

export { useObserveWindowResize, useActiveFocusElement }
