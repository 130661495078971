import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  /** Styles general */
  groupContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  box: {
    height: "100%",
  },
  boxContainer: {
    width: 255,
    minHeight: 144,
    border: `solid 1px ${theme.palette.grey["300"]}`,
    borderRadius: 4,
    position: "relative",
    marginRight: 24,
    padding: 16,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      width: 245,
    },
    [theme.breakpoints.down("sm")]: {
      width: 292,
    },
    [theme.breakpoints.down("xs")]: {
      width: 181,
      minHeight: 184,
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& .MuiRadio-root": {
        borderColor: theme.palette.primary.main,
      },
    },
    "&.active": {
      boxSizing: "border-box",
      borderColor: "rgba(0, 96, 169, 0.5)",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  control: {
    position: "absolute",
    top: 8,
    left: 3.5,
    "& .MuiFormControlLabel-root": {
      marginRight: -16,
      marginTop: -16,
    },
  },
})

export default useStyles
