import { call, select, put, takeEvery } from "redux-saga/effects"

import { generalActions } from "../../store/general/generalSlice"
import { reservationActions } from "../../store/reservation/reservationSlice"
import { airportsActions } from "../../store/airports/airportsSlice"
import { flightsActions } from "../../store/flight/flightSlice"

import { tranformFareData } from "../../utils/generalUtils"
import { setLogRocketID } from "../../utils/checkoutUtils"
import {
  getTravelers,
  getRetainedItinerary,
  getODs,
} from "../../utils/resultPageUtils"

import Api from "../../api"
import TYPES from "./reservationTypes"
import { originsSelector } from "../../store/airports/airportsUtils"
import { saveSearch } from "../../store/flight/flightUtils"
import { ticket } from "../../store/reservation/reservationUtils"
import { pushMasterDataLayer } from "../../utils/analytics"
import { languageSelector } from "../../store/general/generalUtils"
import { getDomain, setCookie } from "../../utils/auth"

export function* fetchReservationSagas({ payload }) {
  try {
    yield put(generalActions.startLoading(true))
    const air = yield call(Api.airports.origins)
    yield put(airportsActions.setOrigins(air.data))
    const airports = yield select(originsSelector)

    const reservaResult = yield call(
      Api.reservation.getEligibility,
      payload.pnr
    )
    yield put(reservationActions.setEligibility(reservaResult.data))

    const { data } = yield call(Api.reservation.getReservation, payload)
    yield put(reservationActions.setReservation({ data, airports }))

    const lang = yield select(languageSelector)
    pushMasterDataLayer(data, lang)
    setLogRocketID(data.passengers, data.pnr)
    yield put(generalActions.setCurrency(data.currency || ""))

    setCookie("currentPos", `CM${data.storefront || ""}`, 3, getDomain())
    yield put(generalActions.stopLoading())
  } catch (e) {
    const errorObject = {
      pnr: payload.pnr,
      lastName: payload.lastName,
      status: e?.request?.status,
    }
    yield put(generalActions.handleError(errorObject))
    yield put(generalActions.logError(e))
    yield put(reservationActions.errorReservation())
    yield put(generalActions.stopLoading())
  }
}

export function* fetchAllReservationSagas({ payload }) {
  try {
    yield put(generalActions.startLoading(true))
    const air = yield call(Api.airports.origins)
    yield put(airportsActions.setOrigins(air.data))
    const airports = yield select(originsSelector)
    const farefamilySelect = []
    const { data } = yield call(Api.reservation.getReservation, payload)
    data.flights.map((flight) => {
      farefamilySelect.push(flight.selectedFareFamily)
      return flight
    })
    yield put(reservationActions.setReservation({ data, airports }))

    yield put(generalActions.setCurrency(data.currency || ""))
    setCookie("currentPos", `CM${data.storefront || ""}`, 3, getDomain())

    const reservaResult = yield call(
      Api.reservation.getEligibility,
      payload.pnr
    )

    yield put(reservationActions.setEligibility(reservaResult.data))
    const saveSearchObject = yield select(saveSearch)
    const ticketObject = yield select(ticket)
    const retainedItinerary = getRetainedItinerary(
      saveSearchObject,
      ticketObject
    )
    const originDestinations = getODs(saveSearchObject)
    const dataReshop = {
      pnr: data.pnr,
      starterLastName: payload.lastName,
      cityCode: data.cityCode,
      currencyCode: data.currency,
      countryCode: data.countryCode,
      travelers: getTravelers(data.passengers),
      selectedFareFamily: farefamilySelect,
      originDestinations,
      ...(retainedItinerary !== null && retainedItinerary),
    }

    const responseRe = yield call(Api.flights.getFlights, dataReshop)

    const offerGroups = tranformFareData(responseRe.data.offerGroups)
    responseRe.data.offerGroups = offerGroups

    yield put(flightsActions.setFlights(responseRe.data))

    yield put(generalActions.stopLoading())
  } catch (e) {
    yield put(flightsActions.setErrorRedirect(e.response?.status || 0))
    const errorObject = {
      pnr: payload.pnr,
      lastName: payload.lastName,
      status: e?.request?.status,
    }
    yield put(generalActions.handleError(errorObject))
    yield put(generalActions.logError(e))
    yield put(reservationActions.errorReservation())
    yield put(generalActions.stopLoading())
  }
}

export function* fetchEligibilitySagas({ payload }) {
  try {
    yield put(generalActions.startLoading(true))
    const { data } = yield call(Api.reservation.getEligibility, payload)
    yield put(reservationActions.setEligibility(data))
    yield put(generalActions.stopLoading())
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(reservationActions.errorEgilibility())
    yield put(generalActions.stopLoading())
  }
}

export function* fetchReservationChange() {
  try {
    yield put(generalActions.startLoading(true))
    const ticketObject = yield select(ticket)
    yield call(Api.reservation.reservationChange, ticketObject.pnr)
    yield put(reservationActions.setReservationChange())
    yield put(generalActions.stopLoading())
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(reservationActions.errorReservationChange(e?.response?.data))
    yield put(generalActions.stopLoading())

    // US 164519: error code 2050 -> unable to sell one or more segments into the reservation
    switch (Number(e?.response?.data?.code)) {
      case 2050:
        yield put(reservationActions.setShouldDisplayNoItineraryModal(true))
        break
      default: {
        yield put(generalActions.setFatalErrorRedirect(true))
        break
      }
    }
  }
}

function* reservationSagas() {
  yield window.location.pathname !== "/notEligible" &&
    takeEvery(TYPES.FETCH_RESERVATION, fetchReservationSagas)
  yield takeEvery(TYPES.FETCH_EGILIBILITY, fetchEligibilitySagas)
  yield takeEvery(TYPES.FETCH_ALLRESERVATION, fetchAllReservationSagas)
  yield takeEvery(TYPES.FETCH_RESERVATION_CHANGE, fetchReservationChange)
}

export default reservationSagas
