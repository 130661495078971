import React, { useEffect, cloneElement } from "react"
import { connect } from "react-redux"
import { useIntl } from "react-intl"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"
import Popover from "@material-ui/core/Popover"
import PropTypes from "prop-types"
import Backdrop from "@material-ui/core/Backdrop"
import { generalActions } from "../../store/general/generalSlice"

import PopperCo from "../Popper/popperCo"
import { ReactComponent as Close } from "../icons/Cross.svg"

import useStyleSmallModal from "./style"

const handleKeyDown = (event, onClose, open) => {
  if (event.key !== "Escape" || !open) {
    return
  }
  document
    .querySelectorAll("[menu-modal-open=true]")[0]
    .setAttribute("menu-modal-open", false)
  onClose()
}
const LargeModal = ({ modalContent, userData, isLoginBox, ...props }) => {
  const { open, onClose } = props

  return (
    <>
      <Backdrop open={open} onClick={onClose} style={{ height: "100vh" }} />
      <PopperCo
        id="customized-menu"
        style={{ marginTop: isLoginBox && "38px" }}
        color="primary"
        arrow
        {...props}
        placement="bottom-end"
        isTrapEnabled={() => true}
        onKeyDown={(event) => handleKeyDown(event, onClose, open)}
        shine
      >
        {React.cloneElement(modalContent, { userData })}
      </PopperCo>
    </>
  )
}

const SmallModal = ({ modalContent, userData, isLoginBox, ...props }) => {
  const classes = useStyleSmallModal()
  const { formatMessage } = useIntl()
  const { onClose } = props
  return (
    <Popover
      id="customized-menu"
      className={classes.menuRoot}
      transitionDuration={0}
      marginThreshold={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    >
      <button
        type="button"
        className={classes.closeButton}
        data-loginbox={isLoginBox}
        onClick={onClose}
        aria-label={formatMessage({
          id: "resultPage.cardsResult.headerWCAG.loginBox.Close",
        })}
      >
        <Close className={classes.iconClose} fill="white" />
      </button>
      {React.cloneElement(modalContent, { small: true, userData })}
    </Popover>
  )
}
const MembersMenuModal = ({
  width,
  children,
  modalContent,
  isOpenLoginBox,
  refElement,
  isLoginBox,
  setLoginBox,
  ...props
}) => {
  const handleCloseMembersModal = () => {
    setLoginBox(!isOpenLoginBox)
  }

  useEffect(() => {
    const cloneRef = refElement
    if (isOpenLoginBox) {
      const parentDiv = cloneRef.current.parentElement.parentElement
      parentDiv.setAttribute("menu-modal-open", true)
    }
    return () => {
      if (cloneRef.current) {
        cloneRef.current.parentElement.parentElement.setAttribute(
          "menu-modal-open",
          false
        )
      }
    }
  }, [isOpenLoginBox, refElement])

  return (
    <>
      {cloneElement(children)}
      {isWidthDown("sm", width) ? (
        <SmallModal
          anchorEl={refElement.current}
          open={isOpenLoginBox}
          onClose={handleCloseMembersModal}
          modalContent={modalContent}
          isLoginBox={isLoginBox}
          {...props}
        />
      ) : (
        <LargeModal
          anchorEl={refElement.current}
          open={isOpenLoginBox}
          modalContent={modalContent}
          onClose={handleCloseMembersModal}
          isLoginBox={isLoginBox}
          {...props}
        />
      )}
    </>
  )
}

LargeModal.propTypes = {
  modalContent: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    userName: PropTypes.string,
    miles: PropTypes.string,
  }),
  isLoginBox: PropTypes.bool,
}

LargeModal.defaultProps = {
  userData: {
    userName: "",
    miles: "",
  },
  isLoginBox: false,
}

SmallModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalContent: PropTypes.node.isRequired,
  userData: PropTypes.shape({
    userName: PropTypes.string,
    miles: PropTypes.string,
  }),
  isLoginBox: PropTypes.bool,
}

SmallModal.defaultProps = {
  userData: {
    userName: "",
    miles: "",
  },
  isLoginBox: false,
}

MembersMenuModal.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  modalContent: PropTypes.node.isRequired,
  isLoginBox: PropTypes.bool,
  isOpenLoginBox: PropTypes.bool.isRequired,
  setLoginBox: PropTypes.func.isRequired,
  refElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
}

MembersMenuModal.defaultProps = {
  width: "100",
  isLoginBox: false,
}

const mapStateToProps = (state) => ({
  isOpenLoginBox: state.general.openLoginBox,
})

const mapDispatchToProps = (dispatch) => ({
  setLoginBox: (box) => dispatch(generalActions.setModalMemberLogin(box)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(MembersMenuModal))
