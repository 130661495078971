import { makeStyles } from "@material-ui/core/styles"
import palette from "@bit/vibenitez.copa-components-library.theme/palette"

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10000,
    backgroundColor: palette.common.white,
  },
  interstitial: {
    maxWidth: 588,
    [theme.breakpoints.down("xs")]: {
      padding: "32px 16px",
    },
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    padding: "32px 0",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
  },
  logo: {
    width: 177,
    height: 30,
  },
  title: {
    marginBottom: 8,
    lineHeight: "24px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  description: {
    color: palette.grey["700"],
    fontSize: 14,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  box: {
    borderRadius: 8,
    border: `solid 1px ${palette.grey["200"]}`,
    padding: 24,
    marginBottom: 24,
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      padding: 16,
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  loadingContainer: {
    marginBottom: 24,
  },
  loading: {
    color: palette.grey["600"],
    fontSize: 16,
    fontWeight: 500,
    marginTop: 13,
  },
  imageBanner: {
    marginRight: 40,
    [theme.breakpoints.down("md")]: {
      marginRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      display: "flex",
      justifyContent: "center",
      marginBottom: 16,
    },
  },
  imageBannerContent: {
    width: 128,
    [theme.breakpoints.down("md")]: {
      width: 88,
      height: 88,
    },
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
  animationContainer: {
    padding: "0 69px",
    [theme.breakpoints.down("md")]: {
      padding: "0 120px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
}))

export default useStyles
