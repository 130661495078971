import isNaN from "lodash/isNaN"
import parseInt from "lodash/parseInt"
import isEmpty from "lodash/isEmpty"

const formatPrice = (num, separator = ".") => {
  let number = String(parseInt(num, 10))
  number = number.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return number
}

const getCurrencyOption = (currency) => {
  const currencyOptions = {
    ARS: {
      thousand: ".",
      decimal: ",",
      centsUnit: 2,
      accountSymbol: "ARS",
      noValid: false,
    },
    BRL: {
      thousand: ".",
      decimal: ",",
      centsUnit: 2,
      accountSymbol: "BRL",
      noValid: false,
    },
    CAD: {
      thousand: ",",
      decimal: ".",
      centsUnit: 2,
      accountSymbol: "CAD",
      noValid: false,
    },
    COP: {
      thousand: ".",
      decimal: null,
      centsUnit: 0,
      accountSymbol: "COP",
      noValid: false,
    },
    MXN: {
      thousand: ",",
      decimal: ".",
      centsUnit: 0,
      accountSymbol: "MXN",
      noValid: false,
    },
    USD: {
      thousand: ",",
      decimal: ".",
      centsUnit: 2,
      accountSymbol: "USD",
      noValid: false,
    },
    CLP: {
      thousand: ".",
      decimal: ".",
      centsUnit: 0,
      accountSymbol: "CLP",
      noValid: false,
    },
    CRC: {
      thousand: ",",
      decimal: ",",
      centsUnit: 0,
      accountSymbol: "CRC",
      noValid: false,
    },
  }
  return isEmpty(currencyOptions[currency])
    ? {
        thousand: ",",
        decimal: ".",
        centsUnit: 2,
        accountSymbol: "USD",
        noValid: true,
      }
    : currencyOptions[currency]
}

const formatMoney = (amount, centsUnit = 2, decimal = ".", thousand = ",") => {
  try {
    centsUnit = Math.abs(centsUnit)
    centsUnit = isNaN(centsUnit) ? 2 : centsUnit

    if (typeof amount !== "number") {
      amount = parseInt(amount.replace(/[.,\s]/g, ""))
    }
    const negativeSign = amount < 0 ? "-" : ""

    const fixedNumber = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(centsUnit)),
      10
    ).toString()
    const calcDecimal = fixedNumber.length > 3 ? fixedNumber.length % 3 : 0

    return (
      negativeSign +
      (calcDecimal ? fixedNumber.substr(0, calcDecimal) + thousand : "") +
      fixedNumber
        .substr(calcDecimal)
        .replace(/(\d{3})(?=\d)/g, `$1${thousand}`) +
      (centsUnit
        ? decimal +
          Math.abs(amount - fixedNumber)
            .toFixed(centsUnit)
            .slice(2)
        : "")
    )
  } catch (e) {
    return 0
  }
}

export { formatPrice, getCurrencyOption, formatMoney }
