import { makeStyles } from "@material-ui/core/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1200,
    paddingTop: 26,
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      maxWidth: 960,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 599,
    },
    "& .alert-text": {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      color: theme.palette.text.primary,
    },
    "& .alert-icon": {
      marginRight: 14,
      transform: "rotate(180deg)",
      maxHeight: 24,
      marginTop: -3,

      "& svg": {
        fill: theme.palette.secondary.main,
      },
    },
  },
}))

export default useStyles
