/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { useIntl } from "react-intl"
import { Grid } from "@material-ui/core"
import clsx from "clsx"

import theme from "@bit/vibenitez.copa-components-library.theme"
import AvatarCo from "@bit/vibenitez.copa-components-library.avatar"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 56,
    paddingBottom: 56,
    [theme.breakpoints.down("md")]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  retunrMargin: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
    },
  },
  caption: {
    color: theme.palette.grey["600"],
  },
  avatarContainer: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 30,
    },
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(11),
      height: theme.spacing(11),
    },
  },
}))

const Heading = ({
  avatarUrl,
  destination,
  title,
  description,
  showReturnBton,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Grid
      data-cy="heading-container"
      container
      alignItems="center"
      className={clsx(classes.root, showReturnBton ? classes.retunrMargin : "")}
    >
      <div
        aria-label={intl.formatMessage({
          id: "searchPage.heading.containerWCAG",
        })}
        data-cy="heading-message"
        tabIndex="0"
        aria-level="1"
        role="heading"
      />
      <Grid
        item
        className={classes.avatarContainer}
        aria-hidden
        md="auto"
        sm={2}
      >
        <AvatarCo
          alt={`${destination} avatar`}
          src={avatarUrl}
          className={classes.avatar}
        />
      </Grid>
      <Grid item aria-hidden md="auto" sm={10}>
        <TypographyCo data-cy="heading-searchPage" variant="overline">
          {intl.formatMessage(
            { id: "searchPage.heading.subtitle" },
            { city: destination }
          )}
        </TypographyCo>
        <TypographyCo
          data-cy="heading-destination"
          variant="h5"
          color="primary"
          component="h1"
        >
          {title}
        </TypographyCo>
        <TypographyCo
          data-cy="heading-destination-date"
          variant="body2"
          className={classes.caption}
        >
          {description}
        </TypographyCo>
      </Grid>
    </Grid>
  )
}

Heading.propTypes = {
  destination: PropTypes.string,
  avatarUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showReturnBton: PropTypes.bool,
}

Heading.defaultProps = {
  showReturnBton: false,
  destination: "",
}

export default Heading
