import React, { createElement } from "react"
import PropTypes from "prop-types"

import useStyles from "./styles"

const FareDetails = ({ details }) => {
  const classes = useStyles()

  const paragraphMarkup = createElement("p", {
    dangerouslySetInnerHTML: { __html: details },
  })

  return (
    <div data-cy="fareRulesModal-fareDetails" className={classes.fareDetails}>
      {paragraphMarkup}
      <div className={classes.blurEffect} />
    </div>
  )
}

FareDetails.propTypes = {
  details: PropTypes.string.isRequired,
}

export default FareDetails
