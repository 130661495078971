/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import { useIntl } from "react-intl"
import Container from "@material-ui/core/Container"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import Divider from "@bit/vibenitez.copa-components-library.divider"
import SingleAlert, { InfoIcon } from "../../../ToLibrary/alert"
import Heading from "../../commons/components/Heading"
import ContainerDatesSelector from "./components/containerDatesSelector"
import Disclaimer from "./components/disclaimer"
import useStyles from "./styles"
import { airportsActions } from "../../../store/airports/airportsSlice"
import { airportsPropTypes } from "./propTypesSchema"
import { reservationActions } from "../../../store/reservation/reservationSlice"
import { flightsActions } from "../../../store/flight/flightSlice"
import EditSearch from "./components/editSearch"
import { fromObjectToQuery } from "../../../utils/queryManager"
import { backToOriginApplication } from "../../../utils/generalUtils"
import {
  formatData,
  getRetainedItinerary,
} from "../../../utils/resultPageUtils"
import { pushPageView } from "../../../utils/analytics"
import { deleteInfoCookies } from "../../../translations/config"
import EndSessionModal from "../../commons/endSessionModal/endSessionModal"
import useSessionCountDown from "../../hooks/useSessionCountDown"
import { getExpireTime, setExpireTime } from "../../../store/flight/flightUtils"

const SearchPage = ({
  originAirports,
  destinationAirports,
  setExchangesParams,
  fetchReservation,
  reservation,
  saveSearch,
  fatalErrorRedirect,
  fetchFlights,
  fetchSaveSearch,
  setFatalError,
  onlyDate,
}) => {
  const { container, containerBton, ctaSearchFlight, disclaimerContainer } =
    useStyles()
  const { ticket, flightDestination, flightOrigin, isEligible } = reservation
  const { formatMessage } = useIntl()
  const {
    push: pushHistory,
    location: { search: locationSearch, state: routerState },
    push: historyPush,
  } = useHistory()
  const showAlert = routerState?.notFoundError || routerState?.fatalError
  const [flightSelect, setFlightSelect] = useState()
  const [disclaimerSectionOpen, setDisclaimerSectionOpen] = useState()
  const [disableContinueButton, setDisableContinueButton] = useState(true)
  const [newSearch, setNewSearch] = useState(null)
  const [isEditSearch] = useState(
    Object.entries(saveSearch).length > 0 && !showAlert
  )

  const params = new URLSearchParams(locationSearch)
  const pnr = params.get("pnr")
  const lastName = params.get("lastName")
  const expTimeFromCookies = getExpireTime()
  const expirationTime = useRef(
    expTimeFromCookies ? moment(expTimeFromCookies).format() : setExpireTime()
  )
  const shouldShowEndSessionModal = useSessionCountDown(expirationTime.current)

  deleteInfoCookies()

  const saveNewSearch = (flights) => setNewSearch(flights)

  const handleFirstClickFlightsSection = () => {
    if (!disclaimerSectionOpen) setDisclaimerSectionOpen(true)
  }

  const validation = newSearch?.some(
    (item) =>
      Object.keys(item.destination).length === 0 ||
      Object.keys(item.origin).length === 0
  )

  const handleLocationSearch = () => {
    if (pnr === "" || lastName === "" || pnr === null || lastName === null) {
      backToOriginApplication(pnr, lastName)
    }
    if (pnr && !isEditSearch) {
      setExchangesParams(locationSearch)
      fetchReservation({ pnr, lastName })
    } else {
      window.scrollTo({ top: 50, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (fatalErrorRedirect) {
      pushHistory("/critical-error")
    }
  }, [fatalErrorRedirect, pushHistory])

  useEffect(handleLocationSearch, [])

  useEffect(() => {
    pushPageView("/")
  }, [])

  if (
    isEligible?.isChangesAllowed !== undefined &&
    !(
      isEligible?.isChangesFromExchangesAllowed === true &&
      isEligible?.isChangesAllowed === true
    )
  ) {
    pushHistory(`/notEligible?pnr=${pnr}&lastName=${lastName}`)
  }

  const getTravelers = () => {
    const newPassengers = []

    let count = 0
    reservation.ticket.passengers.forEach((item) => {
      if (item.typeCode !== "INF") {
        count++
      }

      newPassengers.push({
        dateOfBirth: item.dateOfBirth,
        givenname: item.firstName,
        gender: item.gender === "Female" ? "F" : "M",
        id:
          item.typeCode === "INF"
            ? `${count.toString()}.${1}`
            : count.toString(),
        surname: item.surname,
        paxType: item.typeCode,
      })
    })
    return newPassengers
  }

  const getOriginDestination = () => {
    const currentFlights = newSearch

    const flights = []

    currentFlights.forEach((item) => {
      if (item.isCheck === true)
        flights.push({
          departure: item.origin.code,
          arrival: item.destination.code,
          date: item.date,
        })
    })

    return flights
  }

  const sendReshop = () => {
    const retainedItinerary = getRetainedItinerary(newSearch, ticket)
    const data = {
      pnr,
      starterLastName: lastName,
      cityCode: ticket.cityCode,
      currencyCode: ticket.currency,
      countryCode: ticket.countryCode,
      travelers: getTravelers(),
      selectedFareFamily: reservation.selectedFareFamily,
      originDestinations: getOriginDestination(),
      ...(retainedItinerary !== null && retainedItinerary),
    }

    fetchFlights(data)
  }

  useEffect(() => {
    setFlightSelect(
      isEditSearch
        ? saveSearch
        : formatData(ticket, flightDestination, flightOrigin, originAirports)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation])

  const buildURL = () => {
    const url = {
      pnr,
      lastName,
      onlyDate,
      typeFlight: ticket?.tripType === "ROUNDTRIP" ? "RT" : "OW",
    }
    newSearch.forEach((item, idx) => {
      url[`O${idx + 1}Code`] = item.origin.code
      url[`D${idx + 1}Code`] = item.destination.code
      url[`date${idx + 1}`] = item.date
      url[`startDate${idx + 1}`] = item.startDate
      url[`isCheck${idx + 1}`] = item.isCheck
    })
    return url
  }

  const checkForm = () => {
    if ((newSearch[1]?.date !== "" || newSearch.length === 1) && !validation) {
      const url = fromObjectToQuery(buildURL())

      const newLocation = {
        pathname: "/resultPage/",
        search: url,
        state: { notFoundError: false, fatalError: false },
      }
      setFatalError(200)
      fetchSaveSearch(newSearch)
      historyPush(newLocation)
      sendReshop()
    } else {
      window.scrollTo({ top: 450, behavior: "smooth" })
    }
  }

  let disclaimerErrorMessage = ""
  if (routerState?.fatalError) {
    disclaimerErrorMessage = formatMessage({
      id: "searchPage.notResultsDisclaimer",
    })
  } else if (routerState?.notFoundError) {
    disclaimerErrorMessage = formatMessage({
      id: "searchPage.fatalErrorDisclaimer",
    })
  }

  const routeAirports = ["PTY"]

  const isPanamaOriginOrDestination =
    routeAirports.includes(ticket?.flights?.[0]?.locationDepart) ||
    routeAirports.includes(ticket?.finalDestination)

  const isChangeOfRoute =
    isPanamaOriginOrDestination ||
    process.env.REACT_APP_ENABLE_CHANGE_OF_ROUTE.includes("true")

  return (
    <>
      <EndSessionModal isOpen={shouldShowEndSessionModal} />
      <Container fixed className={container}>
        {!isEditSearch ? (
          <Heading
            showReturnBton={!isEditSearch}
            avatarUrl={
              ticket.finalDestination
                ? `https://d3gwfrazxk4jnl.cloudfront.net/${ticket.finalDestination}.jpg`
                : ""
            }
            destination={ticket.cityDestination}
            date={moment().format("ddd, MMM D, YYYY")}
            isInbound={false}
            title={formatMessage({
              id: "searchPage.heading.title",
            })}
            description={formatMessage({
              id: "searchPage.heading.description",
            })}
          />
        ) : (
          <EditSearch />
        )}
      </Container>
      <Divider aria-hidden />
      <Container fixed className={container}>
        {showAlert && (
          <div className={disclaimerContainer}>
            <SingleAlert
              severity="info"
              fontWeight={500}
              fontSize="0.875rem"
              icon={
                <InfoIcon
                  data-cy="InfoIcon-priceDiscrepancyDisclaimer"
                  fontSize="inherit"
                />
              }
              data-cy="priceDiscrepancyDisclaimer"
            >
              {disclaimerErrorMessage}
            </SingleAlert>
          </div>
        )}
        <ContainerDatesSelector
          flightsData={flightSelect}
          originAirports={originAirports}
          destinationAirports={destinationAirports}
          handleFirstClick={handleFirstClickFlightsSection}
          disableContinueButton={setDisableContinueButton}
          getNewSearch={saveNewSearch}
          isEditSearch={isEditSearch}
          isChangeOfRoute={isChangeOfRoute}
        />
      </Container>
      {(disclaimerSectionOpen || isEditSearch) && (
        <>
          <Disclaimer />
          <Container fixed className={clsx(container, containerBton)}>
            <ButtonCo
              severity="info"
              variant="contained"
              color="secondary"
              size="large"
              className={ctaSearchFlight}
              aria-label={formatMessage({
                id: "searchPage.ctaSearchFlightWCAG",
              })}
              type="submit"
              data-cy="search-flight"
              onClick={checkForm}
              disabled={
                disableContinueButton ||
                !(
                  (newSearch[1]?.date !== "" || newSearch.length === 1) &&
                  !validation
                )
              }
            >
              {formatMessage({
                id: "searchPage.ctaSearchFlight",
              })}
            </ButtonCo>
          </Container>
        </>
      )}
    </>
  )
}

SearchPage.propTypes = {
  originAirports: airportsPropTypes,
  destinationAirports: airportsPropTypes,
  setExchangesParams: PropTypes.func.isRequired,
  fetchReservation: PropTypes.func.isRequired,
  reservation: PropTypes.shape({
    selectedFareFamily: PropTypes.any,
    ticket: PropTypes.shape({
      cityDestination: PropTypes.string,
      flights: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      finalDestination: PropTypes.string,
      tripType: PropTypes.string,
      cityCode: PropTypes.string,
      currency: PropTypes.string,
      countryCode: PropTypes.string,
      passengers: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    }),
    flightDestination: PropTypes.objectOf(PropTypes.any),
    flightOrigin: PropTypes.objectOf(PropTypes.any),
    isEligible: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  fetchSaveSearch: PropTypes.func.isRequired,
  saveSearch: PropTypes.objectOf(PropTypes.any).isRequired,
  fatalErrorRedirect: PropTypes.bool.isRequired,
  fetchFlights: PropTypes.func.isRequired,
  onlyDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setFatalError: PropTypes.func.isRequired,
}

SearchPage.defaultProps = {
  originAirports: [],
  onlyDate: null,
  destinationAirports: [],
}

const mapStateToProps = ({ airports, reservation, flight, general }) => ({
  originAirports: airports.origins,
  destinationAirports: airports.airportsMulticity,
  reservation,
  saveSearch: flight.saveSearch,
  fatalErrorRedirect: general.fatalErrorRedirect,
  onlyDate: flight.onlyDate,
})

const mapDispatchToProps = (dispatch) => ({
  getOriginAirports: () => dispatch(airportsActions.fetchOrigins()),
  setExchangesParams: (search) =>
    dispatch(reservationActions.setExchangesParamsFromQuery(search)),
  fetchReservation: (user) =>
    dispatch(reservationActions.fetchReservation(user)),
  fetchEgilibility: (pnr) => dispatch(reservationActions.fetchEgilibility(pnr)),
  fetchSaveSearch: (flights) =>
    dispatch(flightsActions.fetchSaveSearch(flights)),
  fetchFlights: (data) => dispatch(flightsActions.fetchFlights(data)),
  setFatalError: (data) => dispatch(flightsActions.setErrorRedirect(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
