export const ticket = (state) => state.reservation.ticket

export const lastName = (state) => state.reservation.queryParams.lastName

const multiCityFlightSelected = (data) => {
  const { solutionKeys } = data
  const { originDestination, ...solutionData } = solutionKeys[0]
  const flightObject = {
    ...originDestination,
    solutions: solutionData,
  }
  return flightObject
}

export default multiCityFlightSelected
