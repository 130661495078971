import React, { useState } from "react"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import camelCase from "lodash/camelCase"
import Alert from "@material-ui/lab/Alert"
import Collapse from "@material-ui/core/Collapse"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import theme from "@bit/vibenitez.copa-components-library.theme"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import DividerCo from "@bit/vibenitez.copa-components-library.divider"
import InfoIcon from "./iconInfo"

import useStyles from "./styles"

const getBaseColor = (disclaimerColors) => {
  const precedenceColors = [
    theme.palette.alternative.darkOrange,
    theme.palette.alternative.orange,
    theme.palette.alternative.lightOrange,
    theme.palette.primary.main,
  ]
  if (disclaimerColors.includes(precedenceColors[0])) return precedenceColors[0]
  if (disclaimerColors.includes(precedenceColors[1])) return precedenceColors[1]
  if (disclaimerColors.includes(precedenceColors[2])) return precedenceColors[2]
  return precedenceColors[3]
}

const AlertCo = ({ closeIcon, alertAction, disclaimersArray, ...props }) => {
  const { formatMessage } = useIntl()
  const disclaimersToShow = disclaimersArray
    ? disclaimersArray.filter((disclaimer) => disclaimer.show)
    : []
  const baseColor = getBaseColor(
    disclaimersToShow.map((disclaimer) => disclaimer.color)
  )
  const [open, setOpen] = useState(true)
  const classes = useStyles({ ...props, baseColor })
  const closeEvent = () => setOpen(false)
  const domProps = { ...props }

  delete domProps.fontColor
  delete domProps.borderLeft
  delete domProps.fontSize
  delete domProps.bgColor
  delete domProps.color

  const closeAction = (
    <IconButton
      className={classes.closeAction}
      color="inherit"
      size="small"
      onClick={closeEvent}
      aria-label={formatMessage({ id: "common.closeAlertButtonWCAG" })}
      data-cy="alert-close-button"
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  )

  if (!disclaimersToShow.length) return null

  return (
    <Collapse in={open}>
      <Alert
        classes={{ action: classes.action }}
        className={classes.alertAction}
        action={closeIcon && closeAction}
        role="alert"
        {...domProps}
      >
        <div>
          {disclaimersToShow.map((disclaimer) => (
            <div key={camelCase(disclaimer.title)} className="alertContainer">
              <Grid
                container
                wrap="nowrap"
                className={classes.messageContainer}
                data-cy={`disclaimer-container-${camelCase(disclaimer.title)}`}
              >
                <Grid
                  item
                  xs="auto"
                  className={classes.iconItem}
                  data-cy={`disclaimer-icon-${camelCase(disclaimer.title)}`}
                >
                  <InfoIcon color={baseColor} />
                </Grid>
                <Grid
                  item
                  xs="auto"
                  data-cy={`disclaimer-body-${camelCase(disclaimer.title)}`}
                >
                  <TypographyCo
                    className={classes.titleMessage}
                    variant="body1"
                    data-cy={`disclaimer-title-${camelCase(disclaimer.title)}`}
                  >
                    {formatMessage({ id: disclaimer.title })}
                  </TypographyCo>
                  <TypographyCo
                    className={classes.textMessage}
                    variant="caption"
                    data-cy={`disclaimer-caption-${camelCase(
                      disclaimer.title
                    )}`}
                  >
                    {disclaimer.bodyText}
                  </TypographyCo>
                  {disclaimer.bodyComponent}
                </Grid>
              </Grid>
              <div className="divider">
                <DividerCo />
              </div>
            </div>
          ))}
        </div>
      </Alert>
    </Collapse>
  )
}

AlertCo.propTypes = {
  closeIcon: PropTypes.bool,
  width: PropTypes.string,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontColor: PropTypes.string,
  alertAction: PropTypes.bool,
  bgColor: PropTypes.string,
  disclaimersArray: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      bodyText: PropTypes.string.isRequired,
      bodyComponent: PropTypes.node,
      show: PropTypes.bool,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
}

AlertCo.defaultProps = {
  closeIcon: true,
  width: "100%",
  fontWeight: 300,
  fontSize: "1rem",
  fontColor: theme.palette.grey["700"],
  alertAction: false,
  bgColor: theme.palette.common.white,
}

export default AlertCo
