import React, { memo } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import clsx from "clsx"
import { useIntl, FormattedMessage } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import SvgIcon from "@material-ui/core/SvgIcon"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { ReactComponent as AirplaneBlue } from "../../../../ToLibrary/icons/icon_airplane_blue.svg"
import useStyles from "./styles"

import { from24To12Time } from "../../../../store/flight/flightUtils"

const CardDetails = ({
  isLayover,
  indexFlight,
  flightsQty,
  flightDetails,
  airportsOrigins,
  solutionKey,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { push: historyPush } = useHistory()
  const location = useLocation()

  const AirplaneIcon = () => (
    <SvgIcon className={classes.marginIcon}>
      <AirplaneBlue />
    </SvgIcon>
  )

  const disableViewSeats = location.pathname.match(/checkout/g)
  const currentIndexFlight = indexFlight + 1
  const convert12Hour = (hour) => moment(hour, ["H.mm"]).format("h:mm a")
  const dateFormat = (date) => moment(date).format("ddd, MMMM D")
  const arrivalDateWCAG = moment(flightDetails.arrival.flightDate).format(
    "dddd, YYYY MMMM DD"
  )
  const findCityName = (code) =>
    airportsOrigins.find((airport) => airport.code === code)?.city
  const aircraftName =
    flightDetails.aircraftName === "Boeing 737-900"
      ? "Boeing 737 MAX 9"
      : flightDetails.aircraftName

  const handleViewSeats = () => {
    const summaryRoute = location.pathname.match(/summary/g)
    historyPush({
      pathname: "view-seats",
      state: {
        solutionKey,
        flightKey: flightDetails.flightKey,
        fromSummary: !!summaryRoute,
      },
    })
  }

  const carrierInfo = flightDetails?.marketingCarrier

  return (
    <div
      data-cy={`cardDetailsContainer-${currentIndexFlight}`}
      className={classes.cardContainer}
    >
      <Grid
        container
        aria-label={formatMessage(
          {
            id: flightDetails?.onTimePerformance
              ? "viewMoreDetailsModal.WCAG.segmentSummaryWithOTP"
              : "viewMoreDetailsModal.WCAG.segmentSummary",
          },
          {
            currentflight: currentIndexFlight,
            total: flightsQty,
            origin: findCityName(flightDetails.departure.airportCode),
            departureTime: from24To12Time(
              moment(
                `${flightDetails.departure.flightDate} ${flightDetails.departure.flightTime}`
              )
            ),
            destination: findCityName(flightDetails.arrival.airportCode),
            arrivalTime: from24To12Time(
              moment(
                `${flightDetails.arrival.flightDate} ${flightDetails.arrival.flightTime}`
              )
            ),
            date: arrivalDateWCAG,
            airlineCode: `${carrierInfo?.airlineCode} ${carrierInfo?.flightNumber}`,
            aircraftName,
            airlineName: carrierInfo?.airlineName,
            percent1: flightDetails.onTimePerformance?.cancellationPercentage,
            percent2: flightDetails.onTimePerformance?.delayPercentage30Min,
            percent3: flightDetails.onTimePerformance?.delayPercentage60Min,
          }
        )}
        tabIndex="0"
      >
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <div className={classes.flightCard}>
            <div className={classes.leftContentCard}>
              <div className={classes.lblCityContainer}>
                <strong data-cy="flightDetails-departureAirportCode">
                  {flightDetails.departure.airportCode}
                </strong>
                <span data-cy="flightDetails-departureFlightTime">
                  {convert12Hour(flightDetails?.departure?.flightTime)}
                </span>
              </div>
              <div className={classes.lblCityContainer}>
                <strong data-cy="flightDetails-arrivalAirportCode">
                  {flightDetails.arrival?.airportCode}
                </strong>
                <span data-cy="flightDetails-arrivalFlightTime">
                  {convert12Hour(flightDetails.arrival?.flightTime)}
                </span>
              </div>
            </div>
            <div className={classes.leftLineContent}>
              <div>
                <AirplaneIcon />
              </div>
              <div className={classes.vLine} />
              <div className="circle" />
            </div>
          </div>
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <div className={classes.rightContentCard}>
            <div className="leftContent">
              <TypographyCo
                data-cy="viewMoreDetailsModal-subtitlesCards"
                variant="h4"
                className={classes.captionLbl}
              >
                {formatMessage(
                  {
                    id: "viewMoreDetailsModal.subtitlesCards",
                  },
                  {
                    currentflight: currentIndexFlight,
                    total: flightsQty,
                  }
                )}
              </TypographyCo>
              <TypographyCo
                data-cy="viewMoreDetailsModal-subtitle"
                className="subtitle"
                variant="h3"
              >
                {findCityName(flightDetails.departure.airportCode)}{" "}
                <FormattedMessage id="viewMoreDetailsModal.subtitleTo" />{" "}
                {findCityName(flightDetails.arrival.airportCode)}
              </TypographyCo>
              <div className={classes.inlineBlocks}>
                <div className="item">
                  <strong>
                    <FormattedMessage id="viewMoreDetailsModal.date" />
                  </strong>
                  <span data-cy="flightDetails-flightDate">
                    {dateFormat(flightDetails?.departure?.flightDate)}
                  </span>
                </div>
                <div className="item">
                  <strong>
                    <FormattedMessage id="viewMoreDetailsModal.Flight" />
                  </strong>
                  <span data-cy="flightDetails-airlineCodeFlightNumber">
                    {`${carrierInfo?.airlineCode} ${carrierInfo?.flightNumber}`}
                  </span>
                </div>
                <div className="item item__full">
                  <strong>
                    <FormattedMessage id="viewMoreDetailsModal.aircraftType" />
                  </strong>
                  <span data-cy="flightDetails-aircraftType">
                    {aircraftName}
                  </span>
                </div>
                <div className="item">
                  <strong>
                    <FormattedMessage id="viewMoreDetailsModal.operatedBy" />
                  </strong>
                  <span data-cy="flightDetails-airlineName">
                    {flightDetails?.operatingCarrier?.airlineName ||
                      carrierInfo?.airlineName}
                  </span>
                </div>
              </div>
            </div>
            <div className="rightContent">
              {disableViewSeats && (
                <ButtonCo
                  aria-label={formatMessage({
                    id: "viewMoreDetailsModal.WCAG.availaibleSeats",
                  })}
                  data-cy="viewMoreDetailsModal-availaibleSeats"
                  classes={{ outlined: classes.button }}
                  onClick={handleViewSeats}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  <FormattedMessage id="viewMoreDetailsModal.button" />
                </ButtonCo>
              )}
            </div>
          </div>
          {flightDetails?.onTimePerformance && (
            <div className={classes.rightContentCard}>
              <div className={clsx(classes.contentCardBotton, "leftContent")}>
                <TypographyCo
                  data-cy="viewMoreDetailsModal-OTPTitle"
                  variant="h4"
                  className="optTitle"
                >
                  <FormattedMessage id="viewMoreDetailsModal.OTPTitle" />
                </TypographyCo>
                <div className={classes.inlineBlocks}>
                  <div className="item item_opt">
                    <strong>
                      <FormattedMessage id="viewMoreDetailsModal.OTPCancellations" />
                    </strong>
                    <span data-cy="flightDetails-cancelationPercentage">
                      {flightDetails.onTimePerformance?.cancellationPercentage
                        ?.toString()
                        .replace(".", ",")}
                      %
                    </span>
                  </div>
                  <div className="item item_opt">
                    <strong>
                      <FormattedMessage id="viewMoreDetailsModal.OTPDelays" />
                    </strong>
                    <span data-cy="flightDetails-airlineCodeFlightNumber">
                      {flightDetails.onTimePerformance?.delayPercentage30Min
                        ?.toString()
                        .replace(".", ",")}
                      %
                    </span>
                  </div>
                  <div className="item item_opt item_final">
                    <strong>
                      <FormattedMessage id="viewMoreDetailsModal.OTPDelays2" />
                    </strong>
                    <span data-cy="flightDetails-aircraftType">
                      {flightDetails.onTimePerformance?.delayPercentage60Min
                        ?.toString()
                        .replace(".", ",")}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      {isLayover && currentIndexFlight !== flightsQty && (
        <div className={classes.layoverContainer}>
          <strong className="titleLayover">
            {" "}
            {formatMessage({
              id: "viewMoreDetailsModal.layover",
            })}
          </strong>
          <strong>{flightDetails.layoverTime.replace(/h/g, "h ")}</strong>
        </div>
      )}
    </div>
  )
}

CardDetails.propTypes = {
  isLayover: PropTypes.bool,
  indexFlight: PropTypes.number.isRequired,
  flightsQty: PropTypes.number.isRequired,
  solutionKey: PropTypes.string.isRequired,
  flightDetails: PropTypes.shape().isRequired,
  airportsOrigins: PropTypes.arrayOf(PropTypes.object).isRequired,
}

CardDetails.defaultProps = {
  isLayover: false,
}

const mapStateToProps = ({ airports }) => ({
  airportsOrigins: airports.origins,
})

export default connect(mapStateToProps)(memo(CardDetails))
