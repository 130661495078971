const buildFareRulesDTO = (
  flight,
  solutions,
  currentFlight,
  fareData,
  sessionInfo
) => {
  const currentSolutionKey = solutions.key
  const currentSolution = fareData?.travelerFaresDetails
    ? fareData?.travelerFaresDetails[0]?.fareComponents.find(
        ({ solutionKey }) => solutionKey === currentSolutionKey
      )
    : null
  let currentIndex = 0
  if (sessionInfo.selectedOffer.travelerFaresDetails.length > 0) {
    currentIndex =
      sessionInfo.selectedOffer.travelerFaresDetails[0].fareComponents.findIndex(
        ({ classOfService }) => classOfService === solutions.classOfService
      )
  }
  const bodyFareRules = {
    airlineCode:
      flight?.operatingCarrier?.airlineCode ||
      flight?.marketingCarrier?.airlineCode,
    flightNumber:
      flight?.operatingCarrier?.flightNumber ||
      flight?.marketingCarrier?.flightNumber,
    departure: {
      airportCode: flight?.departure?.airportCode,
      date: flight?.departure?.flightDate,
      time: flight?.departure?.flightTime.substring(
        0,
        flight?.departure?.flightTime.length - 3 // remove seconds from time
      ),
    },
    arrival: {
      airportCode: flight?.arrival?.airportCode,
      date: flight?.arrival?.flightDate,
      time: flight?.arrival?.flightTime.substring(
        0,
        flight?.arrival?.flightTime.length - 3 // remove seconds from time
      ),
    },
    priceClass: {
      code: currentSolution
        ? currentSolution?.fareFamilyCode
        : sessionInfo?.selectedOffer.travelerFaresDetails[0].fareComponents[
            currentIndex
          ].fareFamilyCode,
      name: currentSolution
        ? currentSolution?.fareFamilyName
        : solutions.fareFamily,
    },
    fareComponent: {
      classOfService: currentSolution
        ? currentSolution?.classOfService
        : sessionInfo.selectedOffer.travelerFaresDetails[0].fareComponents[
            currentIndex
          ].classOfService,
      fareRefKey: currentSolution
        ? currentSolution?.fareRefKey
        : sessionInfo.selectedOffer.travelerFaresDetails[0].fareComponents[
            currentIndex
          ].fareRefKey,
      fareBasisCode: currentSolution
        ? currentSolution?.fareBasisCode
        : sessionInfo.selectedOffer.travelerFaresDetails[0].fareComponents[
            currentIndex
          ].fareBasisCode,
    },
  }

  return bodyFareRules
}

export default buildFareRulesDTO
