import React, { useRef } from "react"
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import isEmpty from "lodash/isEmpty"
import { FormattedMessage } from "react-intl"
import clsx from "clsx"
import PropTypes from "prop-types"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import DividerCo from "@bit/vibenitez.copa-components-library.divider"
import { generalActions } from "../../../../../store/general/generalSlice"
import LanguageSelector from "../languageSelector"
import MembersMenuModal from "../../../../../ToLibrary/menuModal/membersMenuModal"
import MembersLoginModal from "../../../../../ToLibrary/menuModal/loginBoxModal"
import ReturnButton from "./returnButton"

import { ReactComponent as Cart } from "../../../../../ToLibrary/icons/Cart.svg"
import { ReactComponent as CopaIcon } from "../../../../../ToLibrary/icons/CopaAirlines_LogoSm.svg"

import useStyles from "./styles"
import { getCookie } from "../../../../../utils/auth"
import FeatureFlags from "../../../../../featureFlags"

const DesktopTopbar = (props) => {
  const {
    passengers,
    reservation,
    total,
    language,
    languages,
    editIcon,
    carrotDown,
    formatMessage,
    loggedIn,
    userInfo,
    onChangeCountryAndLanguage,
    onEditClick,
    showEditButton,
    showPrice,
    isOpenLoginBox,
    setloginBox,
    showInfo,
    isEditSearch,
    lastName,
  } = props

  const location = useLocation()
  const classes = useStyles()
  const refLoginButton = useRef()

  const handleOpenLoginBox = () => {
    setloginBox(!isOpenLoginBox)
  }
  const cookieOrigin = getCookie("origin")
  const lang = getCookie("lang")

  return (
    <>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        data-cy="searchParams"
        tabIndex="-1"
        role="contentinfo"
      >
        <Grid item>
          <ButtonCo
            data-cy="desktopTopBar-copaIcon"
            className={classes.copaIcon}
            color="primary"
            variant="linkInverted"
            aria-label={formatMessage({ id: "topBarWCAG.logo" })}
            start={CopaIcon}
            disabled={cookieOrigin?.toLowerCase() === "app"}
            href="https://www.copaair.com/"
          />
        </Grid>
        {showInfo && !isEmpty(reservation) && (
          <Grid
            item
            md={!showEditButton ? 8 : 12}
            lg={10}
            data-cy="searchParams"
            className={clsx(
              classes.displayFlex,
              classes.removePadding,
              showEditButton
                ? classes.withEditButton
                : classes.withOutEditButton
            )}
            tabIndex="-1"
            role="contentinfo"
            aria-label={formatMessage(
              { id: "topBarWCAG.description" },
              { reservationCode: reservation, numberOfPassengers: passengers }
            )}
          >
            <TypographyCo
              data-cy="searchParams-route"
              variant="body1"
              className={clsx(classes.multicityTitle, classes.toolbarText)}
            >
              {formatMessage({ id: "topBar.description" })}
            </TypographyCo>
            <div className={classes.dividerHalf}>
              <DividerCo
                id="7"
                base="darkSolid"
                orientation="vertical"
                flexItem
                aria-hidden="true"
              />
            </div>
            <TypographyCo
              data-cy="searchParams-date"
              className={classes.toolbarText}
              variant="body2"
            >
              {formatMessage(
                { id: "topBar.reservation" },
                { reservationCode: reservation }
              )}
            </TypographyCo>
            <div className={classes.dividerHalf}>
              <DividerCo
                id="7"
                base="darkSolid"
                orientation="vertical"
                flexItem
                aria-hidden="true"
              />
            </div>
            {passengers > 0 && (
              <TypographyCo
                data-cy="searchParams-passengers"
                className={classes.toolbarText}
                variant="body2"
              >
                {formatMessage(
                  { id: "topBar.passengers" },
                  { numberOfPassengers: passengers }
                )}
              </TypographyCo>
            )}

            {showEditButton ? (
              <>
                <Grid
                  item
                  className={clsx(
                    classes.editButtonPadding,
                    classes.displayFlex
                  )}
                  data-cy="searchParams-editButton"
                >
                  <ButtonCo
                    data-cy="desktopTopBar-editButton"
                    className={classes.editButton}
                    color="primary"
                    variant="linkInverted"
                    size="small"
                    start={editIcon}
                    tabIndex="0"
                    aria-label={formatMessage({ id: "topBarWCAG.editButton" })}
                    onClick={onEditClick}
                  >
                    <FormattedMessage id="topBar.modifySearch" />
                  </ButtonCo>
                  {showPrice && (
                    <div className={classes.dividerFull}>
                      <DividerCo
                        id="7"
                        base="darkSolid"
                        orientation="vertical"
                        flexItem
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item md={isEditSearch ? 1 : false} />
            )}
          </Grid>
        )}
      </Grid>
      {showPrice ? (
        <Grid item md={2} lg={3}>
          {!!total && (
            <TypographyCo
              variant="body2"
              tabIndex="-1"
              className={classes.prices}
            >
              <Cart className={classes.cartIcon} />
              {`${total.formatCurrency()}`}
            </TypographyCo>
          )}
        </Grid>
      ) : (
        <Grid item md={isEditSearch ? 2 : false} lg={3} />
      )}
      <Grid
        item
        md={4}
        className={classes.displayFlex}
        style={{ justifyContent: "flex-end" }}
      >
        <ReturnButton
          toShow={["/", "/critical-error", "/notEligible"].includes(
            location.pathname
          )}
          pnr={reservation}
          lastName={lastName}
          language={language.id}
        />
        <div className={classes.currency}>
          <LanguageSelector
            data-cy="desktopTopBar-languageSelector"
            language={language}
            languages={languages}
            onApply={onChangeCountryAndLanguage}
            pnr={reservation}
            lastName={lastName}
          >
            <ButtonCo
              data-cy="desktopTopBar-languageSelector-btn"
              color="primary"
              variant="linkInverted"
              size="small"
              className={classes.currencyButton}
              end={carrotDown}
              tabIndex="0"
              aria-label={formatMessage(
                {
                  id: "topBarWCAG.languageSelector",
                },
                {
                  language: language.label,
                }
              )}
            >
              {language.label}
            </ButtonCo>
          </LanguageSelector>
        </div>
        {FeatureFlags.TURN_ON_LOGIN && (
          <>
            {loggedIn ? (
              <MembersMenuModal userData={userInfo} refElement={refLoginButton}>
                <div
                  ref={refLoginButton}
                  className={clsx(classes.displayFlex, classes.loggedInDiv)}
                  aria-haspopup="true"
                  aria-controls="simple-menu"
                  aria-describedby="customized-menu"
                >
                  <div className={classes.userMilesContainer}>
                    <TypographyCo variant="body1">{`${userInfo?.givenName}`}</TypographyCo>
                    <TypographyCo variant="caption" className={classes.miles}>
                      15
                      <FormattedMessage id="topBar.miles" />
                    </TypographyCo>
                  </div>
                  <ButtonCo
                    id="btnMembersLoginBox"
                    size="small"
                    color="primary"
                    variant="linkInverted"
                    className={classes.userNameButton}
                    aria-label={formatMessage(
                      { id: "topBarWCAG.loggedIn" },
                      { userName: userInfo?.givenName }
                    )}
                    onClick={handleOpenLoginBox}
                  >
                    {`${userInfo?.givenName[0]}${userInfo?.surname[0]} `}
                  </ButtonCo>
                </div>
              </MembersMenuModal>
            ) : (
              <MembersLoginModal
                userData={userInfo}
                refElement={refLoginButton}
                isLoginBox
              >
                <ButtonCo
                  data-cy="desktopTopBar-loginButton"
                  ref={refLoginButton}
                  id="btnLoginBox"
                  color="primary"
                  size="small"
                  className={
                    lang !== "en" && !showEditButton
                      ? classes.loginEsMedium
                      : classes.loginButton
                  }
                  variant="contained"
                  aria-label={formatMessage({ id: "topBarWCAG.login" })}
                  onClick={handleOpenLoginBox}
                >
                  <FormattedMessage id="topBar.loginCTA" />
                </ButtonCo>
              </MembersLoginModal>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

DesktopTopbar.propTypes = {
  passengers: PropTypes.number.isRequired,
  reservation: PropTypes.string.isRequired,
  total: PropTypes.number,
  language: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
  }),
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
    })
  ),
  editIcon: PropTypes.func.isRequired,
  carrotDown: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onChangeCountryAndLanguage: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    givenName: PropTypes.string,
    surname: PropTypes.string,
    loyalty: PropTypes.objectOf(PropTypes.any),
  }),
  onEditClick: PropTypes.func.isRequired,
  setloginBox: PropTypes.func.isRequired,
  isOpenLoginBox: PropTypes.bool.isRequired,
  showEditButton: PropTypes.bool,
  showPrice: PropTypes.bool,
  showInfo: PropTypes.bool,
  lastName: PropTypes.string.isRequired,
  isEditSearch: PropTypes.bool.isRequired,
}

DesktopTopbar.defaultProps = {
  total: 0,
  language: {
    id: "",
    label: "",
    option: "",
  },
  languages: [],
  userInfo: {},
  showEditButton: true,
  showPrice: true,
  showInfo: true,
}

const mapStateToProps = (state) => ({
  isOpenLoginBox: state.general.openLoginBox,
})

const mapDispatchToProps = (dispatch) => ({
  setloginBox: (open) => dispatch(generalActions.setModalMemberLogin(open)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopTopbar)
