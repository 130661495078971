import { all } from "redux-saga/effects"

import generalSagas from "./general/generalSagas"
import authSaga from "./auth/authSagas"
import airportsSagas from "./airports/airportsSagas"
import reservationSagas from "./reservation/reservationSagas"
import flightSagas from "./flights/flightSagas"
import sessionSaga from "./session/sessionSagas"
import fareRulesSagas from "./fareRules/fareRulesSagas"

export default function* rootSaga() {
  yield all([
    generalSagas(),
    authSaga(),
    airportsSagas(),
    reservationSagas(),
    flightSagas(),
    sessionSaga(),
    fareRulesSagas(),
  ])
}
