const initialState = {
  flightDetailsModal: {
    isVisible: false,
    data: {},
  },
  flightsDataModal: [],
  saveSearch: {},
  onlyDate: null,
  pnrNumber: "",
  numberOfTravelers: 1,
  ods: [
    {
      originDestinationKey: "",
      origin: {
        code: "",
        departureDate: "",
      },
      destination: {
        code: "",
        destinationImageURL: "",
      },
      solutions: [],
    },
  ],
  offerGroups: [],
  fetching: true,
  error: "",
  isLoaded: false,
  flightsSelected: {},
  reShopQuery: "",
  redirectError: 0,
  fareChangeModalOptionSelected: "",
}

export default initialState
