import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useIntl, FormattedMessage } from "react-intl"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import { fareRulesActions } from "../../../../store/fareRules/fareRulesSlice"
import SpinnerCo from "../../../../ToLibrary/Spinner/spinnerCo"
import ModalCo from "../../../../ToLibrary/modal/modalCo"
import SegmentNav from "./segmentNav"
import FareDetails from "./fareDetails"
import FlightMessage from "./flightMessage"
import useStyles from "./styles"

import buildFareRulesDTO from "./fareRulesUtils"

const FareRulesDetails = ({
  isOpen,
  handleClose,
  flightData,
  flightsLength,
  getFareRulesInfo,
  fareRulesError,
  flightPosition,
  showStopover,
  fareData,
  details,
  sessionInfo,
  leg,
}) => {
  const [currentFlight, setCurrentFlight] = useState(flightPosition)

  const { solutions } = flightData
  const flight = solutions?.flights[currentFlight]
  const classes = useStyles()
  const { formatMessage } = useIntl()

  useEffect(() => {
    const bodyFareRules = buildFareRulesDTO(
      flight,
      solutions,
      currentFlight,
      fareData,
      sessionInfo,
      leg
    )
    getFareRulesInfo(bodyFareRules)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCurrentPosition = (flightKey) => {
    const { flights } = solutions
    return flights.findIndex((item) => item.flightKey === flightKey)
  }

  const prevSegment = (e, flightKey) => {
    e.preventDefault()
    const position = getCurrentPosition(flightKey)

    setCurrentFlight(position === 0 ? 0 : currentFlight - 1)
  }

  const nextSegment = (e, flightKey) => {
    e.preventDefault()
    const position = getCurrentPosition(flightKey)

    setCurrentFlight(flightsLength === position + 1 ? 0 : currentFlight + 1)
  }

  const getFareFamilyName = () => {
    return fareRulesError || showStopover ? "-" : solutions?.fareFamily
  }

  return (
    <>
      {solutions && (
        <ModalCo
          data-cy="fareRulesModal"
          classes={{
            paper: classes.modalWidth,
          }}
          onClose={() => handleClose(!isOpen)}
          open={isOpen}
          showClose
          id="fare-rules-main"
          tabIndex="-1"
          wcagObject={[
            {
              id: "container",
              message: formatMessage({
                id: "fareRulesModal.WCAG.modalDescription",
              }),
            },
            {
              id: "closeBotton",
              message: formatMessage({
                id: "fareRulesModal.WCAG.closeBotton",
              }),
            },
          ]}
        >
          <>
            <div
              data-cy="fareRulesModal-container"
              className={classes.container}
            >
              <TypographyCo
                data-cy="fareRulesModal-title"
                variant="h5"
                color="primary"
              >
                <FormattedMessage id="fareRulesModal.title" />
              </TypographyCo>
              <div
                data-cy="fareRulesModal-contentContainer"
                className="contentContainer"
              >
                <p data-cy="fareRulesModal-headingDescription">
                  <FormattedMessage id="fareRulesModal.headingDescription" />
                </p>
                <a
                  data-cy="fareRulesModal-headingLink"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={formatMessage({
                    id: "fareRulesModal.headingLink",
                  })}
                >
                  <FormattedMessage id="fareRulesModal.headingCaption" />
                </a>
              </div>
            </div>

            {flight && (
              <SegmentNav
                fareFamilyName={getFareFamilyName()}
                flightsLength={flightsLength}
                flightInfo={solutions?.flights[currentFlight]}
                prevSegment={prevSegment}
                nextSegment={(flightKey) => nextSegment(flightKey)}
                isPrevDisabled={currentFlight === 0}
                isNextDisabled={flightsLength === currentFlight + 1}
                details={details}
              />
            )}

            {fareRulesError || showStopover ? (
              <FlightMessage errorMessage />
            ) : (
              <>
                {details ? (
                  <FareDetails details={details} />
                ) : (
                  <div
                    data-cy="fareDetails-loader"
                    className={classes.fareDetailsLoader}
                  >
                    <div>
                      <SpinnerCo color={theme.palette.grey["500"]} />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </ModalCo>
      )}
    </>
  )
}

FareRulesDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  flightData: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any)]),
  flightsLength: PropTypes.number.isRequired,
  details: PropTypes.string,
  getFareRulesInfo: PropTypes.func.isRequired,
  showStopover: PropTypes.bool,
  fareRulesError: PropTypes.bool,
  flightPosition: PropTypes.number,
  fareData: PropTypes.objectOf(PropTypes.any),
  sessionInfo: PropTypes.objectOf(PropTypes.any),
  leg: PropTypes.number,
}

FareRulesDetails.defaultProps = {
  flightData: {},
  flightPosition: 0,
  fareRulesError: false,
  showStopover: false,
  details: "",
  fareData: {},
  sessionInfo: {},
  leg: 0,
}

const mapStateToProps = ({ fareRules, session: { sessionInfo } }) => ({
  details: fareRules.rules,
  sessionInfo,
  fareRulesError: fareRules.error,
})

const mapDispatchToProps = (dispatch) => ({
  getFareRulesInfo: (payload) =>
    dispatch(fareRulesActions.loadFareRulesInfo(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FareRulesDetails)
