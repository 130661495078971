import { createAction, createSlice } from "@reduxjs/toolkit"

export const resetAction = createAction("reset")

export const createSliceWithReset = ({ initialState, ...rest }) =>
  createSlice({
    initialState,
    ...rest,
    extraReducers: (builder) =>
      builder.addCase(resetAction, () => initialState),
  })
