const fieldsValidation = (values, formatMessage) => {
  const errors = {}

  if (!values.area1) {
    errors.area1 = formatMessage({
      id: "searchPage.datesSelector.emptyOrigin",
    })
  }

  if (!values.area2) {
    errors.area2 = formatMessage({
      id: "searchPage.datesSelector.emptyDestination",
    })
  }

  if (!values.date1) {
    errors.date1 = formatMessage({
      id: "searchPage.datesSelector.emptyDate",
    })
  }

  return errors
}

export default fieldsValidation
