import React from "react"
import PropTypes from "prop-types"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  /**
   * Styles definding the content.With some breakpoints, it behaves
   * different.
   */
  content: {
    alignContent: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  /**
   * Styles for the divider. With some breakpoints, it behaves
   * different and can even dissapear.
   */
  divider: {
    borderColor: "rgba(209, 209, 209, 0.50)",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
      display: "block",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
      marginTop: theme.spacing(13),
      marginBottom: theme.spacing(10),
    },

    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(13),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(11),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(7),
    },
  },
})

function ModalColumnsCo(props) {
  const { children, dividerClassName, rootClassName } = props
  const styles = useStyles()
  let child1
  let child2
  /**
   * Divides the children in order to place them properly
   */
  if (Array.isArray(children)) {
    ;[child1, child2] = children
  }
  return (
    <div className={clsx(styles.content, rootClassName)}>
      {child1}
      <Divider
        orientation="vertical"
        flexItem
        className={clsx(styles.divider, dividerClassName)}
      />
      {child2}
    </div>
  )
}

/**
 * PropType function which handles the validation for the children to be type <div>.
 */
const childrenPropTypeLogic = (props, propName, componentName) => {
  const prop = props[propName]
  return (
    React.Children.toArray(prop).find((child) => child.type !== "div") &&
    new Error(`${componentName} only accepts "div" elements`)
  )
}

ModalColumnsCo.propTypes = {
  /**
   * A set of two `<div/>` which each one will contain a ser of
   */
  children: childrenPropTypeLogic,
  dividerClassName: PropTypes.string,
  rootClassName: PropTypes.string,
}

ModalColumnsCo.defaultProps = {
  children: [<div />, <div />],
  dividerClassName: null,
  rootClassName: null,
}

export default ModalColumnsCo
