export default {
  topBar: {
    modifySearch: "Nouvelle recherche",
    description: "Modifier le voyage",
    reservation: "Code de réservation : {reservationCode}",
    reservationXS: "Réservation : {reservationCode}",
    passengers:
      "{numberOfPassengers, plural, one {# Passager} other {# Passagers} }",
    loginCTA: "Se connecter",
    miles: "milles",
    close: "Fermer",
    backToMytrips: "Retour à Mes Voyages",
    membersMenu: {
      personalizedGreeting: "Bonjour,",
      LogoutCTA: "Se déconnecter",
      accountCTA: "Mon compte",
      connectmilesLabel: "# CONNECTMILES",
      statusLabel: "STATUT",
      milesLabel: "SOLDE DE MILLES",
      myAccountExternalLink: "/fr/web/profile",
    },
    loginBox: {
      credentialsLabel: "E-mail, numéro ConnectMiles ou nom d'utilisateur",
      required: "Ce champ est obligatoire",
      credentialsPlaceholder: "Entrez votre nom d'utilisateur",
      inlineErrorEmptyField: "Ce champ est obligatoire",
      passwordLabel: "Mot de passe",
      passwordfieldPlaceholder: "Entrez votre mot de passe",
      forgotLink: "Mot de passe oublié ?",
      forgotExternalLink: "https://www.copaair.com/fr/web/gs/forgot",
      createaccountlink: `Pas de compte ? <a> Inscrivez-vous maintenant </a>`,
      createaccountlinkExternal: "https://www.copaair.com/fr/web/gs/enrollment",
      emailMatch: "Les e-mails ne correspondent pas",
      errorbannerWrongData:
        "Les informations fournies ne correspondent pas à nos dossiers. Veuillez vérifier vos saisies et réessayer.",
      errorbannerBlockedAccount:
        "Votre compte a été temporairement bloqué pour des raisons de sécurité. Veuillez contacter le Centre de Service ConnectMiles pour obtenir de l'aide. <a> En savoir plus </a>",
    },
  },
  topBarWCAG: {
    logo: "Logo de Copa Airlines. Appuyez sur Entrée pour aller au site principal de Copa Airlines.",
    description:
      "Section modifier le voyage. Vous allez effectuer un changement dans votre réservation {reservationCode} le changement s'appliquera à tous les {numberOfPassengers} passagers.",
    languageSelector:
      "Bouton de sélection de la langue. La langue actuelle est {language}. Appuyez sur Entrée pour changer la langue sélectionnée.",
    loggedIn:
      "Vous vous êtes connecté avec l'utilisateur [Nom]. Appuyez sur Entrée pour ouvrir le Menu des Membres",
    login:
      "Bouton de connexion ConnectMiles, vous permet d'accéder à votre profil de membre. Appuyez sur Entrée pour accéder au formulaire de connexion.",
    editButton:
      "Bouton Modifier les paramètres de changement de vol. Appuyez sur Entrée pour modifier vos paramètres de changement.",
    backToMytrips:
      "Bouton de retour. Appuyez sur Entrée pour retourner à la page de détails de Mes Voyages.",
    membersMenu: {
      connectMilesLogoAlt: "Logo de ConnectMiles",
      logoutButton:
        "Se déconnecter de votre compte ConnectMiles, bouton. Appuyez sur Entrée pour vous déconnecter de votre compte ConnectMiles.",
      myAccountButton:
        "Mon compte, bouton. Appuyez sur Entrée pour ouvrir votre page de profil ConnectMiles, cette page s'ouvre dans un nouvel onglet de votre navigateur",
      container:
        "Numéro {number} du menu ConnectMiles. Bonjour {name} votre numéro ConnectMiles est {number} et votre statut actuel est {status}. Pour sortir, appuyez sur la touche (ESC)",
    },
    loginBox: {
      CredentialsLabel:
        "Entrez votre email, numéro ou nom d'utilisateur ConnectMiles",
      PasswordLabel: "Entrez votre mot de passe ConnectMiles",
      loginButton:
        "Bouton de connexion. Appuyez sur Entrée pour vous connecter avec vos identifiants",
      fortgotPassword:
        "Lien mot de passe oublié. Appuyez sur Entrée pour aller à ConnectMiles et récupérer votre mot de passe",
      Close:
        "Bouton fermer, appuyez sur Entrée pour fermer la fenêtre contextuelle ",
      InlineErrorMandatoryFiled: "Erreur. Ce champ est obligatoire",
      CreateAccountlink:
        "Lien pour créer un compte. Appuyez sur Entrée si vous n'avez pas de compte et souhaitez en créer un maintenant. La page ConnectMiles s'ouvrira dans un nouvel onglet de votre navigateur.",
      ErrorBannerAlertWrongInformation:
        "Erreur. Les informations que vous avez fournies ne correspondent pas à nos enregistrements. Veuillez vérifier vos saisies et réessayer.",
      ErrorBannerAlertBlockedAccount:
        "Erreur. Votre compte a été temporairement verrouillé pour des raisons de sécurité. Veuillez contacter le Centre de Service ConnectMiles pour obtenir de l'aide. <lien vers le numéro du centre d'appel ConnectMiles>",
    },
  },
  searchPage: {
    heading: {
      subtitle: "Votre voyage à {city}",
      title: "Comment souhaitez-vous modifier votre voyage ?",
      description: "Vous pouvez modifier les dates ou l'heure du voyage",
      containerWCAG:
        "Section Modifier le vol, ici vous pouvez sélectionner le type de changement que vous souhaitez effectuer.",
    },
    editHeading: {
      title: "Nouvelle recherche",
      ctaEdit: "Retour",
      wcagContent:
        "Modifier la section de changement de vol, ici vous pouvez changer le type de changement que vous souhaitez effectuer.",
      wcagBton:
        "Bouton Retour. Appuyez sur Entrée pour revenir à la page précédente.",
    },
    notResultsDisclaimer:
      "Il n'y a pas de disponibilité aux dates et/ou villes sélectionnées. Veuillez modifier votre recherche et réessayer.",
    fatalErrorDisclaimer:
      "Désolé, en raison de difficultés techniques, nous ne pouvons pas continuer avec la modification de votre voyage. Veuillez réessayer plus tard.",
    ctaSearchFlight: "Rechercher des options de vol",
    ctaSearchFlightWCAG:
      "Bouton de recherche. Appuyez sur Entrée pour procéder à la recherche d'options de vol.",
    modeSelector: {
      title: "Que souhaitez-vous modifier ?",
      option1Text: "Modifier les dates et/ou l'heure",
      option3Info: "Date actuelle : {departureDate}",
      option1Info: "Dates actuelles : {departureDate} - {arrivalDate}",
      option2Text: "Changer les villes et les dates",
      option4Info: "{departureCode} · {arrivalCode}, {departureDate}",
      option2Info:
        "{departureCode} · {arrivalCode}, {departureDate} - {arrivalDate}",
    },
    modeSelectorWCAG: {
      changeDates:
        "Option de changer la date et/ou les heures de votre voyage. Appuyez sur Entrée pour sélectionner cette option de changement",
      changeRoutes:
        "Option de changer la ville et la date de votre voyage. Appuyez sur Entrée pour sélectionner cette option de changement",
    },
    datesSelector: {
      title: "Sélectionnez les vols que vous souhaitez modifier",
      infoSegment:
        "Les modifications s'appliqueront à tous les passagers de cette réservation. Si vous souhaitez avancer votre voyage, certaines dates peuvent ne pas être disponibles en raison de restrictions applicables. Vos vols actuels ne changeront pas jusqu'à ce que vous confirmiez votre nouvel itinéraire.",
      flightNumber: "Vol {index}",
      originTitle: "De",
      originPlaceholder: "Entrer l'origine",
      originFocusPlaceholder: "Ville, pays ou aéroport",
      destinationTitle: "Vers",
      destinationPlaceholder: "Entrer la destination",
      destinationFocusPlaceholder: "Ville, pays ou aéroport",
      noResultsAutocomplete: "Oups, aucun résultat trouvé",
      noResultsAutocompleteHelperText: "Veuillez modifier votre recherche",
      noResultsHelperTextOrigin: "Sélectionnez une origine valide",
      noResultsHelperTextDestination: "Sélectionnez une destination valide",
      emptyOrigin: "Sélectionnez une origine",
      emptyDestination: "Sélectionnez une destination",
      datesTitle: "Dates de voyage",
      emptyDate: "Sélectionnez une date de voyage",
      emptyOriginWhenDestination: "Vous devez d'abord sélectionner une origine",
      airportListFail: "Oups, nous n'avons pas pu charger les aéroports.",
      retryButton: "Réessayer",
      datePicker: {
        dateRoundTrip: "Dates de voyage",
        dateRoundTripPlaceHolder: "Entrer les dates de voyage",
        dateOneWay: "Date de voyage",
        dateOneWayPlaceholder: "Entrer la date de voyage",
        flexibleDates: "Dates flexibles",
        confirmCTA: "Confirmer",
        departure: "Départ",
        return: "Retour",
      },
      keyboardShortcuts: {
        title:
          "Panneau d'information où vous pouvez en savoir plus sur la navigation au clavier pour sélectionner des dates. Continuez pour apprendre les raccourcis clavier.",
        enter: "Confirmer la date sélectionnée (Entrée)",
        leftAndRight:
          "Changer de jour en arrière (flèche gauche) ou en avant (flèche droite)",
        upAndDown:
          "Semaine précédente (flèche haut) ou semaine suivante (flèche bas)",
        pageUpAndPageDn:
          "Mois précédent (touche Page haut) ou mois suivant (touche Page bas)",
        homeAndEnd:
          "Premier jour de la semaine (touche Accueil) ou dernier jour (touche Fin)",
        escape: "Retour au champ de saisie de la date (touche Échapper)",
        info: "Ouvrir le panneau d'information sur la Navigation au Clavier",
        botoni:
          "Ouvrir le panneau d'information sur la Navigation au Clavier (touche i)",
        closeBtn:
          "Bouton Fermer. Appuyez sur Entrée pour fermer ce panneau d'information et revenir à la sélection des dates",
      },
      sameDay:
        "Les modifications le même jour de vol ne peuvent être demandées qu'à l'aéroport. Sélectionnez une autre date pour continuer.",
    },
    changeRouteSelector: {
      changeRoute:
        "Modifications disponibles à l'intérieur du pays de votre réservation initiale.",
      notAvailableTitle: "Ville indisponible sur cette plateforme.",
      errorMesaje:
        "Pour changer votre pays d'origine, veuillez contacter notre <a>Centre de Réservations</a>.",
      link: "https://www.copaair.com/fr/web/gs/centre-de-reservations",
    },
    datesSelectorWCAG: {
      originField:
        "Origine. Entrez la ville, le pays ou l'aéroport d'origine de votre voyage.",
      airportLoadingFail:
        "Erreur de chargement. Nous n'avons pas pu charger les aéroports. Appuyez sur Tab pour réessayer et continuer votre recherche.",
      swapButton:
        "Bouton d'échange. Origine {origin}, Destination {destination} Appuyez sur Entrée pour échanger, ou continuez pour sélectionner les dates de voyage.",
      destinationField:
        "Destination. Entrez votre ville, pays ou aéroport de destination.",
      checkBoxWCAG:
        "Vol {numFlight} de votre voyage. Appuyez sur Entrée pour confirmer que vous souhaitez changer ce vol, qui est à l'origine de {origin} à {destination} le {date}.",
      checkBoxWCAGNextFlight:
        "Sinon appuyez sur Tab pour passer au vol suivant",
      datePicker: {
        dateRoundTrip:
          "Sélection des Dates. Vous pouvez sélectionner la date de votre vol. Vous pouvez naviguer par mois et jour pour faire votre choix.",
        dateOneWayTrip:
          "Recherche de vols aller simple, appuyez sur Entrée pour avancer vers la sélection de l'Origine.",
        travelDateField:
          "Sélection des Dates. Vous pouvez sélectionner la date de votre vol. Vous pouvez naviguer par mois et jour pour faire votre choix.",
        monthSelection:
          "{month} {year}. Appuyez sur Entrée pour aller aux jours de {month}. ",
        daySelection:
          "{weekday} {day}. Appuyez sur Entrée pour sélectionner ce jour.",
        roundTripSelection:
          "Vous avez sélectionné {month}, {weekday} {day} pour le vol aller, veuillez sélectionner la date de retour.",
        oneWaySelection: "Vous avez sélectionné {month}, {weekday} {day}",
        flexibleDatesSelection:
          "Dates flexibles. Appuyez sur Entrée pour activer les dates flexibles dans vos résultats de recherche. {active}",
        roundTripConfirmationCTA:
          "Confirmer les dates de recherche. Appuyez sur Entrée pour confirmer que les dates de votre recherche sont : Départ le {departureMonth} {departureDay}, retour le {returnMonth} {returnDay}",
        oneWayConfirmationCTA:
          "Confirmer la date de recherche. Appuyez sur Entrée pour confirmer que la date de votre recherche est : {departureMonth} {departureDay}",
      },
    },
    disclaimer: {
      title: " Lorsque vous modifiez votre voyage, vous devriez savoir ceci :",
      disc1:
        "Le coût de la modification peut varier en fonction de la famille de tarifs initialement achetée. Si le coût de votre nouvel itinéraire est inférieur, vous recevrez un crédit pour un vol futur. Certaines taxes préalablement payées peuvent ne pas être remboursables. Cette plateforme vous permettra de faire un seul changement à votre réservation. Si une deuxième modification est nécessaire, vous devez l'effectuer par l'intermédiaire de notre Centre de réservation.",
      disc2: `Lorsque vous modifiez votre réservation, vous perdrez votre sélection de siège originale et devriez la re-sélectionner dans notre section "Mes Voyages" une fois le changement effectué. Les sièges précédemment sélectionnés ou achetés peuvent ne plus être disponibles sur le nouveau vol. Si le siège a été acheté par un autre passager, vous aurez un crédit associé à la réservation pour sélectionner votre nouveau siège. Si vous n'utilisez pas le crédit, il ne sera pas remboursable.`,
      disc3:
        "La famille de tarifs que vous sélectionnez pour vos nouveaux vols (aller ou retour), sera appliquée à l'ensemble de l'itinéraire, y compris les vols que vous ne modifiez pas. Assurez-vous de revoir les attributs de tarif avant de faire le changement.",
      link: " Consultez notre politique de changement ",
      webLink:
        "https://copaair.com/fr-gs/legal/terms-and-conditions/voluntary-exchanges",
    },
    disclaimerWCAG: {
      contentDisclaimer:
        "Section des messages importants. Rappelez-vous que {disc1}, {disc2}, {disc3}",
      contentDisclaimerMobil:
        "Section des messages importants. Appuyez sur Entrée pour connaître les messages",
      link: "Lien de la politique de changement, appuyez sur Entrée pour voir les détails de la politique de changement.",
    },
  },
  searchPageSEO: {
    description:
      "Choisissez le type de changement que vous souhaitez apporter à votre voyage. Vous pouvez modifier les heures, les dates ou les villes.",
    titlePage: "Changer de Voyage - Copa Airlines",
    keywords:
      "Changements, Vols, Changement de vols, changement de date, changement d'heures, changement de réservations, modification, modifier vols, changer voyage, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  resultPageSEO: {
    description:
      "Liste de tous les vols disponibles pour effectuer votre changement, sélectionnez le vol qui vous convient le mieux.",
    titlePage: "Résultats des Vols | Copa Airlines",
    keywords:
      "Changements, Vols, Changement de vols, changement de date, changement d'heures, changement de réservations, modifier vols, changer voyage, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  confirmationSEO: {
    description:
      "Confirmation du changement apporté à votre voyage. Revoyez le résumé détaillé de votre changement.",
    titlePage: "Confirmation de Changement | Copa Airlines",
    keywords:
      "Changements, Vols, Changement de vols, changement de date, changement d'heures, changement de réservations, modifier vols, changer voyage, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fullWidthAlert: {
    timeLeft: "Votre session expirera dans {time}.",
  },
  resultPage: {
    heading: {
      title: "Sélectionnez un nouvel itinéraire",
      description: "Nouvelle sélection : ",
    },
    fareChangeModal: {
      modalTitle:
        " Tout votre voyage sera converti à la famille de tarifs {fareFamily}",
      unchangedODModalTitle: "Familles tarifaires dans le nouveau itinéraire ",
      upgradeDescriptionRT:
        "Cette action modifiera tous les vols de votre voyage au tarif {fareFamily}, même ceux que vous n'avez pas sélectionnés pour modification. Vous profiterez désormais de plus d'avantages. ",
      downgradeDescriptionRT:
        "Cette action changera tous les vols de votre voyage au tarif {fareFamily}, même ceux que vous n'avez pas sélectionnés pour modification. Votre expérience de voyage et vos avantages pourraient être affectés. ",
      upgradeDescriptionOW:
        "Cette action changera tous les vols de votre voyage au tarif {fareFamily}. Vous profiterez désormais de plus d'avantages.",
      downgradeDescriptionOW:
        "Cette action changera tous les vols de votre voyage au tarif {fareFamily}. Votre expérience de voyage et vos avantages pourraient être affectés. ",
      ctaCancel: "Annuler",
      ctaAccept: "Accepter",
      fareFamilyDetails: {
        text: "Voir plus de détails sur les familles de tarifs",
        link: "https://www.copaair.com/fr-gs/travel-information/fare-families/",
      },
      table: {
        flightHead: "Vol",
        oldFareHead: "Tarif Original",
        newFareHead: "Changera pour",
        warningText: "Changement de Cabine",
        dateText: "Vol {numberOfFlight} · {date}",
      },
      unchangedODPill: "Pas de changements",
      unchangedODAlert:
        "Vous avez sélectionné différentes familles tarifaires pour vos vols. Pour votre commodité, vous pouvez toujours choisir différentes familles tarifaires pour vos vols aller et retour. Cependant, certains avantages tels que les remboursements ou les changements d'itinéraire peuvent être restreints.",
    },
    fareChangeModalWCAG: {
      containerUpgrade:
        "Message important. L'ensemble de votre voyage passera à {newFareFamily}. Cette action changera tous les vols de votre voyage pour la tarification {newFareFamily}, y compris les vols que vous ne modifiez pas. Vous bénéficierez désormais de plus d'avantages. Voici les détails de vos modifications : votre vol de {origin1} à {destination1} qui était en {fareFamily1} passera à {newFareFamily} {alerts1} et votre vol de {origin2} à {destination2} qui était en {fareFamily2} il passera à {newFareFamily} {alerts2}",
      containerDowngrade:
        "Message important. L'ensemble de votre voyage passera à {newFareFamily}. Cette action changera tous les vols de votre voyage pour la tarification {newFareFamily}, y compris les vols que vous ne modifiez pas. Votre expérience de voyage et vos avantages pourraient être affectés. Voici les détails de vos modifications : votre vol de {origin1} à {destination1} qui était en {fareFamily1} passera à {newFareFamily} {alerts1} et votre vol de {origin2} à {destination2} qui était en {fareFamily2} il passera à {newFareFamily} {alerts2}",
      ctaCancel:
        "Bouton Annuler. Appuyez sur Entrée pour revenir en arrière et sélectionner une autre option.",
      ctaAccept:
        "Bouton Accepter. Appuyez sur Entrée pour accepter le changement.",
      fareFamilyDetailsLink:
        "Lien Familles tarifaires. Appuyez sur Entrée pour en savoir plus sur nos familles tarifaires.",
      closeButton:
        "Bouton de fermeture de la fenêtre modale. Appuyez sur le bouton pour fermer la fenêtre modale et sélectionner une autre option.",
    },
    fares: {
      BFU: "Classe Affaires Full",
      PRO: "Classe Affaires Promo",
      EFU: "Classe Économique Full",
      BAS: "Classe Économique Basique",
      CLS: "Classe Économique Classique",
      CLF: "Classe Économique Classique Flex",
      NONE: " ",
    },
    cardsResult: {
      rountripSearchLabel: "Aller-Retour",
      oneWaySearchLabel: "Aller simple",
      totalAmount: "Montant total à payer",
      changeItineraty: "Modifier l'itinéraire pour",
      changeItineratyPlural: "Modifier l'itinéraire de",
      optionText: "Voir l'option",
      optionTextPlural: "Voir les options",
      header: {
        buttonFilter: "Trier & Filtrer",
        economyCabin: " Classe Économique",
        businessCabin: " Classe Affaires",
      },
      headerWCAG: {
        languageAndStorefrontSelector: {
          countryDropdown:
            "Utilisez les touches fléchées haut et bas pour sélectionner le pays selon votre emplacement et la devise de votre préférence. Appuyez sur Tab pour continuer vers la sélection de la langue. Appuyez sur Échappe pour retourner à la navigation.",
          languageDropdown:
            "Utilisez les touches fléchées haut et bas pour sélectionner votre langue préférée. Appuyez sur Échappe pour retourner à la navigation.",
          modifyCTA:
            "Bouton Modifier. Appuyez sur Entrée pour modifier le pays en {currencyCountry} et la langue en {currencyLanguage}",
          closeCTA:
            "Bouton Fermer. Appuyez sur Entrée pour conserver le pays et la langue, retourner à la navigation principale.",
        },
        loginBox: {
          CredentialsLabel:
            "Entrez votre e-mail, numéro ou nom d'utilisateur ConnectMiles",
          PasswordLabel: "Entrez votre mot de passe ConnectMiles",
          loginButton:
            "Bouton de connexion. Appuyez sur Entrée pour vous connecter avec vos identifiants",
          fortgotPassword:
            "Lien Mot de passe oublié. Appuyez sur Entrée pour aller sur ConnectMiles et récupérer votre mot de passe",
          Close: "Bouton Fermer, appuyez sur Entrée pour fermer la fenêtre",
          InlineErrorMandatoryFiled: "Erreur. Ce champ est obligatoire",
          CreateAccountlink:
            "Lien pour créer un compte. Appuyez sur Entrée si vous n'avez pas de compte et souhaitez en créer un maintenant. La page ConnectMiles s'ouvrira dans un nouvel onglet de votre navigateur.",
          ErrorBannerAlertWrongInformation:
            "Erreur. Les informations que vous avez fournies ne correspondent pas à nos enregistrements. Veuillez vérifier vos saisies et réessayer.",
          ErrorBannerAlertBlockedAccount:
            "Erreur. Votre compte a été temporairement bloqué pour des raisons de sécurité. Veuillez contacter le Centre de Service ConnectMiles pour assistance. <lien vers le numéro du centre d'appel ConnectMiles>",
        },
        buttonFilter:
          "Bouton. Selon votre recherche, votre vol retour est le {day}",
      },
      tripCard: {
        viewDetails: "Voir les détails",
        flightDetailLayover: "Escale à",
        flightDetailLayovers: "Escales à",
        flightDetailDirect: "Sans escale",
      },
      tripCardWCAG: {
        viewDetailsDirect:
          "Informations sur le résultat du vol. Vol direct de {origin} à {destination} avec un départ à {departure} et une arrivée à {arrival}; du {day}, {month}, {year}, opéré par {airlines}. La durée totale du vol est de {flightTime}.",
        viewDetailsLayovers:
          "Informations sur le résultat du vol. Vol de {origin} à {destination} avec un départ à {departure} et une arrivée à {arrival}; du {day}, {month}, {year}, avec une escale de {layoverLength} {layoverTime} opérée par {airlines}. La durée totale du vol est de {flightTime}.",
        viewDetails:
          "Lien Voir les détails. Appuyez sur Entrée pour voir plus de détails sur le vol.",
        hours: "heures",
        hour: "heure",
        minutes: "minutes",
      },
      cabin: {
        cabinText1: " À partir de ",
        cabinText2: "Par adulte",
        cabinText3: " Épuisé ",
        economy: " Classe Économique",
        business: " Classe Affaires",
        unavailable: "Non disponible",
        helperTextMobile: "Sélectionnez un tarif",
        oneWay: "Aller simple",
      },
      cabinWCAG: {
        economyCabin:
          "Cabine Classe Économique. Prix à partir de {price} par adulte",
        economySoldOut: "Pas de disponibilité en cabine classe économique",
        businessCabin:
          "Cabine Classe Affaires. Prix à partir de {price} par adulte",
        businessSoldOut: "Pas de disponibilité en cabine classe affaires",
        economyCabinPromo:
          "Cabine Classe Économique. Prix à partir de {price} et avec la remise il est de {originalPrice} par adulte",
        businessCabinPromo:
          "Cabine Classe Affaires. Prix à partir de {price} et avec la remise il est de {originalPrice} par adulte",
        cabinHelperMobileText: "Cabine {type}. Sélectionnez un tarif",
      },
      fareFamilies: {
        basicPill: "Tarif le plus restrictif",
        selectedPill: "Vous avez choisi ce tarif",
        restrictivePill: "Tarif restrictif",
        purchased: "Tarif acheté",
        ctaBasic: "Sélectionnez Économique Basique",
        ctaClassic: "Sélectionnez Économique Classique",
        ctaFull: "Sélectionnez Économique Full",
        ctaFlex: "Sélectionnez Classique Flex",
        ctaExtra: "Sélectionnez",
        ctaBusinessPromo: "Sélectionnez Classe Affaires Promo",
        ctaBusinessFull: "Sélectionnez Classe Affaires Full",
        ctaBack: "Gardez ce tarif",
        linkText: "Détails des tarifs",
        link: "https://www.copaair.com/fr-gs/travel-information/fare-families/",
        changePenaltyDisclaimer:
          "La première modification sans frais de pénalité est applicable si elle est effectuée au moins huit jours avant la date de départ*.",
        fareFamilyDisclaimer:
          "Les prix affichés incluent les surcharges de carburant, les taxes et les frais. Consultez notre <link>politique de bagages</link> pour les vols exploités par Copa Airlines. Des frais supplémentaires peuvent s'appliquer pour les bagages en surpoids, surdimensionnés ou supplémentaires, ainsi que pour certains articles spéciaux.",
        baggagePolicyLink:
          "https://www.copaair.com/fr-gs/information-au-voyage/informations-de-bagages/",
        faresPolicyLink:
          "https://www.copaair.com/fr-gs/information-au-voyage/familles-de-tarifs/",
        preferMemberDisclaimer:
          "Les avantages pour les membres préférentiels varient en fonction du statut du membre.",
        priceIncludeDisclaimer:
          "Les prix incluent les frais de carburant, les taxes et les frais.",
        baggageDisclaimer:
          "Consultez notre <link>politique de bagages</link> pour les vols opérés par Copa Airlines. Des frais supplémentaires peuvent s'appliquer aux bagages en surpoids, surdimensionnés ou supplémentaires, ainsi qu'à certains articles spéciaux.",
        mixedFareFamiliesDisclaimer:
          "Lors de la sélection de <link>familles de tarifs</link> mixtes, les attributs les plus restrictifs des deux tarifs seront appliqués.",
        tooltipPillRestrictive:
          "L'un des vols a un tarif avec des restrictions sur les bagages, les sièges et les modifications. Cliquez sur 'voir les détails' pour en savoir plus.",
        baggagePolicyLinkWCAG:
          "Consultez notre politique en matière de bagages. Appuyez sur 'Entrée' pour plus de détails.",
        faresPolicyLinkWCAG:
          "Découvrez nos familles tarifaires et leurs avantages. Appuyez sur 'Entrée' pour plus de détails.",
      },
      fareFamiliesWCAG: {
        basicContainer:
          "Famille tarifaire Classe Économique Basique, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        classicContainer:
          "Famille tarifaire Classe Économique Classic, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        fullContainer:
          "Famille tarifaire Classe Économique Full, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        extraContainer:
          "Famille tarifaire Extra, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        businessPromoContainer:
          "Famille tarifaire Classe Affaires Promo, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        businessFullContainer:
          "Famille tarifaire Classe Affaires Full, ce tarif est proposé à {price} et présente les attributs suivants {attributeList}",
        cta: "Bouton Sélectionner Tarif. Appuyez sur le bouton pour sélectionner ce tarif ou cliquez sur Tab pour passer au tarif suivant.",
      },
    },
  },
  summaryPage: {
    title: "Votre nouvel itinéraire de voyage",
    subtitle:
      "Veuillez vérifier attentivement votre itinéraire avant de continuer.",
    changeFlight: "Modifier la sélection",
    summaryPageCTA: "Continuer",
    CTAButton:
      "Bouton Continuer. Appuyez sur le bouton pour poursuivre le processus d'achat.",
    titleCostChange: "Coût du changement",
    textCollapsible: "Changement sans coût supplémentaire",
    flightTypeForCookie1: "Aller-retour",
    flightTypeForCookie2: "Aller simple",
    flightTypeForCookie3: "Vols multi-destinations",
    flightTypeForCookie4: "Voyage avec escale",
    adults: "Adultes",
    adult: "Adulte",
    children: "Enfants",
    child: "Enfant",
    infants: "Bébés sur les genoux",
    infant: "Bébé sur les genoux",
    itinerary: {
      businessFull: "Classe Affaires Full",
      businessPromo: "Classe Affaires Promo",
      economyFull: " Classe Économique Full",
      economyBasic: " Classe Économique Basic",
      economyClassic: " Classe Économique Classic",
      viewDetails: "Voir les détails",
      outboundFlight: "Vol aller",
      returnFlight: "Vol retour",
      changeFlight: "Modifier le vol",
      fareDetails: "Règles tarifaires",
      layover: "Escale à",
      layovers: "Escales à",
      leg: "Vol {legNumber}",
      pillText: "Nouveau vol",
      noChangePillText: "Pas de changements",
    },
    priceBreakdown: {
      confirmationCode: "Code d'autorisation",
      newFare: "Nouveau tarif",
      oldFare: "Tarif original",
      credit: "Crédit",
      changePenalty: "Pénalité de modification de voyage",
      subTotalSubTitle: "Sous-total",
      differenceFare: "Différence de tarif",
      taxSubtitle: "Taxes et frais",
      upliftMessage:
        "Vous avez réalisé cet achat en utilisant Uplift. <a>En savoir plus.</a>",
      upliftUrl: "https://www.copaair.com/en/web/gs/payment-options-uplift",
      disclaimer:
        "En confirmant ce changement, vous recevrez un certificat de voyage d'un montant de {price} valable pour de futurs achats de billets avec Copa Airlines.",
      disclamerConfirmation:
        "Vous recevrez un certificat de voyage d'un montant de {price}, valable pour l'achat de futurs billets avec Copa Airlines.",
      chargedAmountSuccesfully:
        "Nous traitons votre paiement. Vous recevrez un email de confirmation de votre réservation",
      chargedAmountInsuranceSuccesfully:
        "Vous recevrez un courriel de confirmation de votre achat. Les frais d'assurance voyage seront indiqués séparément du reste du coût de la réservation.",
      disclaimerBton:
        "En cliquant sur confirmer le changement, vous modifierez votre réservation, et vous ne pourrez plus revenir en arrière ou faire d'autres modifications sur cette plateforme.",
      presidentialAlert:
        "{name}, en tant que PreferMember Présidentiel, vous bénéficiez de plus d'avantages. Pour vous exempter des frais de pénalité pour changement, veuillez contacter notre Centre de Réservation.",
    },
    itineraryNotAvailableModal: {
      title:
        "Nous sommes désolés, l'itinéraire sélectionné n'est plus disponible",
      description:
        "Un ou plusieurs vols que vous avez sélectionnés ne sont plus disponibles, et nous ne pouvons pas procéder à la modification de votre voyage. Veuillez recommencer votre recherche et choisir un nouvel itinéraire.",
      ctaButton: "Recommencer la recherche",
    },
    taxesModal: {
      title: "Détail des taxes et frais",
      subtitle: "Coût de la réservation",
      description: "Description",
      amount: "Montant",
      total: "Taxes et frais totaux",
      payedTaxes: "Taxes et frais déjà payés",
    },
  },
  summaryWCAG: {
    itinerary: {
      linkViewDetails:
        "Lien Voir les détails. Appuyez sur Entrée pour voir plus de détails sur le vol.",
      linkViewRules:
        "Lien pour voir les détails du tarif aérien. Appuyez sur Entrée pour voir les détails du tarif sélectionné.",
      linkModify:
        "Lien Modifier le vol, appuyez sur Entrée pour revenir à la page des résultats des vols et changer la sélection de vol.",
      containerOutboundWithOneScale:
        "Informations sur le vol aller sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; avec {numLayover} escale de {timeLayover} dans {codCountry}; la durée totale des vols est de {duration} avec la famille tarifaire {fare}.",
      containerInboundWithOneScale:
        "Informations sur le vol retour sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; avec {numLayover} escale de {timeLayover} dans {codCountry}; la durée totale des vols est de {duration} avec la famille tarifaire {fare}.",
      containerOutboundWithMultipleScales:
        "Informations sur le vol aller sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; avec {numLayover} escales de {timeLayover} dans {codCountry}; la durée totale des vols est de {duration} avec la famille tarifaire {fare}.",
      containerInboundWithMultipleScales:
        "Informations sur le vol retour sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; avec {numLayover} escales de {timeLayover} dans {codCountry}; la durée totale des vols est de {duration} avec la famille tarifaire {fare}.",
      containerOutboundWithoutScale:
        "Informations sur le vol aller sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; la durée totale du vol est de {duration} avec la famille tarifaire {fare}.",
      containerInboundWithoutScale:
        "Informations sur le vol retour sélectionné. Le vol est le {dateFlight}, de {startFlight} à {endFlight} avec un départ à {hourDeparture} et une arrivée à {hourArrival}; la durée totale des vols est de {duration} avec la famille tarifaire {fare}.",
    },
    itineraryNotAvailableModal: {
      container:
        "Message important. Certains des vols que vous avez choisis ne sont plus disponibles et nous ne pouvons pas procéder à la modification de votre réservation. Veuillez sélectionner un nouvel itinéraire.",
      ctaButton:
        "Bouton Modifier le vol. Appuyez sur le bouton pour sélectionner un autre itinéraire.",
      closeButton: "Appuyez sur le bouton pour fermer le modal.",
    },
    taxesModal: {
      container:
        "Modal taxes et frais. D'ici, vous pouvez voir quelles taxes et quels frais s'appliquent à votre réservation. Ce sont les taxes et les frais qui vous concernent.",
      closeButton:
        "Bouton de fermeture du modal taxes et frais. Appuyez sur le bouton pour fermer le modal et continuer avec l'achat.",
      openPayedTaxes:
        "Détail des frais et taxes déjà payés. Appuyez sur Entrée pour voir les détails des frais et taxes que vous avez initialement payés.",
    },
    priceBreakdown: {
      container:
        "Votre résumé de modification de voyage avec un sous-total de {subTotal}, taxes {taxTotal} et Total de {total}",
      linkViewDetails:
        "Lien Voir les détails des taxes. Appuyez sur Entrée pour voir plus de détails sur les taxes.",
    },
    presidentialAlertContainer:
      "Message important. Avantages pour les passagers avec le statut PreferMember Présidentiel.",
    presidentialAlertDescription:
      "{name}, être un PreferMember Présidentiel vous offre plus d'avantages. Pour être exempté des frais de pénalité de modification de voyage, veuillez contacter notre Centre de Réservation.",
  },
  summarySEO: {
    title: "Résumé du Voyage - Copa Airlines",
    description:
      "Résumé de la modification de votre voyage, examinez attentivement les informations de vos nouveaux vols. Une fois que vous aurez confirmé les informations, le changement sera effectué pour tous les passagers.",
    keywords:
      "Modifications, Vols, Changement de vols, changement de date, changement d'heures, changement de réservations, modifier vols, changement de voyage, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fareRulesModal: {
    title: "Règles Tarifaires",
    fare: "Tarif",
    headingDescription:
      "Ici, vous pourrez lire les règles tarifaires qui s'appliquent à chaque vol dans cette réservation.",
    headingCaption: "En savoir plus sur nos familles tarifaires.",
    headingLink:
      "https://www.copaair.com/fr-gs/informations-de-voyage/familles-tarifaires/",
    lineMessage:
      "Les informations sur les règles tarifaires ne sont pas disponibles pour ce vol car il n'est pas opéré par Copa Airlines.",
    errorMessage:
      "Désolé, les informations sur les règles tarifaires ne sont pas disponibles pour le moment. Veuillez réessayer plus tard.",
    WCAG: {
      modalDescription:
        "Modal Règles Tarifaires. Ici, vous pouvez voir quelles règles s'appliquent au tarif que vous avez sélectionné.",
      fareContent:
        "Vol de {iataOrigin} à {iataDestination}, numéro de vol {airlineCode} {flightNumber}, a les règles suivantes : {fareFamilyName}",
      backwardArrow:
        "Bouton Vol Précédent. Appuyez sur Entrée pour voir les règles tarifaires applicables au vol précédent.",
      forwardArrow:
        "Bouton Vol Suivant. Utilisez ce bouton pour voir les règles tarifaires applicables au vol suivant.",
      errorLineMsg:
        "Vol de [Code IATA Origine] à [code IATA destination], numéro de vol [Code compagnie aérienne + numéro], [tout le texte]",
      closeBotton:
        "Bouton de fermeture du modal Règles Tarifaires. Appuyez sur le bouton pour fermer le modal.",
    },
  },
  notEligiblePage: {
    heading: {
      flightCaption: "Dates du Voyage",
      fareCaption: "Famille Tarifaire",
      reservationCaption: "Code de Réservation",
    },
    withCancel: {
      title:
        "Pour modifier cette réservation, veuillez contacter notre Centre d'Appels.",
      description:
        "Nous ne sommes actuellement pas en mesure de modifier cette réservation en ligne. Veuillez contacter notre <a>Centre d'appels</a> ou visiter l'une de nos <b>agences de vente</b> pour gérer votre réservation.",
      linkReservation:
        "https://www.copaair.com/fr-gs/soutien-au-client/centre-dappels/",
      linkOffices:
        "https://www.copaair.com/fr-gs/soutien-au-client/bureau-de-ventes/",
      items: {
        title: "Quelques raisons possibles peuvent inclure:",
        item1: "Votre réservation a été émise il y a moins de 24 heures.",
        item2:
          "Votre réservation n'a pas été achetée via copa.com, le centre d'appels ou nos bureaux de vente.",
        item3: "Votre réservation bénéficie d'une remise spéciale appliquée.",
        item4:
          "Votre itinéraire inclut des vols opérés par une autre compagnie aérienne.",
        item5: "Votre réservation a été modifiée précédemment.",
        item6:
          "Votre itinéraire comporte plusieurs destinations ou inclut un séjour au Panama.",
        item7:
          "Vous avez acheté des services supplémentaires, tels que des équipements sportifs, des animaux de compagnie, entre autres.",
        item8:
          "Vous avez bénéficié d'une mise à niveau en Classe Affaires sur un ou plusieurs segments de votre réservation.",
        item9:
          "Vous avez déjà complété une partie de votre itinéraire de voyage.",
      },
    },
    cancelTitle: "Autres options disponibles",
    cancelButtonText: "Annuler ma réservation",
    helpSection: {
      message: "Besoin d'aide ?",
      reservationOption: {
        text: "Centre d'appels",
        link: "https://www.copaair.com/fr-gs/soutien-au-client/centre-dappels/",
      },
      salesOption: {
        text: "Bureaux de vente",
        link: "https://www.copaair.com/fr-gs/soutien-au-client/bureau-de-ventes/",
      },
      helpOption: {
        text: "Centre d'Information",
        link: "https://help.copaair.com/hc/fr-fr",
      },
    },
  },
  notEligiblePageWCAG: {
    title: "Réémission non éligible - Copa Airlines",
    container:
      "Message important. La réservation n'est pas éligible pour effectuer des modifications.",
    cancelButton:
      "Bouton Annuler ma réservation. Appuyez sur Entrée pour aller à la page des options d'annulation de réservation.",
    helpSection: {
      reservationOption:
        "Lien vers le centre d'appels. Besoin d'aide ? Appuyez sur Entrée pour accéder à la page du centre d'appels.",
      salesOption:
        "Lien vers les Agences de Vente. Besoin d'aide ? Appuyez sur Entrée pour aller à la page des Agences de Vente.",
      helpOption:
        "Lien vers le Centre d'Aide. Besoin d'aide ? Appuyez sur Entrée pour aller à la page du Centre d'Aide.",
    },
  },
  confirmation: {
    heading: {
      title: "C'est fait ! Vous avez complété votre modification",
      titleWithPricelock:
        "Prêt ! Votre réservation a été enregistrée avec succès",
      description:
        "Vous recevrez bientôt une confirmation de votre modification au courriel <b></b>. ",
      descriptionWithPay:
        "Nous traitons actuellement votre paiement. Vous recevrez bientôt une confirmation avec le billet électronique de votre réservation au courriel <b></b>.",
      codeText: "Code de Réservation",
      btnReservation: "Retourner à Mes voyages",
      btnPrint: "Imprimer l'itinéraire",
      alert:
        "Pour éviter que votre réservation ne soit automatiquement annulée, n'oubliez pas de compléter votre achat au plus tard le",
      pseAlert:
        "Nous traitons la modification de votre voyage, ce qui peut prendre quelques minutes. Pendant cette période, vous pourriez ne pas être en mesure d'accéder aux informations de votre réservation. Une fois terminé, vous recevrez un courriel avec votre nouveau e-billet.",
    },
    itineratyTitle: "Votre nouvel itinéraire de voyage",
    passengerInfo: {
      title: "Passagers dans cette réservation",
      subtitle:
        "Voici les passagers qui voyageront. Veuillez vérifier leurs informations avant de procéder. Une fois votre réservation complétée, vous pouvez sélectionner ou modifier vos sièges dans 'Mes Voyages'.",
      adult: "Adulte",
      child: "Enfant",
      infant: "Bébé sur les genoux",
      notSeatSelected: "N'oubliez pas de sélectionner les sièges ",
    },
    multiCityText: "Vol Multi-Villes",
    errorCreditCards:
      "Désolé, il n'a pas été possible d'enregistrer la carte de crédit dans votre profil. Pour votre prochain achat, vous devrez réentrer les informations de votre carte.",
    errorFQTV:
      "Désolé, il n'a pas été possible d'enregistrer le numéro de voyageur fréquent {passagers} dans votre réservation. Pour l'ajouter, veuillez aller à Mes Voyages et l'ajouter à la section informations sur le passager.",
    insuranceTitle:
      "Informations importantes concernant votre assurance voyage",
    insurancePaymentAlert:
      "Vous avez acheté une assurance voyage auprès de notre allié stratégique CHUBB. Pour traiter la modification de ce service, vous devez contacter directement CHUBB à <a> ces points de contact</a>, en utilisant votre code de réservation comme référence.",
    insuranceContactLink:
      "https://copa.chubbtravelinsurance.com/copa/pa-fr/home.html#",
    description:
      "Page de confirmation de réservation de voyage. Nous détaillons tout le résumé de votre achat.",
    keywords:
      "Vols, Voyage, Réservation, billet de voyage, billet de voyage, achats sécurisés, transactions sécurisées,, Copaair, copa.com, copaair.com, Copa Airlines.",
    descriptionPage: "Confirmation",
    titlePage: "Page de Confirmation",
    disclaimers: {
      retract: {
        title:
          "Informations importantes pour les passagers voyageant depuis le Chili",
        body: "Cet achat n'est pas soumis au droit de rétractation établi dans la Loi Chilienne sur la Consommation.",
      },
      bankTransfer: {
        title:
          "Informations relatives au virement bancaire comme moyen de paiement",
        body: "Le moyen de paiement alternatif du virement bancaire est uniquement disponible si vous effectuez votre achat au moins 14 jours avant votre voyage.",
      },
      bankTicket: {
        title:
          "Informations relatives au boleto bancario comme moyen de paiement",
        body: "Le moyen de paiement alternatif du boleto bancario est uniquement disponible si vous effectuez votre achat au moins 14 jours avant votre voyage.",
      },
      OTP: {
        title: "Performance ponctuelle concernant vos vols",
        body: 'Si vous voyagez vers/depuis le Brésil, vous pouvez vérifier le pourcentage de retards et d\'annulations de vols en cliquant sur le lien "Voir plus de détails" pour chaque vol.',
      },
    },
    pse: {
      title: "Informations de paiement (PSE Colombie)",
      subtitle:
        "Message important : Nous traitons votre paiement. Vous recevrez un e-mail avec la confirmation de votre réservation",
      info: {
        nit: "NIT",
        bank: "Banque",
        company: "Entreprise",
        refNumber: "Numéro de référence",
        paymentDesc: "Description du paiement",
      },
    },
    aditionalServices: {
      title: "Services additionnels",
      aditionalText:
        "Votre achat d'assurance voyage auprès de notre allié stratégique pour la somme de {price} est en cours de traitement. Ils vous enverront bientôt la confirmation de paiement et la police correspondant au service que vous avez acheté.",
      aditionalText2:
        "Pour tout échange, remboursement, demande ou réclamation liée, contactez directement CHUBB à ces <link>points de contact</link>, en utilisant votre code de réservation comme référence.",
      contactPointsLink:
        "https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=fr",
    },
    whereToPaySection: {
      whereToPayTitle: "Comment payer votre réservation :",
      webText: "Via “Mes Voyages” sur",
      webLink: "https://www.copaair.com/",
      webLinkText: "copa.com",
      callingTo: "En appelant notre ",
      callingToLink: "https://www.copaair.com/fr/web/gs/centre-de-reservation",
      callingToLinkText: "Centre de Réservation",
      callingToText2: "(uniquement paiements par carte de crédit)*",
      anotherWayOfPayment: "Vous pouvez également payer dans nos",
      anotherWayOfPaymentLink:
        "https://www.copaair.com/fr/web/gs/bureaux-de-vente",
      anotherWayOfPaymentLinkText: "agences de vente*",
      anotherWayOfPayment2: "ou au comptoir de l'aéroport*",
      disclaimer1:
        "*Si vous faites une réservation via copa.com et souhaitez ensuite payer via notre Centre de Réservation, nos agences de vente ou à l'aéroport, des frais administratifs non remboursables et/ou d'autres charges supplémentaires non remboursables seront appliqués au coût total du billet. Lisez notre ",
      disclaimerLink:
        "https://www.copaair.com/fr/web/gs/conditions-generales-de-vente-et-de-reservation",
      disclaimerLinkText: " Modalités et conditions de PriceLock",
      disclaimer2:
        "pour plus d'informations. Ces tarifs ne sont pas valables pour l'émission ou le paiement dans nos bureaux en Argentine.",
    },
    priceBreakownPriceLock: "Vous avez payé aujourd'hui",
    priceBreakdownDisclaimerText:
      "Vous avez jusqu'au {date} pour compléter votre achat.",
  },
  confirmationWCAG: {
    descriptionPage:
      "La modification de votre voyage a été complétée et votre paiement est en cours de traitement. Votre code de réservation est {codeReservation}. Une fois votre paiement traité, vous recevrez un e-mail de confirmation à {email}",
    heading: {
      btnReservation:
        "Bouton Aller à Mes Voyages. Appuyez sur Entrée pour aller à Mes Voyages et voir les détails de votre vol.",
      btnPrint:
        "Bouton Imprimer la page. Appuyez sur Entrée pour imprimer cette page comme preuve que votre réservation est en cours de traitement.",
    },
    passengersWCAG: {
      linkSeat:
        "Lien de sélection de siège. Appuyez sur Entrée pour aller à Mes Voyages et sélectionner vos sièges.",
      withSeat:
        "Voici les passagers qui voyageront sur cette réservation : {TypePax} Passager {namePax} {surnamePax}, avec date de naissance {birthdate}, avec siège {seat}.",
      withOutSeat:
        "Voici les passagers qui voyageront sur cette réservation : {TypePax} Passager {namePax} {surnamePax}, avec date de naissance {birthdate}, n'a pas de siège sélectionné. Vous pouvez sélectionner un siège après avoir complété l'achat dans Mes Voyages.",
    },
  },
  viewMoreDetailsModal: {
    title: "Détails du voyage",
    subtitlesCards: "Segment de vol {currentflight} sur {total}",
    date: "Date",
    Flight: "Vol",
    subtitleTo: "à",
    aircraftType: "Type d'avion",
    operatedBy: "Exploité par",
    button: "Voir les sièges disponibles",
    layover: "ESCALE",
    fareFamilyText: "Vous voyagez en {fare} :",
    viewMoreText: "Voir plus de détails sur les familles tarifaires",
    viewMoreLink:
      "https://www.copaair.com/fr-gs/informations-de-voyage/familles-tarifaires/",
    OTPTitle: "Pourcentage d'annulations et de retards",
    OTPCancellations: "Annulations",
    OTPDelays: "Retards de plus de 30 min",
    OTPDelays2: "Retards de plus de 60 min",
    mixedFareFamilies:
      "Vous avez sélectionné des vols avec différentes familles tarifaires, certains de ces avantages peuvent être limités.",
    navigationText: "Vol {flightNumber} sur {numberOfFlights}",
    restrictiveFare:
      "Ce résultat est en tarif Basic, qui est notre tarif le plus restrictif et a les restrictions suivantes : Bagages avec coût supplémentaire et pré-sélection des sièges avec coût (ou attribution gratuite à l'aéroport).",
    WCAG: {
      modalDescription: "Section itinéraire de vol",
      itinerarySummary:
        "Informations sur le résultat du vol. Vol de {origin} à {destination} avec un départ à {departureTime} et une arrivée à {arrivalTime}; du {dateFormat}, avec {numberOfLayovers} escale de {timeLayover} opéré par {airlineName}. La durée totale du vol est de {journeyTime}",
      segmentSummary:
        "Informations sur le vol {currentflight} sur {total} de {origin} à {departureTime} arrivant à {destination} à {arrivalTime} le {date}, avec le numéro de vol {airlineCode}, avec le modèle d'avion {aircraftName} et finalement opéré par {airlineName}",
      segmentSummaryWithOTP:
        "Informations sur le vol {currentflight} sur {total} de {origin} à {departureTime} arrivant à {destination} à {arrivalTime} le {date}, avec le numéro de vol {airlineCode}, avec le modèle d'avion {aircraftName} et finalement opéré par {airlineName}. Informations sur le pourcentage d'annulations et de retards de ce vol. Informations sur les annulations. Ce vol a un pourcentage de {percent1} d'annulations. Informations sur les retards de plus de 30 min. Ce vol a un pourcentage de {percent2} de retards de plus de 30 min. Informations sur les retards de plus de 60 min. Ce vol a un pourcentage de {percent3} de retards de plus de 60 min.",
      availaibleSeats:
        "Bouton pour voir les sièges disponibles. Appuyez sur le bouton pour voir la disponibilité des sièges sur ce vol.",
      // De la ligne suivante à OTPText3, elles sont lues dans l'id de segment SummaryWithOTP
      OTPTitle:
        "Informations sur le pourcentage d'annulations et de retards de ce vol.",
      OTPText1:
        "Informations sur les annulations. Ce vol a un pourcentage de {percentage} d'annulations.",
      OTPText2:
        "Informations sur les retards de plus de 30 min. Ce vol a un pourcentage de {percentage} de retards de plus de 30 min.",
      OTPText3:
        "Informations sur les retards de plus de 60 min. Ce vol a un pourcentage de {percentage} de retards de plus de 60 min.",
      fareFamilyText:
        "Vous voyagez en {fare} : Voici les avantages correspondant à cette famille tarifaire {list}.",
      viewMoreLink:
        "Lien pour voir plus de détails de la famille tarifaire. Appuyez sur Entrée pour voir les détails de la famille tarifaire.",
      mixedFareFamilies:
        "Alerte. Vous avez sélectionné des vols avec différentes familles tarifaires, certains de ces avantages peuvent être limités.",
      closeBotton:
        "Bouton de fermeture du modal pour voir les détails du vol. Appuyez sur le bouton pour fermer le modal.",
      backwardArrow:
        "Bouton aller au vol précédent. Appuyez sur ce bouton pour voir les détails du vol précédent.",
      forwardArrow:
        "Bouton aller au vol suivant. Appuyez sur Entrée pour voir les détails du vol suivant.",
    },
  },
  interstitial: {
    loading: "Chargement",
    title: "Votre sécurité est notre priorité",
    description:
      "Nous avons renforcé nos protocoles de biosécurité afin que vous puissiez voyager sans souci.",
    wcagDescription: "Votre page est en cours de chargement",
  },
  endSession: {
    endSessionTitle: "Votre recherche a expiré",
    endSessionBoddy:
      "Le temps imparti pour compléter cette réservation est écoulé. Vous pouvez commencer une nouvelle recherche.",
    endSessionBtn: "Recommencer",
    endSessionWCAG:
      "Message de session expirée. Le temps imparti pour effectuer la modification a expiré, par conséquent, votre modification n'a pas été complétée, veuillez recommencer.",
    endSessionBtnWCAG: "Retour au début",
  },
  paymentErrorModal: {
    title: "Nous n'avons pas pu traiter votre paiement.",
    errorMessage:
      "Vous avez épuisé toutes les tentatives de traitement du paiement.",
    body: "Pour compléter ce changement, veuillez nous contacter via le <a> Centre de Réservation. </a>",
    bodyLink:
      "Pour compléter ce changement, veuillez nous contacter via le Centre de Réservation.",
    link: "https://www.copaair.com/fr/web/us/centre-de-reservations",
    btnText: "Retourner à Mes Voyages",
    btnDecriptionWCAG:
      "Bouton Aller à Mes Voyages. Appuyez sur Entrée pour aller à Mes Voyages et voir les détails de votre vol.",
    descriptionWCAG:
      "Message d'erreur. Désolé, vous avez épuisé toutes les tentatives de traitement du paiement. Pour compléter ce changement, veuillez nous contacter via le Centre de Réservation.",
  },
  criticalErrorPage: {
    titlePage: "Erreur",
    title: "Nous sommes désolés",
    description:
      "Nous n'avons pas pu traiter votre modification, veuillez contacter notre Centre de Réservation pour effectuer ce processus.",
    retryButton: "Contacter le Centre de Réservation",
    helpText: "Besoin d'aide ?",
    reservationCenter: "Centre de Réservation",
    salesOffice: "Bureaux de Vente",
    helpDesk: "Centre d'Information",
    reservationCenterLink:
      "https://www.copaair.com/fr/web/gs/centre-de-reservations",
    salesOfficeLink: "https://www.copaair.com/fr/web/gs/bureaux-de-vente",
    helpDeskLink: "https://help.copaair.com/hc/fr-fr",
  },
  criticalErrorPageWCAG: {
    cta: "Erreur. Nous n'avons pas pu traiter votre modification, veuillez contacter notre Centre de Réservation pour effectuer ce processus.",
    reservationCenter:
      "Lien vers le Centre de Réservation. Appuyez sur Entrée pour aller à la page du Centre de Réservation.",
    salesOffice:
      "Lien vers les Bureaux de Vente. Appuyez sur Entrée pour aller à la page des Bureaux de Vente.",
    helpDesk:
      "Lien vers le Centre d'Aide. Appuyez sur Entrée pour aller à la page du Centre d'Aide.",
  },
  modifyLanguageModal: {
    title: "Cette action réinitialisera votre recherche",
    subText:
      "Cette action réinitialisera votre recherche. Toute sélection de vols sera perdue. Êtes-vous sûr de vouloir continuer ?",
    continueCTA: "Continuer avec les changements",
    stayCTA: "Rester dans cette recherche",
  },
  modifyLanguageModalWCAG: {
    title: "Cette action réinitialisera votre recherche",
    container:
      "Cette action réinitialisera votre recherche. Toute sélection de vols sera perdue. Êtes-vous sûr de vouloir continuer ?",
    continueCTA:
      "Bouton Continuer. Appuyez sur Entrée pour continuer avec le changement",
    stayCTA:
      "Rester dans cette recherche. Bouton. Appuyez sur Entrée pour conserver le pays et la langue, retourner à la navigation principale",
    closeButton:
      "Bouton Fermer. Appuyez sur Entrée pour conserver le pays et la langue, retourner à la navigation principale",
  },
}
