import { createSlice } from "@reduxjs/toolkit"
import initialState from "./authInitialState"

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.pending = true
    },
    loginFailure: (state, action) => {
      state.pending = false
      state.error = action.payload
      state.loggedIn = false
    },
    loginSuccess: (state, action) => {
      state.pending = false
      state.userInfo = action.payload
      state.error = ""
    },
    passengerProfileExtendedRequest: (state) => {
      state.pending = true
    },
    passengerProfileExtendedFailure: (state) => {
      state.pending = false
      state.loggedIn = false
    },
    passengerProfileExtendedSuccess: (state, action) => {
      state.pending = false
      state.passengers = action.payload
      state.loggedIn = true
      state.error = ""
    },
    logoOutRequest: (state) => {
      state.pending = null
      state.userInfo = {}
    },
    // Payload must be an Boolean: true -> Success, false -> Failure
    catchLogoutEvent: (state) => state,
  },
})

export default authSlice.reducer

export const { actions: authActions } = authSlice
