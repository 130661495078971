import React from "react"
import PropTypes from "prop-types"
import Hidden from "@material-ui/core/Hidden"
import clsx from "clsx"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { useIntl } from "react-intl"
import DividerCo from "@bit/vibenitez.copa-components-library.divider"
import { ReactComponent as SwapIcon } from "../../../../../ToLibrary/icons/Arrow-Right.svg"
import useStyles from "./styles"
import { backToOriginApplication } from "../../../../../utils/generalUtils"

const ReturnButton = ({ toShow, pnr, lastName, language }) => {
  const classes = useStyles({ language })
  const { formatMessage } = useIntl()

  const backToMytrips = () => {
    backToOriginApplication(pnr, lastName)
  }

  return (
    <>
      {toShow && (
        <>
          <button
            className={classes.returnToMyTrips}
            type="button"
            onClick={backToMytrips}
            aria-label={formatMessage({
              id: "topBarWCAG.backToMytrips",
            })}
            data-cy="backToMytrips-button"
          >
            <SwapIcon className={classes.swapedIcon} />
            <TypographyCo
              data-cy="backToMytrips-title"
              className={classes.textBack}
            >
              {formatMessage({ id: "topBar.backToMytrips" })}
            </TypographyCo>
          </button>
          <Hidden only={["xs"]}>
            <div className={clsx(classes.dividerHalf, classes.dividerMyTrips)}>
              <DividerCo
                id="7"
                base="darkSolid"
                orientation="vertical"
                flexItem
                aria-hidden="true"
              />
            </div>
          </Hidden>
        </>
      )}
    </>
  )
}
ReturnButton.propTypes = {
  toShow: PropTypes.bool.isRequired,
  pnr: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default ReturnButton
