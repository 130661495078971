import { createSlice } from "@reduxjs/toolkit"
import { criticalError } from "./generalUtils"
import initialState from "./generalInitialState"

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    logError: () => {},
    setLang: (state, { payload: lang }) => {
      state.lang = lang
    },
    updLangAndStoreFront: (state, { payload: { localLang, storeFront } }) => {
      state.lang = localLang
      state.country = storeFront
    },
    startLoading: (state, { payload }) => {
      state.loading += 1
      state.isInterstitialLoading = !!payload
    },
    stopLoading: (state) => {
      state.loading -= 1
    },
    setModalMemberLogin: (state, { payload: loginBox }) => {
      state.openLoginBox = loginBox
    },
    setFatalErrorRedirect: (state, action) => {
      state.fatalErrorRedirect = action.payload
    },
    fetchNotificationService: (state) => {
      return state
    },
    handleError: (state, action) => {
      state.fatalErrorRedirect = criticalError(action.payload)
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload
    },
    setFullAlertWithoutClose: (state, { payload }) => {
      state.displayFullAlert = payload
      state.hideIconClose = true
    },
    setFullAlertMessage: (state, action) => {
      state.fullAlertMessage = action.payload
    },
    displayFullAlert: (state) => {
      state.displayFullAlert = !state.displayFullAlert
      state.hideIconClose = false
    },
  },
})

export default generalSlice.reducer

// Actions
export const { actions: generalActions } = generalSlice
