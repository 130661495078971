import React from "react"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import PropTypes from "prop-types"
import { ReactComponent as InfoIcon } from "../../../../../../ToLibrary/icons/Info-Fill.svg"

import useStyles from "./styles"

const AlertText = ({ message }) => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-cy="info-segment">
      <div className="alert-icon">
        <InfoIcon />
      </div>
      <TypographyCo data-cy="info-segment-text" className="alert-text">
        {message}
      </TypographyCo>
    </div>
  )
}

AlertText.propTypes = {
  message: PropTypes.string.isRequired,
}

export default AlertText
