import { takeEvery, call, put, select } from "redux-saga/effects"

import TYPES from "./airportsTypes"
import api from "../../api"
import { airportsActions } from "../../store/airports/airportsSlice"
import {
  originsSelector,
  originKeyToDestinationSearchSelector,
} from "../../store/airports/airportsUtils"
import { generalActions } from "../../store/general/generalSlice"

function* fetchAirportsOrigins() {
  try {
    const origins = yield select(originsSelector)
    if (!origins.length) {
      const { data } = yield call(api.airports.origins)
      yield put(airportsActions.setOrigins(data))
    }
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(airportsActions.setErrorOrigin())
  }
}

function* fetchAirportsDestinations({ payload }) {
  try {
    const originKey = yield select(originKeyToDestinationSearchSelector)
    if (originKey !== payload) {
      const { data } = yield call(api.airports.destinations, payload)
      yield put(
        airportsActions.setMulticityAirportsDestinations({
          data,
          index: payload.id,
        })
      )
    }
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(airportsActions.setErrorDestination())
  }
}

function* fetchMulticityAirportsDestination({ payload }) {
  try {
    if (payload) {
      const { data } = yield call(api.airports.destinations, payload.origin)
      yield put(
        airportsActions.setMulticityAirportsDestinations({
          data,
          index: payload.id,
        })
      )
    }
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(airportsActions.setErrorDestination())
  }
}

function* airportsSaga() {
  yield takeEvery(TYPES.FETCH_AIRPORTS_ORIGINS, fetchAirportsOrigins)
  yield takeEvery(TYPES.FETCH_AIRPORTS_DESTINATIONS, fetchAirportsDestinations)
  yield takeEvery(
    TYPES.FETCH_MULTICITY_AIRPORTS_DESTINATION,
    fetchMulticityAirportsDestination
  )
}

export default airportsSaga
