export const originsSelector = (state) => state.airports.origins

export const destinationsSelector = (state) => state.airports.destinations

export const originKeyToDestinationSearchSelector = (state) =>
  state.airports.originKeyToDestinationSearch

const getAirportsCode = (flights, key) =>
  flights?.map((f) => ({
    code: f[key]?.airportCode,
    city: f[key]?.cityName,
  }))

export const setOriginsFromSessionUtility = (originDestination) =>
  originDestination?.reduce((sum, od) => {
    const departures = getAirportsCode(od.flights, "departure")
    const arrivals = getAirportsCode(od.flights, "arrival")
    return sum.concat(departures, arrivals)
  }, [])
