import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { useIntl } from "react-intl"
import Seo from "../Seo"

const UserRouter = ({
  layout: Layout,
  component,
  layoutProps,
  ariaText,
  ariaTextProps,
  idContainer,
  pageTitle,
  metaTypeSEO,
  keywords,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const formattedKeywords = keywords.map((keyword) =>
    formatMessage({ id: keyword })
  )
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Seo
            ariaText={formatMessage({ id: ariaText }, ariaTextProps)}
            id={idContainer}
            title={formatMessage({ id: pageTitle })}
            keywords={formattedKeywords}
            metaTypeSEO={formatMessage({ id: metaTypeSEO })}
            {...rest}
          >
            <Layout {...layoutProps}>
              {React.cloneElement(component, { ...props })}
            </Layout>
          </Seo>
        )
      }}
    />
  )
}

UserRouter.propTypes = {
  component: PropTypes.node.isRequired,
  layout: PropTypes.objectOf(PropTypes.any).isRequired,
  layoutProps: PropTypes.shape({
    headerVariant: PropTypes.string,
    searchDone: PropTypes.bool,
  }),
  ariaText: PropTypes.string.isRequired,
  ariaTextProps: PropTypes.objectOf(PropTypes.any),
  idContainer: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  metaTypeSEO: PropTypes.string.isRequired,
}

UserRouter.defaultProps = {
  ariaTextProps: {},
  layoutProps: {},
}

export default UserRouter
