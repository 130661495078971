import { makeStyles } from "@material-ui/core/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const { palette } = theme

const useStyles = makeStyles(() => ({
  root: ({ bgColor, fontSize, fontColor, fontWeight, width }) => ({
    padding: "9px 18px",
    backgroundColor: bgColor,
    color: fontColor,
    fontWeight,
    fontSize,
    width,
    "& .MuiAlert-message": {
      padding: "0 0 1px",
      width: "100%",
    },
    "& .MuiAlert-icon": {
      marginRight: 14,
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 18px 14px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 12px 14px",
    },
  }),
  alertAction: ({
    bgColor,
    fontSize,
    fontColor,
    fontWeight,
    baseColor,
    width,
  }) => ({
    boxShadow:
      "0 4px 5px 0 rgba(0,0,0,0.07), 0 3px 15px 0 rgba(0,0,0,0.08), 0 2px 4px -1px rgba(0,0,0,0.12)",
    padding: [11, 24, 13, 16],
    borderRadius: 4,
    backgroundColor: bgColor,
    color: fontColor,
    fontWeight,
    fontSize,
    width,
    "& .alertContainer:last-child": {
      "& .divider": {
        display: "none",
      },
    },
    "& .MuiAlertTitle-root": {
      marginBottom: 0,
    },
    "& .MuiAlert-message": {
      paddingTop: 18,
      width: "100%",
      "& .divider": {
        margin: "15px 0",
      },
    },
    "& .MuiAlert-icon": {
      right: 10,
      position: "relative",
      borderLeft: `8px solid ${baseColor}`,
      color: palette.alternative.gold,
      borderRadius: 4,
      fontSize: 24,
      marginRight: 0,
      paddingLeft: 5,
      paddingTop: 16,
      "& .MuiSvgIcon-root": {
        marginLeft: 10,
        display: "none",
      },
    },
  }),
  closeAction: {
    backgroundColor: palette.grey[300],
    margin: 10,
    height: 32,
    width: 32,
    color: palette.common.white,
    "&:hover": {
      backgroundColor: palette.grey[300],
    },
    "& .MuiSvgIcon-root": {
      width: 23,
      height: 23,
    },
  },
  buttonContainer: {
    margin: "12px 0",
  },
  action: {
    alignItems: "baseline",
  },
  iconItem: {
    marginRight: 10,
  },
  titleMessage: {
    fontWeight: 500,
    marginBottom: "8px",
  },
  textMessage: {
    display: "block",
  },
}))

export default useStyles
