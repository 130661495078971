import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import SvgIcon from "@material-ui/core/SvgIcon"
import { useHistory } from "react-router-dom"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { ReactComponent as ArrowRight } from "../../../../ToLibrary/icons/Arrow-Right.svg"
import { ReactComponent as ArrowLeft } from "../../../../ToLibrary/icons/Arrow-Left.svg"

const SegmentNav = ({ handleClick, maxValue, flightIndex }) => {
  const history = useHistory()
  const urlPathName = history.location.pathname.split("/")[1]

  const { formatMessage } = useIntl()
  const canPrevClick = flightIndex > 0
  const canNextClick = flightIndex + 1 < maxValue
  const handlePreviousClick = () => {
    if (canPrevClick) {
      handleClick(flightIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (canNextClick) {
      handleClick(flightIndex + 1)
    }
  }

  return (
    <div
      data-cy="moreDetailsSegmentNav-navContent"
      className="navContent"
      aria-label=""
    >
      {urlPathName === "summary" && (
        <div>
          <ButtonCo
            data-cy="moreDetailsSegmentNav-backwardArrow"
            className={`navButton ${canPrevClick ? "" : "disabledNav"}`}
            aria-label={
              canPrevClick
                ? formatMessage({
                    id: "viewMoreDetailsModal.WCAG.backwardArrow",
                  })
                : ""
            }
            color="inherit"
            tabIndex={canPrevClick ? 0 : -1}
            onClick={handlePreviousClick}
          >
            <SvgIcon component={ArrowLeft} />
          </ButtonCo>
          <ButtonCo
            data-cy="moreDetailsSegmentNav-forwardArrow"
            className={`navButton nextButton ${
              canNextClick ? "" : "disabledNav"
            }`}
            aria-label={
              canNextClick
                ? formatMessage({
                    id: "viewMoreDetailsModal.WCAG.forwardArrow",
                  })
                : ""
            }
            color="inherit"
            onClick={handleNextClick}
            tabIndex={canNextClick ? 0 : -1}
          >
            <SvgIcon component={ArrowRight} />
          </ButtonCo>
          <TypographyCo
            data-cy="moreDetailsSegmentNav-flightsText"
            variant="caption"
            className="navText"
          >
            {formatMessage(
              {
                id: "viewMoreDetailsModal.navigationText",
              },
              {
                flightNumber: flightIndex + 1,
                numberOfFlights: maxValue,
              }
            )}
          </TypographyCo>
        </div>
      )}
    </div>
  )
}

SegmentNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  maxValue: PropTypes.number.isRequired,
  flightIndex: PropTypes.number.isRequired,
}

export default SegmentNav
