import React from "react"
import PropTypes from "prop-types"
import SvgIcon from "@material-ui/core/SvgIcon"
import { makeStyles } from "@material-ui/core/styles"

import theme from "@bit/vibenitez.copa-components-library.theme"
import { ReactComponent as InfoFull } from "./assets/Info-Fill.svg"

const useStyles = makeStyles(() => ({
  iconInfo: {
    width: 20,
    height: 20,
  },
}))

const IconInfo = ({ color, ...props }) => {
  const classes = useStyles()
  return (
    <SvgIcon {...props} htmlColor={color} className={classes.iconInfo}>
      <InfoFull />
    </SvgIcon>
  )
}

IconInfo.propTypes = {
  color: PropTypes.string,
}

IconInfo.defaultProps = {
  color: theme.palette.common.white,
}

export default IconInfo
