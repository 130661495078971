import initialState from "./flightInitialState"
import { createSliceWithReset } from "../storeUtils"

const flightSlice = createSliceWithReset({
  name: "flight",
  initialState,
  reducers: {
    setFlightDetailsData: (state, action) => {
      const { flightDetailsModal } = state
      flightDetailsModal.isVisible = true
      flightDetailsModal.data = action.payload
    },
    setFlightsDataModal: (state, { payload }) => {
      state.flightsDataModal = payload
    },
    hideFlightDetailsData: (state) => {
      const { flightDetailsModal } = state
      flightDetailsModal.isVisible = false
      // flightDetailsModal.data = {}
    },
    fetchSaveSearch: (state, action) => {
      const { payload } = action
      state.saveSearch = payload
    },
    fetchOnlyDate: (state, action) => {
      const { payload } = action
      state.onlyDate = payload
    },
    fetchFlights: (state) => {
      state.fetching = true
      state.error = ""
    },
    setFlights: (state, action) => {
      const {
        payload: { pnrNumber, numberOfTravelers, ods, offerGroups },
      } = action

      state.isLoaded = true
      state.pnrNumber = pnrNumber
      state.numberOfTravelers = numberOfTravelers
      state.ods = ods

      const solutions = []
      ods.map((originD) =>
        originD.solutions.map((solution) => {
          solutions.push({
            ...solution,
            originDestination: {
              destination: originD.destination,
              origin: originD.origin,
              originDestinationKey: originD.originDestinationKey,
            },
          })
          return solution
        })
      )
      state.solutions = solutions
      state.offers = offerGroups

      state.fetching = false
      state.error = ""
    },
    errorFlights: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = payload
    },
    setFlightsSelected: (state, { payload }) => {
      const { reShopQuery, ...flightsData } = payload
      state.flightsSelected = flightsData
      state.reShopQuery = reShopQuery
    },
    setErrorRedirect: (state, { payload }) => {
      state.redirectError = payload
    },
    setChangeFareModalOptionSelected: (state, { payload }) => {
      state.fareChangeModalOptionSelected = payload
    },
  },
})

export default flightSlice.reducer

export const { actions: flightsActions } = flightSlice
