import React, { useState } from "react"
import { connect } from "react-redux"
import Container from "@material-ui/core/Container"
import SvgIcon from "@material-ui/core/SvgIcon"
import Right from "@material-ui/icons/ChevronRight"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import moment from "moment"
import clsx from "clsx"

import isUndefined from "lodash/isUndefined"
import isEmpty from "lodash/isEmpty"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import AvatarCo from "@bit/vibenitez.copa-components-library.avatar"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import theme from "@bit/vibenitez.copa-components-library.theme"
import PillCo from "../../../../ToLibrary/Pill/pillCo"
import { ReactComponent as ArrowRight } from "../../../../ToLibrary/icons/Arrow-Right.svg"
import { ReactComponent as FlightTilted } from "../../../../ToLibrary/icons/Flight-Tilted.svg"
import { ReactComponent as Time } from "../../../../ToLibrary/icons/Time-Outline.svg"
// import { ReactComponent as Pin } from "../../../../ToLibrary/icons/MapPin.svg"

import FareRulesDetails from "../fareRulesDetails"
import TooltipCO from "../../../../ToLibrary/tooltip/tooltipCo"
// import DividerCo from "../../UI/divider/dividerCo"
import useStyles from "./styles"

import {
  addExtraDayComparison,
  getFlightSelectedDataModel,
  timeWCAGTransformation,
  layoverText,
  from24To12Time,
  journeyTimeFormat,
} from "../../../../store/flight/flightUtils"

import { flightsActions } from "../../../../store/flight/flightSlice"

const styles = {
  icon: (size) => ({
    fontSize: size,
    marginRight: 5,
    alignSelf: "center",
  }),
  weight: (weight) => ({
    fontWeight: weight,
  }),
}

const translation = (id) => `summaryPage.itinerary.${id}`
const translationWCAG = (id) => `summaryWCAG.itinerary.${id}`

const ItineraryCard = ({
  data,
  shading,
  typeFare,
  isOutboundFlight,
  handleFareDetails,
  handleViewRules,
  handleChangeFlight,
  setFlightModalDetails,
  notAction,
  noChangeFlightAction,
  // catchRemoveFlightFromCart,
  isMulticity,
  // bookingParams,
  leg,
  showStopover,
  pill,
  flightsData,
  ...props
}) => {
  const [isOpenFareDetails, setIsOpenFareDetails] = useState(false)
  const [flightPosition, setFlightPosition] = useState(0)
  const classes = useStyles(props)
  const { formatMessage } = useIntl()
  const isMobile = useMediaQuery(`${theme.breakpoints.down("xs")}`)
  const isMediumOrLower = useMediaQuery(`${theme.breakpoints.down("md")}`)
  // const dateFormatStopOver = (date) => moment(date).format("ddd, MMM D")

  let flightData = data
  if (isUndefined(data) || isEmpty(data)) {
    flightData = getFlightSelectedDataModel()
  } else {
    flightData = data
  }

  // const onChangeFlight = (event) => {
  //   const { originDestinationKey } = data
  //   handleChangeFlight(event)
  //   catchRemoveFlightFromCart(originDestinationKey)
  // }

  const {
    origin,
    destination,
    solutions: {
      numberOfLayovers,
      journeyTime,
      flights,
      classOfService,
      fareFamily,
    },
  } = flightData

  const { flightTime: departureHour, flightDate: departureDate } =
    flights[0].departure
  const { flightTime: arrivalHour, flightDate: arrivalDate } =
    flights[flights.length - 1].arrival
  const journeyTimeFormatted = journeyTime
    ? journeyTimeFormat(journeyTime)
    : "-"

  let arrivalHourWithAdd = ""
  let departureDateToShow = ""
  let departureHourToShow = ""

  if (arrivalDate && departureDate) {
    const arrivalCompleteDate = moment(`${arrivalDate} ${arrivalHour}`)
    const arrivalHourToShow = from24To12Time(arrivalCompleteDate)
    const departureCompleteDate = moment(`${departureDate} ${departureHour}`)
    departureDateToShow = departureCompleteDate.format("ddd, MMM D, YYYY")
    departureHourToShow = from24To12Time(departureCompleteDate)

    arrivalHourWithAdd = addExtraDayComparison(
      moment(arrivalDate),
      moment(departureDate),
      arrivalHourToShow
    )
  }

  const getLayoverAirportCodes = () => {
    const scales = [...flights]
    scales.shift()

    return scales.map((flight) => ` ${flight.departure.airportCode}`)
  }

  const handleFareDetailsModal = (/* RBD */) => {
    // const index = offers?.classOfService?.findIndex(
    //   (classOfService) => classOfService === RBD
    // )
    setFlightPosition(0)
    setIsOpenFareDetails(!isOpenFareDetails)
  }

  // const uniqueArray = (_arr) =>
  //   _arr?.filter((item, pos) => _arr.indexOf(item) === pos) || []

  const getWCAGFlightMessage = () => {
    let messageId = ""
    if (!numberOfLayovers)
      messageId = isOutboundFlight
        ? "containerOutboundWithoutScale"
        : "containerInboundWithoutScale"
    else if (numberOfLayovers > 1)
      messageId = isOutboundFlight
        ? "containerOutboundWithOneScale"
        : "containerInboundWithOneScale"
    else
      messageId = isOutboundFlight
        ? "containerOutboundWithMultipleScales"
        : "containerInboundWithMultipleScales"
    return formatMessage(
      {
        id: translationWCAG(messageId),
      },
      {
        typeFlight: formatMessage({
          id: translation(isOutboundFlight ? "outboundFlight" : "returnFlight"),
        }),
        dateFlight: departureDateToShow,
        startFlight: origin.city,
        endFlight: destination.city,
        hourDeparture: departureHourToShow,
        hourArrival: arrivalHourWithAdd,
        numLayover: numberOfLayovers,
        timeLayover: flights[0].layoverTime,
        codCountry: getLayoverAirportCodes().toString(),
        duration: timeWCAGTransformation(formatMessage, journeyTimeFormatted),
        fare: !notAction && fareFamily,
      }
    )
  }

  return (
    <div
      data-cy="itineraryCard"
      aria-label={getWCAGFlightMessage()}
      className={shading ? classes.shadow : null}
      role="grid"
      tabIndex="0"
    >
      <Container
        fixed
        data-cy="itineraryCard-container"
        className={clsx(classes.container, !shading ? classes.border : null)}
      >
        <Hidden smDown>
          <AvatarCo
            alt={destination.city}
            src={destination.destinationImageURL}
            size="extraSmall"
          />
        </Hidden>
        <div className={classes.content}>
          <div
            data-cy="itineraryCard-contentSummary"
            className={clsx(
              classes.contentSummary,
              notAction ? "contentSummaryModal" : null
            )}
          >
            <div className={classes.generalFlex}>
              {isMediumOrLower && (
                <PillCo
                  data-cy="itinerary-pill"
                  label={formatMessage({
                    id: translation(pill ? "pillText" : "noChangePillText"),
                  })}
                  customColor={pill ? "#0C7E3E" : theme.palette.grey[500]}
                  small
                />
              )}
              <Typography
                data-cy="itineraryCard-departureDateToShow"
                style={styles.weight(500)}
                variant="body2"
              >
                {departureDateToShow}
              </Typography>

              <Typography
                data-cy="itineraryCard-FlightTilted"
                className={classes.firstLineText}
                variant="body2"
              >
                <SvgIcon
                  className={classes.flightIcon}
                  component={FlightTilted}
                />
                {layoverText(
                  formatMessage,
                  getLayoverAirportCodes(),
                  flights[0].layoverTime.replace("h", "h "),
                  notAction &&
                    ((layoverPlace) =>
                      (isMobile && layoverPlace) ||
                      `(${layoverPlace} • ${flights[0].layoverTime.replace(
                        "h",
                        "h "
                      )})`)
                )}
              </Typography>
              <Hidden smDown>
                <Typography
                  data-cy="itineraryCard-journeyTimeToShow"
                  className={classes.firstLineText}
                  variant="body2"
                >
                  <SvgIcon style={styles.icon(12)} component={Time} />{" "}
                  {journeyTimeFormatted}
                </Typography>
              </Hidden>
            </div>
            <div
              data-cy="itineraryCard-itinerary"
              className={classes.itinerary}
            >
              <Typography
                data-cy="itineraryCard-itinerary-origin"
                className={classes.textItinerary}
                variant="body1"
              >
                {`${origin.city} (${origin.code})`}
                <b className={classes.hour}>{departureHourToShow}</b>
              </Typography>
              <SvgIcon className={classes.svgIcon} component={ArrowRight} />
              <Typography
                data-cy="itineraryCard-itinerary-destination"
                className={classes.textItinerary}
                variant="body1"
              >
                {`${destination.city} (${destination.code})`}
                <b className={classes.hour}>{arrivalHourWithAdd}</b>
              </Typography>
            </div>
          </div>
          {!notAction && (
            <div
              data-cy="itineraryCard-contentActions"
              className={classes.contentActions}
            >
              {!isMediumOrLower && (
                <PillCo
                  data-cy="itinerary-pill"
                  label={formatMessage({
                    id: translation(pill ? "pillText" : "noChangePillText"),
                  })}
                  customColor={pill ? "#0C7E3E" : theme.palette.grey[500]}
                  small
                />
              )}
              <div
                data-cy="itineraryCard-contentRules"
                className={classes.contentRules}
              >
                <AddCircleIcon className={classes.iconAdd} />
                <Typography
                  data-cy="itineraryCard-fareFamilyName"
                  variant="button"
                  className={classes.fareFamilyName}
                >
                  {fareFamily}
                </Typography>
                {/* {sameClassOfServices ? ( */}
                <TooltipCO
                  data-cy="itineraryCard-fareDetails"
                  value={formatMessage({
                    id: translation("fareDetails"),
                  })}
                >
                  <ButtonCo
                    data-cy="itineraryCard-linkViewRules"
                    aria-label={formatMessage({
                      id: translationWCAG("linkViewRules"),
                    })}
                    onClick={() => handleFareDetailsModal(classOfService)}
                    variant="text"
                    size="small"
                    className={clsx(classes.link, classes.linkColor)}
                    color="default"
                  >
                    ({classOfService})
                  </ButtonCo>
                </TooltipCO>
                {/* ) : (
                  <div className={classes.classOfServiceFormat}>
                    {uniqueArray(offers?.classOfService).map(
                      (classOfService, idx) => (
                        <>
                          {idx === 0 && "("}
                          <TooltipCO
                            key={uniqueArray(offers?.classOfService).indexOf(
                              classOfService
                            )}
                            data-cy={`itineraryCard-fareDetails-${classOfService}`}
                            value={formatMessage({
                              id: translation("fareDetails"),
                            })}
                          >
                            <ButtonCo
                              data-cy="itineraryCard-linkViewRules"
                              aria-label={formatMessage({
                                id: translationWCAG("linkViewRules"),
                              })}
                              onClick={() =>
                                handleFareDetailsModal(classOfService)
                              }
                              variant="text"
                              size="small"
                              className={clsx(classes.link, classes.linkColor)}
                              color="default"
                            >
                              {idx ===
                              uniqueArray(offers?.classOfService).length - 1
                                ? classOfService
                                : `${classOfService}, `}
                            </ButtonCo>
                          </TooltipCO>
                          {idx ===
                            uniqueArray(offers?.classOfService).length - 1 &&
                            ")"}
                        </>
                      )
                    )}
                  </div>
                  ) */}
                {/* showStopover Variable temporal para mostrar variacion en modal, borrar cuando se consuma servicio */}
                {isOpenFareDetails && (
                  <FareRulesDetails
                    isOpen={isOpenFareDetails}
                    flightPosition={flightPosition}
                    handleClose={handleFareDetailsModal}
                    flightsLength={data?.solutions?.flights.length}
                    flightData={data}
                    fareData={flightsData[leg - 1]}
                    showStopover={pill === 0}
                    leg={leg}
                  />
                )}
              </div>
              <ButtonCo
                data-cy="itineraryCard-linkViewDetails"
                aria-label={formatMessage({
                  id: translationWCAG("linkViewDetails"),
                })}
                onClick={() =>
                  setFlightModalDetails({
                    itinenaryData: data,
                    dataFlights: data.solutions?.flights,
                  })
                }
                size="small"
                end={Right}
                className={clsx(classes.linkColor, classes.viewMore)}
                color="default"
              >
                {formatMessage({ id: translation("viewDetails") })}
              </ButtonCo>
            </div>
          )}
        </div>
        {/* {!notAction && !noChangeFlightAction && (
          <ButtonCo
            data-cy="itineraryCard-linkModify"
            classes={{ outlined: classes.button }}
            aria-label={formatMessage({ id: translationWCAG("linkModify") })}
            onClick={onChangeFlight}
            variant="outlined"
            color="secondary"
            size="small"
          >
            {formatMessage({ id: translation("changeFlight") })}
          </ButtonCo>
        )} */}
      </Container>
    </div>
  )
}

ItineraryCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired, // TODO modify when it have the backend data structure
  shading: PropTypes.bool,
  isOutboundFlight: PropTypes.bool,
  typeFare: PropTypes.string,
  handleFareDetails: PropTypes.func,
  handleViewRules: PropTypes.func,
  handleChangeFlight: PropTypes.func,
  notAction: PropTypes.bool,
  noBorderBottom: PropTypes.bool,
  maxWidthContainer: PropTypes.string,
  setFlightModalDetails: PropTypes.func,
  noChangeFlightAction: PropTypes.bool,
  // catchRemoveFlightFromCart: PropTypes.func.isRequired,
  isMulticity: PropTypes.bool,
  leg: PropTypes.number,
  showStopover: PropTypes.bool,
  // bookingParams: PropTypes.objectOf(PropTypes.any).isRequired,
  pill: PropTypes.bool,
  flightsData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
}

ItineraryCard.defaultProps = {
  typeFare: "",
  isOutboundFlight: true,
  shading: false,
  handleFareDetails: null,
  handleViewRules: null,
  handleChangeFlight: null,
  notAction: false,
  noBorderBottom: false,
  maxWidthContainer: "1200px",
  setFlightModalDetails: null,
  noChangeFlightAction: false,
  isMulticity: false,
  leg: 1,
  showStopover: false,
  pill: false,
  flightsData: {},
}

const mapDispatchToProps = (dispatch) => ({
  setFlightModalDetails: (data) =>
    dispatch(flightsActions.setFlightDetailsData(data)),
  // catchRemoveFlightFromCart: (odKey) =>
  //   dispatch(flightsActions.catchRemoveFlightFromCart(odKey)),
})

export default connect(null, mapDispatchToProps)(ItineraryCard)
