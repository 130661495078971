import { enc, Rabbit } from "crypto-js"
import { PGP_KEY, PGP_KEY_PROD } from "./temporal"

const openpgp = require("openpgp")

const encryptInfo = async (text) => {
  try {
    const pgpkey =
      process.env.REACT_APP_HOST_ENV.includes("production") ||
      process.env.REACT_APP_HOST_ENV.includes("staging")
        ? PGP_KEY_PROD
        : PGP_KEY
    const options = {
      message: openpgp.message.fromText(text),
      publicKeys: (await openpgp.key.readArmored(pgpkey)).keys,
    }
    const encryptResponse = await openpgp.encrypt(options).then((m) => m.data)
    const encode = enc.Utf8.parse(encryptResponse)
    return enc.Base64.stringify(encode)
  } catch (error) {
    return null
  }
}

export const parseStringObject = (object, date) => {
  try {
    const info = Rabbit.decrypt(object, date).toString(enc.Utf8)
    return JSON.parse(info)
  } catch (error) {
    return null
  }
}

const getDomain = () => {
  if (window.location.hostname.indexOf(".copaair.com") !== -1) {
    return ".copaair.com"
  }
  if (window.location.hostname.indexOf("s4n.co") !== -1) {
    return ".s4n.co"
  }
  return process.env.NODE_ENV === "production" ? "" : window.location.hostname
}

const deleteCookie = (cname, domain) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain = ${domain}; path=/`
}

const deleteCookiesMatchingArrays = (arrayCookieNames) => {
  arrayCookieNames.forEach((cookieName) =>
    deleteCookie(cookieName, getDomain())
  )
}

const setCookie = (cname, cvalue, exdays, domain) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires = ${d.toUTCString()}`
  const cookie = `${cname} = ${cvalue}; ${expires}; domain = ${domain}; path=/`
  document.cookie = cookie
}

const setCookiesMatchingArrays = (arrayCookieNames, arrayPropertiesObject) => {
  arrayCookieNames.forEach((cookieName, index) =>
    setCookie(cookieName, arrayPropertiesObject[index], 3, getDomain())
  )
}

const transformPassenger = (passsanger) => {
  const tempPassenger = { ...passsanger }
  if (tempPassenger.name) {
    tempPassenger.givenName = tempPassenger.name?.givenName
    tempPassenger.suffix = tempPassenger.name?.suffix
    tempPassenger.surname = tempPassenger.name?.surname
    tempPassenger.title = tempPassenger.name?.title
    delete tempPassenger.name
  }
  if (!tempPassenger.loyalty) {
    tempPassenger.loyalty = {}
    tempPassenger.loyalty.membershipID = tempPassenger?.ffn
    tempPassenger.loyalty.programID = tempPassenger?.programID
    delete tempPassenger.ffn
    delete tempPassenger.programID
  }
  if (tempPassenger.documents) {
    tempPassenger.knownTravelerNumber =
      tempPassenger.documents?.knownTravelerNumber
    tempPassenger.passports = tempPassenger.documents?.passports
    tempPassenger.redressNumber = tempPassenger.documents?.redressNumber
    delete tempPassenger.documents
  }

  return tempPassenger
}

const passengerProfileTransform = ({
  relatedTraveler,
  ...travelProfileObj
}) => {
  const passengers = [
    ...relatedTraveler,
    ...[{ mainPassenger: true, ...travelProfileObj }],
  ]
  const passengerTransformed = passengers.map(transformPassenger)
  return passengerTransformed
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
  return ""
}

export {
  encryptInfo,
  setCookiesMatchingArrays,
  passengerProfileTransform,
  deleteCookiesMatchingArrays,
  getCookie,
  getDomain,
  setCookie,
}
