import en from "./languages/en"
import es from "./languages/es"
import pt from "./languages/pt"
import fr from "./languages/fr"

import flatten from "../utils/flatten"

export default {
  en: flatten({ ...en }),
  es: flatten({ ...es }),
  pt: flatten({ ...pt }),
  fr: flatten({ ...fr }),
}
