import React, { useEffect, useRef } from "react"
import { injectIntl } from "react-intl"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import logoConnectMiles from "../../../assets/images/logo_connectMiles.svg"
import MembersMenuModal from "../index"
import useContentStyle from "./style"
import definedMessages from "./message"
import { authActions } from "../../../store/auth/authSlice"

const InfoContent = ({ intl, small, userData, initiateLogOut }) => {
  const { formatMessage } = intl
  const classes = useContentStyle()
  const contentRef = useRef()
  useEffect(() => {
    if (contentRef) {
      setTimeout(() => {
        contentRef.current.focus()
      }, 0)
    }
  }, [contentRef])
  return (
    <div
      className={classes.root}
      ref={contentRef}
      aria-label={formatMessage(definedMessages.containerWCAG, {
        name: userData.givenName,
        number: userData?.loyalty?.balance,
        status: userData?.loyalty?.loyalLevel,
      })}
      tabIndex="-1"
    >
      <div className="user--actions">
        <img
          src={logoConnectMiles}
          alt={formatMessage(definedMessages.connectMilesLogoAltWCAG)}
        />
        <div className="buttonContainer">
          <ButtonCo
            data-cy="infoContent-initiateLogOut"
            variant="outlined"
            color="inverted"
            size="small"
            onClick={initiateLogOut}
            aria-label={formatMessage(definedMessages.logoutButtonWCAG)}
          >
            {formatMessage(definedMessages.LogoutCTA)}
          </ButtonCo>
          <ButtonCo
            data-cy="infoContent-myAccountExternalLink"
            variant="contained"
            color="primary"
            size="small"
            aria-label={formatMessage(definedMessages.myAccountButtonWCAG)}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_MAIN_PAGE_URL}${formatMessage(
                  definedMessages.myAccountExternalLink
                )}`
              )
            }
          >
            {formatMessage(definedMessages.accountCTA)}
          </ButtonCo>
        </div>
      </div>
      <div data-cy="infoContent-userInfo" className="user--info">
        <TypographyCo variant="h2">
          <span>{formatMessage(definedMessages.personalizedGreeting)} </span>
          <span>{userData.givenName}</span>
        </TypographyCo>
        <div className="user--info--miles">
          <div>
            <TypographyCo variant="overline">
              {formatMessage(definedMessages.connectmilesLabel)}
            </TypographyCo>
            <TypographyCo variant="subtitle1">
              {userData?.loyalty?.membershipID}
            </TypographyCo>
          </div>
          <div>
            <TypographyCo variant="overline">
              {formatMessage(definedMessages.statusLabel)}
            </TypographyCo>
            <TypographyCo variant="subtitle1">
              {userData?.loyalty?.loyalLevel}
            </TypographyCo>
          </div>
          {small && (
            <div>
              <TypographyCo variant="overline">
                {formatMessage(definedMessages.milesLabel)}
              </TypographyCo>
              <TypographyCo variant="subtitle1">{15}</TypographyCo>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  initiateLogOut: () => dispatch(authActions.logoOutRequest()),
})

const InfoContentWithIntl = connect(
  null,
  mapDispatchToProps
)(injectIntl(InfoContent))

InfoContent.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  small: PropTypes.bool,
  userData: PropTypes.shape({
    givenName: PropTypes.string,
    loyalty: PropTypes.objectOf(PropTypes.any),
  }),
  initiateLogOut: PropTypes.func.isRequired,
}

InfoContent.defaultProps = {
  small: false,
  userData: {
    givenName: "",
    loyalty: {
      membershipID: "",
      balance: "",
      loyalLevel: "",
    },
  },
}

const Modal = ({ children, ...props }) => {
  return (
    <MembersMenuModal
      modalContent={<InfoContentWithIntl />}
      isLoginBox={false}
      {...props}
    >
      {children}
    </MembersMenuModal>
  )
}
Modal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Modal
