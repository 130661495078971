/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.transparent.tooltip,
    fontWeight: 500,
    maxWidth: 232,
    fontSize: 12,
    textAlign: "center",
    padding: "5px 7px",
    margin: 10,
  },
}))

/**
 * Tooltips display a text label identifying an element, such as a description of its function.
 */
const TooltipCO = ({ children, value, ...props }) => {
  const styles = useStyles()

  delete props.valueLabelDisplay
  delete props.valueLabelFormat

  return (
    <Tooltip classes={{ tooltip: styles.root }} title={value} {...props}>
      {children}
    </Tooltip>
  )
}

TooltipCO.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
}

TooltipCO.defaultProps = {
  children: null,
  value: "",
  placement: "bottom",
}

export default TooltipCO
