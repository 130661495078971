import React from "react"
import PropTypes from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import Box from "@material-ui/core/Box"
import SvgIcon from "@material-ui/core/SvgIcon"
import IconButton from "@material-ui/core/IconButton"
import Right from "@material-ui/icons/ChevronRight"
import { withStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import theme from "@bit/vibenitez.copa-components-library.theme"
import { ReactComponent as CloseSVG } from "../icons/Modal_close_default.svg"
/**
 * Function to make the characteristics of the Select component
 * @param {string} property Which property will affect the current style
 * @param {styple object} mapping How will the property affect the current style
 */
const styledBy = (property, mapping) => (props) => mapping[props[property]]

/**
 * Property that transform the CloseSVG icon into a Material Icon
 * @param {props} props for the respective SvgIcon
 */
function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <CloseSVG />
    </SvgIcon>
  )
}

const styles = {
  /**
   * Style defining the paper for the modal.
   * It mostly focuses on changing the margin and borders.
   */

  paperWidthLg: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100% - 16px)",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "calc(100% - 24px)",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "calc(100% - 32px - 16px)",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "calc(100% - (40px + 16px))",
    },
  },

  paperScrollBody: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: "calc(100% - 16px)",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "calc(100% - 24px)",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "calc(100% - 32px - 16px )",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "calc(100% - (40px + 16px))",
    },
  },
  paper: {
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(5),
    },
    marginRight: 0,
    overflow: "visible",
    width: "100%",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 0,
  },

  scrollBody: {
    paddingRight: theme.spacing(2),
  },
  /**
   * Style to define the area for the Action Buttons
   */
  actions: {
    padding: 0,
    margin: 0,
    borderTop: "solid 1px #D1D1D1",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    /* Dynamic size change */
    minHeight: 120,
    [theme.breakpoints.down("xs")]: {
      minHeight: 80,
    },
    alignItems: "stretch",
    paddingTop: -1,
  },
  /**
   * Style to define the Action Buttons
   */
  btnRoot: {
    margin: 0,
    padding: 8,
    borderRadius: 0,
    borderColor: theme.palette.transparent.main,
    /* The color of the text will differ if the button is primary/unique or secondary */
    color: styledBy("type", {
      secondary: theme.palette.grey["600"],
      primary: theme.palette.common.white,
    }),
    /* The background of the button will differ if the button is primary/unique or secondary */
    background: styledBy("type", {
      primary: theme.palette.gradients["500"],
    }),
    "&:hover, &:active, &:focus, &:hover:focus": {
      background: styledBy("type", {
        primary: theme.palette.gradients["600"],
        secondary: theme.palette.common.white,
      }),
      color: styledBy("type", {
        secondary: theme.palette.primary.main,
        primary: theme.palette.common.white,
      }),
      borderColor: styledBy("type", {
        secondary: theme.palette.transparent.main,
      }),
    },
    "&:focus": {
      background: styledBy("type", {
        primary: theme.palette.gradients["500"],
        secondary: theme.palette.common.white,
      }),
      color: styledBy("type", {
        secondary: theme.palette.grey["600"],
        primary: theme.palette.common.white,
      }),
    },
    /* If the button is the element at the right, change their borderRadius */
    "&:last-child": {
      margin: 0,
      borderBottomRightRadius: 8,
    },
    /* If the button is the element at the left, change their borderRadius */
    "&:first-child": {
      margin: 0,
      borderBottomLeftRadius: 8,
    },
  },
  /**
   * Style defined for the label to overflow it's text
   */
  btnLabel: {
    position: "relative",
    display: "inline-block",
    wordWrap: "break-word",
    overflow: "hidden",
    maxHeight: "3.6em",
    lineHeight: "1.2em",
    textAlign: "center",
    textOverflow: "ellipsis",
  },
  /**
   * Variables to make the CloseSVG button stay in place
   */
  CloseSVG: {
    float: "right",
    position: "sticky",
    top: theme.spacing(3),
    marginTop: theme.spacing(3),
    // right: 5,
    padding: 0,
    zIndex: 1,
    fontSize: 0,
    marginBottom: -theme.spacing(9.5),
    [theme.breakpoints.up("xs")]: {
      marginRight: -theme.spacing(2),
      right: -theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: -theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      right: -theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      right: -theme.spacing(2),
    },
  },
}

/**
 * Creates an stylized Action button to apply the dynamic changes
 */
const ActionButton = withStyles(styles)(({ classes, type, ...other }) => {
  return <ButtonCo classes={{ root: classes.btnRoot }} {...other} />
})

/**
 * Creates an stylized Action adrea to apply the dynamic changes
 */
const ActionsArea = withStyles(styles)(({ classes, type, ...other }) => {
  return (
    <DialogActions
      classes={{ root: classes.actions, spacing: classes.actionsSpacing }}
      {...other}
    />
  )
})
/**
 * Creates an stylized paper for the dialog
 */
const Modal = withStyles(styles)(({ classes, ...other }) => {
  return (
    <Dialog
      classes={{
        paper: classes.paper,
        paperWidthLg: classes.paperWidthLg,
        paperScrollBody: classes.paperScrollBody,
        scrollBody: classes.scrollBody,
      }}
      {...other}
    />
  )
})
/**
 * Sets the definition for the CloseButton
 */
const CloseButton = withStyles(styles)(({ classes, ...other }) => {
  return (
    <IconButton
      color="primary"
      size="small"
      {...other}
      classes={{
        root: classes.CloseSVG,
      }}
    >
      <CloseIcon style={{ fontSize: 52 }} />
    </IconButton>
  )
})

/**
 * Function that sets the primary/unique button for the Actions
 *
 * @param {string} text Text to be placed as the label for the button
 * @param {function} action Action to be performed when the button is clicked
 */
const justPrimary = (text, action) => {
  return (
    <ActionButton
      type="primary"
      variant="contained"
      fullWidth
      onClick={action}
      end={Right}
    >
      <div style={styles.btnLabel}>{text}</div>
    </ActionButton>
  )
}
/**
 * Function that sets both the secondary and primary button for the Actions
 *
 * @param {[string, function]} primary Text and Action to be performed by the primary button
 * @param {[string, function]} secondary Text and Action to be performed by the secondary button
 */
const primaryAndSecondary = (primary, secondary) => {
  return [
    <ActionButton
      key="secondary"
      type="secondary"
      color="primary"
      variant="outlined"
      onClick={secondary[1]}
      fullWidth
    >
      <div style={styles.btnLabel}>{secondary[0]}</div>
    </ActionButton>,
    <ActionButton
      key="primary"
      variant="contained"
      type="primary"
      fullWidth
      onClick={primary[1]}
    >
      <div style={styles.btnLabel}>{primary[0]}</div>
    </ActionButton>,
  ]
}

/**
 * Default props to set for the Modal
 */
const defaultProps = {
  fullWidth: true,
  maxWidth: false,
  scroll: "body",
}

function ModalCo(props) {
  const {
    children,
    primarybutton,
    secondarybutton,
    showClose,
    onClose,
    onPrimary,
    onSecondary,
    wcagObject,
  } = props
  const isDevice = useMediaQuery(theme.breakpoints.down("md"))
  const propOnlyDevice = isDevice ? { "aria-labelledby": "content" } : ""
  const currentProps = { ...defaultProps, ...props, ...propOnlyDevice }
  /**
   * Sets the action portion of the modal, according to the props
   */
  let modalButton
  if (primarybutton) {
    if (secondarybutton) {
      modalButton = primaryAndSecondary(
        [primarybutton, onPrimary],
        [secondarybutton, onSecondary]
      )
    } else {
      modalButton = justPrimary(primarybutton, onPrimary)
    }
  }

  /** Deleting a couple of unnecesary elements from the object */
  delete currentProps.onPrimary
  delete currentProps.onSecondary
  delete currentProps.showClose
  delete currentProps.wcagObject
  return (
    <div>
      <Modal
        aria-describedby={`modal-description-${wcagObject[0]?.id}`}
        disableBackdropClick
        {...currentProps}
      >
        <div
          id="modal-content"
          role="dialog"
          aria-modal="true"
          aria-label={wcagObject[0]?.message}
        >
          {showClose && (
            <CloseButton
              data-cy="closeModal-action"
              onClick={onClose}
              aria-label={wcagObject[1]?.message}
            />
          )}
          <div>{children}</div>
          {modalButton && <ActionsArea>{modalButton}</ActionsArea>}
        </div>
      </Modal>
      <Box
        hidden
        aria-hidden="true"
        id={`modal-description-${wcagObject[0]?.id}`}
      >
        {wcagObject[0]?.message}
      </Box>
    </div>
  )
}

ModalCo.propTypes = {
  /**
   * Children to show inside the modal.
   * Preferable for it to be a set of `<ModalContentCo/>` or `<ModalColumnsCo/>`
   */
  children: PropTypes.node,

  /**
   * If `true`, the Modal will be displayed
   */
  open: PropTypes.bool,

  /**
   * `text`to be placed as the label for the primary button
   */
  primarybutton: PropTypes.string,
  /**
   * `function`to be placed as the action for the primary button
   */
  onPrimary: PropTypes.func,

  /**
   * `text`to be placed as the label for the secondary button
   */
  secondarybutton: PropTypes.string,
  /**
   * `function`to be placed as the action for the secondary button
   */
  onSecondary: PropTypes.func,

  /**
   * Reactive function to the behavior of closing the modal
   */
  onClose: PropTypes.func.isRequired,

  /**
   * If `true` sets the Close Button (X) as visible
   */
  showClose: PropTypes.bool,

  /**
   * Add object of WCAG for container and close botton
   */
  wcagObject: PropTypes.instanceOf(Array),
}

ModalCo.defaultProps = {
  open: false,
  children: undefined,
  primarybutton: "",
  secondarybutton: "",
  onPrimary: () => {},
  onSecondary: () => {},
  showClose: false,
  wcagObject: [{ id: "", message: "" }, { message: "" }],
}
export default ModalCo
