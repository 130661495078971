import React from "react"
import { toast } from "react-toastify"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import InfoIcon from "@material-ui/icons/Info"

import theme from "@bit/vibenitez.copa-components-library.theme"
import "react-toastify/dist/ReactToastify.css"

const useStyles = makeStyles(() => ({
  alertToast: {
    "& .MuiAlertTitle-root": {
      marginBottom: 0,
    },
  },
}))

const ToastCo = ({ id, type, children, ...props }) => {
  const { icon, closebutton, title } = props
  const classes = useStyles()
  const dismiss = () => {
    toast.dismiss(this)
  }

  return (
    <Alert
      id={id}
      style={theme.typography.body2}
      variant="filled"
      severity={type}
      icon={icon}
      onClose={closebutton ? () => dismiss(id) : null}
      className={classes.alertToast}
    >
      <AlertTitle>{title}</AlertTitle>
      <span>{children}</span>
    </Alert>
  )
}

ToastCo.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["success", "info", "error"]),
  icon: PropTypes.node,
  closebutton: PropTypes.bool,
  timeclose: PropTypes.number,
  position: PropTypes.oneOf([
    "top-right",
    "top-center",
    "top-left",
    "bottom-right",
    "bottom-center",
    "bottom-left",
  ]),
}

ToastCo.defaultProps = {
  title: "",
  type: "success",
  icon: <InfoIcon />,
  closebutton: false,
  timeclose: 8,
  position: "top-right",
}

export default ToastCo
