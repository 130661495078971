import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import initialState from "./reservationInitialState"

import { fromQueryToObject } from "../../utils/queryManager"

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setExchangesParamsFromQuery: (state, action) => {
      const { payload } = action
      const paramsAsObject = fromQueryToObject(payload)

      state.queryURL = payload
      state.queryParams = {
        ...paramsAsObject,
        pnr: paramsAsObject.pnr || "",
        lastName: paramsAsObject.lastName || "",
      }
    },
    fetchReservation: (state) => {
      state.fetching = true
      state.error = ""
    },
    setReservation: (state, action) => {
      const {
        payload: { data, airports },
      } = action
      const farefamilySelect = []
      axios.defaults.headers.Storefront = data.storefront || "GS"
      const city = airports.find(
        (airport) => airport.code === data.finalDestination
      )

      const destination =
        data?.tripType === "ROUNDTRIP"
          ? data.flights.find(
              (flight) => flight?.locationDepart === data?.finalDestination
            )
          : data.flights.find(
              (flight) => flight?.locationArrive === data?.finalDestination
            )

      data.flights.map((flight) => {
        farefamilySelect.push(flight.selectedFareFamily)
        return flight
      })

      state.flightOrigin = data.flights ? data.flights[0] : {}
      state.flightDestination = destination
      state.selectedFareFamily = farefamilySelect
      state.ticket = { cityDestination: city.city, ...data }
      state.fetching = false
      state.error = ""
      state.isLoaded = true
    },
    errorReservation: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = payload
    },
    fetchEgilibility: (state) => {
      state.fetching = true
      state.error = ""
    },
    setEligibility: (state, action) => {
      state.isEligible = action.payload
    },
    errorEgilibility: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = payload
    },
    showNotAvailablePage: (state) => {
      state.notAvailablePageIsShowed = true
    },
    fetchAllReservation: (state) => {
      state.fetching = true
      state.error = ""
    },
    fetchReservationChange: (state) => {
      state.fetching = true
      state.error = ""
    },
    setReservationChange: (state) => {
      state.changeSuccessful = true
      state.fetching = false
    },
    errorReservationChange: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = payload
      state.changeSuccessful = false
    },
    setShouldDisplayNoItineraryModal: (state, action) => {
      state.shouldDisplayNoItineraryModal = action.payload
    },
  },
})

export default reservationSlice.reducer

export const { actions: reservationActions } = reservationSlice
