import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import clsx from "clsx"
import { connect } from "react-redux"
import moment from "moment"

/**
 * @components
 */
import { Container } from "@material-ui/core"
import CheckboxCo from "@bit/vibenitez.copa-components-library.checkbox"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import DatesSelector from "./datesSelector/datesSelector"
import ModeSelector from "./modeSelector"
import AlertCo from "../../../../../ToLibrary/alert/SingleAlertCo"
import { ReactComponent as ErrorFill } from "../../../../../ToLibrary/icons/Error-Fill.svg"

import { flightsActions } from "../../../../../store/flight/flightSlice"

/**
 * @styles
 */
import useStyles from "./styles"

/**
 * @utils
 */
import { airportsPropTypes, flightsDataPropTypes } from "../../propTypesSchema"

const translation = (id) => `searchPage.datesSelector.${id}`
const translationWCAG = (id) => `searchPage.datesSelectorWCAG.${id}`

const ContainerDatesSelector = ({
  flightsData,
  handleFirstClick,
  disableContinueButton,
  originAirports,
  destinationAirports,
  getNewSearch,
  isEditSearch,
  fetchOnlyDate,
  onlyDate,
  isChangeOfRoute,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const [isOnlyDates, setOnlyDates] = useState(onlyDate === "true")
  const [flightsSectionOpen, setFlightsSectionOpen] = useState(false)
  const [flights, setFlights] = useState(flightsData)
  const flightsChangeFlag = JSON.stringify(flights)
  const noRestrictionChangeRouteGlobal =
    process.env.REACT_APP_ENABLE_CHANGE_OF_ROUTE.includes("true")
  useEffect(() => {
    disableContinueButton(!flights.some((flight) => flight.isCheck))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightsChangeFlag])

  useEffect(() => {
    setFlights(flightsData)
  }, [flightsData])

  const onKeyPress = (e, onClick) => {
    if (e.keyCode === 32 || e.keyCode === 13) onClick()
  }

  const setFocusOnInputs = (index) => {
    if (isOnlyDates) {
      const dateInput = document.getElementById(`date-input-${index}`)
      setTimeout(() => dateInput?.focus(), 0)
    } else {
      const originInput = document.getElementById(
        `origin-autocomplete-${index}`
      )
      setTimeout(() => originInput?.focus(), 0)
    }
  }

  const setFlightCheckValue = (index, value) =>
    setFlights((prevState) => {
      return prevState.map((item) => {
        if (item.index === index) {
          return { ...item, isCheck: value }
        }
        return item
      })
    })

  const onClick = (flight) => {
    if (flight && !flight.isCheck) {
      handleFirstClick()
      setFlightCheckValue(flight.index, true)
      setFocusOnInputs(flight.index)
    }
  }

  const handleChangeCheckbox = (flight) => {
    handleFirstClick()
    setFlightCheckValue(flight.index, !flight.isCheck)
    setFocusOnInputs(flight.index)
  }

  const handleModeSelectorChange = (isOnlyDate) => {
    if (!flightsSectionOpen) {
      setFlightsSectionOpen(true)
    }
    setFlights(flightsData)
    setOnlyDates(isOnlyDate)
    fetchOnlyDate(`${isOnlyDate}`)
  }

  // It autopopulates the flights and forces the user to make an RT
  const setChanges = (flight, prevState) => {
    if (!noRestrictionChangeRouteGlobal) {
      if (flight?.index === 1) {
        if (
          flight?.destination.code !== undefined &&
          flight?.destination.code !== prevState[0]?.destination.code
        ) {
          const result = prevState.map((item) => {
            if (item.index === 2) {
              return {
                ...item,
                origin: flight.destination,
              }
            }
            return flight
          })
          return result
        }
        if (
          flight?.origin.code !== undefined &&
          flight?.origin.code !== prevState[0]?.origin.code
        ) {
          const result = prevState.map((item) => {
            if (item.index === 2) {
              return {
                ...item,
                destination: flight.origin,
              }
            }
            return flight
          })
          return result
        }
      }
      if (flight?.index === 2) {
        if (
          flight?.origin.code !== undefined &&
          flight?.origin.code !== prevState[1]?.origin.code
        ) {
          const result = prevState.map((item) => {
            if (item.index === 1) {
              return {
                ...item,
                destination: flight.origin,
              }
            }
            return flight
          })
          return result
        }
        if (
          flight?.destination.code !== undefined &&
          flight?.destination.code !== prevState[1]?.destination.code
        ) {
          const result = prevState.map((item) => {
            if (item.index === 1) {
              return {
                ...item,
                origin: flight.destination,
              }
            }
            return flight
          })
          return result
        }
      }
    }
    return prevState.map((item) => {
      if (item.index === flight.index) {
        return flight
      }
      return item
    })
  }

  const editSearch = (flight) => {
    setFlights((prevState) => {
      return setChanges(flight, prevState)
    })
  }

  useEffect(() => {
    getNewSearch(flights)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights])

  // handle the calendar limit for the second date
  const handleDateLimit = (data) => {
    setFlights((prevState) =>
      prevState.map((item) => {
        if (item.index === flights[1]?.index) {
          return { ...item, startDate: data, initialAvailableDate: data }
        }
        return item
      })
    )
  }

  // handle when we need to restore the date
  const handleRestoreDate = (data) => {
    const secondDate = moment(flights[1]?.date)
    const firstDate = moment(data)
    if (firstDate.isAfter(secondDate)) {
      setFlights((item) =>
        item.map((element) => {
          if (element.index === flights[1]?.index) {
            return { ...flights[1], date: "" }
          }
          return element
        })
      )
    }
  }

  // handle when dates changes
  const dateChange = (prop, newDate) => {
    if (prop === 2 || prop === 1) {
      setFlights((prevState) => {
        return prevState.map((item) => {
          if (item.index === flights[prop - 1]?.index) {
            return {
              ...flights[prop - 1],
              isCheck: prevState[prop - 1].isCheck,
              date: newDate,
            } // used to fail in mobile
          }
          return item
        })
      })
    }

    if (flights.length > 0 && prop === 1) {
      handleRestoreDate(newDate)
      handleDateLimit(newDate)
    }
  }

  const activCheckBox = (param, id) => {
    if (!noRestrictionChangeRouteGlobal) {
      if (param === "ORIGIN") {
        if (id === 2) onClick(flights[0])
        onClick(flights[1])
      } else {
        if (id === 1) onClick(flights[1])
        onClick(flights[0])
      }
    }
  }

  return (
    <Container data-cy="containerDateSelector" className={classes.root}>
      <ModeSelector
        handleChange={handleModeSelectorChange}
        isEditSearch={isEditSearch}
        isChangeOfRoute={isChangeOfRoute}
      />
      {(flightsSectionOpen || isEditSearch) && (
        <>
          <TypographyCo
            data-cy="containerDateSelector-title"
            variant="h3"
            className={classes.title}
          >
            {formatMessage({ id: translation("title") })}
          </TypographyCo>
          <AlertCo
            className={classes.container}
            severity="error"
            width="100%"
            closeIcon={false}
            fontSize="14px"
            fontWeight="500"
            icon={<ErrorFill className={classes.infoIcon} />}
          >
            {formatMessage({ id: "searchPage.datesSelector.infoSegment" })}
          </AlertCo>
          <div>
            {flights.map((flight, index) => {
              return (
                <div
                  id={`flight-${flight?.index}`}
                  key={flight.index}
                  role="button"
                  tabIndex="0"
                  onKeyDown={(e) => onKeyPress(e, () => onClick(flight))}
                  onClick={() => onClick(flight)}
                  className={clsx(
                    flight.isCheck === true ? "" : classes.containerCardDisable,
                    classes.containerCard
                  )}
                  aria-label={`${formatMessage(
                    { id: translationWCAG("checkBoxWCAG") },
                    {
                      numFlight: flight.index,
                      origin: flight.origin?.city || "",
                      destination: flight.destination?.city || "",
                      date: flight.date,
                    }
                  )} ${
                    flights.length - 1 !== index
                      ? formatMessage({
                          id: translationWCAG("checkBoxWCAGNextFlight"),
                        })
                      : ""
                  }`}
                >
                  <CheckboxCo
                    full
                    id={`checkFlight-${flight.index}`}
                    data-cy={`containerDateSelector-checkFlight-${flight.index}`}
                    inputProps={{
                      "data-cy": `flight-selector-${index}`,
                    }}
                    onChange={() => {
                      handleChangeCheckbox(flight)
                    }}
                    onKeyDown={(e) =>
                      onKeyPress(e, () => handleChangeCheckbox(flight))
                    }
                    checked={flight.isCheck}
                    label={formatMessage(
                      { id: translation("flightNumber") },
                      { index: flight.index }
                    )}
                  />
                  <DatesSelector
                    flightData={flight}
                    originAirports={originAirports}
                    destinationAirports={destinationAirports}
                    transitioning={false}
                    isOnlyDates={isOnlyDates}
                    getChange={editSearch}
                    dateChange={dateChange}
                    isEdidt={isEditSearch}
                    checkSegment={(param, id) => {
                      activCheckBox(param, id)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </Container>
  )
}

ContainerDatesSelector.propTypes = {
  flightsData: PropTypes.arrayOf(flightsDataPropTypes),
  handleFirstClick: PropTypes.func.isRequired,
  disableContinueButton: PropTypes.func.isRequired,
  originAirports: airportsPropTypes,
  destinationAirports: airportsPropTypes,
  getNewSearch: PropTypes.func.isRequired,
  fetchOnlyDate: PropTypes.func.isRequired,
  isEditSearch: PropTypes.bool.isRequired,
  onlyDate: PropTypes.string,
  isChangeOfRoute: PropTypes.bool,
}

ContainerDatesSelector.defaultProps = {
  flightsData: [],
  originAirports: [],
  destinationAirports: [],
  onlyDate: null,
  isChangeOfRoute: false,
}

const mapStateToProps = ({ flight }) => ({
  onlyDate: flight.onlyDate,
})

const mapDispatchToProps = (dispatch) => ({
  fetchOnlyDate: (isOnlyDate) =>
    dispatch(flightsActions.fetchOnlyDate(isOnlyDate)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerDatesSelector)
