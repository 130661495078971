import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyleSmallModal = makeStyles((theme) =>
  createStyles({
    menuRoot: {
      background: theme.palette.transparent.overlayBg,
      width: "100%",
      zIndex: "1 !important",
      [theme.breakpoints.down("xs")]: {
        zIndex: "1300 !important",
      },
      "& .MuiPopover-paper": {
        width: "100%",
        maxWidth: "unset",
        left: "0px",
        borderRadius: "initial",
        [theme.breakpoints.down("xs")]: {
          height: "100%",
          top: "0px",
          maxHeight: "unset",
          "& > div": {
            height: "100%",
          },
        },
        "& .loginboxform-container": {
          marginTop: 24,
          marginBottom: 24,
        },
      },
    },
    closeButton: {
      backgroundColor: "initial",
      border: "none",
      cursor: "pointer",
      padding: 0,
      position: "absolute",
      top: "8px",
      right: "8px",
      zIndex: 1,
      "& svg": {
        size: 40,
        width: 40,
        height: 40,
        color: theme.palette.common.white,
      },
      [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
        top: "30px",
      },
      '&[data-loginbox="true"]': {
        top: "42px",
        right: "16px",
        [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
          top: "52px",
        },
      },
    },
  })
)

export default useStyleSmallModal
