const TYPES = {
  LOGIN_AUTH_REQUEST: "auth/loginRequest",
  LOGIN_AUTH_SUCESS: "auth/loginSuccess",
  LOGIN_AUTH_FAILURE: "auth/loginFailure",
  LOGOUT_REQUEST: "auth/logoOutRequest",
  PASSENGER_PROFILE_EXTENDED_REQUEST: "auth/passengerProfileExtendedRequest",
  PASSENGER_PROFILE_EXTENDED_SUCESS: "auth/passengerProfileExtendedFailure",
  PASSENGER_PROFILE_EXTENDED_FAILURE: "auth/passengerProfileExtendedSuccess",
}

export default TYPES
