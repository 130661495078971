import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import Grid from "@material-ui/core/Grid"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"

// import {
//   deleteCookiesMatchingArrays,
//   setCookiesMatchingArrays,
// } from "../../../utils/auth"

import { ReactComponent as PlaneIcon } from "../../../ToLibrary/icons/plane.svg"
import { ReactComponent as PhoneIcon } from "../../../ToLibrary/icons/phone.svg"
import { ReactComponent as HelpIcon } from "../../../ToLibrary/icons/Help-Fill.svg"
import { ReactComponent as Retail } from "../../../ToLibrary/icons/Retail.svg"
import useStyles from "./styles"
import { pushPageView } from "../../../utils/analytics"

const CriticalError = () => {
  const classes = useStyles()

  const { formatMessage } = useIntl()

  const retail = () => <Retail className={classes.icons} />
  const phoneIcon = () => <PhoneIcon className={classes.icons} />
  const helpIcon = () => <HelpIcon className={classes.icons} />

  // useEffect(() => {
  //   setCookiesMatchingArrays(["critical-error"], ["1"])
  // }, [])

  useEffect(() => {
    pushPageView("/critical-error")
  }, [])

  return (
    <>
      <Grid
        item
        className={classes.errorContainer}
        data-cy="criticalError-container"
      >
        <PlaneIcon />
        <TypographyCo
          data-cy="criticalError-title"
          variant="h1"
          color="primary"
          className={classes.title}
        >
          {formatMessage({ id: "criticalErrorPage.title" })}
        </TypographyCo>
        <TypographyCo variant="body1" className={classes.subtitle}>
          {formatMessage({ id: "criticalErrorPage.description" })}
        </TypographyCo>
        <ButtonCo
          color="primary"
          size="medium"
          variant="contained"
          data-cy="criticalError-retryButton"
          className={classes.retryButton}
          href={formatMessage({
            id: "criticalErrorPage.reservationCenterLink",
          })}
          aria-label={formatMessage({ id: "criticalErrorPageWCAG.cta" })}
        >
          {formatMessage({ id: "criticalErrorPage.retryButton" })}
        </ButtonCo>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.helpContainer}
        data-cy="criticalError-needHelpContainer"
      >
        <TypographyCo variant="body1" className={classes.helpText}>
          {formatMessage({ id: "criticalErrorPage.helpText" })}
        </TypographyCo>
        <ButtonCo
          color="primary"
          size="medium"
          variant="link"
          start={phoneIcon}
          data-cy="criticalError-reservationCenterLink"
          className={classes.helpButton}
          href={formatMessage({
            id: "criticalErrorPage.reservationCenterLink",
          })}
          aria-label={formatMessage({
            id: "criticalErrorPageWCAG.reservationCenter",
          })}
          target="_blank"
        >
          {formatMessage({ id: "criticalErrorPage.reservationCenter" })}
        </ButtonCo>
        <ButtonCo
          color="primary"
          size="medium"
          variant="link"
          start={retail}
          data-cy="criticalError-salesOfficeLink"
          className={classes.helpButton}
          href={formatMessage({ id: "criticalErrorPage.salesOfficeLink" })}
          aria-label={formatMessage({
            id: "criticalErrorPageWCAG.salesOffice",
          })}
          target="_blank"
        >
          {formatMessage({ id: "criticalErrorPage.salesOffice" })}
        </ButtonCo>
        <ButtonCo
          color="primary"
          size="medium"
          variant="link"
          start={helpIcon}
          data-cy="criticalError-helpDeskLink"
          className={classes.helpButton}
          href={formatMessage({ id: "criticalErrorPage.helpDeskLink" })}
          aria-label={formatMessage({ id: "criticalErrorPageWCAG.helpDesk" })}
          target="_blank"
        >
          {formatMessage({ id: "criticalErrorPage.helpDesk" })}
        </ButtonCo>
      </Grid>
    </>
  )
}

export default CriticalError
