import { defineMessages } from "react-intl"

const definedMessages = defineMessages({
  personalizedGreeting: {
    id: "topBar.membersMenu.personalizedGreeting",
  },
  LogoutCTA: {
    id: "topBar.membersMenu.LogoutCTA",
  },
  accountCTA: {
    id: "topBar.membersMenu.accountCTA",
  },
  connectmilesLabel: {
    id: "topBar.membersMenu.connectmilesLabel",
  },
  statusLabel: {
    id: "topBar.membersMenu.statusLabel",
  },
  milesLabel: {
    id: "topBar.membersMenu.milesLabel",
  },
  myAccountExternalLink: {
    id: "topBar.membersMenu.myAccountExternalLink",
  },
  connectMilesLogoAltWCAG: {
    id: "topBarWCAG.membersMenu.connectMilesLogoAlt",
    description: "ConnectionMiles Logo Alt Text",
  },
  logoutButtonWCAG: {
    id: "topBarWCAG.membersMenu.logoutButton",
  },
  myAccountButtonWCAG: {
    id: "topBarWCAG.membersMenu.myAccountButton",
  },
  containerWCAG: {
    id: "topBarWCAG.membersMenu.container",
    description: "Container information",
  },
})

export default definedMessages
