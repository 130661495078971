export const PGP_KEY = `-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: GnuPG v1

mQINBF3urPABEAC4YmSQNfgwdEetxjZgcOgbQjj4GGNJ9oUXKGJgbW0GMSglLoaP
jQ3WvnNFVhg/dEYHS7/YYNRMAt1Phemo0WoMC2eFqa92OBEqwuUApiaZuJHo/Ug9
qdIgvWTKJRGWfH39qpX+qK+rpdtcMugH34FDYai4FVpbxFbjPEOthgqlpMGRSo9W
QtNr7Gdk2BylLLb59cygZwYarWNNclt9iN7sQdng7AuZKwpJGZ8BrP5uaskrHWMe
AWPXqpY79iatem1mTq+oYpXT+mMz5neYptDMYJYtiNOOJ04vqoHkF9e42KLG6xog
xOSZmMJ7g7b3qWwu2kg9Lfqw4gSbd1+7+XfOKc//amdLIJ3Its0i3+rFoBlNB7jG
7RQtO9DM92Emg3ZFmxjdsyf6Czxc2ib5Z8EtTmfZxICkK4d7QPIS7acMws3mrwWA
E5eSH2LbjdlIZ15k2efLQfO7/zn68wxQ4mKu2gcMsT4gt+sxGCmc9ALVQ6gfQr4p
IyvXHwQyBBvEKJThtnmIxWsjyIaeJjjTI3FQ7OO+uoWh5eItQ78xnpNddNcOoVXn
Ct0RMiy7LgUlAf6J1rvKHMvb4crz0LU1KotWr9ifRaoQEteEGFGQuGvnCoZ5svT7
jV0aXb8oqNPlrbMBP1q9MQzRuUxoMuowGsB3BxAdXvfg3FqayIu5+7J9OQARAQAB
tDZjb3BhLXdjaS1kZXYgKGtleXBhaXIgZm9yIGxvZ2luIHBncCkgPHdjaUBjb3Bh
YWlyLmNvbT6JAjgEEwECACIFAl3urPACGwMGCwkIBwMCBhUIAgkKCwQWAgMBAh4B
AheAAAoJECQjQco3YCpxy4IP/2FrwvCpTAaZU4wEY5wl/1WQ5dhAaiu6apsSC740
NHGJ/MqZI3ZTGeHAxoFPDr/IgP8kamIswpbU41hKsuXGM0uBrAh0lUEMht0YdE1j
cuOUgiBSJ2mRRJu/FxXqRwS4J2NI6MNX9Jqe6T9fkByMixOavCy5BMDxpWPwQU9r
HZ5+E+riGVN96PRB3LxhO0iD7N1Wf+NHvrevsGWXQVDIBxktK+mM5C0Rzvvo9FEV
4ud5qR32Sy2NhEtKtLMvCQ5rGsGXsx3OUXHOactf0nlAE7XHtk0Y2vKzqgkRljtd
tF6FVUxJITsaGHtgtqM8BA4PIWd9aLMwORkWHh5kcyMoPJ4sR55KSiRKETS7PN72
FLoBB2PmP4lBRjCnm/zlPymVHegjNibvg/6pMK6OwRxWdccCbg4frk1LctTsfY1Z
yspDk+VfH+FS+4tUQMjEk4KYyihILhMsTOT1DVczIRrZoJs0rZW0XfpybMGNfd8q
4cV9CdGCBOYwo7zYH0iTwfHZ+KYvB49+6xlPYTdhTbfLGTAd0Wh4IVje7Xzm7o/j
pg5pq53NVGFDBLa2Bs4b0qLQHGDxsllXQqcqgocMJUqDSwSr8PWy47J4Iif4lBw/
Q3b7v71ELhi8hzkX7GdBeimSNyDyvOZvqBQyz1678I9ZQC3pvOFMG/LUZ+Vj8e2g
tMaquQINBF3urPABEADg/Z3nAxJvReDo1RZP6u90EGMEOfLv/Rw17dBn9+qE20tV
I8AbrWPIdqIX/IPwQDevelEjoK5kOQVpRMRki2HXDxTAkK7Va8fYuBXkM8tABnu7
jWStBykhgyfvKD3a+znxBIpLemTb7C2Lg9Va4f2+a5XqGasFwcr8t7Hgf5SDXGib
KE7aKRk0F/+DJwT0I5kAKr0TgMzgd/4uTFFZ47NJOMer93aATAlS1HNBy8c+mFBV
Wxc+E2hxzc5VUmdpIVzghfb2GkDbiNnSL0HYW711g8qCGfJUa/SoknFkmL/Xn5Az
7ctqCFsWhl4y7S4omlAJ2N8mT6/mgF387Rk/UrN/aYQi5IZdjUD75nohX/Dpy6j5
1+lJLjS+2CNCJaibzB7ME6oGrT4nTZgod5bOjCcdjE0I1Fr2o9p9lUGCquUTKF29
14YLPbTQX+At/f1Lz84dFZHjA5DoMKP5+oOCNDz8iqAyR2e1/KODSDt411im5bAe
sncCW46uQecZykxgP3GI9XgdiM9/hkqf0R2VkyjDClJ7YsVB30/n7/fr99Jc9ZUF
W0jLHAdbjeNPr9tyAHcn/y6RKborV4AYDzT0S0BIfEOp/F9deM7Hv61MwYFClpaF
waHy0ytXI0fTGg6WKtPeKJYW8QabxC6NQwZQPGJxFzZJdki1ljQwCwPh1N+0LwAR
AQABiQIfBBgBAgAJBQJd7qzwAhsMAAoJECQjQco3YCpxrdUP/2NZrcG0bNzBnQBr
tR/ozS0MBLxwYdU6MAfSCSf4mHoVvLsa19n42i4yNFugr/GZA1kW7MRWIdxSe35p
oxJvKz9NrLW+78b70GMeGFpVeq6m9RFXu3hPybOcnrrLaNVPtYv+cWqYNIuwWTfL
Q5u68mvpqhZp6kjMq0ieGLKU0g4D/ut9cNmQiPA1JCiyBCq50o+Z9Y1hUDWE7dHc
MI0+tYOFw4NQpbJVP5+iQ/a+AdlhXx3VrsNE94MqdyPEENP8TcS7ygjL01KgrVa4
pHrloaVudnzJdulhJ/W7VbuJrVeRLNEAZGQ7lwMBDzq+cRAwExQpVQ5araRJtgPI
IvqrFUpU0z8hj9XiGjnW0aj+umFLe7JP9i1bN6v01PQ/M+BTf/6XKbQrvgXAFM1P
fOmHOoWa6N1LnpIjNCOeT6WL3ixT0iLge7B/Mfqanj5lpHglYZnYfa1Qtr+p7X9h
vRvIld09owwApyYgoLOfZ294d4uuxqRckZo/pTLQJErt4jro50STLCz5eXLWZyc7
Fk/6mxHQyeEW1zcKh1lrS6T9RQPw4mWizfpXzd/ovMo516OHYOcSFCWB3PW0RlEw
VXxSnem4tvhQcVKivMKSBKLQWXDAckBaEZ2MOAGg1jaH/bc/MszeScsJww9UKCRh
dVFXTl5rJQjuaLlsIJJYLBVVR3T7
=Lv7Q
-----END PGP PUBLIC KEY BLOCK-----`

export const PGP_KEY_PROD = `-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: Mailvelope v5.1.1 Comment: https://mailvelope.com

xsFNBGU2y+IBEACheBZx+ckZrGDs9J3v6yIoN41bXICpxGeXyIBUk8og+u60
OOzEp5p6DzJ7dAMf5ryoC7BYcXSjgF1i/VvDbXJKVMhUmwwnaWLTv/fk/k60
LF+JuwyG37JQZa/VtUBfiV+8BV8UEDnxEEhPLQqD/5qCX3RRvPQIHc1DVPkh
Amlx+KXFDUdpq1M/l6l5g1X09QZCAAIP3ghtr0QmeOXDNQz7YyA2PjiF3Bjr
iEfENEciu5VFMHy/FWEmpRvaYk/MyNSXPFimUBt2URgb+oov3wog9tNOpu7y
NXfISo4/TClfSTeSCVZ+tnMsodznlbu7F0vXxYqA3CJB1LKOhSqLOrqTxuce
bZpYgtqFbDmLYnSIAx5LxYNfp/IqfMoA882YaWF3DtHO9adhxeyc562Q0G9s
aKfgm4LkF+srYsA3U1WRPbkH7jpqm22f18ct9KcmGBtW4b+Dywc7zs2bPZNO
3HOxFcthg6y9Q3Rj55JA+6uuTGMuArbLgUOGYC2qkL0DeGjm6pM0N0lFDzXP
MU9Wl1fcM2ID6SV1qckYTzfxs2TQfa55mVeZEe/PDG4j3ovBj3VoK6/Fq+8H
86aZIM1E4bKJgQ8El2dRUXdRmrVIOSr4DqzPtR0tzWSOTJgytHyAJLVrNB7A
6m/nTP0Wcl0hqfiVdd8CqCPlwKi7BuSmD3KqNwARAQABzShQR1AtbG9naW4t
MjAyMyA8ZGV2b3BzLWNvcGFAc2V2ZW40bi5jb20+wsGKBBABCAA+BYJlNsvi
BAsJBwgJkIeceFXXqw+LAxUICgQWAAIBAhkBApsDAh4BFiEEm7+aV2FbXbfO
qPlEh5x4VderD4sAAI1MD/9wrUSRQ14Z4roiXKVq1peqRCmiTyD12svjKra5
25FKzLbqltQ0aaxBzrxobPzNtPNOJTSVuGbqJqSeAYLFOs64zajYkIqC4yuL
ZaqTorgw88jEIpcp1wi811buRDlr6Uye8d4GiJxwSbBWVBtBKP3GLYhCzdLP
jfDctdwhDAFaUurUAkIl2oxNA8qeSntH1a4mIOZgrfJJ10w2H/AYWJc4rz3P
mk5BxaCtSZ12dmtW3xC3BKFQ058wuc2yotjxOUxjPg/6azrxNQm3CfFxjOUj
QwQvq794XWW59pAuvzIWFyH3sQViqPwyl79+mHSNH93ME2UQYPVmhJzUQDLz
sSr9R+MxhvhldrmLEEY4vr00Jj/1BnDTY86BfA2xa8Kjn6KWYiOTcEny2fdC
sRYPRsQOtSeFDwsvHqLtidxr/1yHg0JsDO7nOpNca7nrat/Yj5mhwtCOTpKp
dpJGLvrHMfcmbj7JlVeE9dDDsGVbzp7mwDoapX45SuP5RFvmTrtbiUDoL4wv
Yx9p1NTqASaLovh/6rO4SsvHqApvQ4jvJU44TxOujGvM5ZNw6inED31NWbDS
o0JII26K5/9ccD1Zjh2Iv2QynWpnFvDeN7w37X6Up3WGyO0SRv5ZubDzudCE
1TFKt/uG/HSQdNe2Q4UEL+B7wsSK3gezYR55uf1v2w1cP8LBkAQQAQgARAWC
ZTbMIgWJA9qlbgQLCQcICZCHnHhV16sPiwMVCAoEFgACAQIZAQKbAwIeARYh
BJu/mldhW123zqj5RIeceFXXqw+LAACFUg//Wn9ag0ea4iHnQFb8VeYVhPu0
xWWZ3eEpL/KeJpBC3xF8OcFXZBsrHvssAlIY84O1czN687Dc9+prWX7oCKP5
T7x6RirSYs6Y++jRWS9dQYNeq5CXAlxgoDwxyT/oS12mitj6Ng4jrZiFokku
8x7KnQ5SA8zMydnDQzzNYCdxUqeUotXZDwEK9NigAjE9EhROvnUdcvvy/con
LqLPuTcfmt7uK0E7NsVfMqVrZDzT4qw+Tn7ZoxgDpJE+vJJSXas+IewUvWUn
CP8GzaMmOtZ6rNJKcAOa5I9qXgrikc0pVGdMEoxev31esVgK/jJ6YmogG7Hx
a8UDirZKmlPUzzoMA5uLXwYh6UVJ9lbhIaG9WCoxYEY7dYdaXl4NWj103FPy
E4qonz/SYKYOp7ZLRlxwL+a1MljyrIW81DuhskRK8koRYQGM5X4IxUilDTm3
MUanQaCypaEg51eKLUEOrbDIZIn3zZ2pH+VjVMklQFv59Yd0bJwImkCOHqwe
C367uFyLkVP7LKwOhTokhbcMxvd3m+Nh4rPKPx9jN56y3i9GLFHXfbObvWwS
1OiEYDf9MU3hVV3prhcXUzSJ55Urq4f2nRAePMxO3WPnuCMSnSVsoVDhTEM/
7nAEMHR751iR2JGVxsXQyatpH8b2/D4xrawz/bMjbLEgxKGbLvkH8kGi5WPO
wU0EZTbL4gEQAL4tc1lBABj8raLI0ZUbeBnK+bMzBJ1T914G0jD+57PuF+Xy
54TcI2JaoYRYdAw+gVWkhXdsfn77ehSbkOo3QPaTRVsbw8JFdhPD/q8Xh5mE
6BLpg93Q94YDmwCix0aHMAHIGpXSd1d4EwRDRxTiXobGNuv2a11iTZ5ArQbb
oBp4PEOqgOd6bFfadDNIEDvdvMIUXcvxKHBgc3SURM0YViJoMRNcAL9a+Lgs
EDakzTvvvoviFdtR/nfNvGDcAdLj7dnmZ70xgPB8ieJVmDJGa8bJP7nhUm8W
oMGTCVluTGtxmDbWnExf4YmlUKpM8YaDUiXtvI6IbpPIFuhA324dEnHH8XO3
VCIpNtS7oVBYhFvAQDjUy/gd5d2WwLjZa+B/EnMuPZud0EmyOq8V8nTdOsoS
EaFlwnSo3w4QCQafUJOZXbJyPhPdenss3y/UQIZ6BlUfdKIo85XrYpM3R1b6
dt/fmL1ub7IM/doLePqZCXWMTUJ9b5l4XkGc6S3UQBMRyKLINHuxS2fPQP33
07tILlOxd3uWWF3VF1wZZ2vv8xEgIwdAUI6xZ9lbjpMDPdiTK/KSluzQtETw
n5eq6Q7z0JvANl43tY76xPZ9UNzVINhr0p0GCBNHsYAMHi+T2uFR7ZrtPQd5
cEDeABWZt5n44pnFDQ1v38QcEvLde0PSvy0hABEBAAHCwXwEGAEIADAFgmU2
zCIFiQPapW4JkIeceFXXqw+LApsMFiEEm7+aV2FbXbfOqPlEh5x4VderD4sA
AJdnD/4g8byyamzIDdz9C3lPmGfXBbVg6KzMOdpbvsddJrAAUTHNCPaiaUNY
orZz276zWAinG0cKh+EkuYyd7D1p1CFG67vrW6TGHzPCOXFBkK3g8sSLQG0C
5d0UKzgbq4FuZdmRk2EYb3CJBf6AJK/cfFqVePxcDfOeC5ENWrKuRSJntuLP
9FK3jcKn7LgkLKXju5fMU6UFLUbWrbmZSOU595fXKtRcpfG/cb9lBgCUcCFx
lE8qSS9dPOoK4ZDk5IxxAzzzRE9LIq+9pAs7DWzQLM9C+fa3TbPUUurwEPjr
S8aCjOm1wWkXKDhDtnZltaoVjwPFsdvUeV3aYi+dSvW66LQahljkjK0lO08d
LLUtvewLsoj5TE5RsBzCuBR8ItBRTjMPnmhHFRnpExIkOY3rKJ0b+B4qLXgD
FAn8VGIIyYAiFdIf3qELMGt4/kmdGIvFLZ84etRlMfeqpTTe8K8oFiYyW57J
qAi1zZS7LhbTnZUSIIZqWeNIhj6b1lr4q/PRhBCqmy8omcks6eChEQVEYKZa
rXgBW86pwoqPnsl2DNHMVhwpHNXMBOXtZQQA1tl8m3jdUZzyd9Mz5D9Uz2l2
rGEFX+H5TzS/3F7jyzHEOZcgeTpyy++II749W7qIR7spzzfxLDTey9ul+Dkc
n7TWRGMYPxwYa7pVniBP1NIUcAh1lQ==
=9VNf
-----END PGP PUBLIC KEY BLOCK-----`
