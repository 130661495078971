import React, { useState } from "react"
import PropTypes from "prop-types"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import Collapse from "@material-ui/core/Collapse"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

import theme from "@bit/vibenitez.copa-components-library.theme"
import useStyles from "./styles"

const AlertCo = ({
  children,
  alertTitle,
  closeIcon,
  alertAction,
  ...props
}) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles(props)
  const closeEvent = () => setOpen(false)
  const domProps = { ...props }

  delete domProps.fontColor
  delete domProps.bgColor
  delete domProps.borderLeftIcon
  delete domProps.fontSize
  delete domProps.hideIcon

  const closeAction = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={closeEvent}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  )
  return (
    <Collapse in={open}>
      <Alert
        classes={{ action: classes.action }}
        className={classes.root}
        action={closeIcon && closeAction}
        role="alert"
        variant="filled"
        {...domProps}
      >
        <div>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          {children}
        </div>
      </Alert>
    </Collapse>
  )
}

AlertCo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alertTitle: PropTypes.string,
  closeIcon: PropTypes.bool,
  width: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontColor: PropTypes.string,
  alertAction: PropTypes.bool,
  hideIcon: PropTypes.bool,
  bgColor: PropTypes.string,
  borderLeftIcon: PropTypes.string,
}

AlertCo.defaultProps = {
  children: "",
  closeIcon: false,
  alertTitle: "",
  width: "100%",
  fontWeight: 300,
  fontSize: "1rem",
  fontColor: theme.palette.common.white,
  alertAction: false,
  hideIcon: false,
  bgColor: "",
  borderLeftIcon: theme.palette.alternative.gold,
}

export default AlertCo
