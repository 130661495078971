import axios from "axios"
import { getCookie } from "../utils/auth"

export default {
  airports: {
    origins: () => axios.get("catalog/booking-airports"),
    destinations: (origin) =>
      axios.get(`catalog/booking-airports?origin=${origin}`),
  },
  connectMiles: {
    logIn: ({ payload }) => axios.post(`loyalty/login`, payload),
    getProfile: ({ token, membership: membershipID }) =>
      axios.get(`loyalty/passenger/${membershipID}/profile/extended`, {
        headers: {
          Token: token,
        },
      }),
    logOut: (credentials) => axios.post(`loyalty/logout`, credentials),
  },
  reservation: {
    getReservation: (payload) =>
      axios.get(
        `rebooking/reservation/${payload.pnr}?last_name=${payload.lastName}`
      ),
    getEligibility: (payload) => axios.get(`catalog/eligibility/${payload}`),
    reservationChange: (pnr) =>
      axios.get(`/rebooking/reservation/${pnr}/change`),
  },
  flights: {
    getFlights: (payload) =>
      axios.post(`rebooking/fare-reshop`, { ...payload }),
  },
  session: {
    sendSelection: (payload) =>
      axios.post(`rebooking/reservation/${payload.confirmationId}/change`, {
        ...payload,
      }),
    getSession: (payload) =>
      axios.get(
        `rebooking/reservation/session/${payload.pnr}?last_name=${payload.surname}`
      ),
  },
  fareRules: {
    getFareRules: (payload) => {
      delete axios.defaults.headers.Storefront
      axios.defaults.headers.Storefront = getCookie("currentPos").replace(
        "CM",
        ""
      )
      return axios.post(`rebooking/fare-rules`, { ...payload.payload })
    },
  },
}
