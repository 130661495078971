export default {
  topBar: {
    modifySearch: "Nova pesquisa",
    description: "Mudar de viagem",
    reservation: "Código de reserva: {reservationCode}",
    reservationXS: "Reserva: {reservationCode}",
    passengers:
      "{numberOfPassengers, plural, one {# Passageiro} other {# Passageiros} }",
    loginCTA: "Iniciar Sessão",
    miles: "milhas",
    close: "Fechar",
    backToMytrips: "Voltar para Minhas Viagens",
    membersMenu: {
      personalizedGreeting: "Olá,",
      LogoutCTA: "Desconectar",
      accountCTA: "Minha conta",
      connectmilesLabel: "# CONNECTMILES",
      statusLabel: "STATUS",
      milesLabel: "SALDO DE MILHAS ACUMULADAS",
      myAccountExternalLink: "/pt/web/profile ",
    },
    loginBox: {
      credentialsLabel: "Número do ConnectMiles, e-mail ou nome de usuário",
      required: "Este campo é requerido",
      credentialsPlaceholder: "Insira de usuário",
      inlineErrorEmptyField: "Este campo é obrigatório",
      passwordLabel: "Senha",
      passwordfieldPlaceholder: "Insira sue senha",
      forgotLink: "Esqueceu sua senha?",
      forgotExternalLink: "https://www.copaair.com/pt/web/gs/forgot",
      createaccountlink: `Não tem uma conta? <a> Crie uma agora </a>`,
      createaccountlinkExternal: "https://www.copaair.com/pt/web/gs/enrollment",
      emailMatch: "Os e-mails não coincidem",
      errorbannerWrongData:
        "As informações fornecidas não estão de acordo com nossos registros. Confira as palavras usadas e tente novamente.",
      errorbannerBlockedAccount:
        "Por questões de segurança, sua conta foi bloqueada temporariamente. Entre em contato com a Central de Atendimento do ConnectMiles para obter ajuda. <a> Saber mais </a>",
    },
  },
  topBarWCAG: {
    logo: "Logotipo da Copa Airlines. Pressione Enter para ir à página principal da Copa Airlines",
    description:
      "Seção de mudanças. Você vai fazer uma alteração em sua reserva {reservationCode}, isso se aplicará a todos os {numberOfPassengers} passageiros.",
    languageSelector:
      "Selector de  línguas. O língua activo é {language}. Pressione enter para mudar de língua.",
    loggedIn:
      "Você iniciou sessão com o usuário [Nombre]. Pressione Enter para ir para o menú de membro.",
    login:
      "Botão de login do ConnectMiles. Permite acessar ao seu perfil de membro. Pressione Enter para ir para o formulário de início de sessão.",
    editButton:
      "Editar parâmetros de pesquisa. Pressione Enter para modificar seus parâmetros de pesquisa.",
    backToMytrips:
      "Botão para voltar. Pressione Enter para retornar para a à seção de detalhes de Minhas viagens.",
    membersMenu: {
      personalizedGreeting: "Olá,",
      LogoutCTA: "Desconectar",
      accountCTA: "Minha conta",
      connectmilesLabel: "# CONNECTMILES",
      statusLabel: "STATUS",
      milesLabel: "SALDO DE MILHAS ACUMULADAS",
      myAccountExternalLink: "/pt/web/profile ",
    },
    loginBox: {
      credentialsLabel: "Número do ConnectMiles, e-mail ou nome de usuário",
      required: "Este campo é requerido",
      credentialsPlaceholder: "Insira de usuário",
      inlineErrorEmptyField: "Este campo é obrigatório",
      passwordLabel: "Senha",
      passwordfieldPlaceholder: "Insira sue senha",
      forgotLink: "Esqueceu sua senha?",
      forgotExternalLink: "https://www.copaair.com/pt/web/gs/forgot",
      createaccountlink: `Não tem uma conta? <a> Crie uma agora </a>`,
      createaccountlinkExternal: "https://www.copaair.com/pt/web/gs/enrollment",
      emailMatch: "Os e-mails não coincidem",
      errorbannerWrongData:
        "As informações fornecidas não estão de acordo com nossos registros. Confira as palavras usadas e tente novamente.",
      errorbannerBlockedAccount:
        "Por questões de segurança, sua conta foi bloqueada temporariamente. Entre em contato com a Central de Atendimento do ConnectMiles para obter ajuda. <a> Saber mais </a>",
    },
  },
  searchPage: {
    heading: {
      subtitle: "Sua viagem para {city}",
      title: "Como você quer alterar sua viagem?",
      description: "Você pode mudar sua data ou hora de vôo",
      containerWCAG:
        "Seção de mudança de voo, aqui você pode selecionar o tipo de mudança que deseja fazer.",
    },
    editHeading: {
      title: "Nova pesquisa",
      ctaEdit: "Retorna",
      wcagContent:
        "Modifique a seção de alteração de voo, aqui você pode alterar o tipo de alteração que deseja fazer.",
      wcagBton:
        "Botão de retorno. Pressione Enter para retornar à página em que você estava.",
    },
    notResultsDisclaimer:
      "Não há disponibilidade nas datas e / ou cidades selecionadas. Por favor modifique sua pesquisa e tente novamente.",
    fatalErrorDisclaimer:
      "Desculpe, devido a dificuldades técnicas, não conseguimos continuar com sua mudança. Por favor, tente novamente mais tarde.",
    ctaSearchFlight: "Pesquisar opções de voo",
    ctaSearchFlightWCAG:
      "Botão de pesquisa. Pressione Enter para prosseguir com a pesquisa de opções de voo.",
    modeSelector: {
      title: "O que você quer alterar?",
      option1Text: "Mudar datas e/ou horas",
      option3Info: "Data atuai: {departureDate}",
      option1Info: "Datas atuais: {departureDate} - {arrivalDate}",
      option2Text: "Alterar cidades e data",
      option4Info: "{departureCode} · {arrivalCode}, {departureDate}",
      option2Info:
        "{departureCode} · {arrivalCode}, {departureDate} - {arrivalDate}",
    },
    modeSelectorWCAG: {
      changeDates:
        "Opção de alterar a data e / ou horas da sua viagem. Pressione Enter para selecionar esta opção de alteração",
      changeRoutes:
        "Opção de alteração da cidade e data da sua viagem. Pressione Enter para selecionar esta opção de alteração",
    },
    datesSelector: {
      title: "Selecione os voos que deseja mudar",
      infoSegment:
        "As mudanças serão aplicadas a todos os passageiros desta reserva. Se você deseja antecipar sua viagem, algumas datas podem não estar disponíveis devido a restrições. Seus voos atuais não serão alterados até que você confirme seu novo itinerário.",
      flightNumber: "Vôo {index}",
      originTitle: "Desde",
      originPlaceholder: "Digite a origem",
      originFocusPlaceholder: "Cidade, país ou aeroporto",
      destinationTitle: "Para",
      destinationPlaceholder: "Digite o destino",
      destinationFocusPlaceholder: "Cidade, país ou aeroporto",
      noResultsAutocomplete: "Oops, nenhum resultado encontrado",
      noResultsAutocompleteHelperText: "Modifique sua pesquisa",
      noResultsHelperTextOrigin: "Selecione uma origem válida",
      noResultsHelperTextDestination: "Selecione um destino válido",
      emptyOrigin: "Selecione uma origem",
      emptyDestination: "Selecione um destino",
      datesTitle: "Datas de viagem",
      emptyDate: "Selecionar data da viagem",
      emptyOriginWhenDestination: "Você deve selecionar uma origem primeiro",
      airportListFail: "Ops, não foi possível carregar os aeroportos.",
      retryButton: "Tente novamente.",
      datePicker: {
        dateRoundTrip: "Datas de viagem",
        dateRoundTripPlaceHolder: "Escolha datas de vôo",
        dateOneWay: "Data da viagem",
        dateOneWayPlaceholder: "Escolha data de vôo",
        flexibleDates: "Datas flexíveis",
        confirmCTA: "Confirme",
        departure: "Saída",
        return: "Retorno",
      },
      keyboardShortcuts: {
        title:
          "Painel de informações onde você pode aprender mais sobre a navegação do teclado para selecionar datas. Continue para os atalhos do teclado. ",
        enter: "Confirme a data selecionada (Enter)",
        leftAndRight:
          "Alterar o dia para trás (esquerda) ou para a frente (direita)",
        upAndDown:
          "Semana anterior (seta para cima) ou próxima semana (seta para baixo)",
        pageUpAndPageDn:
          "Mês anterior (tecla Page up) ou para o mês seguinte (tecla Page down)",
        homeAndEnd:
          "Primeiro dia da semana (tecla Home) ou último dia (tecla End)",
        escape: "Retornar campo de entrada de data (tecla Escape)",
        info: "Abra o painel de informações de navegação do teclado",
        botoni:
          "Abra o painel de informações de navegação do teclado (tecla i)",
        closeBtn:
          "Botão Fechar. Pressione Enter para fechar este painel informativo e retornar à seleção de data",
      },
      sameDay:
        "Mudanças no mesmo dia de voo só podem ser solicitadas no aeroporto. Selecione outra data para continuar.",
    },
    changeRouteSelector: {
      changeRoute: "Alterações disponíveis no país de sua reserva original.",
      notAvailableTitle: "Cidade não disponível para este meio.",
      errorMesaje:
        "Para alterar seu país de origem, entre em contato com nossa <a>Central de Reservas</a>.",
      link: "https://www.copaair.com/pt/web/gs/reservation-center",
    },
    datesSelectorWCAG: {
      originField:
        "Origem. Digite a cidade, país ou aeroporto de origem da sua viagem.",
      airportLoadingFail:
        "Erro ao carregar. Não conseguimos carregar os aeroportos. Pressione Tab para tentar novamente e continuar sua pesquisa.",
      swapButton:
        "Permutar. Origem {origin}, Destino {destination} Pressione Enter para permutar ou continue a selecionar as datas da viagem.",
      destinationField:
        "Destino. Digite sua cidade, país ou aeroporto de destino.",
      checkBoxWCAG:
        "Voo {numFlight} da sua viagem. Pressione Enter para confirmar que deseja alterar este voo, que é originalmente de  {origin}  para {destination} em {date}.",
      checkBoxWCAGNextFlight:
        "Caso contrário, pressione tab para ir para o próximo voo.",
      datePicker: {
        dateRoundTrip:
          "Seleção de Fechas. Para continuar, selecione os fechos do seu veículo. Podrás navegar por mes y días para realizar tu selección.",
        dateOneWayTrip:
          "Procure voos de ida, pressione Enter para avançar para a seleção de Origem.",
        travelDateField:
          "Seleção de Fechas. Para continuar, selecione os fechos do seu veículo. Podrás navegar por mes y días para realizar tu selección.",
        monthSelection:
          "{month} {year}. Toque duas vezes para ir para os dias de {month}",
        daySelection:
          "{weekday} {day}. Toque duas vezes para selecionar este dia.",
        roundTripSelection:
          "Você selecionou  {month}, {weekday} {day} para o voo de partida, selecione a data de retorno",
        oneWaySelection: "Você selecionou  {month}, {weekday} {day}",
        flexibleDatesSelection:
          "Datas flexíveis. Pressione Enter para ativar datas flexíveis nos resultados da pesquisa. {active}",
        roundTripConfirmationCTA:
          "Confirme as datas da pesquisa. Pressione enter para confirmar que as datas da viagem de pesquisa são: Partida {departureMonth} {departudeDay}, retornando {returnMonth} {returnDay}",
        oneWayConfirmationCTA:
          "Confirme a data da pesquisa. Pressione Enter para confirmar que a data da sua pesquisa é: {departureMonth} {departudeDay}",
      },
    },
    disclaimer: {
      title:
        "Ao fazer uma alteração em sua viagem, você deve saber o seguinte:",
      disc1:
        "O custo da mudança pode variar de acordo com a família tarifária comprada originalmente. Se o custo do seu novo itinerário for menor, você receberá um crédito para um voo futuro. Alguns impostos podem não ser reembolsáveis. Esta plataforma permitirá que você faça uma única alteração na reserva. Caso necessite de uma segunda alteração, deverá fazê-lo através da nossa Central de Reservas.",
      disc2:
        "Ao fazer uma mudança, você perderá sua seleção de assento original e terá que selecioná-la novamente em nossa seção “Minhas viagens” depois de concluir a alteração. Assentos previamente selecionados ou comprados podem não estar mais disponíveis no novo voo. Se o assento foi adquirido por outro passageiro, voce terá um crédito associado à reserva para selecionar um novo assento. Se o crédito não for utilizado, não será reembolsável.",
      disc3:
        "A família tarifária selecionada para seus novos voos (ida ou volta) será aplicada a todo o itinerário, incluindo os voos que você não está alterando. Certifique-se de revisar os atributos da tarifa antes de fazer a mudança.",
      link: "Veja nossa política de troca",
      webLink:
        "https://copaair.com/pt-gs/legal/termos-e-condicoes/alteracoes-voluntarias",
    },
    disclaimerWCAG: {
      contentDisclaimer:
        "Seção de mensagens importantes. Lembre-se de que {disc1}, {disc2}, {disc3}",
      contentDisclaimerMobil:
        "Sección de mensajes importantes. Presione enter para conocer los mensajes",
      link: "Alterar o link da política, pressione Enter para ver os detalhes da política de mudança.",
    },
  },
  searchPageSEO: {
    description:
      "Escolha o tipo de mudança que deseja fazer para seu viagem. Você pode fazer alterações nos horários, datas ou ciudades.",
    titlePage: "Mudar viagem - Copa Airlines",
    keywords:
      "Mudanças, voos, mudança de voos, mudança de data, mudança de horários, alterar reservas, modificar voos, alterar viagem, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  resultPageSEO: {
    description:
      "Lista de todos os voos disponíveis para fazer a sua mudançã, selecione o voo que mais lhe convém.",
    titlePage: "Resultados do voo - Copa Airlines",
    keywords:
      "Mudanças, voos, mudança de voos, mudança de data, mudança de horários, alterar reservas, modificar voos, alterar viagem, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  confirmationSEO: {
    description:
      "Confirmação da mudança da sua viagem. Detalhamos o resumo da sua alteração",
    titlePage: "Confirmação de Mudança | Copa Airlines",
    keywords:
      "Mudanças, voos, mudança de voos, mudança de data, mudança de horários, alterar reservas, modificar voos, alterar viagem, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fullWidthAlert: {
    timeLeft: "Sua sessão expirará em {time}.",
  },
  resultPage: {
    heading: {
      title: "Selecione um novo itinerário",
      description: "Nova seleção: ",
    },
    fareChangeModal: {
      modalTitle: "Toda a sua viagem mudará para {fareFamily}",
      unchangedODModalTitle: "Famílias de tarifas em novo itinerário",
      upgradeDescriptionRT:
        "Esta ação mudará todos os voos de sua viagem para a tarifa {fareFamily}, mesmo os voos que você não está alterando. Agora você terá mais benefícios.",
      downgradeDescriptionRT:
        "Esta ação mudará todos os voos de sua viagem para a tarifa {fareFamily}, mesmo os voos que você não está alterando. Sua experiência de viagem e benefícios podem ser afetados. ",
      upgradeDescriptionOW:
        "Esta ação mudará todos os voos de sua viagem para a tarifa {fareFamily}. Agora você terá mais benefícios.",
      downgradeDescriptionOW:
        "Esta ação mudará todos os voos de sua viagem para a tarifa {fareFamily}. Sua experiência de viagem e benefícios podem ser afetados.",
      ctaCancel: "Cancelar",
      ctaAccept: "Aceite",
      fareFamilyDetails: {
        text: "Veja mais detalhes as regras de tarifa",
        link: "https://www.copaair.com/pt-gs/informacoes-ao-viajar/familias-de-tarifas/",
      },
      table: {
        flightHead: "Voo",
        oldFareHead: "Tarifa Original",
        newFareHead: "Vai mudar para",
        warningText: "Mudança de cabine",
        dateText: "Voo {numberOfFlight} · {date}",
      },
      unchangedODPill: "Sem alterações",
      unchangedODAlert:
        "Você selecionou diferentes famílias de tarifas para seus voos. Para sua conveniência, você sempre poderá escolher famílias de tarifas diferentes para seus voos de ida e volta. No entanto, alguns benefícios, como reembolsos ou alterações de itinerário, podem ser restritos.",
    },
    fareChangeModalWCAG: {
      containerUpgrade:
        "Mensagem importante. Toda a sua viagem mudará para {newFareFamily}. Esta ação mudará todos os voos de sua viagem para a tarifa {newFareFamily}, mesmo os voos que você não está alterando. Agora você terá mais benefícios. Estes são os detalhes de suas alterações: seu voo de {origin1} para {destination1} que estava em {fareFamily1} será alterado para {newFareFamily} {alerts1} e seu voo de {origin2} para {destination2} que estava na {fareFamily2}, ela será alterada para {newFareFamily} {alerts2}",
      containerDowngrade:
        "Mensagem importante. Toda a sua viagem mudará para {newFareFamily}. Esta ação mudará todos os voos de sua viagem para a tarifa {newFareFamily}, mesmo os voos que você não está alterando. Sua experiência de viagem e benefícios podem ser afetados. Estes são os detalhes de suas alterações: seu voo de {origin1} para {destination1} que estava em {fareFamily1} será alterado para {newFareFamily} {alerts1} e seu voo de {origin2} para {destination2} que estava na {fareFamily2}, ela será alterada para {newFareFamily} {alerts2}",
      ctaCancel:
        "Botão Cancelar. Pressione Enter para voltar e selecionar outra opção.",
      ctaAccept:
        "Aceitar botão de alteração. Pressione Enter para aceitar a alteração.",
      fareFamilyDetailsLink:
        "Link famílias tarifarias. Pressione Enter para saber mais sobre as famílias de tarifas.",
      closeButton:
        "Fechar botão modal. Pressione o botão para fechar o modal e selecionar outra opção.",
    },
    fares: {
      BFU: "Executiva Full",
      PRO: "Executiva Promo",
      EFU: "Econômica Full",
      CLS: "Econômica Classic",
      BAS: "Econômica Basic",
      CLF: "Econômica Classic Flex",
      NONE: " ",
    },
    cardsResult: {
      rountripSearchLabel: "Ida e volta",
      oneWaySearchLabel: "Só de ida",
      totalAmount: "Total a pagar",
      changeItineraty: "Mudança de itinerário de",
      changeItineratyPlural: "Mudança de itinerário de",
      optionText: "Veja as opçõe",
      optionTextPlural: "Veja as opções",
      header: {
        buttonFilter: "Classificar e filtrar",
        economyCabin: "Econômica",
        businessCabin: "Executiva",
      },
      headerWCAG: {
        languageAndStorefrontSelector: {
          countryDropdown:
            "Use as teclas de seta para cima e para baixo para selecionar país de acordo com sua localização e a moeda de sua preferência. Pressione tab para continuar na seleção de idioma. Pressione Esc para retornar à navegação.",
          languageDropdown:
            "Use as teclas de seta para cima e para baixo para selecionar seu idioma preferido. Pressione Esc para retornar à navegação.",
          modifyCTA:
            "Botão modificar. Pressione Enter para modificar o país para {currencyCountry} e o idioma para {currencyLanguage}",
          closeCTA:
            "Botón de cerrar. Presione Enter para mantener país e idioma, regresar a la navegación principal.",
        },
        loginBox: {
          CredentialsLabel: "Digite o nome de usuário do ConnectMiles",
          PasswordLabel: "Digite a senha do ConnectMiles",
          loginButton:
            "Botão de login. Pressione enter para entrar com suas credenciais",
          fortgotPassword:
            "Esqueça o link da senha. Pressione enter para ir para o ConnectMiles e recuperar sua senha",
          Close: "Botão fechar, pressione enter para fechar o pop up",
          InlineErrorMandatoryFiled: "Erro. Este campo é obrigatório",
          CreateAccountlink:
            "Link para criar uma conta. Pressione enter se você não tiver uma conta e quiser criar uma conta agora. A página connectmiles será aberta em uma nova guia no seu navegador.",
          ErrorBannerAlertWrongInformation:
            "Erro. As informações fornecidas não estão de acordo com nossos registros. Confira as palavras usadas e tente novamente.",
          ErrorBannerAlertBlockedAccount:
            "Erro. Por questões de segurança, sua conta foi bloqueada temporariamente. Entre em contato com a Central de Atendimento do ConnectMiles para obter ajuda. <link to ConnectMiles call center number>",
        },
        buttonFilter:
          "Botão. De acordo com a sua pesquisa, o seu voo de regresso é no {day}",
      },
      tripCard: {
        viewDetails: "Veja detalhes",
        flightDetailLayover: "Escala em",
        flightDetailLayovers: "Escalas em",
        flightDetailDirect: "Sem escalas",
      },
      tripCardWCAG: {
        viewDetailsDirect:
          "Informações sobre o resultado do voo. Voo direto de {origin} para {destination} com hora de partida {departure} e chegada em {arrival}; do {day}, {month}, {year}, operado por {airlines}. A duração total do voo é de {flightTime}.",
        viewDetailsLayovers:
          "Informações sobre o resultado do voo. Voo de {origin} para {destination} com hora de partida {departure} e chegada em {arrival}; do {day}, {month}, {year}, com {layoverLength} escala de {layoverTime}, operado por {airlines}. A duração total do voo é de {flightTime}.",
        viewDetails:
          "Enlace de ver detalles, presiona enter para ver más detalles de los vuelos.",
        hours: "horas",
        hour: "hora",
        minutes: "minutos",
      },
      cabin: {
        cabinText1: "A partir de",
        cabinText2: "Por adulto",
        cabinText3: "Esgotado",
        economy: "Econômica",
        business: "Executiva",
        unavailable: "Não disponível",
        helperTextMobile: "Selecione uma tarifa",
        oneWay: "Só de ida",
      },
      cabinWCAG: {
        economyCabin: "Cabine econômica. Preço de {price} por adulto",
        economySoldOut: "Não há disponibilidade na cabine econômica",
        businessCabin: "Cabine executiva. Preço de {price} por adulto",
        businessSoldOut: "Não há disponibilidade na cabine executiva",
        economyCabinPromo:
          "Cabine Econômica. Preço de {price} e com o desconto é {originalPrice} por adulto",
        businessCabinPromo:
          "Cabine Executiva. Preço de {price} e com o desconto é {originalPrice} por adulto",
        cabinHelperMobileText: "Cabine {type}. Selecione uma tarifa",
      },
      fareFamilies: {
        basicPill: "Tarifa mais restritiva",
        selectedPill: "Você escolheu esta tarifa",
        restrictivePill: "Tarifa retritiva",
        purchased: "Tarifa comprada",
        ctaBasic: "Selecione Econômica Basic",
        ctaClassic: "Selecione Econômica Classic",
        ctaFull: "Selecione Econômica Full",
        ctaFlex: "Selecione Classic Flex",
        ctaExtra: "Selecione",
        ctaBusinessPromo: "Selecione Executiva Promo",
        ctaBusinessFull: "Selecione Executiva Full",
        ctaBack: "Mantenha esta tarifa",
        linkText: "Detalhes da tarifa",
        link: "https://www.copaair.com/pt-gs/informacoes-ao-viajar/familias-de-tarifas/",
        changePenaltyDisclaimer:
          "A primeira alteração sem taxas de penalização é aplicável se for feita pelo menos oito dias antes da data de partida*.",
        fareFamilyDisclaimer:
          "Os preços apresentados incluem taxas de combustível, impostos e outras taxas. Consulte nossa <link>política de bagagem</link> para voos operados pela Copa Airlines. Encargos adicionais podem ser aplicados a malas grandes, com excesso de peso ou tamanho, bem como a certos itens especiais.",
        baggagePolicyLink:
          "https://www.copaair.com/pt-gs/informacoes-ao-viajar/informacoes-sobre-bagagem/",
        faresPolicyLink:
          "https://www.copaair.com/pt-gs/informacoes-ao-viajar/familias-de-tarifas/",
        preferMemberDisclaimer:
          "Os benefícios para PreferMembers variam dependendo do status do membro.",
        priceIncludeDisclaimer:
          "Os preços incluem taxas de combustível, impostos e tarifas.",
        baggageDisclaimer:
          "Consulte a nossa <link>política de bagagem</link> para voos operados pela Copa Airlines. Taxas adicionais podem ser aplicadas para bagagens com excesso de peso, tamanho excedente ou adicionais, assim como para determinados itens especiais.",
        mixedFareFamiliesDisclaimer:
          "Ao selecionar combinações de <link>famílias de tarifas</link> mistas, os atributos mais restritivos de ambas as tarifas serão aplicados.",
        tooltipPillRestrictive:
          "Um dos voos possui tarifa com restrição de bagagem, assento e alteração. Clique em 'ver detalhes' para saber mais.",
        baggagePolicyLinkWCAG:
          "Confira nossa política de bagagem. Pressione 'Enter' para mais detalhes.",
        faresPolicyLinkWCAG:
          "Explore nossas famílias tarifárias e seus benefícios. Pressione 'Enter' para mais detalhes.",
      },
      fareFamiliesWCAG: {
        basicContainer:
          "Familia tarifaria Econômica Basic, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        classicContainer:
          "Familia tarifaria Econômica Classic, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        fullContainer:
          "Familia tarifaria Econômica Full, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        extraContainer:
          "Familia tarifaria Econômica Extra, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        businessPromoContainer:
          "Familia tarifaria Executiva Promo, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        businessFullContainer:
          "Familia tarifaria Executiva Full, esta taxa tem o preço de {price} e possui os seguintes atributos {attributeList}",
        cta: "Selecione o botão de taxa. Pressione o botão para selecionar essa taxa ou clique em Tab para ir para a próxima taxa.",
      },
    },
  },
  summaryPage: {
    title: "Seu novo itinerário de viagem",
    subtitle: "Revise seu itinerário cuidadosamente antes de continuar.",
    changeFlight: "modificar seleção",
    summaryPageCTA: "Continuar",
    CTAButton:
      "Botão Continuar. Pressione o botão para continuar com o fluxo de compras.",
    titleCostChange: "Custo da mudança",
    textCollapsible: "Mudança sem custo adicional",
    flightTypeForCookie1: "Ida e volta",
    flightTypeForCookie2: "Só de ida",
    flightTypeForCookie3: "Multicidade",
    flightTypeForCookie4: "Stopover",
    adults: "Adultos",
    adult: "Adulto",
    children: "Crianças",
    child: "Criança",
    infants: "Bebês ",
    infant: "Bebê",
    itinerary: {
      businessFull: "Executiva Full",
      businessPromo: "Executiva Promo",
      economyFull: "Econômica Full",
      economyClassic: "Econômica Classic",
      economyBasic: "Econômica Basic",
      viewDetails: "Veja detalhes",
      outboundFlight: "Voo de ida",
      returnFlight: "Voo de volta",
      changeFlight: "Alterar voo",
      fareDetails: "Regras tarifárias",
      layover: "Escala em",
      layovers: "Escalas em",
      leg: "Voo {legNumber}",
      pillText: "Novo vôo",
      noChangePillText: "Sem alterações",
    },
    priceBreakdown: {
      confirmationCode: "Código de autorização",
      newFare: "Nova tarifa",
      oldFare: "Taxa original",
      credit: "Quantia a favor",
      changePenalty: "Pena por mudança",
      subTotalSubTitle: "Subtotal",
      differenceFare: "Diferença tarifária",
      taxSubtitle: "Taxas e impostos",
      upliftMessage:
        "Você completou esta compra usando o Uplift. <a>Saiba mais.</a>",
      upliftUrl: "https://www.copaair.com/pt/web/gs/opcoes-de-pagamento-uplift",
      disclaimer:
        "Ao confirmar essa mudança, você receberá um certificado de viagem no valor de {price} válido para futuras compras de passagens com a Copa Airlines.",
      disclamerConfirmation:
        "Você receberá um certificado de viagem de {price}, válido para futuras compras de passagens com a Copa Airlines.",
      chargedAmountSuccesfully:
        "Estamos processando seu pagamento. Você receberá um email com a confirmação da sua reserva.",
      chargedAmountInsuranceSuccesfully:
        "Receberá um email com a confirmação da sua compra. O custo do seguro de viagem será refletido como um encargo separado do resto do custo da reserva.",
      disclaimerBton:
        "Ao clicar em confirmar mudança, você estará modificando sua reserva e não poderá retornar ou fazer alterações adicionais nesta plataforma.",
      presidentialAlert:
        "{name}, ser um PreferMember Presidential oferece mais benefícios. Para dispensar o custo da multa pela alteração do seu bilhete, entre em contato conosco na Central de Reservas.",
    },
    itineraryNotAvailableModal: {
      title:
        "Desculpe, o itinerário que você selecionou não está mais disponível.",
      description:
        "Um ou mais dos voos que você selecionou não estão mais disponíveis e não podemos prosseguir com a mudança de sua reserva. Por favor, reinicie sua pesquisa e escolha um novo itinerário.",
      ctaButton: "Reiniciar pesquisa",
    },
    taxesModal: {
      title: "Detalhe de taxas e impostos",
      subtitle: "Custo de reserva",
      description: "Descripção",
      amount: "Montante",
      total: "Total taxas e impostos",
      payedTaxes: "Taxas e impostos pagos anteriormente",
    },
  },
  summaryWCAG: {
    itinerary: {
      linkViewDetails:
        "Link de ver detalhes, pressione Enter para ver mais detalhes do voo.",
      linkViewRules:
        "Link para ver detalhes da passagem aérea, pressione enter para ver os detalhes da tarifa selecionada.",
      linkModify: `Link "mudar voo", pressione Enter para retornar à página de resultados do voo e mudar a seleção de voo.`,
      containerOutboundWithOneScale:
        "Informações sobre o voo de partida selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; com {numLayover} escala de {timeLayover} em {codCountry}; a duração total dos voos é {duration} com a família de tarifas {fare}",
      containerInboundWithOneScale:
        "Informações sobre o voo de partida selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; com {numLayover} escala de {timeLayover} em {codCountry}; a duração total dos voos é {duration} com a família de tarifas {fare}",
      containerOutboundWithMultipleScales:
        "Informações sobre o voo de partida selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; com escalas {numLayover} em {codCountry}; a duração total dos voos é {duration} com as seguintes famílias de tarifas {fare}",
      containerInboundWithMultipleScales:
        "Informações sobre o voo de retorno selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; com escalas {numLayover} em {codCountry}; a duração total dos voos é {duration} com as seguintes famílias de tarifas {fare}",
      containerOutboundWithoutScale:
        "Informações sobre o voo de partida selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; a duração total do voo é {duration} com a família de tarifas {fare}",
      containerInboundWithoutScale:
        "Informações sobre o voo de retorno selecionado. O dia do voo é {dateFlight}, de {startFlight} a {endFlight} com horário de partida {hourDeparture} e horário de chegada às {hourArrival}; a duração total do voo é {duration} com a família de tarifas {fare}",
    },
    itineraryNotAvailableModal: {
      container:
        "Mensagem importante. Alguns dos voos que você escolheu não estão mais disponíveis e não podemos prosseguir com a alteração da sua reserva. Selecione um novo itinerário.",
      ctaButton:
        "Botão Alterar voo. Pressione o botão para selecionar outro itinerário.",
      closeButton: "Pressione o botão para fechar o modal.",
    },
    taxesModal: {
      container:
        "Modal de taxas e impostos. Aqui você pode ver os detalhes das taxas e impostos que se aplicam à sua reserva. Esta é a lista de impostos que se aplicam a você.",
      closeButton:
        "Botão Fechar modal de taxas e impostos. Pressione o botão para fechar o modal e continuar com a compra.",
      openPayedTaxes:
        "Detalhe de taxas e impostos já pagos. Pressione para ver os detalhes das taxas e impostos que você pagou originalmente.",
    },
    priceBreakdown: {
      container:
        "O resumo da mudança de sua reserva com um subtotal de {subTotal}, taxa de {taxTotal} dando um total de {total}",
      linkViewDetails:
        "Link de ver detalhes dos impostos, pressione Enter para ver mais detalhes do imposto.",
    },
    presidentialAlertContainer:
      "Mensagem importante. Benefícios para passageiros com status PreferMember Presidential.",
    presidentialAlertDescription:
      "{name},ser um PreferMember Presidential oferece mais benefícios. Para dispensar o custo da multa pela alteração do seu bilhete, entre em contato conosco na Central de Reservas.",
  },
  summarySEO: {
    title: "Resumo da viagem- Copa Airlines",
    description:
      "Resumo da sua mudança de viagem, reveja atentamente as informações dos seus novos voos. Depois de confirmar as informações, as alterações serão feitas para todos os passageiros.",
    keywords:
      "Mudanças, voos, mudança de voos, mudança de data, mudança de horários, alterar reservas, modificar voos, alterar viagem, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fareRulesModal: {
    title: "Regras Tarifárias",
    fare: "Tarifa",
    headingDescription:
      "Aqui você pode ver os detalhes das regras tarifárias aplicáveis ​​a cada um dos voos selecionados.",
    headingCaption: "Saiba mais sobre nossas famílias tarifárias.",
    headingLink:
      "https://www.copaair.com/pt-gs/informacoes-ao-viajar/familias-de-tarifas/",
    lineMessage:
      "As informações sobre as regras da tarifa não estão disponíveis para este voo, pois não são operadas pela Copa Airlines.",
    errorMessage:
      "Desculpe, as informações da regras tarifárias não estão disponíveis no momento. Por favor, tente novamente mais tarde.",
    WCAG: {
      modalDescription:
        "Modal de regras tarifárias: Desde aqui, você poderá ver as regras que se aplicam à tarifa que comprou.",
      fareContent:
        "O voo de {iataOrigin} para {iataDestination}, número do voo {airlineCode} {flightNumber}, tem as seguintes regras: {fareFamilyName}",
      backwardArrow:
        "Botão de voo anterior. Pressione enter para ver as regras tarifárias que aplicam ao voo anterior.",
      forwardArrow:
        "Botão de próximo vôo. Pressione Enter para ver as regras tarifárias que aplicam ao próximo voo.",
      errorLineMsg:
        "O voo de [CÓDIGO IATA DE ORIGEM] para [código IATA de destino], número do voo [código da companhia aérea + número], [todo o texto]",
      closeBotton:
        "Botão de fechamento modal das regras de tarifa. Pressione o botão para fechar o modal.",
    },
  },
  notEligiblePage: {
    heading: {
      flightCaption: "Datas de viagem",
      fareCaption: "Família tarifária",
      reservationCaption: "Código de reserva",
    },
    withCancel: {
      title:
        "Para modificar esta reserva, por favor, entre em contato conosco através do nosso Centro de Atendimento",
      description:
        "Neste momento, não podemos modificar esta reserva. Por favor, entre em contato com o nosso <a>Centro de Atendimento</a> ou visite uma de nossas <b>agências de vendas</b> para gerenciar a alteração.",
      linkReservation:
        "https://www.copaair.com/pt-gs/atendimento-ao-cliente/centro-de-chamadas/",
      linkOffices:
        "https://www.copaair.com/pt-gs/atendimento-ao-cliente/escritorios-de-vendas/",
      items: {
        title: "Algumas razões podem incluir:",
        item1: "Sua reserva foi emitida há menos de 24 horas.",
        item2:
          "Sua reserva não foi adquirida através do copa.com, do centro de atendimento ou de uma de nossas agências de vendas.",
        item3: "Sua reserva tem um desconto especial aplicado.",
        item4: "Seu itinerário inclui voos operados por outra companhia aérea.",
        item5: "Sua reserva foi modificada anteriormente.",
        item6:
          "Seu itinerário possui vários destinos ou inclui uma estadia no Panamá.",
        item7:
          "Você adquiriu serviços adicionais, como equipamento esportivo, transporte de animais, entre outros.",
        item8:
          "Você foi promovido para a Classe Executiva em um ou mais segmentos da sua reserva.",
        item9: "Você já concluiu parte do seu itinerário de viagem.",
      },
    },
    cancelTitle: "Outras opções disponíveis",
    cancelButtonText: "Cancelar minha reserva",
    helpSection: {
      message: "Precisa de ajuda?",
      reservationOption: {
        text: "Centro de Atendimento",
        link: "https://www.copaair.com/pt-gs/atendimento-ao-cliente/centro-de-chamadas/",
      },
      salesOption: {
        text: "Escritórios de Vendas",
        link: "https://www.copaair.com/pt-gs/atendimento-ao-cliente/escritorios-de-vendas/",
      },
      helpOption: {
        text: "Centro de Informações",
        link: "https://help.copaair.com/hc/pt-br",
      },
    },
  },
  notEligiblePageWCAG: {
    title: "Alteração não elegível - Copa Airlines",
    container:
      "Mensagem importante. A reserva não está qualificada para fazer alterações.",
    cancelButton:
      "Botão Cancelar minha reserva. Pressione Enter para ir para a página de opções de cancelamento de reserva.",
    helpSection: {
      reservationOption:
        "Link para o Centro de Atendimento. Precisa de ajuda? Pressione enter para ir para a página do Centro de Atendimento da Copa Airlines.",
      salesOption:
        "Link da Escritórios de Vendas. Precisa de ajuda? Pressione Enter para ir para a página Escritórios de Vendas.",
      helpOption:
        "Link da Central de Ajuda. Precisa de ajuda? Pressione Enter para ir para a página Central de Ajuda.",
    },
  },
  confirmation: {
    heading: {
      title: "Pronto! Você completou sua mudança",
      titleWithPricelock: "Pronto! Sua reserva foi salva com sucesso",
      description:
        "Em breve você receberá uma confirmação com o tíquete eletrônico de sua reserva no e-mail <b></b>.",
      descriptionWithPay:
        "No momento, estamos processando seu pagamento. Em breve você receberá uma confirmação com o tíquete eletrônico de sua reserva no e-mail <b></b>.",
      codeText: "Código de reserva",
      btnReservation: "Voltar para minhas viagens",
      btnPrint: "Imprimir itinerário",
      alert:
        "Para evitar que sua reserva seja cancelada automaticamente, lembre-se de completar sua compra antes do",
      pseAlert:
        "Estamos processando suas mudanças, o que pode levar alguns minutos. Durante esse período, você pode não conseguir acessar as informações da sua reserva. Assim que o processo for concluído, você receberá um e-mail com seu novo bilhete eletrônico atualizado.",
    },
    itineratyTitle: "Seu novo itinerário de viagem",
    passengerInfo: {
      title: "Passageiros nesta reserva",
      subtitle:
        "Estes são os passageiros que viajarão. Por favor, verifique sua informaçõe antes de proceder. Assim que a compra estiver concluída, você poderá selecionar e alterar os assentos em Minhas Viagens",
      adult: "Adulto",
      child: "Criança",
      infant: "Bebê",
      notSeatSelected: "Lembre-se de selecionar os assentos",
    },
    multiCityText: "Vôo para várias cidades",
    errorCreditCards:
      "Desculpe, não foi possível salvar o cartão de crédito. Para sua próxima compra, deverá ingresar a informação do seu cartão de crédito novamente.",
    errorFQTV:
      "Não foi possível salvar o número de passageiro frequente de {passagers} na reserva. Para tentar novamente, vá para Minhas viagens e adicione-o à seção de informações do passageiro.",
    insuranceTitle: "Informações importantes sobre o seu seguro de viagem",
    insurancePaymentAlert:
      "Você comprou um seguro de viagem com o nosso aliado estratégico CHUBB. Para processar a mudança deste serviço, você deve se comunicar diretamente com a CHUBB <a> nestes pontos de contato</a>, usando seu código de reserva como referência.",
    insuranceContactLink:
      "https://copa.chubbtravelinsurance.com/copa/pa-es/home.html#",
    description:
      "Resultado da compra da sua reserva de viagem. Nós detalhamos todo o resumo de sua compra.",
    keywords:
      "Voos, viagens, reservas, passagem, passagem, compras seguras, transações seguras, Copaair, copa.com, copaair.com, Copa Airlines.",
    descriptionPage: "Confirmation",
    titlePage: "Confirmation Page",
    disclaimers: {
      retract: {
        title: "Informações importantes para passageiros que viajam do Chile",
        body: "Esta compra não tem direito de retratação estabelecida na Lei do Consumidor do Chile.",
      },
      bankTransfer: {
        title: "Informações de transferência bancária como forma de pagamento",
        body: "A forma alternativa de pagamento por transferências bancárias estão disponíveis somente se a reserva é realizada com no mínimo 14 dias de antecedência.",
      },
      bankTicket: {
        title: "Informações de boleto bancário como forma de pagamento",
        body: "A forma alternativa de pagamento por boleto bancário estão disponíveis somente se a reserva é realizada com no mínimo 14 dias de antecedência. ",
      },
      OTP: {
        title: "Pontualidade de seus voos",
        body: 'Se você estiver viajando de / para o Brasil, você pode verificar a porcentagem de atrasos e cancelamentos de voos clicando no link "Ver mais detalhes" de cada voo.',
      },
    },
    pse: {
      title: "Informação de pagamento (PSE Colômbia)",
      subtitle:
        "Mensagem importante: Estamos processando seu pagamento. Você receberá um email com a confirmação da sua reserva.",
      info: {
        nit: "NIT",
        bank: "Banco",
        company: "Empresa",
        refNumber: "Número de referência",
        paymentDesc: "Descrição do pagamento",
      },
    },
    aditionalServices: {
      title: "Serviços adicionais",
      aditionalText:
        "Sua compra de seguro de viagem com nosso aliado estratégico no valor de {price} está sendo processada. Em breve enviarão a confirmação do pagamento e a apólice correspondente ao serviço adquirido.",
      aditionalText2:
        "Para qualquer troca, reembolso, consulta ou reclamação relacionada, entre em contato com a CHUBB diretamente nestes <link>pontos de contato</link>, usando seu código de reserva como referência.",
      contactPointsLink:
        "https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=en",
    },
    whereToPaySection: {
      whereToPayTitle: "Como pagar sua reserva:",
      webText: "Entrando a “Minhas Viagens” desde",
      webLinkText: "copa.com",
      webLink: "https://www.copaair.com/",
      callingTo: "Ligando para nossa",
      callingToLink: "https://www.copaair.com/pt/web/gs/central-de-reservas",
      callingToLinkText: "Central de Reservas",
      callingToText2: "(só para pagos com cartão de crédito)*",
      anotherWayOfPayment: "Também pode pagar em nossos",
      anotherWayOfPaymentLink:
        "https://www.copaair.com/pt/web/gs/escritorios-de-vendas",
      anotherWayOfPaymentLinkText: "escritórios de vendas*",
      anotherWayOfPayment2: "ou no balcão no aeroporto*",
      disclaimer1:
        "*Se você faz uma reserva através do copa.com e depois deseja pagar em nossa Central de Reservas, escritórios de vendas ou no aeroporto, se aplicará uma taxa administrativa não-reembolsável e/ou outras taxas adicionais não-reembolsáveis ao custo total do bilhete. Para mais informaçãos, leia nossos",
      disclaimerLink:
        "https://www.copaair.com/pt/web/gs/termos-condicoes-book-and-hold-fee",
      disclaimerLinkText: "termos e condições de PriceLock",
      disclaimer2:
        "Estas taxas não são válidas para emissão de bilhetes ou pagamento em nossos escritórios na Argentina.",
    },
    priceBreakownPriceLock: "Você pagou hoje",
    priceBreakdownDisclaimerText:
      "Você tem até o {date} para completar sua compra.",
  },
  confirmationWCAG: {
    descriptionPage:
      "Sua mudança de itinerário foi feita e seu pagamento está sendo processado. Seu código de reserva é {codeReservation}. Assim que seu pagamento for processado, você receberá um e-mail de confirmação para {email}",
    heading: {
      btnReservation:
        "Vá para o botão Minhas viagens. Pressione Enter para ir para Minhas Viagens e ver os detalhes do seu voo.",
      btnPrint:
        "Botão para imprimir a página. Pressione Enter para imprimir esta página como prova de que sua reserva está sendo processada.",
    },
  },
  passengersWCAG: {
    linkSeat:
      "Link de seleção de assento. Pressione enter para ir para Minhas viagens e selecionar seus assentos.",
    withSeat:
      "Estes são os passageiros que viajarão nesta reserva: Passageiro {TypePax} {namePax} {surnamePax}, com data de nascimento {birthdate}, com assento {seat}.",
    withOutSeat:
      "Estes são os passageiros que viajarão nesta reserva: Passageiro {TypePax} {namePax} {surnamePax}, com data de nascimento {birthdate}, não tem assento selecionado. Você pode selecionar o assento após concluir a compra no fluxo Minhas viagens.",
  },
  viewMoreDetailsModal: {
    title: "Detalhes da viagem",
    subtitlesCards: "Segmento de voo {currentflight} de {total}",
    date: "Dia",
    Flight: "Voar",
    subtitleTo: "para",
    aircraftType: "Tipo de avião",
    operatedBy: "Operado por",
    button: "Ver assentos disponíveis",
    layover: "ESCALA",
    fareFamilyText: "Você está viajando em {fare}:",
    viewMoreText: "Veja mais detalhes as regras de tarifa",
    viewMoreLink:
      "https://www.copaair.com/pt-gs/informacoes-ao-viajar/familias-de-tarifas/",
    OTPTitle: "Porcentagem de cancelamentos e atrasos",
    OTPCancellations: "Cancelamentos",
    OTPDelays: "Atrasos maiores que 30 min",
    OTPDelays2: "Atrasos maiores que 60 min",
    mixedFareFamilies:
      "Você selecionou voos com famílias de tarifas diferentes, alguns desses benefícios podem ser restritos.",
    navigationText: "Voo {flightNumber} de {numberOfFlights}",
    restrictiveFare:
      "Esse resultado é a classe Básica, que é nossa tarifa mais restritiva e possui as seguintes restrições: Bagagem no porão com custo adicional e pré-seleção de assentos com custo (ou atribuição gratuita no aeroporto).",
    WCAG: {
      modalDescription: "Seção de itinerário de voo",
      itinerarySummary:
        "Informações sobre o resultado do voo. Voo de {origin} para {destination} com hora de partida {departureTime} e chegada em {arrivalTime}; do {dateFormat}, com {numberOfLayovers} escala de {timeLayover} operado por {airlineName}. A duração total do voo é de {journeyTime}",
      segmentSummary:
        "Informações de voo {currentflight} de {total} de {origin} às {departureTime} chegando em {destination} às {arrivalTime} em {date}, com o número do voo {airlineCode}, com modelo de avião {aircraftName} e, por último, operado por {airlineName}",
      segmentSummaryWithOTP:
        "Informações de voo {currentflight} de {total} de {origin} às {departureTime} chegando em {destination} às {arrivalTime} em {date}, com o número do voo {airlineCode}, com modelo de avião {aircraftName} e, por último, operado por {airlineName}. Informações sobre a porcentagem de cancelamentos e atrasos deste voo. Informações sobre cancelamentos. Este vôo tem uma porcentagem de {percent1} cancelamentos. Informações sobre atrasos superiores a 30 min. Este vôo tem uma porcentagem de {percent2} atrasos superiores a 30 min. Informações sobre atrasos superiores a 60 min. Este vôo tem uma porcentagem de {percent3} atrasos superiores a 60 min.",
      availaibleSeats:
        "Botão ver assentos disponíveis. Pressione o botão para ver a disponibilidade de assentos neste voo.",
      // From the following line to OTPText3 they are read in the segment id SummaryWithOTP
      OTPTitle:
        "Informações sobre a porcentagem de cancelamentos e atrasos deste voo.",
      OTPText1:
        "Informações sobre cancelamentos. Este vôo tem uma porcentagem de {percentage} cancelamentos.",
      OTPText2:
        "Informações sobre atrasos superiores a 30 min. Este vôo tem uma porcentagem de {percentage} atrasos superiores a 30 min.",
      OTPText3:
        "Informações sobre atrasos superiores a 60 min. Este vôo tem uma porcentagem de {percentage} atrasos superiores a 60 min.",
      fareFamilyText:
        "Você está viajando em {fare}: Esses são os benefícios que correspondem a esta família de tarifas {list}.",
      viewMoreLink:
        "Link para ver mais detalhes das Familia tarifaria. Pressione Enter para ver os detalhes das Familia tarifaria.",
      mixedFareFamilies:
        "Você selecionou voos com famílias de tarifas diferentes, alguns desses benefícios podem ser restritos.",
      closeBotton:
        "Fechar botão modal para ver os detalhes do voo. Pressione o botão para fechar o modal.",
      backwardArrow:
        "Vá para o botão de voo anterior. Pressione este botão para ver os detalhes do vôo anterior.",
      forwardArrow:
        "Vá para o próximo botão de voo. Pressione este botão para ver os detalhes do próximo vôo.",
    },
  },
  interstitial: {
    loading: "Carregando",
    title: "Sua segurança é nossa prioridade",
    description:
      "Fortalecimos nossos protocolos de biosegurança para que você possa viajar sem preocupações.",
    wcagDescription: "Sua página está carregando",
  },
  endSession: {
    endSessionTitle: "Sua pesquisa expirou",
    endSessionBoddy:
      "O tempo para completar esta reserva se esgotou. Você pode começar uma nova pesquisa.",
    endSessionBtn: "Voltar ao início",
    endSessionWCAG:
      "Mensagem de sessão expirada. O tempo para concluir a alteração desta reserva expirou, portanto, sua alteração expirou, reinicie.",
    endSessionBtnWCAG: "Voltar ao botão iniciar",
  },
  paymentErrorModal: {
    title: "Não foi possível processar seu pagamento.",
    errorMessage: "Você esgotou todas as tentativas de processar o pagamento.",
    body: "Para completar esta alteração, entre em contato conosco através da <a> Central de Reservas. </a>",
    bodyLink:
      "Para completar esta alteração, entre em contato conosco através da Central de Reservas.",
    link: "https://www.copaair.com/pt/web/us/central-de-reservas",
    btnText: "Voltar para Minhas Viagens",
    btnDecriptionWCAG:
      "Vá para o botão Minhas viagens. Pressione Enter para ir para Minhas Viagens e ver os detalhes do seu voo.",
    descriptionWCAG:
      "Mensagem de erro. Você esgotou todas as tentativas de processar o pagamento. Para completar esta alteração, entre em contato conosco através da Central de Reservas.",
  },
  criticalErrorPage: {
    titlePage: "Error",
    title: "Desculpe",
    description:
      "Não conseguimos processar a sua mudança, por favor, ligue para a nossa Central de Reservas para fazer este processo.",
    retryButton: "Contactar Central de Reservas",
    helpText: "Precisa de ajuda?",
    reservationCenter: "Central de Reservas",
    salesOffice: "Escritórios de Vendas",
    helpDesk: "Centro de Informações",
    reservationCenterLink:
      "https://www.copaair.com/pt/web/gs/centro-de-reservaciones",
    salesOfficeLink: "https://www.copaair.com/pt/web/gs/oficinas-de-ventas",
    helpDeskLink: "https://help.copaair.com/hc/pt-br",
  },
  criticalErrorPageWCAG: {
    cta: "Erro. Não foi possível processar seu mudança, por favor, ligue para a nossa Central de Reservas para fazer o processo.",
    reservationCenter:
      "Link da Central de reservas. Pressione Enter para ir para a página Central de Reservas.",
    salesOffice:
      "Link da Escritórios de Vendas. Pressione Enter para ir para a página Escritórios de Vendas.",
    helpDesk:
      "Link da Central de Ajuda. Pressione Enter para ir para a página Central de Ajuda.",
  },
  modifyLanguageModal: {
    title: "Esta ação irá reiniciar sua pesquisa",
    subText:
      "Esta ação irá reiniciar sua pesquisa. Todas as seleções de voo feitas serão perdidas. Você tem certeza que quer continuar?",
    continueCTA: "Continuar con cambio",
    stayCTA: "Permanecer nesta pesquisa",
  },
  modifyLanguageModalWCAG: {
    title: "Esta ação irá reiniciar sua pesquisa",
    container:
      "Esta ação irá reiniciar sua pesquisa. Todas as seleções de voo feitas serão perdidas. Você tem certeza que quer continuar?",
    continueCTA:
      "Botão Continuar. Pressione Enter para prosseguir com a alteração",
    stayCTA:
      "botão Permaneça nesta pesquisa. Pressione Enter para manter o país e o idioma, retornar à navegação principal",
    closeButton:
      "Botão fechar. Pressione Enter para manter o país e o idioma e retornar à navegação principal",
  },
}
