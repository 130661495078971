/* eslint-disable import/prefer-default-export */
const filterSelectedFareFamily = (payload) => {
  const selectedFareFamily = payload?.selectedFareFamily.filter(
    (fareFamily) => fareFamily && fareFamily.length <= 3
  )

  return { ...payload, selectedFareFamily }
}

export { filterSelectedFareFamily }
