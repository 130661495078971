import "react-app-polyfill/stable"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import LogRocket from "logrocket"
// import uuid from "uuid-random"
// import { nanoid } from "nanoid"
// import TagManager from "react-gtm-module"
import { ThemeProvider } from "@material-ui/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Router, Route } from "react-router-dom"

import { Provider } from "react-redux"

import theme from "@bit/vibenitez.copa-components-library.theme"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

// TODO: Import from library

import store from "./store"
import history from "./utils/history"
import { configLanguage, configOrigin } from "./translations/config"
// import { setCookieUUID } from "./utils/generalUtils"
import setBaseUrl from "./utils/setBaseUrl"

// TODO: Import from library ?
import InterstitialLoading from "./ToLibrary/interstitial/interstitial"

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
// }`

// TagManager.initialize(tagManagerArgs)

if (process.env.REACT_APP_API_URL) {
  setBaseUrl(process.env.REACT_APP_API_URL)
}

LogRocket.init(process.env.REACT_APP_LOG_ROCKET_INIT, {
  rootHostname: process.env.REACT_APP_LOG_ROCKET_DOM,
})
// create uuid, send to LogRocket and save in cookie
// const uuidValue = uuid()
// LogRocket.identify(uuidValue)
// setCookieUUID(uuidValue)

configLanguage(store)
configOrigin()

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<InterstitialLoading />}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Route component={App} />
        </Provider>
      </ThemeProvider>
    </Router>
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
