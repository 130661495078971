import { createSlice } from "@reduxjs/toolkit"

import initialState from "./airportsInitialState"
import { setOriginsFromSessionUtility } from "./airportsUtils"

const airportsSlice = createSlice({
  name: "airports",
  initialState,
  reducers: {
    fetchOrigins: (state) => {
      state.errorOrigin = false
      state.fetching = true
    },
    setOrigins: (state, action) => {
      state.origins = action.payload
      state.fetching = false
    },
    fetchDestinations: (state) => {
      state.errorDestination = false
      state.fetching = true
    },
    setDestinations: (state, action) => {
      const { payload } = action
      state.destinations = payload.data
      state.originKeyToDestinationSearch = payload.originKey
      state.fetching = false
    },
    fetchMulticityDestinations: (state) => {
      state.errorDestination = false
      state.fetching = true
    },
    setMulticityAirportsDestinations: (state, action) => {
      const { payload } = action
      const newAirports = state.airportsMulticity.map((list) => {
        if (list.id === payload.index) {
          return { ...list, destinations: payload.data }
        }
        return list
      })
      state.airportsMulticity = newAirports
      state.fetching = false
    },
    setErrorOrigin: (state) => {
      state.fetching = false
      state.errorOrigin = true
    },
    setErrorDestination: (state) => {
      state.fetching = false
      state.errorDestination = true
    },
    setOriginsFromSession: (state, { payload }) => {
      state.origins = setOriginsFromSessionUtility(payload)
      state.destinations = setOriginsFromSessionUtility(payload)
    },
    setDestinationSelected: (state, { payload }) => {
      state.destinationSelected = payload
    },
    setOriginSelected: (state, { payload }) => {
      state.originSelected = payload
    },
    catchOriginDestinationSelected: (state) => state,
  },
})

export default airportsSlice.reducer

export const { actions: airportsActions } = airportsSlice
