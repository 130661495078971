import { takeEvery, call, put } from "redux-saga/effects"

import TYPES from "./sessionTypes"
import api from "../../api"
import { sessionActions } from "../../store/session/sessionSlice"
import { generalActions } from "../../store/general/generalSlice"

let timer = 0
let seconds = 2
function* fetchSession({ payload }) {
  try {
    const firstTime = new Date()
    if (timer === 0) {
      timer = firstTime
    } else {
      seconds = (firstTime.getTime() - timer.getTime()) / 1000
      timer = new Date()
    }
    if (seconds > 1) {
      yield put(generalActions.startLoading(true))
      const { data } = yield call(api.session.sendSelection, payload)
      yield put(sessionActions.setSession(data))
      yield put(generalActions.stopLoading())
    }
  } catch (e) {
    yield put(generalActions.logError(e))
    yield put(sessionActions.errorSession(e))
    yield put(generalActions.stopLoading())
  }
}

function* fetchSessionInformation({ payload }) {
  try {
    yield put(generalActions.startLoading(true))
    const { data } = yield call(api.session.getSession, payload)
    yield put(sessionActions.setConfirmationSession(data))
    yield put(generalActions.setCurrency(data?.selectedOffer?.currency))
    yield put(generalActions.stopLoading())
  } catch (e) {
    yield put(generalActions.setFatalErrorRedirect(true))
    yield put(generalActions.logError(e))
    yield put(sessionActions.errorSession())
    yield put(generalActions.stopLoading())
  }
}

function* sessionSaga() {
  yield takeEvery(TYPES.FETCH_SESSION, fetchSession)
  yield takeEvery(TYPES.FETCH_CONFIRMATION_SESSION, fetchSessionInformation)
}

export default sessionSaga
