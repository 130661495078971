import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles(() => ({
  modal: {
    margin: -16,
    marginTop: -4,
  },
  modalTitle: {
    color: theme.palette.grey["600"],
    margin: "0 12px",
    marginBottom: 16,
    minWidth: "max-content",
  },
  backDrop: {
    zIndex: 1,
    height: "100vh",
  },
  modalContainer: {
    position: "relative",
    width: "max-content",
  },
  containerClose: {
    padding: "0 8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  popper: {
    width: "max-content",
    transform: "translate(-30%, 10px) !important",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-50%, 15px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-65%, 10px) !important",
    },
  },
  popperArrow: {
    right: "50% !important",
    transform: "translateX(50%) !important",
    [theme.breakpoints.down("sm")]: {
      right: "40% !important",
      left: "unset !important",
    },
    [theme.breakpoints.down("xs")]: {
      right: "90px !important",
    },
  },
  icon: {
    color: theme.palette.grey[400],
    pointerEvents: "none",
    marginRight: theme.spacing(2),
    background: theme.palette.transparent.main,
    zIndex: 100,
  },
  iconClose: {
    width: 24,
    height: 24,
    fill: theme.palette.grey[400],
  },
  closeButton: {
    backgroundColor: "inherit",
    border: "none",
    cursor: "pointer",
    padding: 0,
  },
  option: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "& .MuiTypography-root": {
      display: "block",
      fontSize: 16,
      margin: "0 12px",
      padding: "12px 0",
      borderBottom: `1px solid ${theme.palette.grey[300]}80`,
    },
    "&:last-child": {
      "& .MuiTypography-root": {
        border: 0,
      },
    },
    "&.active": {
      "& .MuiTypography-root": {
        color: theme.palette.alternative.lightBlue,
      },
    },
  },
}))

export default useStyles
