import some from "lodash/some"
import "moment/locale/es"
import "moment/locale/pt"
import "moment/locale/fr"
import axios from "axios"
import isEmpty from "lodash/isEmpty"
import {
  getCookie,
  setCookiesMatchingArrays,
  deleteCookiesMatchingArrays,
} from "../utils/auth"
import { generalActions } from "../store/general/generalSlice"
import {
  getParameterFromCurrentURLByName,
  setMomentLocal,
  getLanguageCountry,
  getSupportedLanguages,
} from "../utils/generalUtils"
import { formatMoney, getCurrencyOption } from "../utils/numbers"

const configLanguage = (store) => {
  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    "en"

  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

  const exitLanguage = some(
    getSupportedLanguages(),
    (item) => item === languageWithoutRegionCode
  )
  const resultLanguage = exitLanguage ? languageWithoutRegionCode : "en"
  const langRawFromCookies =
    getParameterFromCurrentURLByName("langid") || getCookie("lang")
  const langFromCookies = getSupportedLanguages().includes(langRawFromCookies)
    ? langRawFromCookies
    : undefined
  const currentLang = langFromCookies || resultLanguage
  setCookiesMatchingArrays(["currentLang", "lang"], [currentLang, currentLang])
  setMomentLocal(currentLang)
  axios.defaults.headers = {
    "Accept-Language": getLanguageCountry(currentLang),
    "Content-Type": "application/json",
  }

  store.dispatch(generalActions.setLang(currentLang))
}

const configOrigin = () => {
  const originFromQueryParams = getParameterFromCurrentURLByName("origin")
  const getToOriginFromCookies = getCookie("origin")
  if (
    isEmpty(getToOriginFromCookies) ||
    (getToOriginFromCookies !== originFromQueryParams &&
      getToOriginFromCookies !== "app")
  ) {
    const validOriginValues = ["SC", "KY", "EM", "GF", "APP", "Mtrips"]
    const validOrigin =
      validOriginValues.indexOf(originFromQueryParams) >= 0
        ? originFromQueryParams
        : "Mtrips"
    setCookiesMatchingArrays(["origin"], [validOrigin])
  }
}

const configCookies = (pnr, lastName) => {
  if (pnr && lastName) {
    setCookiesMatchingArrays(["pnr", "lastName"], [pnr, lastName])
  }
}

const deleteInfoCookies = () => {
  deleteCookiesMatchingArrays(["pnr", "lastName"])
}

const configCurrency = (currency) => {
  const currencyOptions = getCurrencyOption(currency)
  if (!Number.prototype.formatCurrency) {
    // eslint-disable-next-line no-extend-native
    Number.prototype.formatCurrency = function () {
      return `${formatMoney(
        this,
        currencyOptions.centsUnit,
        currencyOptions.decimal,
        currencyOptions.thousand
      )} ${currencyOptions.accountSymbol}`
    }
    // eslint-disable-next-line no-extend-native
    String.prototype.formatCurrency = function () {
      return `${formatMoney(
        this,
        currencyOptions.centsUnit,
        currencyOptions.decimal,
        currencyOptions.thousand
      )} ${currencyOptions.accountSymbol}`
    }
  }
}

export {
  configLanguage,
  configOrigin,
  configCurrency,
  configCookies,
  deleteInfoCookies,
}
