const initialState = {
  queryURL: "",
  queryParams: {
    pnr: "",
    lastName: "",
  },
  ticket: {
    pnr: "",
    totalPassengers: 0,
    finalDestination: "",
    cityDestination: "",
    flights: [],
    passengers: [],
  },
  flightOrigin: {},
  flightDestination: {},
  fetching: false,
  error: "",
  isEligible: {},
  isLoaded: false,
  changeSuccessful: false,
  notAvailablePageIsShowed: false,
  shouldDisplayNoItineraryModal: null,
}

export default initialState
