import * as Yup from "yup"
import definedMessages from "./message"

const logInValidationSchema = (formatMessage) => {
  return Yup.object().shape({
    userName: Yup.string()
      .matches(/^[^$/\-!=!&]+$/, formatMessage(definedMessages.emailMatch))
      .required(formatMessage(definedMessages.inlineErrorRequiredField)),
    password: Yup.string().required(
      formatMessage(definedMessages.inlineErrorRequiredField)
    ),
  })
}
const initValues = {
  userName: "",
  password: "",
}
export { logInValidationSchema, initValues }
