import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import RadioGroup from "@material-ui/core/RadioGroup"
import Box from "@material-ui/core/Box"
import Radio from "../RadioButton"
import useStyles from "./styles"

const RadioBox = ({ children, handleChange, initialValue }) => {
  const [val, setVal] = useState(initialValue)
  const classes = useStyles()

  const setValue = (value) => {
    setVal(value)
    handleChange(value)
  }

  const onKeyPress = (e, value) => {
    if (e.keyCode === 32 || e.keyCode === 13) setValue(value)
  }

  return (
    <RadioGroup
      name="optionModes"
      value={val}
      className={classes.groupContainer}
    >
      {children.map(({ id, value, component, wcag, dataCy }) => (
        <Box
          key={id}
          role="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.boxContainer, val === value ? "active" : "")}
          onClick={() => setValue(value)}
          onKeyDown={(e) => onKeyPress(e, value)}
          aria-label={wcag}
          tabIndex={0}
          data-cy={dataCy}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            aria-hidden
            className={classes.box}
          >
            <div className={classes.control}>
              <Radio
                id={id}
                value={value}
                checked={val === value}
                aria-hidden
                tabIndex="-1"
              />
            </div>
            {component}
          </Box>
        </Box>
      ))}
    </RadioGroup>
  )
}

RadioBox.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string,
      component: PropTypes.node,
      wcag: PropTypes.string,
      dataCy: PropTypes.string,
    })
  ),
  handleChange: PropTypes.func,
  initialValue: PropTypes.string,
}

RadioBox.defaultProps = {
  children: [],
  handleChange: () => {},
  initialValue: "",
}

export default RadioBox
