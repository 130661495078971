export default {
  topBar: {
    modifySearch: "Nueva búsqueda",
    description: "Cambiar viaje",
    reservation: "Código de Reserva: {reservationCode}",
    reservationXS: "Reserva: {reservationCode}",
    passengers:
      "{numberOfPassengers, plural, one {# Pasajero} other {# Pasajeros} }",
    loginCTA: "Iniciar Sesión",
    miles: "millas",
    close: "Cerrar",
    backToMytrips: "Volver a Mis Viajes",
    membersMenu: {
      personalizedGreeting: "Hola,",
      LogoutCTA: "Cerrar sesión",
      accountCTA: "Mi cuenta",
      connectmilesLabel: "# CONNECTMILES",
      statusLabel: "ESTATUS",
      milesLabel: "BALANCE DE MILLAS",
      myAccountExternalLink: "/es/web/profile",
    },
    loginBox: {
      credentialsLabel: "Correo electrónico, número de ConnectMiles o usuario",
      required: "Este campo es requerido",
      credentialsPlaceholder: "Ingresa tu usuario",
      inlineErrorEmptyField: "Este campo es requerido",
      passwordLabel: "Contraseña",
      passwordfieldPlaceholder: "Ingresa tu contraseña",
      forgotLink: "¿Olvidaste tu contraseña?",
      forgotExternalLink: "https://www.copaair.com/es/web/gs/forgot",
      createaccountlink: `¿No tienes una cuenta? <a> Crear una ahora </a>`,
      createaccountlinkExternal: "https://www.copaair.com/es/web/gs/enrollment",
      emailMatch: "Los correos electrónicos no coinciden",
      errorbannerWrongData:
        " La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.",
      errorbannerBlockedAccount:
        "Tu cuenta ha sido bloqueada temporalmente por razones de seguridad. Comunícate con nuestro Centro de Servicio de ConnectMiles para obtener ayuda. <a> Saber más </a>",
    },
  },
  topBarWCAG: {
    logo: "Logo de Copa Airlines. Presiona enter para ir a la página principal de Copa Airlines",
    description:
      "Sección de cambios. Vas a realizar un cambio en tu reserva {reservationCode}, este aplicará a los {numberOfPassengers} pasajeros.",
    languageSelector:
      "Botón de selector idiomas. El idioma activo es {language}. Presiona Enter para cambiar de idioma.",
    loggedIn:
      "Has iniciado sesión con el usuario {userName}. Presiona Enter para ir al menú de Miembros.",
    login:
      "Botón de inicio de sesión de ConnectMiles. Permite acceder a perfil de miembro. Presiona Enter para ir a formulario de inicio de sesión.",
    editButton:
      "Botón de editar Parámetros del cambio de vuelo. Presiona Enter para modificar tus parámetros de cambio.",
    backToMytrips:
      "Botón de regreso. Presiona Enter para volver a la sección de detalles de Mis Viajes.",
    membersMenu: {
      connectMilesLogoAlt: "ConnectMiles Logo",
      logoutButton:
        "Boton de cerrar la sesión de tu cuenta de ConnectMiles, presiona enter para cerrar la sesión de tu cuenta de ConnectMiles",
      myAccountButton:
        "Mi cuenta, botón.  Presiona enter para abrir la página de tu perfil en ConnectMiles, está página se abre en una nueva pestaña de su navegador",
      container:
        "Menu de ConnectMiles. Hola {name} tu númeró de connectMiles es {number} y tu estatus actual es {status}. Para salir, presione la tecla (ESC)",
    },
    loginBox: {
      CredentialsLabel:
        "Ingrese su email, número o nombre de usuario de Connectmiles",
      PasswordLabel: "Ingrese su contraseña de ConnectMiles",
      loginButton:
        "Boton de Iniciar sesión. Presione enter para iniciar sesión con sus credenciales",
      fortgotPassword:
        "Enlace de olvide contraseña. Presiona enter para ir a ConnectMiles y recuperar tu contraseña",
      Close: "Botón de cierre, pulse enter para cerrar la ventana emergente",
      InlineErrorMandatoryFiled: "Error. Este campo es mandatorio",
      CreateAccountlink:
        "Enlace para crear cuenta. Presiona enter si no tienes una cuenta y quieres crear una cuenta ahora. Se abrirá la página de connectmiles en una nueva pestaña de su navegador.",
      ErrorBannerAlertWrongInformation:
        "Error. La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.",
      ErrorBannerAlertBlockedAccount:
        "Error. Tu cuenta ha sido bloqueada temporalmente por razones de seguridad. Comunícate con nuestro Centro de Servicio de ConnectMiles para obtener ayuda. <enlace al número de centro de llamadas de ConnectMiles>",
    },
  },
  searchPage: {
    heading: {
      subtitle: "Tu viaje a {city}",
      title: "¿Cómo deseas cambiar tu viaje?",
      description: "Puedes modificar tu fecha u hora de vuelo",
      containerWCAG:
        "Sección de cambio de vuelos, aquí podra seleccionar que tipo de cambio desea realizar.",
    },
    editHeading: {
      title: "Nueva búsqueda",
      ctaEdit: "Regresar",
      wcagContent:
        "Sección de modificar cambio de vuelos, aquí podra cambiar el tipo de cambio desea realizar.",
      wcagBton:
        "Botón de regresar. Presiona enter para regresar a la página en la que estaba.",
    },
    notResultsDisclaimer:
      "No hay disponibilidad en las fechas y/o ciudades seleccionadas. Por favor, modifica tu búsqueda y vuelve a intentarlo.",
    fatalErrorDisclaimer:
      "Lo sentimos, debido a dificultades técnicas no hemos podido continuar con tu cambio de viaje. Por favor, intenta nuevamente más tarde.",
    ctaSearchFlight: "Buscar opciones de vuelo",
    ctaSearchFlightWCAG:
      "Botón Buscar. Presione Enter para proceder con la búsqueda de opciones de vuelo.",
    modeSelector: {
      title: "¿Qué deseas cambiar?",
      option1Text: "Cambiar fechas y/u horas",
      option3Info: "Fecha actual: {departureDate}",
      option1Info: "Fechas actuales: {departureDate} - {arrivalDate}",
      option2Text: "Cambiar ciudades y fecha",
      option4Info: "{departureCode} · {arrivalCode}, {departureDate}",
      option2Info:
        "{departureCode} · {arrivalCode}, {departureDate} - {arrivalDate}",
    },
    modeSelectorWCAG: {
      changeDates:
        "Opción para cambiar la fecha y/u horas de tu viaje. Presiona enter para seleccionar esta opción de cambio",
      changeRoutes:
        "Opción para cambiar la ciudad y fecha de tu viaje. Presiona enter para seleccionar esta opción de cambio",
    },
    datesSelector: {
      title: "Selecciona los vuelos que deseas cambiar",
      infoSegment:
        "Los cambios se aplicarán a todos los pasajeros en esta reserva. Si deseas adelantar tu viaje, es posible que algunas fechas no estén disponibles debido a restricciones. Tus vuelos actuales no cambiarán hasta que confirmes tu nuevo itinerario.",
      flightNumber: "Vuelo {index}",
      originTitle: "Desde",
      originPlaceholder: "Ingresa el origen",
      originFocusPlaceholder: "Ciudad, país o aeropuerto",
      destinationTitle: "Hacia",
      destinationPlaceholder: "Ingresa el destino",
      destinationFocusPlaceholder: "Ciudad, país o aeropuerto",
      noResultsAutocomplete: "Ups, no se encontraron resultados",
      noResultsAutocompleteHelperText: "Por favor modifica tu busqueda",
      noResultsHelperTextOrigin: "Selecciona un origen válido",
      noResultsHelperTextDestination: "Selecciona un destino válido",
      emptyOrigin: "Selecciona un origen",
      emptyDestination: "Selecciona un destino",
      datesTitle: "Fechas de viaje",
      emptyDate: "Selecciona fecha de viaje",
      emptyOriginWhenDestination: "Debes seleccionar un origen primero",
      airportListFail: "Ups, no pudimos cargar los aeropuertos.",
      retryButton: "Intenta nuevamente.",
      datePicker: {
        dateRoundTrip: "Fechas de viaje",
        dateRoundTripPlaceHolder: "Ingresa fechas de vuelo",
        dateOneWay: "Fecha de Viaje",
        dateOneWayPlaceholder: "Ingresa fecha de vuelo",
        flexibleDates: "Fechas flexibles",
        confirmCTA: "Confirmar",
        departure: "Salida",
        return: "Regreso",
      },
      keyboardShortcuts: {
        title:
          "Panel informativo donde podrás conocer más sobre la navegación mediante teclado para la selección de fechas. Continua para conocer los atajos de teclado.",
        enter: "Confirma la fecha seleccionada (Enter)",
        leftAndRight:
          "Cambiar día hacia atrás (flecha izquierda) o hacia adelante (flecha derecha)",
        upAndDown:
          "Semana anterior (flecha arriba) o siguiente semana (flecha abajo)",
        pageUpAndPageDn:
          "Mes anterior (tecla retroceder página) o al siguiente mes (tecla avanzar página)",
        homeAndEnd:
          "Primer día (tecla Inicio) o último día (tecla Fin) de la semana",
        escape: "Regresar campo de entrada de fecha (tecla Escape)",
        info: "Abrir panel de información de Navegación de teclado",
        botoni: "Abrir panel de información de Navegación de teclado (tecla i)",
        closeBtn:
          "Botón de Cierre. Presiona Enter para cerrar este modal y regresar a selección de fecha",
      },
      sameDay:
        "Cambios dentro del mismo día de vuelo solo pueden ser solicitados en el aeropuerto. Selecciona otra fecha para continuar.",
    },
    changeRouteSelector: {
      changeRoute:
        "Cambios disponibles dentro del país de tu reserva original.",
      notAvailableTitle: "Ciudad no disponible por este medio.",
      errorMesaje:
        "Para cambiar tu país de origen, por favor contacta nuestro <a>Centro de Reservaciones</a>.",
      link: "https://www.copaair.com/es/web/gs/reservation-center",
    },
    datesSelectorWCAG: {
      originField:
        "Origen. Introduce Ciudad, país o aeropuerto de origen de tu viaje.",
      airportLoadingFail:
        "Error de carga. No pudimos cargar los aeropuertos. Presiona Tab para intentar nuevamente la carga y continuar con tu búsqueda.",
      swapButton:
        "Origen {origin}, Destino {destination} Presiona Enter para intercambiar, o continúa para seleccionar fechas de viaje.",
      destinationField:
        "Destino. Introduce Ciudad, país o aeropuerto de origen de tu viaje.",
      checkBoxWCAG:
        "Vuelo {numFlight} de su viaje. Presione enter para confirmar que quieres cambiar este vuelo, que originalmente es de {origin}  a {destination} el {date}.",
      checkBoxWCAGNextFlight:
        "De lo contrario presiona tab para ir al siguiente vuelo.",
      datePicker: {
        dateRoundTrip:
          "Selección de Fechas. A continuación podrás seleccionar la fecha de tu vuelo. Podrás navegar por mes y días para realizar tu selección.",
        dateOneWayTrip:
          "Búsqueda de vuelos de solo ida, presiona enter para avanzar a selección de Origen.",
        travelDateField:
          "Selección de Fechas. A continuación podrás seleccionar la fecha de tu vuelo. Podrás navegar por mes y días para realizar tu selección.",
        monthSelection:
          "{month} {year}. Presiona enter para ir a días de {month}",
        daySelection:
          "{weekday} {day}. Toca dos veces para seleccionar este día.",
        roundTripSelection:
          "Has seleccionado {month}, {weekday} {day} para vuelo de ida, seleccione la fecha de regreso",
        oneWaySelection: "Has seleccionado {month}, {weekday} {day}",
        flexibleDatesSelection:
          "Fechas flexibles. Presiona enter para activar búsqueda con resultado de fechas flexibles. {active}",
        roundTripConfirmationCTA:
          "Confirmar fechas de búsqueda. Presiona enter para confirmar que tus fechas de búsqueda de viaje son: salida {departureMonth} {departureDay}; Regreso {returnMonth} {returnDay}",
        oneWayConfirmationCTA:
          "Confirmar fecha de búsqueda. Presiona enter para confirmar que tu fecha de búsqueda de viaje es {departureMonth} {departureDay}",
      },
    },
    disclaimer: {
      title: "Al momento de realizar un cambio en tu viaje debes saber esto:",
      disc1:
        "El costo del cambio puede variar dependiendo de la familia tarifaria originalmente comprada. Si el costo de tu nuevo itinerario es menor, recibirás un crédito para un vuelo futuro. Algunos impuestos pagados previamente pueden no ser reembolsables. Esta plataforma te permitirá hacer un solo cambio en la reserva. De requerir un segundo cambio, deberá hacerlo a través de nuestro Centro de Reservaciones.",
      disc2:
        "Al realizar un cambio, perderás tu selección de asiento original y deberás volver a seleccionarlos en nuestra sección de “Mis Viajes” una vez hayas finalizado el cambio. Es posible que los asientos previamente seleccionados o comprados ya no estén disponibles en el nuevo vuelo. Si el asiento fue comprado por otro pasajero, tendrá un crédito asociado a la reserva para seleccionar tu nuevo asiento. De no utilizar el crédito, el mismo no será reembolsable.",
      disc3:
        "La familia tarifaria que selecciones para tus nuevos vuelos (ida o regreso), será aplicada para todo el itinerario, incluyendo los vuelos que no estés modificando. Asegúrate de revisar los atributos de la tarifa antes de realizar el cambio.",
      link: "Consulta nuestra política de cambios",
      webLink:
        "https://copaair.com/es-gs/legal/terminos-y-condiciones/cambios-voluntarios",
    },
    disclaimerWCAG: {
      contentDisclaimer:
        "Sección de mensajes importantes. Recuerde que {disc1}, {disc2}, {disc3}",
      contentDisclaimerMobil:
        "Sección de mensajes importantes. Presione enter para conocer los mensajes",
      link: "Enlace política de cambios, presiona enter para ver el detalles de la política de cambios.",
    },
  },
  searchPageSEO: {
    description:
      "Escoge el tipo de cambio de viaje que deseas realizar. Puedes realizar cambios de horas, de fechas o de ciudades.",
    titlePage: "Cambiar viaje - Copa Airlines",
    keywords:
      "Cambios, Vuelos, Cambio de vuelos, cambio de fecha, cambio de horas, cambiar reservaciones, modificar vuelos, cambiar viaje,  Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  resultPageSEO: {
    description:
      "Listado de todos los vuelos disponibles para realizar tu cambio, selecciona el vuelo que más te convenga.",
    titlePage: "Resultados de vuelo - Copa Airlines",
    keywords:
      "Cambios, Vuelos, Cambio de vuelos, cambio de fecha, cambio de horas, cambiar reservaciones, modificar vuelos, cambiar viaje,  Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  confirmationSEO: {
    description:
      "Confirmación del cambio de tu viaje. Te detallamos todo el resumen de tu cambio",
    titlePage: "Confirmación de Cambio | Copa Airlines",
    keywords:
      "Cambios, Vuelos, Cambio de vuelos, cambio de fecha, cambio de horas, cambiar reservaciones, modificar vuelos, cambiar viaje,  Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fullWidthAlert: {
    timeLeft: "Tu sesión expirará en {time}.",
  },
  resultPage: {
    heading: {
      title: "Seleccionar nuevo itinerario",
      description: "Nueva selección: ",
    },
    fareChangeModal: {
      modalTitle: "Todo tu viaje cambiará a {fareFamily}",
      unchangedODModalTitle: "Familias tarifarias en nuevo itinerario",
      upgradeDescriptionRT:
        "Esta acción cambiará todos los vuelos de tu viaje a la tarifa {fareFamily}, incluso los vuelos que no seleccionaste para modificar. Ahora disfrutarás de más beneficios. ",
      downgradeDescriptionRT:
        "Esta acción cambiará todos los vuelos de tu viaje a la tarifa {fareFamily}, incluso los vuelos que no seleccionaste para modificar. Tu experiencia de viaje y beneficios podrían verse afectados.",
      upgradeDescriptionOW:
        "Esta acción cambiará todos los vuelos de tu viaje a la tarifa {fareFamily}. Ahora disfrutarás de más beneficios.",
      downgradeDescriptionOW:
        "Esta acción cambiará todos los vuelos de tu viaje a la tarifa {fareFamily}. Tu experiencia de viaje y beneficios podrían verse afectados.",
      ctaCancel: "Cancelar",
      ctaAccept: "Aceptar",
      fareFamilyDetails: {
        text: "Ver más detalles de las familias tarifarias",
        link: "https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/",
      },
      table: {
        flightHead: "Vuelo",
        oldFareHead: "Tarifa Original",
        newFareHead: "Cambiará a",
        warningText: "Cambio de cabina",
        dateText: "Vuelo {numberOfFlight} · {date}",
      },
      unchangedODPill: "Sin cambios",
      unchangedODAlert:
        "Has seleccionado familias tarifarias distintas en tus vuelos. Para tu conveniencia, siempre podrás elegir familias tarifarias distintas en tus vuelos de ida y regreso. Sin embargo, algunos beneficios como reembolsos o cambios de itinerario pueden verse restringidos.",
    },
    fareChangeModalWCAG: {
      containerUpgrade:
        "Mensaje importante. Todo tu viaje cambiará a {newFareFamily}. Esta acción cambiará todos los vuelos de tu viaje a la tarifa {newFareFamily}, incluso los vuelos que no estas cambiando. Ahora disfrutarás de más beneficios. Estos son los detalles de tus cambios: tu vuelo de {origin1} a {destination1} que estaba en {fareFamily1} cambiará a {newFareFamily} {alerts1} y tu vuelo de {origin2} a {destination2} que estaba en {fareFamily2} cambiará a {newFareFamily} {alerts2}",
      containerDowngrade:
        "Mensaje importante. Todo tu viaje cambiará a {newFareFamily}. Esta acción cambiará todos los vuelos de tu viaje a la tarifa {newFareFamily}, incluso los vuelos que no estas cambiando. Tu experiencia de viaje y beneficios podrían verse afectados. Estos son los detalles de tus cambios: tu vuelo de {origin1} a {destination1} que estaba en {fareFamily1} cambiará a {newFareFamily} {alerts1} y tu vuelo de {origin2} a {destination2} que estaba en {fareFamily2} cambiará a {newFareFamily} {alerts2}",
      ctaCancel:
        "Boton de Cancelar. Presiona el botón para regresar y seleccionar otra opción.",
      ctaAccept:
        "Boton de Aceptar cambio. Presiona enter para aceptar el cambio.",
      fareFamilyDetailsLink:
        "Enlace de Familias tarifarias. Presiona enter para conocer más acerca de las familias tarifarias.",
      closeButton:
        "Botón de cerrar modal. Presiona el botón para cerrar el modal y seleccionar otra opción.",
    },
    fares: {
      BFU: "Ejecutiva Full",
      PRO: "Ejecutiva Promo",
      EFU: "Económica Full",
      CLS: "Económica Classic",
      BAS: "Económica Basic",
      CLF: "Económica Classic Flex",
      NONE: " ",
    },
    cardsResult: {
      rountripSearchLabel: "Ida y vuelta",
      oneWaySearchLabel: "Solo ida",
      totalAmount: "Total a pagar",
      changeItineraty: "Cambio de itinario por",
      changeItineratyPlural: "Cambio de itinerario desde",
      optionText: "Ver opción",
      optionTextPlural: "Ver opciones",
      header: {
        buttonFilter: "Ordenar y filtrar",
        economyCabin: "Económica",
        businessCabin: "Ejecutiva",
      },
      headerWCAG: {
        loginBox: {
          CredentialsLabel:
            "Ingrese su email, número o nombre de usuario de Connectmiles",
          PasswordLabel: "Ingrese su contraseña de ConnectMiles",
          loginButton:
            "Boton de Iniciar sesión. Presione enter para iniciar sesión con sus credenciales",
          fortgotPassword:
            "Enlace de olvide contraseña. Presiona enter para ir a ConnectMiles y recuperar tu contraseña",
          Close:
            "Botón de cierre, pulse enter para cerrar la ventana emergente",
          InlineErrorMandatoryFiled: "Error. Este campo es mandatorio",
          CreateAccountlink:
            "Enlace para crear cuenta. Presiona enter si no tienes una cuenta y quieres crear una cuenta ahora. Se abrirá la página de connectmiles en una nueva pestaña de su navegador.",
          ErrorBannerAlertWrongInformation:
            "Error. La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.",
          ErrorBannerAlertBlockedAccount:
            "Error. Tu cuenta ha sido bloqueada temporalmente por razones de seguridad. Comunícate con nuestro Centro de Servicio de ConnectMiles para obtener ayuda. <enlace al número de centro de llamadas de ConnectMiles>",
        },
        buttonFilter:
          "Boton. Según tu busqueda tu vuelo de regreso es el {day}",
        languageAndStorefrontSelector: {
          countryDropdown:
            "Use las flechas hacia arriba y hacia abajo para seleccionar el país según su locación y la moneda en que desea realizar su compra. Presione Tab para continuar a selección de Idioma. Presione Escape para regresar a navegación.",
          languageDropdown:
            "Use las flechas hacia arriba y hacia abajo para seleccionar el idioma de su preferencia. Presione Escape para regresar a navegación.",
          modifyCTA:
            "Botón de modificar. Presione Enter para modificar País a {currencyCountry} e idioma a {currencyLanguage}",
          closeCTA:
            "Botón de cerrar. Presione Enter para mantener país e idioma, regresar a la navegación principal.",
        },
      },
      tripCard: {
        viewDetails: "Ver detalles",
        flightDetailLayover: "Escala en",
        flightDetailLayovers: "Escalas en",
        flightDetailDirect: "Sin escalas",
      },
      tripCardWCAG: {
        viewDetailsDirect:
          "Información de resultado de vuelo. Vuelo directo de {origin} a {destination} con hora de salida {departure} y llegada a las {arrival}; del {day}, {month} {year}, operado por {airlines}. La duración total del vuelo es de {flightTime}",
        viewDetailsLayovers:
          "Información de resultado de vuelo. Vuelo de {origin} a {destination} con hora de salida {departure} y llegada a las {arrival}; del {day}, {month} {year}, con {layoverLength} escala de {layoverTime} operado por {airlines}. La duración total del vuelo es de {flightTime}",
        viewDetails:
          "Enlace de ver detalles, presiona enter para ver más detalles de los vuelos.",
        hours: "horas",
        hour: "hora",
        minutes: "minutos",
      },
      cabin: {
        cabinText1: "Desde",
        cabinText2: "Por adulto",
        cabinText3: "Agotado",
        economy: "Económica",
        business: "Ejecutiva",
        unavailable: "No disponible",
        helperTextMobile: "Selecciona una tarifa",
        oneWay: "Solo ida",
      },
      cabinWCAG: {
        economyCabin: "Cabina Economica. Precio desde {price} por adulto",
        economySoldOut: "No hay disponibilidad en cabina económica",
        businessCabin: "Cabina ejecutiva. Precio desde {price} por adulto",
        businessSoldOut: "No hay disponibilidad en cabina ejecutiva",
        economyCabinPromo:
          "Cabina Económica. Precio desde {price} , y con el descuento queda en {originalPrice} por adulto",
        businessCabinPromo:
          "Cabina Ejecutiva. Precio desde {price} y con el descuento queda en {originalPrice} por adulto",
        cabinHelperMobileText: "Cabina {type}. Selecciona una tarifa",
      },
      fareFamilies: {
        basicPill: "Tarifa más restrictiva",
        selectedPill: "Escogiste esta tarifa",
        restrictivePill: "Tarifa restrictiva",
        purchased: "Tarifa comprada",
        ctaBasic: "Seleccionar Económica Basic",
        ctaClassic: "Seleccionar Económica Classic",
        ctaFull: "Seleccionar Económica Full",
        ctaFlex: "Seleccionar Classic Flex",
        ctaExtra: "Seleccionar",
        ctaBusinessPromo: "Seleccionar Ejecutiva Promo",
        ctaBusinessFull: "Seleccionar Ejecutiva Full",
        ctaBack: "Mantener esta tarifa",
        linkText: "Detalle de tarifas",
        link: "https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/",
        changePenaltyDisclaimer:
          "El primer cambio sin penalidad es aplicable si se realiza al menos ocho días antes de la fecha de salida*.",
        fareFamilyDisclaimer:
          "Los precios mostrados incluyen cargos de combustible, impuestos y tasas. Consulta nuestra <link>política de equipaje</link> para vuelos operados por Copa Airlines. Pueden aplicar cargos adicionales en maletas extras, con sobrepeso, o sobredimensión, así como a ciertos artículos especiales.",
        baggagePolicyLink:
          "https://www.copaair.com/es-gs/informacion-al-viajar/informacion-de-equipaje/",
        faresPolicyLink:
          "https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/",
        preferMemberDisclaimer:
          "Los beneficios PreferMember varían dependiendo del estatus del miembro.",
        priceIncludeDisclaimer:
          "Los precios mostrados incluyen cargos de combustible, impuestos y tasas.",
        baggageDisclaimer:
          "Consulta nuestra <link>política de equipaje</link> para vuelos operados por Copa Airlines. Pueden aplicar cargos adicionales en maletas extras, con sobrepeso, o sobredimensión, así como a ciertos artículos especiales.",
        mixedFareFamiliesDisclaimer:
          "Si seleccionas <link>familias tarifarias</link> diferentes en tu vuelo de ida y de regreso, se aplicarán los atributos más restrictivos.",
        tooltipPillRestrictive:
          "Uno de los vuelos tiene tarifa con restricciones de equipaje, asientos y cambios. Haz click en 'ver detalles' para saber más.",
        baggagePolicyLinkWCAG:
          "Consulta nuestra política de equipaje. Presiona 'Enter' para obtener más detalles.",
        faresPolicyLinkWCAG:
          "Consulta nuestras familias tarifarias y sus beneficios. Presiona 'Enter' para obtener más detalles.",
      },
      fareFamiliesWCAG: {
        basicContainer:
          "Familia tarifaria Económica Basic, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        classicContainer:
          "Familia tarifaria Económica Classic, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        fullContainer:
          "Familia tarifaria Económica Full, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        extraContainer:
          "Familia tarifaria Económica Extra, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        businessPromoContainer:
          "Familia tarifaria Ejecutiva Promo, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        businessFullContainer:
          "Familia tarifaria Ejecutiva Full, esta tarifa tiene un precio de {price} y cuenta con los siguientes atributos {attributeList}",
        cta: "Boton de seleccionar tarifa. Presiona el botón para seleccionar esta tarifa o dale Tab para ir  la siguiente tarifa.",
      },
    },
  },
  summaryPage: {
    title: "Tu nuevo itinerario de viaje ",
    subtitle: "Revisa con detenimiento tu itinerario antes de continuar.",
    changeFlight: "Modificar selección",
    summaryPageCTA: "Continuar",
    CTAButton:
      "Boton de Continuar. Presiona el botón para continuar con el flujo de compra.",
    titleCostChange: "Costo del cambio",
    textCollapsible: "Cambio sin costo adicional",
    flightTypeForCookie1: "Ida y vuelta",
    flightTypeForCookie2: "Solo Ida",
    flightTypeForCookie3: "Multiciudad",
    flightTypeForCookie4: "Viaje Stopover",
    adults: "Adultos",
    adult: "Adulto",
    children: "Niños",
    child: "Niño",
    infants: "Infantes",
    infant: "Infante",
    itinerary: {
      businessFull: "Ejecutiva Full",
      businessPromo: "Ejecutiva Promo",
      economyFull: "Económica Full",
      economyClassic: "Económica Classic",
      economyBasic: "Económica Basic",
      viewDetails: "Ver detalles",
      outboundFlight: "Vuelo de ida",
      returnFlight: "Vuelo de regreso",
      changeFlight: "Cambiar de vuelo",
      fareDetails: "Reglas tarifarias",
      layover: "Escala en",
      layovers: "Escalas en",
      leg: "Vuelo {legNumber}",
      pillText: "Nuevo vuelo",
      noChangePillText: "Sin cambios",
    },
    priceBreakdown: {
      confirmationCode: "Código de autorización",
      newFare: "Nueva tarifa",
      oldFare: "Tarifa original",
      credit: "Monto a favor",
      changePenalty: "Penalidad por cambio",
      subTotalSubTitle: "Subtotal",
      differenceFare: "Diferencia de tarifa",
      taxSubtitle: "Tasas e impuestos",
      upliftMessage:
        "Completaste esta compra utilizando Uplift. <a>Más información.</a>",
      upliftUrl: "https://www.copaair.com/es/web/gs/opciones-de-pago-uplift",
      disclaimer:
        "Al confirmar este cambio recibirás un certificado de viaje por {price} válido para futuras compras de boletos con Copa Airlines.",
      disclamerConfirmation:
        "Recibirás un certificado de viaje por {price}, válido para futuras compras de boletos con Copa Airlines.",
      chargedAmountSuccesfully:
        "Cobramos este monto con éxito. Recibirás un correo con la confirmación de tu compra.",
      chargedAmountInsuranceSuccesfully:
        "Recibirás un correo con la confirmación de tu compra. El cobro del seguro de viajes se reflejará como un cobro separado del resto del costo de la reserva.",
      disclaimerBton:
        "Al hacer clic en confirmar cambio, estarás modificando tu reserva y no podrás regresar o realizar modificaciones adicionales en esta plataforma",
      presidentialAlert:
        "{name}, ser PreferMember Presidential te da más beneficios. Para eximir el costo de penalidad por cambio de tu boleto, contáctanos a nuestro Centro de Reservaciones.",
    },
    itineraryNotAvailableModal: {
      title: "Lo sentimos, el itinerario elegido ya no está disponible.",
      description:
        "Uno o más de los vuelos que has seleccionado ya no están disponibles y no podemos continuar con el cambio de tu reserva. Por favor, reinicia la búsqueda y elige un nuevo itinerario.",
      ctaButton: "Reiniciar búsqueda",
    },
    taxesModal: {
      title: "Detalle de tasas e impuestos",
      subtitle: "Costo de la reserva",
      description: "Descripción",
      amount: "Monto",
      total: "Total tasas e impuestos",
      payedTaxes: "Tasas e impuestos previamente pagados",
    },
  },
  summaryWCAG: {
    itinerary: {
      linkViewDetails:
        "Enlace de ver detalles, presiona enter para ver más detalles de los vuelos.",
      linkViewRules:
        "Enlace de ver detalles de la tarifa aérea, presiona enter para ver el detalles de la tarifa seleccionada.",
      linkModify:
        "Enlace de cambiar vuelo, presiona enter para regresar a la página de resultados de vuelos y cambiar la selección de vuelo.",
      containerOutboundWithOneScale:
        "Información del vuelo de ida seleccionado.  El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; con {numLayover} escala de {timeLayover} en {codCountry}; la duración total de los vuelos es de {duration}; con la familia tarifaria {fare}.",
      containerInboundWithOneScale:
        "Información del vuelo de ida seleccionado. El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; con {numLayover} escala de {timeLayover} en {codCountry}; la duración total de los vuelos es de {duration} con la familia tarifaria {fare}",
      containerOutboundWithMultipleScales:
        "Información del vuelo de ida seleccionado. El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; con {numLayover} escalas en {codCountry}; la duración total de los vuelos es de {duration} con las siguiente familias tarifarias {fare}",
      containerInboundWithMultipleScales:
        "Información del vuelo de regreso seleccionado. El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; con {numLayover} escalas en {codCountry}; la duración total de los vuelos es de {duration} con las siguientes familias tarifarias {fare}",
      containerOutboundWithoutScale:
        "Información del vuelo de ida seleccionado. El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; la duración total del vuelo es de {duration} con la familia tarifaria {fare}",
      containerInboundWithoutScale:
        "Información del vuelo de regreso seleccionado. El día del vuelo es {dateFlight}, desde {startFlight} hacia {endFlight} con hora de salida {hourDeparture} y hora de llegada a las {hourArrival}; la duración total del vuelo es de {duration} con la familia tarifaria {fare}",
    },
    itineraryNotAvailableModal: {
      container:
        "Mensaje importante. Algunos de los vuelos que elegiste ya no están disponibles y no podemos continuar con el cambio de tu reserva. Por favor, selecciona un nuevo itinerario.",
      ctaButton:
        "Boton de cambiar selección. Presiona para ir y seleccionar otro itinerario",
      closeButton: "Presiona el botón para cerrar el modal.",
    },
    taxesModal: {
      container:
        "Modal de tasas e impuestos. Aquí podrás ver el detalle de las tasas y los impuestos que aplican a tu cambio; este es el listado de los impuestos que te aplican. (Leer listado de taxes y montos)",
      closeButton:
        "Botón de cerrar modal de Tasas e impuestos. Presiona el botón para cerrar el modal y continuar con la compra.",
      openPayedTaxes:
        "Detalle de tasas e impuestos ya pagada. Presion para ver el detalle de las tasas e impuestos que pagaste originalemente.",
    },
    priceBreakdown: {
      container:
        "El resumen del cambio de su reserva con un subtotal de {subTotal}, impuestos de {taxTotal} dando un total de {total}",
      linkViewDetails:
        "Enlace de ver detalles de impuestos, presiona enter para ver más detalles de los impuestos.",
    },
    presidentialAlertContainer:
      "Mensaje importante. Beneficios para pasajeros con estatus PreferMember Presidential. ",
    presidentialAlertDescription:
      "{name},ser PreferMember Presidential te da más beneficios. Para eximir el costo de penalidad por cambio de tu boleto, contáctanos a nuestro Centro de Reservaciones.",
  },
  summarySEO: {
    title: "Resumen de viaje– Copa Airlines",
    description:
      "Resumen del cambio de tu viaje, revisa cuidadosamente la información de tus nuevos vuelos. Una vez que confirmes la información, el cambio se hará a todos los pasajeros.",
    keywords:
      "Cambios, Vuelos, Cambio de vuelos, cambio de fecha, cambio de horas, cambiar reservaciones, modificar vuelos, cambiar viaje,  Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fareRulesModal: {
    title: "Reglas Tarifarias",
    fare: "Tarifa",
    headingDescription:
      "Aquí podras ver el detalle de las reglas tarifarias que aplican para cada uno de los vuelos seleccionados.",
    headingCaption: "Conoce más acerca de nuestras familias tarifarias.",
    headingLink:
      "https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/",
    lineMessage:
      "La información de las reglas tarifarias no está disponible para este vuelo ya que no es operado por Copa Airlines.",
    errorMessage:
      "Lo sentimos, la información de las reglas tarifarias no está disponible en este momento. Por favor intenta nuevamente más tarde.",
    WCAG: {
      modalDescription:
        "Modal de reglas tarifarias. Desde aquí podrás ver las reglas que aplican a la tarifa que compraste.",
      fareContent:
        "El vuelo desde {iataOrigin} a {iataDestination}, número de vuelo {airlineCode} {flightNumber}, tiene las siguientes reglas: {fareFamilyName}",
      backwardArrow:
        "Botón vuelo anterior. Presiona enter para ver las reglas tarifarias que aplican al vuelo anterior.",
      forwardArrow:
        "Botón siguiente vuelo. Presiona enter para ver las reglas tarifarias que aplican al próximo vuelo.",
      errorLineMsg:
        "El vuelo desde [CÓDIGO IATA de origen] a [código IATA de destino], número de vuelo [código de aerolínea + número],  [todo el texto]",
      closeBotton:
        "Botón de cerrar modal de reglas tarifarias. Presiona el botón para cerrar el modal. ",
    },
  },
  notEligiblePage: {
    heading: {
      flightCaption: "Fechas de viaje",
      fareCaption: "Familia Tarifaria",
      reservationCaption: "Código de reserva",
    },
    withCancel: {
      title:
        "Para modificar esta reserva, por favor, contáctanos a nuestro Centro de Llamadas",
      description:
        "En este momento, no podemos modificar esta reserva. Por favor, contacta a nuestro <a>Centro de Llamadas</a> o visita una de nuestras <b>oficinas de ventas</b> para gestionar el cambio.",
      linkReservation:
        "https://www.copaair.com/es-gs/atencion-al-cliente/centro-de-llamadas/",
      linkOffices:
        "https://www.copaair.com/es-gs/atencion-al-cliente/oficina-de-ventas/",
      items: {
        title: "Algunas razones pueden ser:",
        item1: "Tu reserva tiene menos de 24 horas de haber sido emitida.",
        item2:
          "Tu reserva no fue comprada a través de copa.com, el centro de llamadas o una de nuestras oficinas de ventas.",
        item3: "Tu reserva tiene un descuento especial aplicado.",
        item4: "Tu itinerario tiene vuelos operados por otra aerolínea.",
        item5: "Tu reserva fue modificada previamente.",
        item6:
          "Tu itinerario cuenta con varios destinos o incluye una estadía en Panamá.",
        item7:
          "Compraste servicios adicionales, como equipo deportivo, mascotas, entre otros.",
        item8:
          "Recibiste un ascenso a Clase Ejecutiva en alguno de los segmentos de la reserva.",
        item9: "Ya completaste parte de tu itinerario de viaje.",
      },
    },
    cancelTitle: "Otras opciones disponibles",
    cancelButtonText: "Cancelar mi reserva",
    helpSection: {
      message: "¿Necesitas Ayuda?",
      reservationOption: {
        text: "Centro de Llamadas",
        link: "https://www.copaair.com/es-gs/atencion-al-cliente/centro-de-llamadas/",
      },
      salesOption: {
        text: "Oficinas de Ventas",
        link: "https://www.copaair.com/es-gs/atencion-al-cliente/oficina-de-ventas/",
      },
      helpOption: {
        text: "Centro de Información",
        link: "https://help.copaair.com/hc/es-419",
      },
    },
  },
  notEligiblePageWCAG: {
    title: "Cambio no elegible - Copa Airlines",
    container:
      "Mensaje importante. La reserva no es elegible para realizar cambios.",
    cancelButton:
      "Boton de Cancelar mi reserva. Presione enter para ir a la página de opciones de cancelar reserva.",
    helpSection: {
      reservationOption:
        "Enlace a Centro de Llamadas. ¿Necesitas Ayuda? Presiona enter para ir a la página de Centro de Llamadas de Copa Airlines.",
      salesOption:
        "Enlace a Oficinas de Ventas.  ¿Necesitas Ayuda? Presiona enter para ir a la página de Oficinas de Venta de Copa Airlines.",
      helpOption:
        "Enlace a Centro de Ayuda. ¿Necesitas Ayuda? Presiona enter para ir a la página del Centro de Ayuda de Copa Airlines.",
    },
  },
  confirmation: {
    heading: {
      title: "¡Listo! Has completado tu cambio",
      titleWithPricelock: "¡Listo! Tu reserva fue guardada con éxito",
      description:
        "Próximamente recibirás la confirmación del cambio de itinerario al correo <b></b>.",
      descriptionWithPay:
        "En estos momentos estamos procesando tu pago. Próximamente recibirás una confirmación con el boleto electrónico de tu reserva al correo <b></b>.",
      codeText: "Código de Reserva",
      btnReservation: "Regresar a Mis Viajes",
      btnPrint: "Imprimir itinerario",
      alert:
        "Para evitar que tu reserva sea cancelada automáticamente, recuerda completar tu compra a más tardar el",
      pseAlert:
        "Estamos procesando tus cambios, lo que puede demorar unos minutos. Durante este tiempo, es posible que no puedas acceder a la información de tu reserva. Una vez sea completado, recibirás un correo con tu nuevo boleto electrónico actualizado.",
    },
    itineratyTitle: "Tu nuevo itinerario de viaje",
    passengerInfo: {
      title: "Pasajeros en la reserva",
      subtitle:
        'Estos son los pasajeros que van a estar viajando. Por favor, verifica su información antes de proceder. Una vez completada la compra podrás seleccionar y cambiar los asientos en "Mis Viajes".',
      adult: "Adulto",
      child: "Niño",
      infant: "Infante",
      notSeatSelected: "Recuerda seleccionar asientos ",
    },
    multiCityText: "Vuelo Multiciudad",
    errorCreditCards:
      "Lo sentimos, no pudimos guardar la tarjeta de crédito. Para tu próxima compra, deberás volver a ingresar la información de tu tarjeta y guardarla nuevamente.",
    errorFQTV:
      "Lo sentimos, no pudimos guardar el número de viajero frecuente de {passagers} a la reserva. Para intentarlo nuevamente, por favor, ingresa a Mis Viajes y agrégalo en la sección de información de pasajeros.",
    insuranceTitle: "Información importante sobre tu seguro de viaje ",
    insurancePaymentAlert:
      "Compraste un seguro de viaje con nuestro aliado estratégico CHUBB. Para procesar el cambio de este servicio deberás comunicarte directamente con CHUBB en <a> estos puntos de contacto</a>, utilizando tu código de reserva como referencia.",
    insuranceContactLink:
      "https://copa.chubbtravelinsurance.com/copa/pa-es/home.html#",
    description:
      "Resultado de la compra de tu reserva de viaje. Te detallamos todo el resumen de tu compra.",
    keywords:
      "Vuelos, Viaje, Reserva, boleto de viaje, tiquete de viaje, compras seguras, transacciones seguras, , Copaair, copa.com, copaair.com, Copa Airlines.",
    descriptionPage: "Confirmation",
    titlePage: "Confirmation Page",
    disclaimers: {
      retract: {
        title: "Información importante para pasajeros viajando desde Chile",
        body: "Esta compra no tiene derecho de retracto establecido en la Ley del Consumidor Chilena.",
      },
      bankTransfer: {
        title: "Información referente al pago con transferencia bancaria ",
        body: "La forma de pago alterna de transferencia bancaria solo se encuentra disponible si realizas tu compra un mínimo de 14 días antes de tu viaje.",
      },
      bankTicket: {
        title: "Información referente al pago con boleto bancario",
        body: "La forma de pago alterna de boleto bancario solo se encuentra disponible si realizas tu compra con un mínimo de 14 días antes de tu viaje.",
      },
      OTP: {
        title: "Puntualidad de tus vuelos",
        body: 'Si viajas hacia / desde Brasil, puedes verificar el porcentaje de retrasos y cancelaciones de vuelos haciendo clic en el enlace de "Ver Detalles" de cada vuelo. ',
      },
    },
    pse: {
      title: "Información del pago (PSE Colombia)",
      subtitle:
        "Mensaje importante: Estamos procesando tu pago. Recibirás un correo con la confirmación de tu compra.",
      info: {
        nit: "NIT",
        bank: "Banco",
        company: "Empresa",
        refNumber: "Número de referencia",
        paymentDesc: "Descripción del pago",
      },
    },
    aditionalServices: {
      title: "Servicios adicionales",
      aditionalText:
        "Se está procesando tu compra de seguro de viaje con nuestros aliado estratégico Chubb por la suma de {price}. Pronto recibirás la confirmación del pago y la poliza correspondiente al servicio que adquiriste.",
      aditionalText2:
        "Para cualquier cambio, reembolso, consulta o reclamo relacionado, comunícate directamente con CHUBB en estos <link>puntos de contacto</link>, utilizando tu código de reserva como referencia.",
      contactPointsLink:
        "https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=es",
    },
    whereToPaySection: {
      whereToPayTitle: "Cómo pagar tu reserva:",
      webText: "Ingresando a “Mis Viajes” desde",
      webLinkText: "copa.com",
      webLink: "https://www.copaair.com/",
      callingTo: "Llamando a nuestro",
      callingToLinkText: "Centro de Reservaciones",
      callingToLink:
        "https://www.copaair.com/es/web/gs/centro-de-reservaciones",
      callingToText2: "(pagos con tarjeta de crédito)*",
      anotherWayOfPayment: "También puedes pagar en nuestras",
      anotherWayOfPaymentLinkText: "oficinas de ventas*",
      anotherWayOfPaymentLink:
        "https://www.copaair.com/es/web/gs/oficinas-de-ventas",
      anotherWayOfPayment2: "o en el mostrador del aeropuerto*",
      disclaimer1:
        "*Si haces una reserva a través de copa.com y luego deseas pagar por nuestro Centro de Reservaciones, oficinas de ventas o en el aeropuerto, se te aplicará una tasa administrativa no reembolsable y/u otros cargos adicionales no reembolsables al costo total del boleto. Lee nuestros",
      disclaimerLink:
        "https://www.copaair.com/es/web/gs/terminos-y-condiciones-de-book-and-hold-fee",
      disclaimerLinkText: "términos y condiciones de PriceLock",
      disclaimer2:
        "para más información. Estas tarifas no son válidas para emisión o pago en nuestras oficinas en Argentina.",
    },
    priceBreakownPriceLock: "Pagaste hoy",
    priceBreakdownDisclaimerText:
      "Tienes hasta el {date} para completar tu compra.",
  },
  confirmationWCAG: {
    descriptionPage:
      "Se realizó su cambio de itinerario y tu pago está siendo procesado. Tu código de reserva es {codeReservation}. Una vez tu pago haya sido procesado, recibirá un correo electrónico de confirmación a {email}.",
    heading: {
      btnReservation:
        "Botón de ir a Mis Viajes. Presiona enter para ir a Mis Viajes y ver los detalles de tu vuelo.",
      btnPrint:
        "Botón para imprimir página. Presiona enter para imprimir esta página como constancia que tu reserva está siendo procesada.",
    },
    passengersWCAG: {
      linkSeat:
        "Enlace de selección de asientos. Presione enter para ir a Mis viajes y seleccionar tus asientos.",
      withSeat:
        "Estos son los pasajeros a viajar en esta reservación: Pasajero {TypePax} {namePax} {surnamePax}, con fecha de nacimiento {birthdate}, con asiento {seat}.",
      withOutSeat:
        "Estos son los pasajeros a viajar en esta reservación: Pasajero {TypePax} {namePax} {surnamePax}, con fecha de nacimiento {birthdate}, no tiene seleccionado asiento, puedes seleccionarlo luego de haber finalizado la compra en Mis Viajes.",
    },
  },
  viewMoreDetailsModal: {
    title: "Detalles del Viaje",
    subtitlesCards: "Segmento de vuelo {currentflight} de {total}",
    date: "Fecha",
    Flight: "Vuelo",
    subtitleTo: "hacia",
    aircraftType: "Modelo Aeronave",
    operatedBy: "Operado por",
    button: "Ver asientos disponibles",
    layover: "ESCALA",
    fareFamilyText: "Estás viajando en {fare}:",
    viewMoreText: "Ver más detalles de las familias tarifarias",
    viewMoreLink:
      "https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/",
    OTPTitle: "Porcentaje de Cancelaciones y Retrasos",
    OTPCancellations: "Cancelaciones",
    OTPDelays: "Retrasos mayor a 30 min",
    OTPDelays2: "Retrasos mayor a 60 min",
    mixedFareFamilies:
      "Seleccionaste vuelos con familias tarifarias distintas, algunos de estos beneficios pueden estar restringidos.",
    navigationText: "Vuelo {flightNumber} de {numberOfFlights}",
    restrictiveFare:
      "Este resultado es clase Basic, la cual es nuestra tarifa más restrictiva que tiene las siguientes restricciones: Equipaje en bodega con costo adicional y preselección de asientos con costo (o asignación gratis en el aeropuerto)",
    WCAG: {
      modalDescription: "Sección de detalles del viaje",
      itinerarySummary:
        "Información de itinerario de vuelo. Vuelo de {origin} a {destination} con hora de salida {departureTime} y llegada a las {arrivalTime}; del {dateFormat}, con {numberOfLayovers} escala de {timeLayover} operado por {airlineName}. La duración total del vuelo es de {journeyTime}",
      segmentSummary:
        "Información de vuelo {currentflight} de {total} de {origin} a las {departureTime} con llegada a {destination} a las {arrivalTime} del día {date}, con número de vuelo {airlineCode},  con modelo de avión {aircraftName} operado por {airlineName}",
      segmentSummaryWithOTP:
        "Información de vuelo {currentflight} de {total} de {origin} a las {departureTime} con llegada a {destination} a las {arrivalTime} del día {date}, con número de vuelo {airlineCode},  con modelo de avión {aircraftName} operado por {airlineName}. Información sobre Porcentaje de Cancelaciones y Retrasos de este vuelo. Información sobre Cancelaciones. Este vuelo tiene un porcentaje de {percent1} de Cancelaciones. Información sobre Retrasos mayor a 30 min. Este vuelo tiene un porcentaje de {percent2} de retrasos mayor a 30 min. IInformación sobre Retrasos mayor a 60 min. Este vuelo tiene un porcenataje de {percent3} de retrasos mayor a 60 min.",
      availaibleSeats:
        "Botón de ver asientos disponibles. Presiona el botón para ver la disponibilidad de asientos en este vuelo.",
      // From the following line to OTPText3 they are read in the segment id SummaryWithOTP
      OTPTitle:
        "Información sobre Porcentaje de Cancelaciones y Retrasos de este vuelo.",
      OTPText1:
        "Información sobre Cancelaciones. Este vuelo tiene un porcentaje de {percentage} de Cancelaciones.",
      OTPText2:
        "Información sobre Retrasos mayor a 30 min. Este vuelo tiene un porcentaje de {percentage} de retrasos mayor a 30 min.",
      OTPText3:
        "Información sobre Retrasos mayor a 60 min. Este vuelo tiene un porcenataje de {percentage} de retrasos mayor a 60 min.",
      fareFamilyText:
        "Estás viajando en {fare}: estos son los beneficios que tiene esta clase {list}",
      viewMoreLink:
        "Enlace de Ver más detalles de las familias tarifarias. Presiona enter para ver el detalle de la familias tarifarias.",
      mixedFareFamilies:
        "Alerta. Seleccionaste vuelos con familias tarifarias distintas, algunos de estos beneficios pueden estar restringidos.",
      closeBotton:
        "Botón de cerrar modal de ver detalles de vuelo. Presiona el botón para cerrar el modal.",
      backwardArrow:
        "Boton de ir al vuelo anterior. Presiona este boton para ver los detalles del vuelo anterior.",
      forwardArrow:
        "Boton de ir al siguiente vuelo. Presiona este boton para ver los detalles del siguiente vuelo.",
    },
  },
  interstitial: {
    loading: "Cargando",
    title: "Tu seguridad es nuestra prioridad",
    description:
      "Hemos fortalecido nuestros protocolos de bioseguridad para que puedas viajar sin preocupaciones.",
    wcagDescription: "Su página se está cargando",
  },
  endSession: {
    endSessionTitle: "Tu búsqueda expiró",
    endSessionBoddy:
      "El tiempo para completar esta reserva se ha agotado. Puedes comenzar una nueva búsqueda.",
    endSessionBtn: "Volver al inicio",
    endSessionWCAG:
      "Mensaje de sesión expirada. El tiempo para completar el cambio a esta reserva se ha agotado, por lo tanto tu cambio expiró.Por favor comienza nuevamente.",
    endSessionBtnWCAG: "Botón de volver al inicio",
  },
  paymentErrorModal: {
    title: "No pudimos procesar tu pago.",
    errorMessage: "Has agotado todos los intentos para procesar el pago.",
    body: "Para completar este cambio, por favor contáctanos a través del <a> Centro de Reservaciones. </a>",
    bodyLink:
      "Para completar este cambio, por favor contáctanos a través del Centro de Reservaciones.",
    link: "https://www.copaair.com/es/web/us/centro-de-reservaciones",
    btnText: "Regresar a Mis Viajes",
    btnDecriptionWCAG:
      "Botón de ir a Mis Viajes. Presiona enter para ir a Mis Viajes y ver los detalles de tu vuelo.",
    descriptionWCAG:
      "Mensaje de error.  Lo sentimos,  has agotado todos los intentos para procesar el pago. Para completar este cambio, por favor contáctanos a través del Centro de Reservaciones.",
  },
  criticalErrorPage: {
    titlePage: "Error",
    title: "Lo sentimos",
    description:
      "No pudimos procesar tu cambio, por favor, llama a nuestro Centro de Reservaciones para realizar este proceso.",
    retryButton: "Contactar Centro de Reservaciones",
    helpText: "¿Necesitas ayuda?",
    reservationCenter: "Centro de Reservaciones",
    salesOffice: "Oficinas de Ventas",
    helpDesk: "Centro de Información",
    reservationCenterLink:
      "https://www.copaair.com/es/web/gs/centro-de-reservaciones",
    salesOfficeLink: "https://www.copaair.com/es/web/gs/oficinas-de-ventas",
    helpDeskLink: "https://help.copaair.com/hc/es-419",
  },
  criticalErrorPageWCAG: {
    cta: "Error.No pudimos procesar tu cambio, por favor, llama a nuestro Centro de Reservaciones para realizar este proceso.",
    reservationCenter:
      "Enlace a Centro de Reservaciones. Presiona enter para ir a la página de Centro de Reservaciones de Copa Airlines.",
    salesOffice:
      "Enlace a Oficinas de Ventas. Presiona enter para ir a la página de Oficinas de Venta de Copa Airlines.",
    helpDesk:
      "Enlace a Centro de Ayuda. Presiona enter para ir a la página del Centro de Ayuda de Copa Airlines.",
  },
  modifyLanguageModal: {
    title: "Esta acción reiniciará tu búsqueda",
    subText:
      "Esta acción reiniciará tu búsqueda. Cualquier selección de vuelos que hayas hecho se perderá. ¿Estás seguro que deseas continuar?",
    continueCTA: "Continuar con ajustes",
    stayCTA: "Permanecer en esta búsqueda",
  },
  modifyLanguageModalWCAG: {
    title: "Esta acción reiniciará tu búsqueda",
    container:
      "Esta acción reiniciará tu búsqueda. Cualquier selección de vuelos que hayas hecho hasta este punto se perderá. ¿Estás seguro que deseas continuar?",
    continueCTA: "Botón Continuar. Presione Enter para proceder con el cambio",
    stayCTA:
      "Botón Permanecer en esta búsqueda. Presiona Enter si prefieres mantener el país e idioma que tenias antes y continuar con el flujo",
    closeButton:
      "Botón de cerrar. Presione Enter para mantener país e idioma, regresar a la navegación principal",
  },
}
