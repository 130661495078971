import { call, takeEvery } from "redux-saga/effects"

import TYPES from "./generalTypes"

import Api from "../../api"

export function* notificationService(action) {
  yield call(Api.notification.callNotificationService, {
    memberId: action.payload.memberId,
    pnrNumber: action.payload.pnrNumber,
    surname: action.payload.surname,
    userId: action.payload.userId,
  })
}

function* generalSaga() {
  yield takeEvery(TYPES.FETCH_NOTIFICATION_SERVICE, notificationService)
}

export default generalSaga
