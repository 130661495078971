import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  /** Styles general */
  container: {
    marginTop: 32,
    "& .inputDatesSelector": {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  containerMobil: {
    display: "flex",
    justifyContent: "space-between",
  },
  dateSelectorItem: {
    marginBottom: 8,
  },
  flightsMobil: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
  },
  dateMobil: {
    marginTop: 24,
    color: theme.palette.grey["600"],
  },
  arrowOnlyDate: {
    fill: theme.palette.grey[400],
    marginLeft: 17,
    marginRight: 17,
  },
  arrawRight: {
    fill: theme.palette.grey[400],
    transform: "rotate(-90deg)",
    marginLeft: 14,
    marginRight: 14,
  },
  calendarGrid: {
    alignSelf: "flex-start",
    [theme.breakpoints.only("sm")]: {
      marginTop: "1em",
    },
  },
})

export default useStyles
