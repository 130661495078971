import React, { useEffect } from "react"
import { useErrorBoundary } from "react-error-boundary"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import CriticalError from "../pages/criticalError"
import MainLayout from "../layouts/main"
import Seo from "../commons/components/Seo"
import { flightsActions } from "../../store/flight/flightSlice"
import { resetAction } from "../../store/storeUtils"

const ErrorPageComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <Seo
      ariaText={formatMessage({ id: "criticalErrorPage.description" })}
      id="criticalErrorPageID"
      title={formatMessage({ id: "criticalErrorPage.titlePage" })}
      keywords={[formatMessage({ id: "notFoundPage.keywords" })]}
      metaTypeSEO={formatMessage({ id: "criticalErrorPage.description" })}
    >
      <MainLayout
        showEditButton={false}
        showPrice={false}
        showInfo={false}
        withoutModal
      >
        <CriticalError />
      </MainLayout>
    </Seo>
  )
}

const ErrorFallBack = () => {
  const { push } = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    try {
      sessionStorage.clear()
      localStorage.clear()
    } catch (e) {
      console.error("Error clearing storage")
    }
  }, [])

  useEffect(() => {
    dispatch(resetAction())
  }, [dispatch])

  useEffect(() => {
    push("/critical-error")
  }, [push])

  return <ErrorPageComponent />
}

export default ErrorFallBack
