import React from "react"
import PropTypes from "prop-types"
import SvgIcon from "@material-ui/core/SvgIcon"
import { useIntl, FormattedMessage } from "react-intl"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"

import { ReactComponent as WarningOutline } from "../../../../ToLibrary/icons/Warning-Outline.svg"
import { ReactComponent as ErrorOutline } from "../../../../ToLibrary/icons/Error-Outline.svg"
import useStyles from "./styles"

const FlightMessage = ({ errorMessage, lineMessage }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <div
      data-cy="fareSegmentNav-errorLineMsg"
      className={classes.flightMessage}
      aria-label={formatMessage({
        id: "fareRulesModal.WCAG.errorLineMsg",
      })}
      role="grid"
      tabIndex="0"
    >
      {lineMessage && (
        <div data-cy="fareSegmentNav-errorOutline" className="message">
          <SvgIcon className="outlineIcon" component={ErrorOutline} />
          <TypographyCo data-cy="fareSegmentNav-lineMessage" variant="body1">
            <FormattedMessage id="fareRulesModal.lineMessage" />
          </TypographyCo>
        </div>
      )}

      {errorMessage && (
        <div data-cy="fareSegmentNav-warningOutline" className="message">
          <SvgIcon className="outlineIcon" component={WarningOutline} />
          <TypographyCo data-cy="fareSegmentNav-errorMessage" variant="body1">
            <FormattedMessage id="fareRulesModal.errorMessage" />
          </TypographyCo>
        </div>
      )}
    </div>
  )
}

FlightMessage.propTypes = {
  errorMessage: PropTypes.bool,
  lineMessage: PropTypes.bool,
}

FlightMessage.defaultProps = {
  errorMessage: false,
  lineMessage: false,
}

export default FlightMessage
