const initialState = {
  loading: 0,
  isInterstitialLoading: false,
  lang: null,
  openLoginBox: false,
  fatalErrorRedirect: false,
  currency: "",
  fullAlertMessage: {
    type: "info",
    message: "",
  },
}

export default initialState
