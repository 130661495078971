const ENABLE_FRENCH_TRASNLATION =
  process.env.REACT_APP_ENABLE_FRENCH_TRANSLATION?.includes?.("true") || false
const TURN_ON_LOGIN =
  process.env.REACT_APP_TURN_ON_LOGIN?.includes?.("true") || false
const ALREADY_PURCHASED =
  process.env.REACT_APP_ALREADY_PURCHASED?.includes?.("true") || false

const ENV_FEATURE_FLAGS = {
  ENABLE_FRENCH_TRASNLATION,
  TURN_ON_LOGIN,
  ALREADY_PURCHASED,
}

const ALLOWED_DYNAMIC_ENVS = ["dev", "qa", "uat", "sis"]

const HOST_ENV = process.env.REACT_APP_HOST_ENV

const FEATURE_FLAGS = Object.entries(ENV_FEATURE_FLAGS).reduce(
  (featureFlags, [featureKey, envValue]) => {
    featureFlags[featureKey] = envValue

    if (
      !ALLOWED_DYNAMIC_ENVS.some((env) => HOST_ENV && HOST_ENV?.includes?.(env))
    ) {
      return featureFlags
    }

    try {
      const localValue = JSON.parse(localStorage.getItem(featureKey))
      if (localValue !== null && localValue !== undefined) {
        featureFlags[featureKey] = localValue
      }
    } catch (e) {
      console.error("Invalid feature value")
    }
    return featureFlags
  },
  {}
)

export default FEATURE_FLAGS
