export const fromQueryToObject = (query) => {
  const paramsIterator = new URLSearchParams(query).entries()
  const paramsArray = Array.from(paramsIterator)
  paramsArray.forEach((param) => {
    try {
      param[1] = JSON.parse(param[1])
    } catch {
      param[1] = param[1].toString()
    }
  })
  const paramsMap = new Map(paramsArray)
  return Object.fromEntries(paramsMap)
}

export const fromObjectToQuery = (object) => {
  const { segments, ...objectWithoutSegments } = object
  const bookingParamsAsArray = Object.entries(objectWithoutSegments)
  let queryResult = "?"
  bookingParamsAsArray.forEach((bookingParam) => {
    queryResult = queryResult.concat(`${bookingParam[0]}=${bookingParam[1]}&`)
  })
  return queryResult.slice(0, -1)
}
