import React, { useState } from "react"
import { useIntl } from "react-intl"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Container } from "@material-ui/core"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import useStyles from "./styles"

import { ReactComponent as Icon1 } from "../../../../../ToLibrary/icons/disclaimerExchange.svg"
import { ReactComponent as Icon2 } from "../../../../../ToLibrary/icons/disclaimerSeat.svg"
import { ReactComponent as Icon3 } from "../../../../../ToLibrary/icons/disclaimerFare.svg"
import { ReactComponent as Arrow } from "../../../../../ToLibrary/icons/Carrot-Down.svg"
import { ReactComponent as ArrowRight } from "../../../../../ToLibrary/icons/KeyboardArrow-Right.svg"

const Disclaimer = () => {
  const { formatMessage } = useIntl()
  const isMobile = useMediaQuery(`${theme.breakpoints.down("xs")}`)
  const [open, setOpen] = useState(false)
  const classes = useStyles({ open })
  const translation = (id) => `searchPage.disclaimer.${id}`
  const translationWCAG = (id) => `searchPage.disclaimerWCAG.${id}`

  const handleClose = () => {
    if (isMobile) {
      setOpen(!open)
    } else {
      setOpen(true)
    }
  }

  return (
    <div className={classes.root} data-cy="disclaimer">
      <Container className={classes.rootElements}>
        <button
          data-cy="disclaimer-title"
          type="button"
          onClick={handleClose}
          className={classes.containerText}
          aria-label={
            isMobile
              ? formatMessage(
                  { id: translationWCAG("contentDisclaimerMobil") },
                  {
                    numFlight: 874,
                    disc1: `${formatMessage({ id: translation("disc1") })}`,
                    disc2: `${formatMessage({ id: translation("disc2") })}`,
                    disc3: `${formatMessage({ id: translation("disc3") })}`,
                  }
                )
              : formatMessage(
                  { id: translationWCAG("contentDisclaimer") },
                  {
                    numFlight: 874,
                    disc1: `${formatMessage({ id: translation("disc1") })}`,
                    disc2: `${formatMessage({ id: translation("disc2") })}`,
                    disc3: `${formatMessage({ id: translation("disc3") })}`,
                  }
                )
          }
        >
          <TypographyCo variant="h3" color="primary" className={classes.title}>
            {formatMessage({ id: translation("title") })}
          </TypographyCo>
          {isMobile && (
            <div>
              <Arrow
                className={open ? classes.arrowOpen : classes.arrowClose}
              />
            </div>
          )}
        </button>
        {((isMobile && open) || !isMobile) && (
          <>
            <div className={classes.container}>
              <div className={classes.item} data-cy="disclaimer-item-1">
                <div>
                  <Icon1 className={classes.iconItem} />
                </div>
                <TypographyCo variant="h4" className={classes.textItem}>
                  {formatMessage({ id: translation("disc1") })}
                </TypographyCo>
              </div>
              <div className={classes.item} data-cy="disclaimer-item-2">
                <div>
                  <Icon2 className={classes.iconItem} />
                </div>
                <TypographyCo variant="h4" className={classes.textItem}>
                  {formatMessage({ id: translation("disc2") })}
                </TypographyCo>
              </div>
              <div className={classes.item} data-cy="disclaimer-item-3">
                <div>
                  <Icon3 className={classes.iconItem} />
                </div>
                <TypographyCo variant="h4" className={classes.textItem}>
                  {formatMessage({ id: translation("disc3") })}
                </TypographyCo>
              </div>
            </div>
            <a
              data-cy="disclaimer-link"
              href={formatMessage({ id: translation("webLink") })}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.containerLink}
              aria-label={formatMessage({ id: translationWCAG("link") })}
            >
              <TypographyCo
                data-cy="disclaimer-link-text"
                variant="h4"
                color="primary"
                className={classes.textLink}
              >
                {formatMessage({ id: translation("link") })}
              </TypographyCo>
              <ArrowRight className={classes.arrowLink} />
            </a>
          </>
        )}
      </Container>
    </div>
  )
}

export default Disclaimer
