import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

import useCountDownTimer from "./useCountDownTimer"
import { generalActions } from "../../store/general/generalSlice"
import { redirectToMyTrips } from "../../utils/redirectFlow"

/**
 *
 * @param {number} expirationTime tells to the hook when the session expires
 * @param {string} redirectURL where to redirect if the expirationTime is not defined
 * @param {() => bool} predicateToRedirect redirects immediately to the redirectURL if returns true
 * @param {number} minutesToShowCountdown tells to the hook when to show the full width countdown alert
 */
const useSessionCountDown = (
  expirationTime,
  redirectURL,
  predicateToRedirect,
  minutesToShowCountdown = 5
) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const {
    push: historyPush,
    location: { search: locationSearch },
  } = useHistory()
  const { min, sec, isEndTime, isDownTime } = useCountDownTimer(
    expirationTime,
    minutesToShowCountdown
  )

  useEffect(() => {
    return () => dispatch(generalActions.setFullAlertWithoutClose(false))
  }, [dispatch])

  useEffect(() => {
    if (min <= minutesToShowCountdown && isDownTime) {
      const formattedSeconds = sec.toString().padStart(2, "0")
      dispatch(
        generalActions.setFullAlertMessage({
          type: "info",
          message: formatMessage(
            {
              id: `fullWidthAlert.timeLeft`,
            },
            { time: `${min}:${formattedSeconds}` }
          ),
          role: sec % 20 === 0 || sec === 0 ? "alert" : "toolbar",
        })
      )

      if (!isEndTime) {
        dispatch(generalActions.setFullAlertWithoutClose(true))
      }
    }
  }, [
    dispatch,
    formatMessage,
    isDownTime,
    isEndTime,
    min,
    minutesToShowCountdown,
    sec,
  ])

  useEffect(() => {
    const shouldRedirectByImplementation = predicateToRedirect
      ? predicateToRedirect(min, sec)
      : false
    // This tells to the hook to redirects if
    // the condition written on implementation is fulfilled
    // or if the expirationTime is not defined
    const shouldRedirect = !expirationTime || shouldRedirectByImplementation
    if (shouldRedirect) {
      const params = new URLSearchParams(locationSearch)
      const pnr = params.get("pnr")
      const lastName = params.get("lastName")

      redirectToMyTrips(pnr, lastName).runWith(window.location)
    }
  }, [
    predicateToRedirect,
    min,
    sec,
    expirationTime,
    historyPush,
    redirectURL,
    locationSearch,
  ])

  return isEndTime
}

export default useSessionCountDown
