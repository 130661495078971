// import uuid from "uuid-random"
// import { setCookiesMatchingArrays } from "../../../utils/auth"
// import CONSTANTS from "../../../utils/constants"
import moment from "moment"
import { SHA256, Rabbit } from "crypto-js"
import flattenDeep from "lodash/flattenDeep"
import { isEmpty } from "lodash"

import { getDate } from "../../../utils/generalUtils"
import { setCookiesMatchingArrays } from "../../../utils/auth"

// const verifyIfDiscountApplyByAirportCode = (airportCode) =>
//   [
//     "PTY",
//     "VVI",
//     "SJO",
//     "UIO",
//     "GYE",
//     "SAP",
//     "TGU",
//     "MEX",
//     "MTY",
//     "CUN",
//     "GUA",
//     "PVR",
//     "SJU",
//   ].includes(airportCode)

// export const verifyIfDiscountApplyToFlights = (
//   flightsSelected,
//   isMulticity
// ) => {
//   let shouldApplyDiscount = false
//   if (isMulticity) {
//     shouldApplyDiscount = flightsSelected.some((flight) =>
//       verifyIfDiscountApplyByAirportCode(flight.origin.code)
//     )
//   } else {
//     const outboundFlight = flightsSelected.find(
//       (flight) => flight.originDestinationKey === "OD1"
//     )
//     shouldApplyDiscount = verifyIfDiscountApplyByAirportCode(
//       outboundFlight?.origin?.code
//     )
//   }
//   return shouldApplyDiscount
// }

// export const fillDataPriceLock = (flightsSelected, passengersPL) => {
//   const flightsData = []
//   const faresData = []

//   const interlineFlights = flightsSelected
//     .map((flight) => {
//       return (
//         flight?.solutions?.flights?.filter(
//           (segment) => segment?.operatingCarrier
//         ) || []
//       )
//     })
//     .flat()

//   if (interlineFlights.length) {
//     const dataNotShouldBeFill = interlineFlights.some(
//       (segment) =>
//         segment.operatingCarrier?.airlineName !== CONSTANTS.P5_AEROREPUBLICA
//     )

//     if (dataNotShouldBeFill) return null
//   }

//   flightsSelected.forEach((flightSelected) => {
//     const {
//       flights,
//       offers: { fareBasisCodes },
//     } = flightSelected?.solutions || []
//     flights.forEach((flight, index) => {
//       flightsData.push({
//         key: uuid(),
//         flightNumber: flight?.marketingCarrier?.flightNumber,
//         airlineCode: flight?.marketingCarrier?.airlineCode,
//         ...(flight?.operatingCarrier?.airlineCode && {
//           operatingAirlineCode: flight?.operatingCarrier?.airlineCode,
//         }),
//         classOfService:
//           flightSelected?.solutions?.offers?.classOfService[index],
//         departure: {
//           airportCode: flight?.departure?.airportCode,
//           airportName: flight?.departure?.airportName,
//           flightDate: flight?.departure?.flightDate,
//           flightTime: flight?.departure?.flightTime,
//           terminal: `${flight?.departure?.terminal}`,
//         },
//         arrival: {
//           airportCode: flight?.arrival?.airportCode,
//           airportName: flight?.arrival?.airportName,
//           flightDate: flight?.arrival?.flightDate,
//           flightTime: flight?.arrival?.flightTime,
//           terminal: `${flight?.arrival?.terminal}`,
//         },
//       })
//     })
//     fareBasisCodes.forEach((fareBasisCode) => {
//       faresData.push({
//         key: uuid(),
//         fareBasisCode,
//         fareFamilyCode: flightSelected?.solutions?.offers?.fareFamily?.code,
//       })
//     })
//   })

//   return {
//     passengers: passengersPL,
//     flights: flightsData,
//     fares: faresData,
//     currency: flightsSelected[0]?.currency?.code,
//   }
// }

// export const fillDataFlightPrice = (
//   flightsSelected,
//   promoCode,
//   isConventionCode
// ) => ({
//   ...(promoCode.promoCode !== null &&
//     flightsSelected[0]?.promoCodeApplied &&
//     promoCode),
//   ...(promoCode.isConventionCode != null && isConventionCode),
//   numberOfAdults: flightsSelected[0]?.passengers?.adults,
//   numberOfChildren: flightsSelected[0]?.passengers?.children,
//   numberOfInfants: flightsSelected[0]?.passengers?.infants,
//   originDestinations: flightsSelected.map((data) => ({
//     od: data?.originDestinationKey,
//     flights: data.solutions.flights.map((flight, index) => {
//       const flightData = {
//         flightKey: flight?.flightKey,
//         airlineCode: flight?.marketingCarrier?.airlineCode,
//         flightNumber: flight?.marketingCarrier?.flightNumber,
//         departure: {
//           airportCode: flight?.departure?.airportCode,
//           date: flight?.departure?.flightDate,
//           time: flight?.departure?.flightTime,
//         },
//         arrival: {
//           airportCode: flight?.arrival?.airportCode,
//           date: flight?.arrival?.flightDate,
//           time: flight?.arrival?.flightTime,
//         },
//         fareComponent: {
//           classOfService: data?.solutions?.offers?.classOfService[index],
//           fareRefKey: data?.solutions?.offers?.fareRefKey[index],
//         },
//       }
//       if (data?.solutions?.offers?.fareFamily) {
//         flightData.priceClass = {
//           code: data?.solutions?.offers?.fareFamily?.code,
//           name: data?.solutions?.offers?.fareFamily?.name,
//         }
//       }
//       return flightData
//     }),
//   })),
// })

// const verifyMixedFareFamilies = (fareFamilyCode1, fareFamilyCode2) => {
//   if (fareFamilyCode1 !== fareFamilyCode2) {
//     const fareFamiliesExceptions = ["EFU", "BFU"]
//     if (
//       fareFamiliesExceptions.includes(fareFamilyCode1) &&
//       fareFamiliesExceptions.includes(fareFamilyCode2)
//     )
//       return false
//     return true
//   }
//   return false
// }

// export const verifyIfFlightHasMixedFareFamilies = (flightsSelected) => {
//   if (flightsSelected.length === 1) return false
//   const fareFamiliesCode = flightsSelected.map(
//     (flight) => flight.solutions?.offers?.fareFamily?.code
//   )
//   return verifyMixedFareFamilies(fareFamiliesCode[0], fareFamiliesCode[1])
// }

// export const verifyIfOriginDestinationBR = (flightsSelected) =>
//   flightsSelected.some(
//     (flight) =>
//       flight.origin.countryCode === "BR" ||
//       flight.destination.countryCode === "BR"
//   )

export const getFlightsDataForItinerary = (
  flightsData,
  originalSelectionData = {}
) => {
  if (flightsData) {
    const { saveSearch, ticket, formatMessage } = originalSelectionData
    let fareFamilyName = ""
    let retainedFlightIndex = null

    if (ticket && !isEmpty(saveSearch)) {
      retainedFlightIndex = saveSearch.findIndex(
        ({ isCheck }) => isCheck === false
      )

      if (retainedFlightIndex >= 0) {
        fareFamilyName = ticket.flights[retainedFlightIndex].selectedFareFamily

        if (fareFamilyName === "") {
          fareFamilyName = "NONE"
        }
      }
    }
    const { solutionsData, fareFamily } = flightsData
    const classesPerLeg =
      fareFamily?.classOfServiceList.length / solutionsData?.length
    return (
      solutionsData?.map((flightData, index) => {
        const fareFamilyIndex = index * classesPerLeg
        const { originDestination, disable, ...flightInfo } = flightData
        return {
          ...originDestination,
          ...(saveSearch && { isChecked: saveSearch[index].isCheck }),
          ...(ticket && {
            originalFareFamilyCode:
              ticket.flights[fareFamilyIndex].selectedFareFamily,
          }),
          travelerFaresDetails: fareFamily.travelerFaresDetails,
          disable,
          solutions: {
            ...flightInfo,
            fareFamilyCode:
              fareFamily.travelerFaresDetails[0].fareComponents.find(
                ({ solutionKey }) => solutionKey === flightData.key
              ).fareFamilyCode,
            classOfService: fareFamily.classOfServiceList[fareFamilyIndex].code,
            fareFamily:
              index === retainedFlightIndex
                ? formatMessage({
                    id: `resultPage.fares.${fareFamilyName}`,
                  })
                : formatMessage({ id: `resultPage.fares.${fareFamily.code}` }),
          },
        }
      }) || []
    )
  }
  return []
}

export const seatTypes = {
  15: "premiumSeat",
  16: "convenientSeat",
  17: "regularSeat",
  18: "exitRowSeat",
  23: "economyExtra",
  insurance: "totalInsurance",
}

// export const saveExchangeRates = (rate) => {
//   setCookiesMatchingArrays(["c_rsc"], [rate])
// }

const tripTypes = {
  rt: "summaryPage.flightTypeForCookie1",
  ow: "summaryPage.flightTypeForCookie2",
  mc: "summaryPage.flightTypeForCookie3",
  so: "summaryPage.flightTypeForCookie4",
}

const getTripType = (tripTypeParam, formatMessage) => {
  let tripType = tripTypes.ow
  if (tripTypeParam === "ROUNDTRIP") tripType = tripTypes.rt
  return formatMessage({ id: tripType }).replace("  <b></b>", "")
}

export const saveExchangeRates = (rate) => {
  setCookiesMatchingArrays(["c_rsc"], [rate])
}

// missing to add transactionID
export const setCookiesForPayments = (pnr, lastName) => {
  const expTime = moment().add(15, "minutes").format()
  const paymentsOrigin = {
    o: "exchanges",
    ru: `${
      process.env.PUBLIC_URL || process?.env?.REACT_APP_PUBLIC_URL
    }/confirmation/?pnr=${pnr}&lastName=${lastName}`,
  }
  setCookiesMatchingArrays(
    ["c_op", "exp_time"],
    [JSON.stringify(paymentsOrigin), expTime]
  )
}

const getFormatDate = (flightDate, flightTime) => {
  return moment(`${flightDate} ${flightTime}`).format("YYYY-MM-DDTHH:mm:ss")
}

const generateCurrency = ({ currency }) => ({ C: currency })

const generateFareInfo = (differenceFare, penalty) => ({
  DF: differenceFare,
  P: penalty || 0,
})

const searchLastFlightSelected = (lastOD) => {
  const solutionsAmount = lastOD?.solutions?.flights || []
  return solutionsAmount[solutionsAmount.length - 1]
}

const getDescription = (type, number) => {
  if (type === "ADT") {
    if (number === 1) {
      return "summaryPage.adult"
    }
    return "summaryPage.adults"
  }
  if (type === "CHD") {
    if (number === 1) {
      return "summaryPage.child"
    }
    return "summaryPage.children"
  }
  if (type === "INF") {
    if (number === 1) {
      return "summaryPage.infant"
    }
    return "summaryPage.infants"
  }
  return "summaryPage.adult"
}

const generatePayInformation = (
  ticket,
  formatMessage,
  subTotal,
  taxTotal,
  travelerFaresDetails,
  total
) => {
  return {
    AFL: travelerFaresDetails.map((afl) => ({
      TC: afl.travelerType,
      D: formatMessage({
        id: getDescription(afl.travelerType, afl.numberOfTravelers),
      }),
      PN: afl.numberOfTravelers,
      A: afl.totalAmount,
    })),
    TT: getTripType(ticket.tripType, formatMessage),
    S: subTotal,
    TF: taxTotal,
    T: total,
  }
}

const getLegsResumed = (flightsSelected) => {
  const legs = []
  flightsSelected.map((fs) => {
    const fareClass = fs.solutions.fareFamily
    return legs.push(
      fs.solutions.flights.map((leg) => {
        return {
          D: {
            C: leg.departure.airportCode,
            DT: getFormatDate(
              leg.departure.flightDate,
              leg.departure.flightTime
            ),
            gmt: "-05:00",
          },
          A: {
            C: leg.arrival.airportCode,
            DT: getFormatDate(leg.arrival.flightDate, leg.arrival.flightTime),
            gmt: "-05:00",
          },
          EI: {
            FC: fareClass,
            CC:
              leg.operatingCarrier === null
                ? leg.marketingCarrier.airlineCode
                : leg.operatingCarrier.airlineCode,
            S:
              leg.operatingCarrier === null
                ? leg.marketingCarrier.airlineName
                : leg.operatingCarrier.airlineName,
          },
        }
      })
    )
  })
  return flattenDeep(legs)
}

const calculateSegmenteNumber = (flightsSelected) => {
  const segmentNumber = flightsSelected.map((fs) => fs.solutions.flights.length)
  return segmentNumber.reduce((sum, value) => sum + value, 0)
}

const getTraverlesInfo = (passengerInformation) => {
  const traverlesInfo = []
  passengerInformation.map((pass, idx) => {
    return traverlesInfo.push({
      id: idx,
      first_name: pass.firstName,
      last_name: pass.surname,
      date_of_birth: moment(pass.dateOfBirth, "YYYY/MM/DD").format(
        "MM/DD/YYYY"
      ),
    })
  })
  return traverlesInfo
}

const getInfoDestination = (flightsSelected, isMultiCity, flightMultiCity) =>
  isMultiCity
    ? {
        N: flightMultiCity.destination.city,
        I: flightMultiCity.destination.destinationImageURL,
      }
    : {
        N: flightsSelected[0].destination.city,
        I: flightsSelected[0].destination.destinationImageURL,
      }

const getTripInfoForPayLayer = (flightsSelected) => {
  const isMultiCity = false // pending to know this flag
  const lastFlight = searchLastFlightSelected(
    flightsSelected[flightsSelected.length - 1]
  )
  return {
    D: getInfoDestination(flightsSelected, isMultiCity, {}),
    SD: getFormatDate(
      flightsSelected[0].solutions.flights[0].departure.flightDate,
      flightsSelected[0].solutions.flights[0].departure.flightTime
    ),
    ED: getFormatDate(
      lastFlight.arrival.flightDate,
      lastFlight.arrival.flightTime
    ),
    SN: calculateSegmenteNumber(flightsSelected),
    L: getLegsResumed(flightsSelected),
  }
}

export const generatePayReservationInfo = (
  formatMessage,
  subTotal,
  taxTotal,
  ticket,
  flightItineraty,
  differenceFare,
  travelerFaresDetails,
  penalty,
  total,
  passengerInformation,
  storeFront
) => ({
  PI: generatePayInformation(
    ticket,
    formatMessage,
    subTotal,
    taxTotal,
    travelerFaresDetails,
    total
  ),
  TI: getTripInfoForPayLayer(flightItineraty),
  C: generateCurrency(ticket),
  FI: generateFareInfo(differenceFare, penalty),
  ...((storeFront === "CA" || storeFront === "US") && {
    PX: getTraverlesInfo(passengerInformation),
  }),
})

export const persistReservationInfo = (pnr, reservationInfo) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const plainReservationInfo = Rabbit.encrypt(
    JSON.stringify(reservationInfo),
    date
  ).toString()
  setCookiesMatchingArrays(["c_ri"], [plainReservationInfo])
}

export const saveGoBackURL = (pnr, url) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const urlGoBack = Rabbit.encrypt(
    JSON.stringify(`${process.env.PUBLIC_URL}${url}`),
    date
  ).toString()
  setCookiesMatchingArrays(["gobackurl"], [urlGoBack])
}
