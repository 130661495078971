import React, { useRef, useEffect } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Popper from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"
import TrapFocus from "@material-ui/core/Unstable_TrapFocus"
import omit from "lodash/omit"
import PropTypes from "prop-types"

import SWOOSH from "../../assets/images/swoosh.svg"

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  paper: ({ color, shine }) => ({
    width: shine === true ? "auto" : "auto",
    padding: shine ? 0 : 16,
    overflow: "auto",
    boxShadow: `5px 5px 17px 5px  ${theme.palette.transparent.shadow}`,
    background:
      color === "primary" ? theme.palette.gradients.gradientDark : null,
    position: "relative",
    height: shine === true ? "auto" : "auto",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      margin: "0 auto",
    },
  }),
  shine: {
    backgroundImage: `url(${SWOOSH})`,
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 300,
    zIndex: 0,
  },
  popper: ({ shine }) => ({
    zIndex: 1,
    '&[x-placement*="top"]': {
      marginBottom: 10,
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "calc((100% / 2) - 1.5em)",
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderRightColor: theme.palette.transparent.main,
        borderLeftColor: theme.palette.transparent.main,
        borderBottomColor: theme.palette.transparent.main,
      },
    },
    '&[x-placement*="top-start"] $arrow': {
      left: 15,
    },
    '&[x-placement*="top-end"] $arrow': {
      left: "unset",
      right: 15,
    },
    '&[x-placement*="right"]': {
      marginLeft: 10,
    },
    '&[x-placement*="right"] $arrow': {
      top: "calc((100% / 2) - 7px)",
      left: 0,
      marginLeft: "-0.9em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderLeftColor: theme.palette.transparent.main,
        borderBottomColor: theme.palette.transparent.main,
        borderTopColor: theme.palette.transparent.main,
      },
    },
    '&[x-placement*="right-start"] $arrow': {
      top: 15,
    },
    '&[x-placement*="right-end"] $arrow': {
      top: "unset",
      bottom: 15,
    },
    '&[x-placement*="bottom"]': {
      marginTop: shine ? 20 : 40,
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      right: "calc((100% / 2) - 1.5em)",
      marginTop: "-11px",
      width: "4em",
      height: "2em",
      "&::before": {
        borderWidth: "0 2em 2em 2em",
        borderRightColor: theme.palette.transparent.main,
        borderLeftColor: theme.palette.transparent.main,
        borderTopColor: theme.palette.transparent.main,
      },
    },
    '&[x-placement*="bottom-start"] $arrow': {
      left: 15,
    },
    '&[x-placement*="bottom-end"] $arrow': {
      bottom: "unset",
      right: shine ? 30 : 50,
      left: "unset !Important",
      [theme.breakpoints.down("sm")]: {
        right: 20,
      },
      [theme.breakpoints.down("xs")]: {
        right: "25%",
      },
    },
    '&[x-placement*="bottom-end"]': {
      marginRight: shine && 10,
    },
    '&[x-placement*="left"]': {
      marginRight: 10,
    },
    '&[x-placement*="left"] $arrow': {
      top: "calc((100% / 2) - 7px)",
      right: 0,
      marginRight: "-0.9em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderRightColor: theme.palette.transparent.main,
        borderBottomColor: theme.palette.transparent.main,
        borderTopColor: theme.palette.transparent.main,
      },
    },
    '&[x-placement*="left-start"] $arrow': {
      top: 15,
    },
    '&[x-placement*="left-end"] $arrow': {
      top: "unset",
      bottom: 15,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      transform: "translate3d(0, 60px, 0px) !important",
    },
  }),
  arrow: ({ color }) => ({
    position: "absolute",
    fontSize: 7,
    width: 7,
    height: 14,
    "&::before": {
      borderColor:
        color === "primary"
          ? `${theme.palette.secondary.dark}`
          : `${theme.palette.common.white}`,
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  }),
}))

const PopperCo = ({
  anchorEl,
  arrow,
  children,
  flip,
  open,
  isTrapEnabled,
  ariaLabel,
  popperModifiers,
  ...props
}) => {
  const classes = useStyles(props)
  const wereFocus = useRef(false)
  const arrowRef = useRef()
  const newProps = omit(props, ["shine", "arrowClassName"])
  const { arrowClassName } = props

  const getDoc = () => document

  useEffect(() => {
    if (!open && anchorEl) {
      setTimeout(
        (parent) => {
          const { firstElementChild } = parent || {}
          if (firstElementChild && wereFocus.current) {
            firstElementChild.focus()
            wereFocus.current = false
          }
        },
        0,
        anchorEl
      )
    } else if (open) {
      wereFocus.current = true
    }
  }, [open, anchorEl])

  if (!anchorEl) {
    return null
  }

  return (
    <Popper
      {...newProps}
      anchorEl={anchorEl}
      open={open}
      className={clsx(newProps.className, classes.popper)}
      disablePortal
      modifiers={{
        flip: {
          enabled: flip,
        },
        preventOverflow: {
          enabled: true,
          padding: 0,
          boundariesElement: "scrollParent",
        },
        offset: {
          enabled: true,
        },
        arrow: {
          enabled: arrow,
          element: arrow && arrowRef.current,
        },
        ...popperModifiers,
      }}
    >
      <TrapFocus open={open} isEnabled={isTrapEnabled} getDoc={getDoc}>
        <Paper aria-label={ariaLabel} tabIndex="-1" className={classes.paper}>
          <div style={{ zIndex: 1, position: "relative" }}>{children}</div>
        </Paper>
      </TrapFocus>
      {arrow ? (
        <span className={clsx(arrowClassName, classes.arrow)} ref={arrowRef} />
      ) : null}
    </Popper>
  )
}

PopperCo.propTypes = {
  /**
   * It's used to set the position of the popper. The return value will passed as the reference object of the Popper instance.
   */
  anchorEl: PropTypes.instanceOf(HTMLElement),

  /**
   * Show WCAG
   */
  ariaLabel: PropTypes.string,
  /**
   * Show or hide the arrow
   */

  arrow: PropTypes.bool,
  /**
   * Class name for the arrow
   */

  arrowClassName: PropTypes.string,
  /**
   * Popper render function or node.
   */
  children: PropTypes.node,
  /**
   * Popper placement.
   * */
  placement: PropTypes.string,
  /**
   * `Flip` (flip the popper’s placement when it starts to overlap its reference element)
   * */
  flip: PropTypes.bool,
  /**
   * has two the type values of a string the first is 'white' than set the popper the white color, the second parameter is 'primary' that is applied a blue background
   */
  color: PropTypes.string,
  /**
   * show or hide an image for the blue background
   */
  shine: PropTypes.bool,

  /**
   * Show or hide the popper modal
   */
  open: PropTypes.bool.isRequired,

  /**
   * this function must return a boolean
   * indicates when to trap the focus, helpful if you want to focus only elements inside the popper.
   * if you are using things like selects (which have their own focus trap) it´s recommended to control
   * when those element are open and return false when they are
   */
  isTrapEnabled: PropTypes.func,

  popperModifiers: PropTypes.shape({
    preventOverflow: PropTypes.shape({
      enabled: PropTypes.bool,
      padding: PropTypes.number,
      boundariesElement: PropTypes.oneOf([
        "scrollParent",
        "viewport",
        "disabled",
        "window",
      ]),
    }),
  }),
}

PopperCo.defaultProps = {
  placement: "bottom",
  anchorEl: null,
  arrow: true,
  arrowClassName: "",
  children: null,
  flip: true,
  color: "white",
  shine: false,
  ariaLabel: null,
  isTrapEnabled: () => false,
  popperModifiers: {},
}

export default PopperCo
