import React, { useLayoutEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

import Topbar from "./components/topBar"

import { generalActions } from "../../../store/general/generalSlice"
import { flightsActions } from "../../../store/flight/flightSlice"
import { deleteCookiesMatchingArrays, getCookie } from "../../../utils/auth"
import ViewMoreDetails from "../../commons/components/viewMoreDetails"

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  main: {
    paddingTop: ({ cookieOrigin }) =>
      cookieOrigin?.toLowerCase() === "app" ? 0 : 80,
  },
}))

const Main = ({
  headerVariant,
  showEditButton,
  showPrice,
  showInfo,
  language,
  setLang,
  loggedIn,
  userInfo,
  bookingURL,
  bookingParams,
  totalPriceFlightsSelected,
  isOpenViewDetails,
  hideFlightModalDetails,
  tripType,
  withoutModal,
  ...props
}) => {
  const { children } = props
  const classes = useStyles({ cookieOrigin: getCookie("origin") })
  const history = useHistory()
  const urlPathName = history.location.pathname.split("/")[1]

  useLayoutEffect(() => {
    if (
      urlPathName !== "" &&
      urlPathName !== "summary" &&
      urlPathName !== "resultPage" &&
      getCookie("exp_time")
    ) {
      deleteCookiesMatchingArrays(["exp_time"])
    }
  }, [urlPathName])

  // const handleChangeCountryAndLanguage = (newCountry, newLanguage) => {
  //   setLang(newLanguage)
  // }

  return (
    <div className={classes.root}>
      <Topbar
        searchDone={false}
        origin="PTY"
        destination="IAH"
        tripType={0}
        total={15}
        currency="USD"
        country="PT"
        language={language}
        loggedIn={loggedIn}
        userInfo={userInfo}
        variant={headerVariant}
        showEditButton={showEditButton}
        showPrice={showPrice}
        countries={[]}
        onChangeCountryAndLanguage={() => {}}
        bookingURL={bookingURL}
        showInfo={showInfo}
      />
      <main className={classes.main}>{children}</main>
      {!withoutModal && (
        <ViewMoreDetails
          isOpen={isOpenViewDetails}
          handleClose={hideFlightModalDetails}
        />
      )}
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  headerVariant: PropTypes.string,
  language: PropTypes.string,
  setLang: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any),
  bookingURL: PropTypes.string,
  bookingParams: PropTypes.objectOf(PropTypes.any),
  totalPriceFlightsSelected: PropTypes.number,
  isOpenViewDetails: PropTypes.bool.isRequired,
  hideFlightModalDetails: PropTypes.func.isRequired,
  showEditButton: PropTypes.bool,
  showPrice: PropTypes.bool,
  showInfo: PropTypes.bool,
  tripType: PropTypes.number,
  withoutModal: PropTypes.bool,
}

Main.defaultProps = {
  children: null,
  headerVariant: "gradient100",
  language: null,
  userInfo: {},
  showEditButton: true,
  showPrice: true,
  tripType: 0,
  showInfo: true,
  bookingURL: "",
  bookingParams: {},
  totalPriceFlightsSelected: 0,
  withoutModal: false,
}

const mapsStateToProps = ({
  general,
  auth: { loggedIn, passengers },
  flight: { flightDetailsModal },
}) => ({
  language: general.lang,
  loggedIn,
  userInfo: passengers.find((passenger) => passenger?.mainPassenger),
  isOpenViewDetails: flightDetailsModal.isVisible,
})

export default connect(mapsStateToProps, {
  setLang: generalActions.setLang,
  hideFlightModalDetails: flightsActions.hideFlightDetailsData,
})(Main)
