import React from "react"
import PropTypes from "prop-types"
import theme from "@bit/vibenitez.copa-components-library.theme"

import useStyles from "./styles"

function SpinnerCo({ width, heigth, color }) {
  const classes = useStyles({ width, heigth, color })
  return <div className={classes.spinner} />
}

SpinnerCo.propTypes = {
  width: PropTypes.number,
  heigth: PropTypes.number,
  color: PropTypes.string,
}

SpinnerCo.defaultProps = {
  width: 20,
  heigth: 20,
  color: theme.palette.grey["200"],
}

export default SpinnerCo
