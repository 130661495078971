import { makeStyles, withStyles } from "@material-ui/styles"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      padding: "78px 22px",
      alignItems: "center",
      "& > .row-content": {
        flexGrow: 0,
        padding: "38px 80px",
        paddingBottom: 10,
        maxWidth: "50%",
        flexBasis: "50%",
        position: "relative",
        "&:first-child::after": {
          content: "''",
          width: 1,
          position: "absolute",
          right: 0,
          top: 0,
          border: "none",
          height: "100%",
          margin: 0,
          flexShrink: 0,
          backgroundColor: theme.palette.dividerLight,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "78px 0px",
        paddingTop: 120,
        "& > .row-content": {
          padding: "8px 62px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "72px 52px",
        paddingTop: 54,
        "& > .row-content": {
          padding: "0px 0px",
          paddingTop: 38,
          paddingBottom: 0,
          maxWidth: "100%",
          flexBasis: "100%",
          "&:first-child::after": {
            content: "none",
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: "52px 28px",
      },
    },
  },
  footer: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "&>button": {
        width: "100%",
        margin: 0,
        "&:first-of-type": {
          marginBottom: theme.spacing(3),
        },
      },
      "&> :not(:first-child)": {
        margin: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}))

export const ButtonOutlined = withStyles((theme) => ({
  root: {
    size: 16,
    borderRadius: 50,
    fontWeight: 500,
    height: 72,
    width: 368,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: 272,
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      height: 52,
    },
    "&:first-child": {
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(3),
      },
    },
    "&:last-child": {
      [theme.breakpoints.up("sm")]: {
        width: 264,
        padding: "12px 16px",
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0,
      },
    },
  },
  containedSecondary: {
    background: theme.palette.gradients[500],
  },
}))(Button)

export default useStyles
