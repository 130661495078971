const initialState = {
  fetching: false,
  originKeyToDestinationSearch: "",
  origins: [],
  destinations: [],
  errorOrigin: false,
  errorDestination: false,
  airportsMulticity: [
    { id: 0, destinations: [] },
    { id: 1, destinations: [] },
  ],
  originSelected: undefined,
  destinationSelected: undefined,
}

export default initialState
