const setIconCode = (code) => {
  let iconCode = "check"
  if (code === "1" || code === "basicInfo") iconCode = "price"
  if (code === "2" || code === "classicInfo") iconCode = "check"
  if (code === "3") iconCode = "cross"

  return iconCode
}

// eslint-disable-next-line import/prefer-default-export
export { setIconCode }
