import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { SvgIcon } from "@material-ui/core"
import { Formik, Form } from "formik"
import InputAdornment from "@material-ui/core/InputAdornment"
import isEmpty from "lodash/isEmpty"
import theme from "@bit/vibenitez.copa-components-library.theme"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import { ReactComponent as ErrorFill } from "../../icons/Error-Fill.svg"
import { ReactComponent as Check } from "../../icons/Check.svg"
import InputCo from "../../input/inputCo"

import definedMessages from "./message"
import { logInValidationSchema, initValues } from "./validations"

const IconError = <SvgIcon component={ErrorFill} />

const IconCheck = () => (
  <InputAdornment position="end">
    <SvgIcon
      style={{ color: theme.palette.alternative.gold }}
      component={Check}
    />
  </InputAdornment>
)

const LogInForm = ({ formatMessage, pending, initiateAuth, logInError }) => {
  const userNameInputRef = useRef()

  useEffect(() => {
    if (userNameInputRef.current) {
      userNameInputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (userNameInputRef.current && !isEmpty(logInError)) {
      userNameInputRef.current.focus()
    }
  }, [logInError])

  return (
    <Formik
      initialValues={initValues}
      validationSchema={logInValidationSchema(formatMessage)}
      onSubmit={(values, { resetForm }) => {
        if (!pending) {
          initiateAuth(values)
        }
        resetForm()
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form
          data-cy="loginbox-form"
          noValidate
          autoComplete="off"
          id="loginbox-form"
        >
          <InputCo
            id="userName"
            name="userName"
            variant="filled"
            value={values.userName}
            onBlur={handleBlur}
            handleChange={handleChange}
            hasErrors={touched.userName && !isEmpty(errors.userName)}
            label={formatMessage(definedMessages.credentialsLabel)}
            placeholder={formatMessage(definedMessages.credentialsPlaceholder)}
            helperText={
              touched.userName && !isEmpty(errors.userName)
                ? errors.userName
                : ""
            }
            component={IconError}
            inputProps={{
              "aria-label":
                touched.userName && !isEmpty(errors.userName)
                  ? formatMessage(definedMessages.credentialsLabelWCAG)
                  : `${formatMessage(
                      definedMessages.inlineErrorRequiredField
                    )}. ${formatMessage(definedMessages.credentialsLabelWCAG)}`,
              "data-cy": "login-username-field",
            }}
            inputRef={userNameInputRef}
            autoComplete="userName"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={
              touched.userName && isEmpty(errors.userName)
                ? { endAdornment: IconCheck() }
                : {}
            }
          />

          <div className="forgotPass--container">
            <a
              data-cy="loginbox-forgotExternalLink"
              href={formatMessage(definedMessages.forgotExternalLink)}
              target="_blank"
              rel="noopener noreferrer"
              className="gray--text"
              aria-label={formatMessage(definedMessages.fortgotPasswordWCAG)}
            >
              {formatMessage(definedMessages.forgotLink)}
            </a>
            <InputCo
              id="password"
              name="password"
              variant="filled"
              value={values.password}
              onBlur={handleBlur}
              handleChange={handleChange}
              hasErrors={touched.password && !isEmpty(errors.password)}
              label={formatMessage(definedMessages.passwordLabel)}
              placeholder={formatMessage(
                definedMessages.passwordfieldPlaceholder
              )}
              helperText={
                touched.password && !isEmpty(errors.password)
                  ? errors.password
                  : ""
              }
              component={IconError}
              inputProps={{
                "aria-label":
                  touched.password && !isEmpty(errors.password)
                    ? formatMessage(definedMessages.passwordLabelWCAG)
                    : `${formatMessage(
                        definedMessages.inlineErrorRequiredField
                      )}. ${formatMessage(definedMessages.passwordLabelWCAG)}`,
                type: "password",
                "data-cy": "login-password-field",
              }}
              autoComplete="current-password"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={
                touched.password && isEmpty(errors.password)
                  ? { endAdornment: IconCheck() }
                  : {}
              }
            />
          </div>
          <ButtonCo
            severity="info"
            variant="contained"
            color="primary"
            size="large"
            aria-label={formatMessage(definedMessages.loginButtonWCAG)}
            type="submit"
            data-cy="login-submit"
            loading={pending}
          >
            {formatMessage(definedMessages.loginCTA)}
          </ButtonCo>
        </Form>
      )}
    </Formik>
  )
}

LogInForm.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  initiateAuth: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  logInError: PropTypes.string.isRequired,
}

export default LogInForm
