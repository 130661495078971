import Reader from "crocks/Reader"
import { sanitizePNR } from "./sanitizeData"

export const processRedirect = (url, pnr, surName) =>
  Reader((location) => {
    const finalUrl = `${url}/:pnr/${surName.toUpperCase()}`.replace(
      ":pnr",
      sanitizePNR(pnr)
    )
    location.assign(finalUrl)
  })

export const redirectToMyTrips = (pnr, surName) =>
  Reader((location) => {
    if (surName != null || pnr != null) {
      const url =
        `${process.env.REACT_APP_MY_TRIPS_URL}/trip-detail/:pnr/${surName}`.replace(
          ":pnr",
          sanitizePNR(pnr)
        )
      location.replace(url)
    } else {
      const url = `${process.env.REACT_APP_MY_TRIPS_URL}/trip-detail/`
      location.replace(url)
    }
  })
