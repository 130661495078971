import React, { cloneElement } from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import useStyles from "./styles"

const ModeSelector = ({ icon, label, description }) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.box}
    >
      <div className={classes.iconContainer}>
        {cloneElement(icon, { className: classes.icon })}
      </div>
      <Typography variant="h4" align="center">
        {label}
      </Typography>
      <Typography variant="caption" align="center" className={classes.caption}>
        {description}
      </Typography>
    </Box>
  )
}

ModeSelector.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ModeSelector
