import { backToOriginApplication } from "../../utils/generalUtils"

const languageSelector = (state) => state.general.lang

const criticalError = (payload) => {
  const isCritical = payload.status === 500 || payload.status === 0
  if (
    isCritical === false &&
    payload.status !== 0 &&
    payload?.service !== "fare-reshop"
  ) {
    backToOriginApplication(payload.pnr, payload.lastName)
  }
  return isCritical
}

export { criticalError, languageSelector }
