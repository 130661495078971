import { SHA256, Rabbit } from "crypto-js"
import moment from "moment"
import LogRocket from "logrocket"
import flattenDeep from "lodash/flattenDeep"
import {
  setCookiesMatchingArrays,
  getCookie,
  deleteCookiesMatchingArrays,
} from "./auth"
import { getDate } from "./generalUtils"
import { getCurrencyOption } from "./numbers"

const calcPaxNumber = (passengers) => {
  const count = passengers
    .map((p) => p.total)
    .reduce((sum, value) => sum + value, 0)
  return count
}

const insuranceInfoObj = (typeCode, counter, sumPrice) => ({
  typeCode,
  counter,
  sumPrice,
})

const getFormatDate = (flightDate, flightTime) => {
  return moment(`${flightDate} ${flightTime}`).format("YYYY-MM-DDTHH:mm:ss")
}

const getLegsResumed = (flightsSelected) => {
  const legs = []
  flightsSelected.map((fs) => {
    return legs.push(
      fs.solutions.flights.map((leg) => {
        return {
          D: {
            C: leg.departure.airportCode,
            DT: getFormatDate(
              leg.departure.flightDate,
              leg.departure.flightTime
            ),
            gmt: "-05:00",
          },
          A: {
            C: leg.arrival.airportCode,
            DT: getFormatDate(leg.arrival.flightDate, leg.arrival.flightTime),
            gmt: "-05:00",
          },
        }
      })
    )
  })
  return flattenDeep(legs)
}

const calculateSegmenteNumber = (flightsSelected) => {
  const segmentNumber = flightsSelected.map((fs) => fs.solutions.flights.length)
  return segmentNumber.reduce((sum, value) => sum + value, 0)
}

const tripTypes = {
  rt: "summaryItinerary.priceBreakdown.flightTypeForCookie1",
  wa: "summaryItinerary.priceBreakdown.flightTypeForCookie2",
  mc: "summaryItinerary.priceBreakdown.flightTypeForCookie3",
  so: "summaryItinerary.priceBreakdown.flightTypeForCookie4",
}

const getTripType = (bookingParams, formatMessage) => {
  let tripType = tripTypes.wa
  if (bookingParams.roundtrip) tripType = tripTypes.rt
  else if (bookingParams.advanced_air_search) {
    if (
      bookingParams.stopoverNights !== null &&
      bookingParams.stopoverLegNumber !== null
    )
      tripType = tripTypes.so
    else tripType = tripTypes.mc
  }
  return formatMessage({ id: tripType }).replace("  <b></b>", "")
}

const searchLastFlightSelected = (lastOD) => {
  const solutionsAmount = lastOD?.solutions?.flights || []
  return solutionsAmount[solutionsAmount.length - 1]
}

const generatePayInformation = (
  flight,
  passengers,
  formatMessage,
  subTotal,
  taxTotal
) => ({
  AFL: passengers
    .filter((afl) => afl.total > 0)
    .map((afl) => ({
      TC: afl.type,
      D: formatMessage(
        { id: afl.description.id },
        { count: afl.description.total }
      ),
      PN: afl.total,
      A: afl.price,
    })),
  TT: getTripType(flight.bookingParams, formatMessage),
  S: subTotal,
  TF: taxTotal,
})

const isMultiCityFlights = () => {
  const bookingParams = localStorage.getItem("bookingURL")
  const urlParams = new URLSearchParams(bookingParams)
  const isMultiCity = urlParams.get("advanced_air_search") === "true"
  return isMultiCity
}

const getTripInfoForMultiCity = (flightsSelected) => {
  const ODFlight = flightsSelected.find((flight, key) => {
    const lastFlights = flight.solutions.flights
    const nextFlights = flightsSelected[key + 1]?.solutions?.flights
    const lastArrival = moment(
      `${lastFlights[lastFlights.length - 1].arrival?.flightDate} ${
        lastFlights[lastFlights.length - 1].arrival?.flightTime
      }`
    )
    const nextDeparture = nextFlights
      ? moment(
          `${nextFlights[0].departure?.flightDate} ${nextFlights[0].departure?.flightTime}`
        )
      : lastArrival
    const duration = moment
      .duration(moment(nextDeparture).diff(lastArrival))
      .asHours()
    return duration > 24
  })
  if (!ODFlight) {
    return flightsSelected[
      flightsSelected[0].origin.code ===
      flightsSelected[flightsSelected.length - 1].destination.code
        ? flightsSelected.length - 2
        : flightsSelected.length - 1
    ]
  }
  return ODFlight
}

const getInfoDestination = (flightsSelected, isMultiCity, flightMultiCity) =>
  isMultiCity
    ? {
        N: flightMultiCity.destination.city,
        I: flightMultiCity.destination.destinationImageURL,
      }
    : {
        N: flightsSelected[0].destination.city,
        I: flightsSelected[0].destination.destinationImageURL,
      }

const getTripInfoForPayLayer = ({ flightsSelected }) => {
  const isMultiCity = isMultiCityFlights()
  const flightMultiCity = isMultiCity
    ? getTripInfoForMultiCity(flightsSelected)
    : {}
  const lastFlight = searchLastFlightSelected(
    isMultiCity ? flightMultiCity : flightsSelected[flightsSelected.length - 1]
  )
  return {
    D: getInfoDestination(flightsSelected, isMultiCity, flightMultiCity),
    SD: getFormatDate(
      flightsSelected[0].solutions.flights[0].departure.flightDate,
      flightsSelected[0].solutions.flights[0].departure.flightTime
    ),
    ED: getFormatDate(
      lastFlight.arrival.flightDate,
      lastFlight.arrival.flightTime
    ),
    SN: calculateSegmenteNumber(flightsSelected),
    L: getLegsResumed(flightsSelected),
  }
}

const generateCurrency = ({ currencyCode }) => ({ C: currencyCode })

const generatePayReservationInfo = (
  flight,
  fareGroup,
  passengers,
  formatMessage,
  subTotal,
  taxTotal
) => ({
  PI: generatePayInformation(
    flight,
    passengers,
    formatMessage,
    subTotal,
    taxTotal
  ),
  TI: getTripInfoForPayLayer(flight),
  C: generateCurrency(fareGroup),
})

const persistReservationInfo = (pnr, reservationInfo) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const plainReservationInfo = Rabbit.encrypt(
    JSON.stringify(reservationInfo),
    date
  ).toString()
  setCookiesMatchingArrays(["c_ri"], [plainReservationInfo])
}

const setPricelockAmountCookie = (pnr, total) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const totalPriceLock = Rabbit.encrypt(JSON.stringify(total), date).toString()
  setCookiesMatchingArrays(["c_plv"], [totalPriceLock])
}

const retriveTypeOfSeats = (seats) => {
  if (!seats) return []
  return seats.seatsSelected.map((s) => ({
    tp: s.typeCode,
    ct: s.counter,
    sp: s.sumPrice,
  }))
}

const retrieveSeatsDetails = (seats) => {
  if (!seats) return []
  return seats.map((s) => ({
    bs: s.price.base,
    t: s.price.charges,
    fl: s.flightNumber,
    psk: s.passengerInfo.key,
    dc: s.dcsPassengerRefNumber,
    sn: s.seatNumber,
    e: s.isExitRow,
    c: s.isConvenientRow,
    r: s.isRegularSeat,
    p: s.isPremiumRow,
    co: s.code,
    sc: s.subCode,
    f: s.paxInfo
      .filter((p) => p.key === s.passengerInfo.key)
      .map((f) => ({ f: f.free })),
  }))
}

const setPayExtrasInformation = (pnr, seatsSelectedInfo) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const summaryValues = Rabbit.encrypt(
    JSON.stringify({
      tx: seatsSelectedInfo.taxes,
      subt: seatsSelectedInfo.subtotal,
      tt: seatsSelectedInfo.total,
    }),
    date
  ).toString()
  const summaryTypeOfSeats = Rabbit.encrypt(
    JSON.stringify(retriveTypeOfSeats(seatsSelectedInfo.typeInfoSeats)),
    date
  ).toString()
  const seatsSummary = Rabbit.encrypt(
    JSON.stringify(retrieveSeatsDetails(seatsSelectedInfo.seats)),
    date
  ).toString()

  return setCookiesMatchingArrays(
    ["c_v", "c_s", "c_t"],
    [summaryValues, seatsSummary, summaryTypeOfSeats]
  )
}

const setSurnamePassenger = (passengerInformation) => {
  const surName = passengerInformation[0]?.surname
  setCookiesMatchingArrays(["c_sn"], [surName])
}

const setCookiesForPayments = (transactionID) => {
  const expTime = moment().add(15, "minutes").format()
  const paymentsOrigin = {
    o: "ibe",
    ru: `${process.env.PUBLIC_URL}/confirmation/${transactionID}`,
  }
  setCookiesMatchingArrays(
    ["c_op", "exp_time"],
    [JSON.stringify(paymentsOrigin), expTime]
  )
}

const getPassengerCookie = () => {
  return getCookie("c_aet")
}

const saveCookiePromocode = (promocode, pnr) => {
  try {
    const date = SHA256(`${pnr}${getDate()}`).toString()
    const pc = Rabbit.encrypt(JSON.stringify(promocode), date).toString()
    return setCookiesMatchingArrays(["c_pc"], [pc])
  } catch (e) {
    return false
  }
}

const saveCookiePassenger = (passengerInfo, pnr) => {
  try {
    const passengertoJson = JSON.parse(passengerInfo)
    const date = SHA256(`${pnr}${getDate()}`).toString()
    const passengerInformation = Rabbit.encrypt(
      JSON.stringify({
        t: passengertoJson.t,
        a: passengertoJson.a,
        e: passengertoJson.e,
      }),
      date
    ).toString()
    return setCookiesMatchingArrays(["c_aet"], [passengerInformation])
  } catch (e) {
    return false
  }
}

const getPayFlag = () => {
  const payFlag = getCookie("c_pai")
  deleteCookiesMatchingArrays(["c_pai"])
  if (!payFlag || payFlag === "") return false
  return payFlag === "false"
}

const isDifferentCurrency = (storeFront, currencyPNR) => {
  return getCurrencyOption(storeFront).accountSymbol !== currencyPNR
}

const setInsuranceErrorCookie = () => {
  setCookiesMatchingArrays(["c_if"], [true])
}

const setAssignmentWarningsCookie = (pnr) => {
  setCookiesMatchingArrays(["c_saf"], [pnr])
}

const setLogRocketID = (passengers, pnr) => {
  LogRocket.identify(pnr, {
    name: `${passengers[0]?.name} ${passengers[0]?.surname}`,
    email: passengers[0]?.mail,
  })
}

const validateConfirmation = (pnr) => {
  try {
    const redirectionCookie = getCookie("c_puc")
    const parsedCookie = JSON.parse(redirectionCookie)
    if (parsedCookie.pnr === pnr) return parsedCookie.url
    return false
  } catch (e) {
    return false
  }
}

export {
  calcPaxNumber,
  insuranceInfoObj,
  generatePayReservationInfo,
  persistReservationInfo,
  setPayExtrasInformation,
  calculateSegmenteNumber,
  setSurnamePassenger,
  setCookiesForPayments,
  getPayFlag,
  setPricelockAmountCookie,
  isDifferentCurrency,
  isMultiCityFlights,
  getTripInfoForMultiCity,
  searchLastFlightSelected,
  saveCookiePassenger,
  getPassengerCookie,
  saveCookiePromocode,
  setInsuranceErrorCookie,
  setAssignmentWarningsCookie,
  setLogRocketID,
  validateConfirmation,
}
