import cloneDeep from "lodash/cloneDeep"
import moment from "moment"
import { getCookie, setCookiesMatchingArrays } from "../../utils/auth"

export const saveSearch = (state) => state.flight.saveSearch

export const from24To12Time = (time24) => {
  const newTime = time24.format("hh:mma")
  return newTime
}

export const journeyTimeFormat = (journeyTime) =>
  journeyTime.replace("PT", "").replace("H", "h ").replace("M", "m")

export const cityCode = (city, code) => {
  return `${city} (${code})`
}

export const dateCommaDDmmYYY = (date) => {
  // Format [day, month, year]
  return (
    date &&
    date
      .split("-")
      .reverse((item) => item)
      .join(", ")
  )
}

export const addExtraDayComparison = (
  arrivalDate,
  departureDate,
  destinationTime
) => {
  if (arrivalDate > departureDate) {
    const diff = arrivalDate.diff(departureDate, "days")
    if (diff) destinationTime = `${destinationTime}+${diff}`
  }
  return destinationTime
}

export const getFlightSelectedDataModel = () =>
  cloneDeep({
    originDestinationKey: "OD1",
    origin: {
      code: "",
      departureDate: "",
      countryCode: "",
      city: "",
    },
    destination: {
      code: "",
      destinationImageURL: "",
      countryCode: "",
      city: "",
    },
    currency: {
      code: "",
      decimals: 0,
    },
    passengers: {
      adults: 1,
      children: 0,
      infants: 0,
    },
    solutions: {
      key: "",
      numberOfLayovers: 0,
      journeyTime: "",
      lowestPrice: 0,
      lowestPriceCoachCabin: 0,
      lowestPriceBusinessCabin: 0,
      originalLowestPriceCoachCabin: 0,
      originalLowestPriceBusinessCabin: 0,
      economyBasicPriceDiff: 0,
      flights: [
        {
          flightKey: "",
          flightRefKey: "",
          layoverTime: "",
          changeOfDay: "",
          aircraftName: "",
          marketingCarrier: {
            flightNumber: "",
            airlineCode: "",
            airlineName: "",
          },
          operatingCarrier: "",
          arrival: {
            airportCode: "",
            airportName: "",
            flightDate: "",
            flightTime: "",
            terminal: "",
          },
          departure: {
            airportCode: "",
            airportName: "",
            flightDate: "",
            flightTime: "",
            terminal: "",
          },
          onTimePerformance: "",
        },
      ],
      offers: {
        id: "",
        pricePerAdult: 0,
        fareRefKey: [],
        totalPrice: 0,
        fareFamily: {
          id: "",
          name: "",
          code: "",
          isEconomyBasic: false,
          iconCode: "",
          subtitle: "",
          descriptions: [],
          order: 0,
        },
        originalPricePerAdult: 0,
        classOfService: [],
        fareBasisCodes: [],
        promoCodeApplied: false,
      },
    },
  })

export const layoverText = (
  formatMessage,
  layoversArray,
  layoverTime,
  formatLayoverTextFn = null
) => {
  const numberOfLayovers = layoversArray.length
  const layoverPlace = layoversArray.join(", ")
  const layover = `${layoverPlace}`

  if (numberOfLayovers === 0) {
    return formatMessage({
      id: "resultPage.cardsResult.tripCard.flightDetailDirect",
    })
  }
  if (numberOfLayovers === 1) {
    let layoverStr = `${layover} (${layoverTime})`
    if (formatLayoverTextFn) {
      layoverStr = formatLayoverTextFn(layover)
    }
    return `${formatMessage({
      id: "resultPage.cardsResult.tripCard.flightDetailLayover",
    })} ${layoverStr}`
  }
  return `${formatMessage({
    id: "resultPage.cardsResult.tripCard.flightDetailLayovers",
  })} ${layover}`
}

export const timeWCAGTransformation = (formatMessage, time) => {
  const hours = formatMessage({
    id: "resultPage.cardsResult.tripCardWCAG.hours",
  })
  const minutes = formatMessage({
    id: "resultPage.cardsResult.tripCardWCAG.minutes",
  })
  return time.replace("h", ` ${hours}`).replace("m", ` ${minutes}`)
}

export const getExpireTime = () => {
  const expTime = getCookie("exp_time")
  return expTime || ""
}

export const setExpireTime = () => {
  const expTime = moment().add(20, "minutes").format()
  setCookiesMatchingArrays(["exp_time"], [expTime])
  return expTime
}

export const transactionIDSelector = (state) =>
  state.flight.transactionIdentifier

export const checkUnchangedOD = (flightsData = []) => {
  const unchangedLeg = flightsData?.find(({ isChecked }) => isChecked === false)
  return {
    hasUnchangedLeg: !!unchangedLeg,
    unchangedLeg,
  }
}
