import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  root: () => ({
    padding: "96px 97px 48px 97px",
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    height: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "101px 82px 40px 82px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "88px 48px 36px 48px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "77px 24px 45px 24px",
    },
  }),
  modalContainer: {
    maxWidth: "1200px",
    margin: "auto",
    "& .MuiDialog-scrollBody": {
      overflowYo: "hidden",
    },
  },
  cardContainer: {
    padding: "76px 97px 71px",
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    "&:nth-child(n+3)": {
      paddingTop: 100,
    },
    "&:last-child": {
      borderBottom: "none",
      marginBottom: "40px",
    },
    "& .MuiGrid-container": {
      marginTop: "20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "78px 82px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 48px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "39px 24px 24px",
      "&:nth-child(n+3)": {
        paddingTop: 30,
      },
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      padding: "39px 24px 24px",
    },
  },
  flightCard: {
    display: "flex",
    height: "105%",
  },
  leftContentCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginRight: "20px",
    minHeight: 158,
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      marginRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  lblCityContainer: {
    display: "flex",
    flexDirection: "column",
    width: 60,
    "& strong": {
      fontSize: 20,
      color: theme.palette.grey["750"],
      fontWeight: 600,
    },
    "& span": {
      color: theme.palette.grey["600"],
      fontSize: 14,
    },
  },
  leftLineContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 158,
    "& .circle": {
      background: theme.palette.primary.main,
      borderRadius: "50%",
      margin: "8px auto",
      width: 7,
      height: 7,
      minHeight: 7,
    },
  },
  vLine: {
    borderLeft: `1px solid ${theme.palette.border.light}`,
    margin: "auto",
    height: "100%",
  },
  rightContentCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginLeft: "60px",
    "& .subtitle": {
      color: theme.palette.grey["750"],
      fontWeight: 500,
    },
    "& .optTitle": {
      paddingTop: 32,
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      marginLeft: "90px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 90,
      "& .subtitle": {
        width: "189px",
      },
      "& .optTitle": {
        width: 189,
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    "& .leftContent": {
      width: "80%",
      marginBottom: "auto",
      [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
      },
    },
    "& .rightContent": {
      alignSelf: "flex-end",
      paddingBottom: "17px",
      width: "35%",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        alignSelf: "flex-start",
        paddingBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      marginLeft: "75px",
      "& .subtitle": {
        width: "141px",
      },
    },
  },
  contentCardBotton: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 25,
    },
  },
  captionLbl: {
    color: theme.palette.grey["600"],
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 12,
    marginBottom: "5px",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  inlineBlocks: {
    display: "inline-flex",
    flexDirection: "row",
    marginTop: "20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexWrap: "wrap",
      marginTop: "30px",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      width: "260px",
      flexWrap: "wrap",
      marginTop: "30px",
      justifyContent: "space-between",
    },
    "& .item": {
      width: "180px",
      marginRight: 0,
      display: "flex",
      flexDirection: "column",
      "&_opt": {
        width: "auto",
        marginRight: 54,
        "& strong": {
          height: 30,
        },
      },
      "&_final": {
        [theme.breakpoints.down("xs")]: {
          marginBottom: 0,
        },
      },
      [theme.breakpoints.down("md")]: {
        "&_opt": {
          marginRight: 48,
          "& strong": {
            height: 45,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        marginRight: "-20px",
      },
      [theme.breakpoints.down("xs")]: {
        "&_opt": {
          width: "100%",
          "& strong": {
            height: "auto",
          },
        },
      },
      "&__full": {
        width: "120px",
        marginRight: "49px",
        marginLeft: "-30px",
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      },
      "& strong": {
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.grey["700"],
      },
      "& span": {
        color: theme.palette.grey["700"],
        fontSize: 16,
      },
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      width: "250px",
    },
  },
  button: {
    alignSelf: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "315px",
      marginTop: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      width: "85%",
      padding: "20px",
    },
  },
  layoverContainer: {
    position: "relative",
    top: "90px",
    left: "40px",
    width: "86px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      top: "73px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "40px",
      left: 0,
      right: 0,
      margin: "0 auto",
    },
    "& .titleLayover": {
      letterSpacing: "1px",
      color: theme.palette.grey["500"],
      fontWeight: 600,
      textTransform: "capitalize",
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      left: "5rem",
    },
  },
  marginIcon: {
    marginTop: "5px",
    marginBottom: "-10px",
  },
  modalWidth: {
    [theme.breakpoints.down("lg")]: {
      width: 1200,
    },
    [theme.breakpoints.down("md")]: {
      width: 900,
    },
    [theme.breakpoints.down("sm")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: 339,
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      width: 283,
    },
  },
  fareDetailsTitle: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  fareDetailsDescription: {
    color: theme.palette.grey[700],
    paddingLeft: 11,
  },
  fareDetailsContianer: {
    padding: "16px 0px 0px 5px",
    height: "auto",
  },
  fareListContianer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },
  fareDescription: {
    display: "flex",
    flexDirection: "row",
    width: 180,
    height: "auto",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginRight: 24,
    [theme.breakpoints.down("md")]: {
      marginRight: 16,
      width: 147,
    },
    [theme.breakpoints.down("sm")]: {
      width: 156,
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      margin: 0,
      paddingBottom: 32,
    },
  },
  smallFareStyles: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      paddingTop: 28,
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  fareIcon: {
    fill: theme.palette.grey[600],
  },
  checkFareRules: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "& .itineraryCardContainer": {
      width: "80%",
    },
    "& .navContent": {
      display: "flex",
      alignItems: "center",
      marginBottom: 40,
      "& .navButton": {
        minHeight: 48,
        minWidth: 48,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: 0,
        borderRadius: "50%",
        "& .MuiSvgIcon-root": {
          fontSize: "1.15rem",
        },
        "&.disabledNav": {
          pointerEvents: "none",
          background: theme.palette.grey["300"],
          opacity: "0.5",
        },
      },
      "& .nextButton": {
        marginLeft: 10,
        marginRight: 20,
      },
      "& .navText": {
        color: theme.palette.grey["600"],
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      marginTop: 40,
      "& .navContent": {
        marginBottom: 8,
      },
      "& .itineraryCardContainer": {
        width: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .navContent": {
        marginBottom: 0,
      },
      marginTop: 24,
    },
    [theme.breakpoints.down("xs")]: {
      "& .itineraryCardContainer": {
        marginLeft: "-10px",
      },
    },
  },
}))

export default useStyles
