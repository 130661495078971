import { makeStyles } from "@material-ui/core/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  /** Style for the general div */
  container: {
    maxWidth: 1248,
    [theme.breakpoints.down("md")]: {
      maxWidth: 1008,
    },
    "&.slider": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "fit-content",
        padding: 0,
      },
    },
  },
  containerBton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 72,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 128,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      paddingTop: 80,
      paddingBottom: 56,
    },
  },
  ctaSearchFlight: {
    width: 348,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  disclaimerContainer: {
    marginTop: 39,
  },
})

export default useStyles
