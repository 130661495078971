import { makeStyles, createStyles } from "@material-ui/core/styles"

const useContentStyle = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4),
      color: theme.palette.common.white,
      background: `linear-gradient(90deg, rgba(14, 74,129,1) 0%, #0D3050 50%)`,
      position: "relative",
      "& h2": {
        color: theme.palette.common.white,
        fontSize: theme.typography.h2,
        marginTop: 30,
        "& span": {
          display: "block",
          [theme.breakpoints.down("sm")]: {
            display: "inline",
          },
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
      },
      "& .user--actions": {
        zIndex: 1,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        "& > .buttonContainer": {
          display: "flex",
          "& > button:first-child": {
            marginRight: 10,
          },
        },
        "& > img": {
          width: 110,
          marginRight: 40,
        },
      },
      "& .user--info": {
        zIndex: 1,
        width: "100%",
        position: "inherit",
        "& .user--info--miles": {
          display: "flex",
          marginTop: 40,
          justifyContent: "flex-start",
          "& > div": {
            width: 150,
            height: 61,
            [theme.breakpoints.down("md")]: {
              "&:first-child": {
                marginRight: 60,
              },
              "&:last-child": {
                marginLeft: 20,
              },
            },
            [theme.breakpoints.down("xs")]: {
              "&:first-child": {
                marginRight: 0,
              },
              "&:last-child": {
                marginLeft: 0,
              },
            },
          },
          "& .MuiTypography-overline": {
            fontSize: theme.typography.overline.fontSize,
            color: theme.palette.grey[300],
          },
          "& h6": {
            fontSize: theme.typography.subtitle2.fontSize,
          },
          [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            "& > div": {
              flex: "0 0 100%",
            },
          },
        },
      },
      "&:before": {
        content: "''",
        background: `radial-gradient(223% 145% at 95% 110%,#0D3050 50%, transparent 50.25%) 0px 100%`,
        position: "absolute",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: "0",
        [theme.breakpoints.down("md")]: {
          background:
            "radial-gradient(200% 200% at 95% 121%,#0D3050 50%, transparent 50.25%) 0px 100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 56,
        paddingLeft: 56,
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
        "& .user--actions": {
          display: "block",
          marginBottom: 40,
          "& > .buttonContainer": {
            justifyContent: "space-between",
            marginTop: 30,
            "& > button": {
              marginRight: "0px !important",
              width: "45%",
            },
          },
        },
      },
    },
  })
)

export default useContentStyle
