import { takeEvery, call, put, select } from "redux-saga/effects"

import TYPES from "./flightTypes"
import api from "../../api"
import { flightsActions } from "../../store/flight/flightSlice"
import { generalActions } from "../../store/general/generalSlice"
import { lastName } from "../../store/reservation/reservationUtils"

import { tranformFareData } from "../../utils/generalUtils"
import { filterSelectedFareFamily } from "../../utils/flightUtils"

function* fetchFlights({ payload }) {
  try {
    yield put(generalActions.startLoading(true))
    const { data } = yield call(
      api.flights.getFlights,
      filterSelectedFareFamily(payload)
    )
    const offerGroups = tranformFareData(data.offerGroups)
    data.offerGroups = offerGroups

    yield put(flightsActions.setFlights(data))
    yield put(generalActions.stopLoading())
  } catch (e) {
    const surname = yield select(lastName)
    const errorObject = {
      pnr: payload.pnr,
      lastName: surname,
      status: e?.request?.status,
      service: "fare-reshop",
    }
    yield put(generalActions.handleError(errorObject))
    yield put(flightsActions.setErrorRedirect(e.response?.status || 0))
    yield put(generalActions.logError(e))
    yield put(flightsActions.errorFlights())
    yield put(generalActions.stopLoading())
  }
}

function* flightSaga() {
  yield takeEvery(TYPES.FETCH_FLIGHTS, fetchFlights)
}

export default flightSaga
