import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  contentButton: {
    height: 136,
    display: "flex",
    padding: theme.spacing(4),
    justifyContent: "flex-end",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      height: 119,
      padding: 24,
    },
    [theme.breakpoints.down("xs")]: {
      height: 95,
      padding: "34.5px 16px 12.5px 16px",
    },
  },
  button: {
    width: 658,
    [theme.breakpoints.down("md")]: {
      width: 523,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    borderRadius: theme.spacing(5),
  },
  contentInfo: {
    paddingTop: theme.spacing(17),
    paddingBottom: theme.spacing(11.9),
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(10.5),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentLeft: {
    paddingLeft: theme.spacing(12.75),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(10.25),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down("sm")]: {
      padding: "88px 48px 40px 48px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "72px 40px 32px 24px",
    },
  },
  contentRight: {
    paddingRight: theme.spacing(12.75),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(10.25),
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 48px 51px 48px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 40px 64px 24px",
    },
  },
  modal: {
    width: 1200,
    [theme.breakpoints.down("md")]: {
      width: 960,
    },
    [theme.breakpoints.down("sm")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
}))

export default useStyles
