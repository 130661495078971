import { makeStyles } from "@material-ui/styles"

const styledBy = (property, mapping) => (props) => mapping[props[property]]

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 80,
    boxShadow: "none",
    borderRadius: 0,
    width: "100%",
    position: ({ cookieOrigin }) =>
      cookieOrigin?.toLowerCase() === "app" ? "relative" : "fixed",
    zIndex: 12,
    "& .MuiToolbar-regular": {
      minHeight: 80,
    },
    [theme.breakpoints.down("xs")]: {
      zIndex: 1290,
    },
    "& div[menu-modal-open='true']": {
      pointerEvents: "none",
      "& #customized-menu": {
        pointerEvents: "auto",
      },
      "& .MuiBackdrop-root": {
        pointerEvents: "auto",
      },
    },
  },
  gradient100: {
    backgroundColor: theme.palette.primary.main,
    background: theme.palette.gradients[100],
  },
  gradient700: {
    backgroundColor: theme.palette.primary.main,
    background: theme.palette.gradients[700],
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.transparent.bg,
  },
  toolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  copaIcon: {
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 50,
      height: "100%",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.transparent.main,
    },
  },
  displayTextFlex: {
    display: "flex",
    justifyContent: "center",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 80,
    padding: "0px 0px 0px 5px",
  },
  displayFlexMobil: {
    justifyContent: "flex-end",
  },
  displayFlexEnd: {
    justifyContent: styledBy("tripType", {
      multicity: "flex-end",
      other: "space-evenly",
    }),
  },
  prices: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    fontWeight: 500,
  },
  font: {
    fontWeight: 500,
  },
  icon: {
    width: 16,
    fill: theme.palette.common.white,
  },
  containerMobil: {
    display: "flex",
    alignItems: "center",
  },
  returnToMyTrips: {
    width: 211,
    padding: 0,
    background: theme.palette.transparent.main,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 215,
      minWidth: 210,
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      maxWidth: "none",
    },
  },
  swapedIcon: {
    width: 28,
    height: 18,
    alignSelf: "center",
    fill: theme.palette.common.white,
    transform: "rotate(180deg)",
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      width: 38,
    },
    "@media screen and (max-width: 649px)": {
      marginLeft: 72,
    },
  },
  textBack: {
    width: "100%",
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    whiteSpace: "nowrap",
    "@media screen and (min-width: 960px) and (max-width: 1039px)": {
      whiteSpace: ({ language }) => (language === "pt" ? "initial" : "nowrap"),
      fontSize: ({ language }) => (language === "pt" ? 11 : "inherit"),
    },
    "@media screen and (max-width: 649px)": {
      whiteSpace: ({ language }) => (language === "pt" ? "initial" : "nowrap"),
      fontSize: ({ language }) => (language === "pt" ? 11 : "inherit"),
    },
  },
  returnToMyTripsMobil: {
    display: "flex",
    justifyContent: "center",
    background: theme.palette.grey[900],
    cursor: "pointer",
    paddingTop: 6,
    paddingBottom: 6,
  },
  dividerMyTrips: {
    marginLeft: 32,
    marginRight: 32,
    [theme.breakpoints.down("md")]: {
      marginLeft: 15,
      marginRight: 15,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  loginIcon: {
    width: 16,
    fill: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  arrowIcon: {
    margin: "0px 10px",
  },
  loggedInDiv: {
    marginLeft: 10,
    lineHeight: 0,
    textAlign: "right",
  },
  cartIcon: {
    fill: "white",
    paddingRight: 5,
  },
  currencyIcon: {
    marginLeft: -3,
    width: 24,
    fill: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  currencyButton: {
    margin: 0,
    height: 40,
    padding: 10,
    marginRight: 10,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginRight: 0,
    },
  },
  removePadding: {
    padding: 0,
  },
  withEditButton: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      flexBasis: "88%",
    },
  },
  withOutEditButton: {
    maxWidth: "100%",
    flexBasis: "88%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      flexBasis: "64%",
    },
  },
  editButtonPadding: {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      padding: "3%",
    },
  },
  editButton: {
    margin: 0,
    padding: 0,
    height: 40,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
      fontSize: 14,
      height: 20,
      color: theme.palette.grey[300],
      "&:hover": {
        backgroundColor: theme.palette.transparent.main,
        color: theme.palette.grey[300],
      },
    },
  },
  loginButton: {
    whiteSpace: "nowrap",
    height: 40,
    textTransform: "uppercase",
    minWidth: "110px",
    // width:
    //   "max-content" ||
    //   styledBy("tripType", {
    //     multicity: "theme.spacing(4)",
    //     other: 0,
    //   }),
    // marginRight: styledBy("tripType", {
    //   multicity: theme.spacing(-1),
    //   other: 0,
    // }),
    // minWidth: styledBy("tripType", {
    //   multicity: theme.spacing(4),
    //   other: theme.spacing(8),
    // }),
    // textTransform: "uppercase",
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: -7,
    //   marginRight: -10,
    //   paddingLeft: 20,
    //   paddingRight: 20,
    // },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      minWidth: styledBy("tripType", {
        multicity: theme.spacing(5),
        other: theme.spacing(5),
      }),
      maxWidth: 40,
      "& .MuiButton-startIcon": {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: styledBy("tripType", {
        multicity: theme.spacing(5),
        other: theme.spacing(18.125),
      }),
      minWidth: styledBy("tripType", {
        multicity: theme.spacing(5),
        other: theme.spacing(5),
      }),
      maxWidth: 40,
      "& .MuiButton-startIcon": {
        marginRight: 0,
      },
    },
  },
  loginEsMedium: {
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    height: 40,

    "&.MuiButton-root": {
      minWidth: "auto",
    },
    // temp
    // width:
    // "max-content" ||
    // styledBy("tripType", {
    //   multicity: "theme.spacing(4)",
    //   other: 0,
    // }),
    // marginRight: styledBy("tripType", {
    //   multicity: theme.spacing(-1),
    //   other: 0,
    // }),
    // minWidth: styledBy("tripType", {
    //   multicity: theme.spacing(4),
    //   other: theme.spacing(8),
    // }),
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: -7,
    //   marginRight: -10,
    //   paddingLeft: 60,
    //   paddingRight: 60,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0,
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: styledBy("tripType", {
    //     multicity: theme.spacing(5),
    //     other: theme.spacing(18.125),
    //   }),
    //   minWidth: styledBy("tripType", {
    //     multicity: theme.spacing(5),
    //     other: theme.spacing(8),
    //   }),
    // },
  },
  loginButtonMC: {
    height: 40,
    minWidth: 32,
    marginRight: theme.spacing(-2),
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  buttonUserContainer: {
    "&:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
  },
  flightInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    color: theme.palette.common.white,
    textDecoration: "none",
  },
  dates: {
    color: theme.palette.common.white,
    textTransform: "capitalize",
  },
  dividerHalf: {
    height: 40,
  },
  dividerFull: {
    height: 80,
  },
  userNameButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: "100%",
    height: 32,
    minWidth: 32,
    padding: 0,
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 20,
    },
  },
  userMilesContainer: {
    height: 40,
  },
  miles: {
    color: theme.palette.grey[300],
    textTransform: "capitalize",
  },
  multicityTitle: {
    lineHeight: "24px",
    fontWeight: 500,
  },
  multicityTitleMobile: {
    fontWeight: 500,
  },
  toolbarText: {
    margin: "0 30px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      margin: "0 2%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  editText: {
    marginRight: 5,
    [theme.breakpoints.down("xs")]: {
      textAlign: "initial",
      lineHeight: 1,
    },
  },
  textMobileContainer: {
    width: "100%",
  },
}))

export default useStyles
