import { makeStyles } from "@material-ui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  /** Styles general */
  root: {
    padding: 0,
    marginBottom: 32,
  },
  title: {
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 64,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 56,
    },
  },
  containerCard: {
    borderRadius: 4,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: 0.5,
    marginTop: 27,
    padding: 32,
    outline: "none",
    cursor: "pointer",
    "& .labelCheckBox .MuiTypography-body1": {
      color: theme.palette.grey["700"],
      fontSize: 16,
      fontWeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
  },
  containerCardDisable: {
    cursor: "pointer",
    borderColor: theme.palette.grey["300"],
    "& .labelCheckBox .MuiTypography-body1": {
      color: theme.palette.grey["600"],
    },
  },
  infoIcon: {
    fill: theme.palette.alternative.blueDark,
  },
  container: {
    marginTop: 16,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey["700"],
    paddingLeft: 0,
    "& .MuiAlert-icon": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
  },
})

export default useStyles
