import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"
import InfoIcon from "@material-ui/icons/InfoOutlined"
import SvgIcon from "@material-ui/core/SvgIcon"
import theme from "@bit/vibenitez.copa-components-library.theme"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"

// import { ReactComponent as LockIcon } from "../../../assets/icons/Lock-Outline.svg"

const useStyles = makeStyles({
  root: {
    padding: (props) => (props.small ? "2px 8px" : "2px 16px"),
    height: (props) => (props.small ? 20 : "auto"),
    marginLeft: 0,
    marginRight: (props) => (props.smHide ? 0 : 10),
    "& restrictivePill": {
      display: "flex",
      flexDirection: "row-reverse",
    },
  },
  label: {
    padding: 0,
    fontSize: (props) => (props.small ? "0.75rem" : "0.875rem"),
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      display: (props) => (props.smHide ? "none" : "inherit"),
    },
  },
  icon: {
    marginLeft: 0,
    marginRight: 6,
    height: 16,
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: (props) => (props.smHide ? 6 : 0),
      marginRight: (props) => (props.smHide ? 6 : 6),
    },
  },
  colorPrimary: {
    backgroundColor: (props) => props.customColor,
    color: (props) => props.textColor,
  },
})

const PillCo = (props) => {
  const classes = useStyles(props)
  const {
    label,
    check,
    info,
    lock,
    small,
    outlined,
    color,
    disabled,
    className,
  } = props
  const chipProps = {
    icon:
      (check && <DoneIcon />) ||
      (info && <InfoIcon />) ||
      (lock && <SvgIcon component={SwapHorizIcon} />) ||
      null,
    size: small ? "small" : "medium",
    variant: outlined ? "outlined" : "default",
    label,
    classes,
    color: color || "primary",
    disabled,
    className,
  }
  return <Chip {...chipProps} />
}

PillCo.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  check: PropTypes.bool,
  info: PropTypes.bool,
  lock: PropTypes.bool,
  small: PropTypes.bool,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  customColor: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.node,
}

PillCo.defaultProps = {
  check: false,
  info: false,
  lock: false,
  small: false,
  outlined: false,
  disabled: false,
  color: "primary",
  customColor: "",
  textColor: "",
  className: null,
}

export default PillCo
