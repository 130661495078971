import React, { useState, useEffect, useRef, useMemo } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import clsx from "clsx"
import isEmpty from "lodash/isEmpty"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import matchSorter from "match-sorter"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import InputAdornment from "@material-ui/core/InputAdornment"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"

import theme from "@bit/vibenitez.copa-components-library.theme"
import BookingPanelAutocomplete from "./bookingAutocomplete/bookingPanelAutocomplete"
import InputCoBooking from "../../../../../../ToLibrary/inputCoBooking/inputCoBooking"
import { airportsActions } from "../../../../../../store/airports/airportsSlice"

import { ReactComponent as SwapIcon } from "../../../../../../ToLibrary/icons/Swap-Horizontal.svg"
import { ReactComponent as ArrowIcon } from "../../../../../../ToLibrary/icons/Arrow-Right.svg"
import { ReactComponent as UpPlane } from "../../../../../../ToLibrary/icons/UpPlane.svg"
import { ReactComponent as Clear } from "../../../../../../ToLibrary/icons/Cross.svg"
import { ReactComponent as InfoIcon } from "../../../../../../ToLibrary/icons/Info-Fill.svg"

import useStyles from "./styles"

const translation = (id) => `searchPage.datesSelector.${id}`
const translationWCAG = (id) => `searchPage.datesSelectorWCAG.${id}`

const SearchPanelAutocomplete = (props) => {
  const {
    onOriginChange,
    onDestinationChange,
    onOriginTouched,
    onDestinationTouched,
    loading,
    errorOrigin,
    errorDestination,
    sendButtonPressed,
    getDestinationAirports,
    originAirports,
    id,
    originOptions,
    destinationOptions,
    origin,
    destination,
    destinationAirports,
    handleOriginClear,
    errorOriginField,
    errorDestinationField,
    touchedOrigin,
    touchedDestination,
    originFieldValue,
    destinationFieldValue,
    setSwap,
    handleSwapChanges,
    isStopover,
    originDisabledStopover,
    destinationDisabledStopover,
    disableStopoversegment,
    newFieldAdded,
    showIcons,
    isOnlyDates,
    checkSegment,
    originReservation,
    destinationReservation,
  } = props

  const classes = useStyles()
  const [noOptions, setNoOptions] = useState({
    origin: false,
    destination: false,
  })
  const [open, setOpen] = useState({
    origin: false,
    destination: false,
  })
  const [swapColor, setSwapColor] = useState(false)
  const [swapClicked, setSwapClick] = useState(false)
  const swapRef = useRef()
  const [inputValOrigin, setInputValueOrigin] = useState("")
  const [inputValDestination, setInputValueDestination] = useState("")
  const [originalFocus, setOriginalFocus] = useState("")
  const [destinationFocus, setDestinationFocus] = useState("")
  const [showButton, setShowButton] = useState({
    planeButton: true,
    swapButton: true,
  })
  const [noOriginError, setNoOriginError] = useState({
    error: false,
    helperText: "",
  })
  const [prevOrigin, setPrevOrigin] = useState()

  const panamaAirportsCode = ["PTY", "DAV"]

  const noRestrictionChangeRouteGlobal =
    process.env.REACT_APP_ENABLE_CHANGE_OF_ROUTE.includes("true")
  const originRef = useRef()
  const destinationRef = useRef()

  const { formatMessage } = useIntl()
  let firstValue = {}

  const handleOriginOptionsChange = () => {
    if (originOptions && newFieldAdded && !isStopover) {
      originRef.current.focus()
    }
  }

  const isPanamaDisabled = (value) =>
    !(
      panamaAirportsCode.includes(originReservation) &&
      panamaAirportsCode.includes(destinationReservation)
    ) &&
    panamaAirportsCode.includes(value) &&
    !noRestrictionChangeRouteGlobal

  useEffect(handleOriginOptionsChange, [originOptions])

  const handleOriginValuesChange = () => {
    if (originFieldValue === "") {
      onOriginChange({})
    }
    if (isStopover) {
      if (originFieldValue) {
        const initialOrigin = originAirports.find(
          (option) => option.code === originFieldValue
        )
        onOriginChange(initialOrigin)
      }
    }
  }

  useEffect(handleOriginValuesChange, [originFieldValue])

  const handleDestinationValuesChange = () => {
    if (!destinationFieldValue) {
      const selectedDestination = destinationAirports[0].destinations.find(
        (option) => option.code === destinationFieldValue
      )
      onDestinationChange(id, selectedDestination)
    }
    if (isStopover) {
      if (destinationFieldValue) {
        const initialDestination = originAirports.find(
          (option) => option.code === destinationFieldValue
        )
        onDestinationChange(initialDestination)
      }
    }
  }

  useEffect(handleDestinationValuesChange, [destinationFieldValue])

  const handleOriginStatusChanges = () => {
    if (origin?.code) {
      getDestinationAirports(origin.code, id - 1)
      setPrevOrigin(origin.code)
    }
  }
  useEffect(handleOriginStatusChanges, [origin])

  const handlePrevOriginChange = () => {
    if (prevOrigin && prevOrigin === origin?.code) {
      if (origin?.code && !destinationFieldValue && !isStopover) {
        destinationRef.current.focus()
      }
    }
  }
  useEffect(handlePrevOriginChange, [prevOrigin])

  const setAirportsDestination = () => {
    if (destinationAirports[id - 1]?.destinations.length > 0) {
      if (!destinationFieldValue) {
        const initialDestination = destinationAirports[
          id - 1
        ].destinations.find((option) => option.code === destinationFieldValue)
        onDestinationChange(id - 1, initialDestination)
      }
    }
  }
  useEffect(setAirportsDestination, [destinationAirports, isOnlyDates])

  const handleDestinationAirports = () => {
    if (destinationAirports[id]?.destinations.length > 0) {
      if (origin?.code) {
        if (swapClicked) {
          if (swapRef.current) {
            swapRef.current.focus()
          }
          setSwapClick(false)
        } else if (!isStopover) {
          destinationRef.current.focus()
        }
      }
    }
  }
  useEffect(handleDestinationAirports, [destinationOptions])

  const handleSendButton = () => {
    if ((errorOriginField || errorDestination) && sendButtonPressed) {
      originRef.current.focus()
    }
  }
  useEffect(handleSendButton, [sendButtonPressed])

  const filteredOptions = (filteredAirport, field) => {
    const applyFilter =
      (field === "origin" && id === 1) ||
      (field === "destination" && id === 2) ||
      (panamaAirportsCode.includes(origin?.code) &&
        panamaAirportsCode.includes(destination?.code))

    if (filteredAirport && applyFilter) {
      const getCountry = originAirports.find(
        (option) => option.code === filteredAirport
      )
      const sameCountryAirports = originAirports.filter(
        (option) => option.countryCode === getCountry?.countryCode
      )

      const copaAirports = sameCountryAirports.filter(
        (option) => option.isCodeShare === false
      )

      return copaAirports
    }

    return originAirports.filter(
      ({ code, isCodeShare }) =>
        isCodeShare === false &&
        code !== (field === "destination" ? origin?.code : destination?.code)
    )
  }

  const handleRetryDestination = () => {
    if (origin?.code) {
      getDestinationAirports(origin.code, id)
    }
  }

  const handleOriginChange = (event, newValue) => {
    checkSegment("ORIGIN", id)
    onOriginChange(newValue)
    setShowButton({ ...showButton, planeButton: true })
    setNoOptions({ ...noOptions, origin: false })
    if (newValue === null && !isStopover) {
      handleOriginClear([])
      if (!isPanamaDisabled(destinationReservation ? destination.code : null)) {
        onDestinationChange({})
      }
    }
  }

  const handleOriginBlur = (event) => {
    onOriginTouched(id)
    setOpen({ ...open, origin: false })
    if (!origin?.code) {
      handleOriginChange(event, {})
      if (event.target.value.length > 3 && !origin?.code) {
        setNoOptions({ ...noOptions, origin: true })
      } else {
        setNoOptions({ ...noOptions, origin: false })
      }
    }
  }

  const handleDestinationChange = (event, newValue) => {
    checkSegment("DEST", id)
    onDestinationChange(newValue)
    setShowButton({ ...showButton, swapButton: true })
    setNoOptions({ ...noOptions, destination: false })
    if (newValue === null) {
      onDestinationChange({})
    }
  }

  const handleDestinationBlur = (event) => {
    onDestinationTouched(id)
    setOpen({ ...open, destination: false })
    if (!destination?.code) {
      handleDestinationChange(event, {})
      if (event.target.value.length > 3 && !destination?.code) {
        setNoOptions({ ...noOptions, destination: true })
      } else {
        setNoOptions({ ...noOptions, destination: false })
      }
    }
  }

  const handleOpen = (event, prop, button) => {
    event.preventDefault()
    if (event.currentTarget.value.length > 0) {
      if (prop === "destination" && noOriginError.error) {
        setOpen({ ...open, [prop]: false })
        setShowButton({ ...showButton, [button]: true })
        setDestinationFocus(event.currentTarget.value)
      } else {
        setOpen({ ...open, [prop]: true })
        setShowButton({ ...showButton, [button]: false })
        setOriginalFocus(event.currentTarget.value)
      }
    }
  }

  const handleClose = (event, prop) => {
    event.preventDefault()
    if (errorOrigin || errorDestination) {
      setOpen({ ...open, [prop]: true })
    } else {
      setOpen({ ...open, [prop]: false })
    }
  }

  const handleSwap = () => {
    handleSwapChanges(id)
    setSwapColor(!swapColor)
    setSwapClick(true)
    setSwap(true)
  }

  const handleClearOrigin = () => {
    setInputValueOrigin("")
    onOriginChange({})
    if (
      !isStopover &&
      !isPanamaDisabled(destinationReservation ? destination.code : null)
    ) {
      setInputValueDestination("")
      onDestinationChange({})
    }
  }

  const handleDestinationClear = () => {
    setInputValueDestination("")
    onDestinationChange({})
  }

  const handleOriginHelperText = () => {
    let helperText = ""
    if (noOptions.origin) {
      helperText = formatMessage({
        id: translation("noResultsHelperTextOrigin"),
      })
    } else if (touchedOrigin && errorOriginField) {
      helperText = errorOriginField
    }
    return helperText
  }

  const originError = handleOriginHelperText()

  const handleDestinationHelperText = () => {
    let helperText = ""
    if (noOptions.destination) {
      helperText = formatMessage({
        id: translation("noResultsHelperTextDestination"),
      })
    } else if (!origin?.code && touchedDestination) {
      helperText = formatMessage({
        id: translation("emptyOriginWhenDestination"),
      })
    } else if (touchedDestination && errorDestinationField) {
      helperText = errorDestinationField
    }
    if (inputValDestination === "") {
      helperText = formatMessage({
        id: translation("emptyDestination"),
      })
    }
    return helperText
  }

  const handleDestinationOnFocus = () => {
    if (!origin?.code) {
      setNoOriginError({
        ...noOriginError,
        error: true,
        helperText: formatMessage({
          id: translation("emptyOriginWhenDestination"),
        }),
      })
    } else {
      setNoOriginError({ ...noOriginError, error: false, helperText: "" })
    }
  }

  const destinationError =
    noOriginError.helperText || handleDestinationHelperText()

  const handleIconClick = () => {
    originRef.current.focus()
  }

  const handleOriginKeyDown = (event) => {
    const charCode = event.keyCode
    if (
      charCode === 9 &&
      !isEmpty(firstValue) &&
      originalFocus !== inputValOrigin
    ) {
      handleOriginChange(event, firstValue)
    }
  }

  const handleDestinationKeyDown = (event) => {
    const charCode = event.keyCode
    if (
      charCode === 9 &&
      !isEmpty(firstValue) &&
      destinationFocus !== inputValDestination
    ) {
      handleDestinationChange(event, firstValue)
    }
  }

  const matchSorterAcrossKeys = (list, search, options) => {
    const joinedKeysString = (item) =>
      options.keys.map((key) => item[key]).join(" ")
    return matchSorter(list, search, {
      ...options,
      keys: [...options.keys, joinedKeysString],
    })
  }

  const filterOptions = (options, { inputValue }) => {
    const regExp = new RegExp(/\(([^)]+)\)/)
    const newVal = regExp.exec(inputValue)

    let newInput = inputValue

    if (newVal && newVal[1]) {
      // The newVal expresion returns an array that contains on its second position `newVal[1]` the value without parenthesis.
      // This will be use to match the code in case the users types something like Panama (PTY)
      // The newInput will be PTY which matches the code
      const codeVal = newVal[1]
      newInput = codeVal
    }

    const filterValues = matchSorterAcrossKeys(options, newInput, {
      keys: ["code", "city", "name", "country", "thesaurus"],
    })
    const firstVal = filterValues[0]
    firstValue = firstVal

    return filterValues
  }

  const renderOptions = (option, { inputValue }) => {
    const matches = match(option.city, inputValue)
    const matchesCode = match(option.code, inputValue)
    const cityParts = parse(option.city, matches)
    const codeParts = parse(option.code, matchesCode)

    return (
      <div
        data-cy="searchPanelAutocomplete"
        className={classes.searchContainer}
        role="option"
        aria-selected="true"
      >
        <div
          data-cy={`searchPanelAutocomplete-textContainer-${id}`}
          className={classes.textContainer}
        >
          <div>
            {cityParts.map((part) => (
              <span
                key={part.text}
                data-cy={`searchPanelAutocomplete-cityParts${part.text}-${id}`}
                style={{
                  color: part.highlight
                    ? theme.palette.primary.light
                    : theme.palette.grey[700],
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
          <span
            data-cy={`searchPanelAutocomplete-airportsName-${id}`}
            className={classes.airports}
          >
            {option.name}
          </span>
        </div>
        <div className={classes.codeContainer}>
          {codeParts.map((part) => (
            <span
              key={part.text}
              data-cy={`searchPanelAutocomplete-codeParts${part.text}-${id}`}
              className={classes.code}
              style={{
                color: part.highlight
                  ? theme.palette.primary.light
                  : theme.palette.primary.dark,
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </div>
    )
  }

  const ListComponent = useMemo(() => {
    return React.forwardRef((listProps, ref) => {
      return (
        <div
          className={classes.listComponent}
          role="listbox"
          ref={ref}
          {...listProps}
        />
      )
    })
  }, [classes.listComponent])

  const getOptions = (field) => {
    const isSameCitiesField =
      (field === "origin" && id === 1) || (field === "destination" && id === 2)
    const currentReservation = isSameCitiesField
      ? originReservation
      : destinationReservation

    if (!noRestrictionChangeRouteGlobal) {
      if (
        (field === "origin" &&
          !isPanamaDisabled(origin?.code ? origin.code : null)) ||
        !isPanamaDisabled(originReservation ? origin.code : null) ||
        panamaAirportsCode.includes(currentReservation)
      ) {
        return filteredOptions(currentReservation, field)
      }

      if (
        (field === "destination" &&
          !isPanamaDisabled(destination?.code ? destination.code : null)) ||
        !isPanamaDisabled(destinationReservation ? destination.code : null) ||
        panamaAirportsCode.includes(currentReservation)
      ) {
        return filteredOptions(currentReservation, field)
      }
      const options = isSameCitiesField ? originOptions : destinationOptions

      return options.filter(
        ({ code, isCodeShare }) =>
          code !==
            (field === "destination" ? origin?.code : destination?.code) &&
          isCodeShare === false
      )
    }

    const options = isSameCitiesField ? originOptions : destinationOptions

    return options.filter(
      ({ code }) =>
        code !== (field === "destination" ? origin?.code : destination?.code)
    )
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={5} md={5} lg={5} className={classes.container}>
        <Hidden only="xs">
          {showIcons && (
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
              onClick={handleIconClick}
              className={classes.planeContianer}
            >
              <UpPlane className={classes.upPlaneIcon} />
            </Grid>
          )}
        </Hidden>
        <Hidden smUp>
          {showButton.planeButton && showIcons && (
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
              onClick={handleIconClick}
              className={classes.planeContianer}
            >
              <UpPlane className={classes.upPlaneIcon} />
            </Grid>
          )}
        </Hidden>
        <Grid
          item
          md={10}
          lg={8}
          sm={10}
          xs={12}
          data-cy="searchPanelAutocomplete-originContainer"
          className={clsx(classes.originContainer, "inputDatesSelector")}
        >
          <div className={classes.autocompleteContainer}>
            <BookingPanelAutocomplete
              id={`origin-autocomplete-${id}`}
              data-cy="origin-autocomplete"
              options={getOptions("origin")}
              clearOnBlur={false}
              open={open.origin || (errorOrigin && originOptions?.length === 0)}
              ListboxComponent={ListComponent}
              disablePortal
              autoHighlight
              disabled={
                originDisabledStopover ||
                disableStopoversegment ||
                (isPanamaDisabled(origin?.code ? origin.code : null) &&
                  panamaAirportsCode.includes(
                    id === 1 ? originReservation : destinationReservation
                  ))
              }
              onKeyDown={handleOriginKeyDown}
              inputValue={inputValOrigin}
              forcePopupIcon={false}
              loading={loading}
              error={errorOrigin}
              onInputChange={(event, newInputValue) =>
                !newInputValue.includes("undefined") &&
                setInputValueOrigin(newInputValue)
              }
              value={
                origin?.code !== destination?.code
                  ? origin?.code
                    ? origin
                    : null
                  : !isPanamaDisabled(origin?.code ? origin.code : null) &&
                    id !== 1
                  ? null
                  : origin
              }
              getOptionLabel={(option) => `${option.city} (${option.code})`}
              getOptionSelected={(option, val) => option.code === val.code}
              onOpen={(event) => handleOpen(event, "origin", "planeButton")}
              onClose={(event) => handleClose(event, "origin")}
              onBlur={handleOriginBlur}
              onChange={handleOriginChange}
              errorlabel={formatMessage({
                id: "searchPage.changeRouteSelector.notAvailableTitle",
              })}
              errormessage={formatMessage(
                {
                  id: "searchPage.changeRouteSelector.errorMesaje",
                },
                {
                  a: () => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={formatMessage({
                        id: "searchPage.changeRouteSelector.link",
                      })}
                    >
                      <FormattedMessage id="confirmation.whereToPaySection.callingToLinkText" />
                    </a>
                  ),
                }
              )}
              errorloadinglabel={formatMessage({
                id: translation("airportListFail"),
              })}
              errorloadingaction={formatMessage({
                id: translation("retryButton"),
              })}
              handlereload={() => {}}
              filterOptions={filterOptions}
              renderOption={renderOptions}
              renderInput={(params) => (
                <InputCoBooking
                  {...params}
                  name={`origin-${id}`}
                  isOnlyDates={isOnlyDates}
                  inputRef={originRef}
                  placeholder={formatMessage({
                    id: translation("originPlaceholder"),
                  })}
                  alternativePlaceholder={formatMessage({
                    id: translation("originFocusPlaceholder"),
                  })}
                  aria-label={
                    errorOrigin
                      ? formatMessage({
                          id: translationWCAG("airportLoadingFail"),
                        })
                      : formatMessage({ id: translationWCAG("originField") })
                  }
                  id={`origin-input-${id}`}
                  label={formatMessage({ id: translation("originTitle") })}
                  hasErrors={
                    noOptions.origin ||
                    (touchedOrigin && !isEmpty(errorOriginField))
                  }
                  helperText={originError}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: inputValOrigin.length > 0 && (
                      <InputAdornment
                        position="end"
                        className={classes.clearContainer}
                        onClick={handleClearOrigin}
                      >
                        <Clear className={classes.clearButton} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          {id === 1 &&
            !isOnlyDates &&
            noRestrictionChangeRouteGlobal &&
            // (Remove) This originReservation logic is temporal until OSL can handle this
            (!originReservation.includes("PTY") ||
              //
              noRestrictionChangeRouteGlobal) && (
              <div className={classes.alertFlights}>
                <InfoIcon width={10} height={10} fill="#DB3131" />
                <span
                  key="alert"
                  data-cy="pt"
                  role="definition"
                  className={classes.sameOriginCountryMsg}
                >
                  {formatMessage({
                    id: "searchPage.changeRouteSelector.changeRoute",
                  })}
                </span>
              </div>
            )}
        </Grid>
      </Grid>
      {isOnlyDates && (
        <Grid
          item
          data-cy="searchPanelAutocomplete-handleSwapContainer"
          className={classes.arrowIcon}
        >
          <ArrowIcon
            className={clsx(classes.swapIcon, swapColor && classes.swapedIcon)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={7} lg={6} className={classes.container}>
        {showIcons && (
          <>
            <Hidden only="xs">
              <Grid
                item
                md={3}
                sm={2}
                xs={2}
                lg={2}
                data-cy="searchPanelAutocomplete-handleSwapContainer"
                className={classes.swapButtonContainer}
              >
                <ButtonCo
                  className={classes.swapButton}
                  variant="linkInverted"
                  size="medium"
                  color="primary"
                  onClick={handleSwap}
                  data-cy="searchPanelAutocomplete-handleSwap"
                  ref={swapRef}
                  aria-label={formatMessage(
                    { id: translationWCAG("swapButton") },
                    {
                      origin: origin ? origin.city : "",
                      destination: destination ? destination.city : "",
                    }
                  )}
                  disabled={isStopover}
                >
                  <SwapIcon
                    className={clsx(
                      classes.swapIcon,
                      swapColor && classes.swapedIcon
                    )}
                  />
                </ButtonCo>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {showButton.swapButton && (
                <Grid
                  item
                  md={3}
                  sm={2}
                  xs={2}
                  lg={2}
                  data-cy="searchPanelAutocomplete-swapButtonContainer"
                  className={classes.swapButtonContainer}
                >
                  <ButtonCo
                    className={classes.swapButton}
                    variant="linkInverted"
                    size="medium"
                    color="primary"
                    onClick={handleSwap}
                    data-cy="searchPanelAutocomplete-swapButton"
                    aria-label={formatMessage(
                      { id: translationWCAG("swapButton") },
                      {
                        origin: origin ? origin.city : "",
                        destination: destination ? destination.city : "",
                      }
                    )}
                  >
                    <SwapIcon
                      className={clsx(
                        classes.swapIcon,
                        swapColor && classes.swapedIcon
                      )}
                    />
                  </ButtonCo>
                </Grid>
              )}
            </Hidden>{" "}
          </>
        )}
        <Grid
          item
          lg={9}
          md={8}
          sm={10}
          xs={12}
          data-cy="searchPanelAutocomplete-destinationContianer"
          className={classes.destinationContianer}
        >
          <div
            className={
              (classes.autocompleteContainer,
              id === 1 &&
              !isOnlyDates &&
              // (Remove) This originReservation logic is temporal until OSL can handle this
              (!originReservation.includes("PTY") ||
                //
                noRestrictionChangeRouteGlobal)
                ? classes.marginTextAlert
                : "")
            }
          >
            <BookingPanelAutocomplete
              id={`destination-autocomplete-${id}`}
              data-cy="searchPanelAutocomplete-destination-autocomplete"
              options={getOptions("destination")}
              clearOnBlur={origin?.code && false}
              ListboxComponent={ListComponent}
              disablePortal
              autoHighlight
              disabled={
                destinationDisabledStopover ||
                disableStopoversegment ||
                (isPanamaDisabled(
                  destination?.code ? destination.code : null
                ) &&
                  panamaAirportsCode.includes(
                    id === 1 ? destinationReservation : originReservation
                  ))
              }
              onKeyDown={handleDestinationKeyDown}
              loading={loading}
              error={errorDestination}
              inputValue={inputValDestination}
              forcePopupIcon={false}
              onFocus={handleDestinationOnFocus}
              onInputChange={(event, newInputValue) =>
                !newInputValue.includes("undefined") &&
                setInputValueDestination(newInputValue)
              }
              value={
                destination?.code !== origin?.code
                  ? destination?.code
                    ? destination
                    : null
                  : !isPanamaDisabled(
                      destination?.code ? destination.code : null
                    ) && id !== 2
                  ? null
                  : destination
              }
              getOptionLabel={(option) => `${option.city} (${option.code})`}
              getOptionSelected={(option, val) => option.code === val.code}
              open={
                open.destination ||
                (errorDestination && destinationOptions.length === 0)
              }
              onOpen={(event) => handleOpen(event, "destination", "swapButton")}
              onClose={(event) => handleClose(event, "destination")}
              onBlur={handleDestinationBlur}
              onChange={handleDestinationChange}
              errorlabel={formatMessage({
                id: translation("noResultsAutocomplete"),
              })}
              errormessage={formatMessage({
                id: translation("noResultsAutocompleteHelperText"),
              })}
              errorloadinglabel={formatMessage({
                id: translation("airportListFail"),
              })}
              errorloadingaction={formatMessage({
                id: translation("retryButton"),
              })}
              handlereload={handleRetryDestination}
              filterOptions={filterOptions}
              renderOption={renderOptions}
              renderInput={(params) => (
                <InputCoBooking
                  {...params}
                  name={`destination-${id}`}
                  isOnlyDates={isOnlyDates}
                  inputRef={destinationRef}
                  placeholder={formatMessage({
                    id: translation("destinationPlaceholder"),
                  })}
                  alternativePlaceholder={formatMessage({
                    id: translation("destinationFocusPlaceholder"),
                  })}
                  aria-label={
                    errorDestination
                      ? formatMessage({
                          id: translationWCAG("airportLoadingFail"),
                        })
                      : formatMessage({
                          id: translationWCAG("destinationField"),
                        })
                  }
                  id={`destination-input-${id}`}
                  label={formatMessage({ id: translation("destinationTitle") })}
                  hasErrors={
                    inputValDestination === "" ||
                    noOptions.destination ||
                    (touchedDestination && !isEmpty(errorDestinationField)) ||
                    noOriginError.error
                  }
                  helperText={destinationError}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: inputValDestination.length > 0 && (
                      <InputAdornment
                        position="end"
                        className={classes.clearContainer}
                        onClick={handleDestinationClear}
                      >
                        <Clear className={classes.clearButton} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

SearchPanelAutocomplete.propTypes = {
  originAirports: PropTypes.arrayOf(PropTypes.shape([])),
  getDestinationAirports: PropTypes.func.isRequired,
  destinationAirports: PropTypes.arrayOf(PropTypes.shape([])),
  onOriginChange: PropTypes.func,
  onDestinationChange: PropTypes.func,
  onOriginTouched: PropTypes.func,
  onDestinationTouched: PropTypes.func,
  loading: PropTypes.bool,
  errorOrigin: PropTypes.bool,
  errorDestination: PropTypes.bool,
  sendButtonPressed: PropTypes.bool,
  id: PropTypes.number,
  originOptions: PropTypes.arrayOf(PropTypes.shape([])),
  destinationOptions: PropTypes.arrayOf(PropTypes.shape([])),
  origin: PropTypes.shape({ code: PropTypes.string, city: PropTypes.string }),
  destination: PropTypes.shape({
    code: PropTypes.string,
    city: PropTypes.string,
  }),
  setDestinationOptions: PropTypes.func,
  handleOriginAirportChanges: PropTypes.func,
  handleDestinationAirportChanges: PropTypes.func,
  airports: PropTypes.PropTypes.arrayOf(PropTypes.shape([])),
  handleOriginClear: PropTypes.func,
  errorOriginField: PropTypes.string,
  errorDestinationField: PropTypes.string,
  touchedOrigin: PropTypes.bool,
  touchedDestination: PropTypes.bool,
  originFieldValue: PropTypes.string,
  destinationFieldValue: PropTypes.string,
  setSwap: PropTypes.func.isRequired,
  handleSwapChanges: PropTypes.func,
  isStopover: PropTypes.bool,
  originDisabledStopover: PropTypes.bool,
  destinationDisabledStopover: PropTypes.bool,
  disableStopoversegment: PropTypes.bool,
  newFieldAdded: PropTypes.bool,
  isOnlyDates: PropTypes.bool,
  showIcons: PropTypes.bool,
  checkSegment: PropTypes.func.isRequired,
  originReservation: PropTypes.string.isRequired,
  destinationReservation: PropTypes.string.isRequired,
}

SearchPanelAutocomplete.defaultProps = {
  originAirports: [],
  destinationAirports: [],
  onOriginChange: null,
  onDestinationChange: null,
  onOriginTouched: null,
  onDestinationTouched: null,
  loading: false,
  errorOrigin: false,
  errorDestination: false,
  sendButtonPressed: false,
  id: 0,
  originOptions: [],
  destinationOptions: [],
  origin: {},
  destination: {},
  setDestinationOptions: null,
  handleOriginAirportChanges: null,
  handleDestinationAirportChanges: null,
  airports: [],
  handleOriginClear: null,
  errorOriginField: "",
  errorDestinationField: "",
  touchedOrigin: false,
  touchedDestination: false,
  originFieldValue: "",
  destinationFieldValue: "",
  handleSwapChanges: null,
  isStopover: false,
  originDisabledStopover: false,
  destinationDisabledStopover: false,
  disableStopoversegment: false,
  newFieldAdded: false,
  showIcons: true,
  isOnlyDates: false,
}

const mapStateToProps = ({ airports, reservation, flight, general }) => ({
  originAirports: airports.origins,
  destinationAirports: airports.airportsMulticity,
  reservation,
  saveSearch: flight.saveSearch,
  fatalErrorRedirect: general.fatalErrorRedirect,
  onlyDate: flight.onlyDate,
  originReservation: reservation.flightOrigin.locationDepart,
  destinationReservation: reservation.flightOrigin.locationArrive,
})

const mapDispatchToProps = (dispatch) => ({
  getDestinationAirports: (origin, id) =>
    dispatch(airportsActions.fetchMulticityDestinations({ origin, id })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPanelAutocomplete)
