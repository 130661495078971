import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import clsx from "clsx"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import MobileTopbar from "./mobileTopBar"
import DesktopTopbar from "./desktopTopBar"
import ReturnButton from "./returnButton"

import { ReactComponent as EditIcon } from "../../../../../ToLibrary/icons/Edit.svg"
import { ReactComponent as CurrencyIcon } from "../../../../../ToLibrary/icons/Currency-Outline.svg"
import { ReactComponent as CarrotDown } from "../../../../../ToLibrary/icons/Carrot-Down.svg"
import { ReactComponent as LoginIcon } from "../../../../../ToLibrary/icons/Login_Small.svg"
import { getCookie } from "../../../../../utils/auth"
import { sessionActions } from "../../../../../store/session/sessionSlice"

import useStyles from "./styles"
import { getLanguages } from "../../../../../utils/generalUtils"

const Topbar = ({
  tripType,
  variant,
  pnr,
  passengers,
  passengersNum,
  language,
  saveSearch,
  lastName,
  resetSession,
  ...props
}) => {
  const classes = useStyles({
    cookieOrigin: getCookie("origin"),
    tripType: tripType === 2 ? "multicity" : "other",
  })

  const { formatMessage } = useIntl()
  const {
    goBack,
    push,
    location: { pathname, state: routerState, search: locationSearch },
  } = useHistory()

  const languages = getLanguages()

  const params = new URLSearchParams(locationSearch)
  const pnrParam = params.get("pnr")
  const surnameParam = params.get("lastName")
  const showAlert = routerState?.notFoundError || routerState?.fatalError
  const isEditSearch =
    pathname === "/" ||
    pathname === "/critical-error" ||
    pathname === "/notEligible" ||
    Object.entries(saveSearch).length <= 0 ||
    showAlert
  const editIcon = () => <EditIcon className={classes.icon} />
  const currencyIcon = () => <CurrencyIcon className={classes.currencyIcon} />
  const carrotDown = () => <CarrotDown className={classes.currencyIcon} />
  const loginIcon = () => <LoginIcon className={classes.loginIcon} />

  const tripTypeText = () => {
    if (tripType === 0) return "header.roundTripSearch"
    if (tripType === 1) return "header.oneWay"
    if (tripType === 2) return "header.multiCitySearch"
    return "header.multiCitySearch"
  }

  const handleEdit = () => {
    if (window.location.pathname.includes("/summary")) {
      resetSession()
      push(`/?pnr=${pnr}&lastName=${lastName}`)
      return
    }
    goBack()
  }

  const getLanguage = (lang) => languages[lang] || languages?.en

  return (
    <AppBar
      className={clsx(classes.appBar, classes[variant])}
      position="static"
    >
      <Toolbar className={classes.toolBar}>
        <Hidden only={["sm", "xs"]}>
          <DesktopTopbar
            {...props}
            language={getLanguage(language)}
            languages={Object.values(languages)}
            formatMessage={formatMessage}
            editIcon={editIcon}
            currencyIcon={currencyIcon}
            carrotDown={carrotDown}
            loginIcon={loginIcon}
            tripTypeText={tripTypeText}
            tripType={tripType}
            onEditClick={() => {
              handleEdit()
            }}
            reservation={pnr || pnrParam}
            passengers={passengers.length || passengersNum}
            isEditSearch={!isEditSearch}
            lastName={lastName || surnameParam}
          />
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <MobileTopbar
            {...props}
            language={getLanguage(language)}
            languages={Object.values(languages)}
            formatMessage={formatMessage}
            editIcon={editIcon}
            currencyIcon={currencyIcon}
            carrotDown={carrotDown}
            loginIcon={loginIcon}
            tripTypeText={tripTypeText}
            tripType={tripType}
            reservation={pnr || pnrParam}
            passengers={passengers.length}
            onEditClick={() => {
              handleEdit()
            }}
            isEditSearch={!isEditSearch}
            lastName={lastName || surnameParam}
          />
        </Hidden>
      </Toolbar>
      <Hidden smUp>
        {!isEditSearch ? (
          <div className={classes.returnToMyTripsMobil}>
            <ReturnButton
              toShow={!isEditSearch}
              pnr={pnr}
              lastName={lastName}
              language={language}
            />
          </div>
        ) : (
          <></>
        )}
      </Hidden>
    </AppBar>
  )
}
const mapStateToProps = ({ reservation, flight, session }) => ({
  pnr: reservation.ticket.pnr,
  passengers: reservation.ticket.passengers,
  lastName: reservation.queryParams.lastName,
  saveSearch: flight.saveSearch,
  passengersNum: session.sessionInfo.passengers.length,
})

const mapDispatchToProps = (dispatch) => ({
  resetSession: () => dispatch(sessionActions.resetSession()),
})

Topbar.propTypes = {
  props: PropTypes.shape({}),
  tripType: PropTypes.number,
  variant: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  pnr: PropTypes.string.isRequired,
  passengers: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.array,
  ]).isRequired,
  passengersNum: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  saveSearch: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.array,
  ]).isRequired,
  resetSession: PropTypes.func.isRequired,
}

Topbar.defaultProps = {
  props: {},
  tripType: 0,
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)
