import moment from "moment"
import { SHA256 } from "crypto-js"
import {
  deleteCookiesMatchingArrays,
  getCookie,
  setCookiesMatchingArrays,
  parseStringObject,
} from "./auth"
import { getDate } from "./generalUtils"

const formatDate = (date) => moment(date).format("MMM D")

const setCookieForRedirection = (url, pnr) => {
  const cookie = JSON.stringify({ url, pnr })
  setCookiesMatchingArrays(["c_puc"], [cookie])
}

const getCreditCardError = () => {
  const creditCardError = getCookie("c_sce")
  deleteCookiesMatchingArrays(["c_sce"])
  return creditCardError === "true"
}

const getDevideIdCookie = () => {
  const deviceId = getCookie("deviceID")
  deleteCookiesMatchingArrays(["deviceID"])
  return deviceId
}

const getOriginFromApp = () => {
  return getCookie("origin")
}

const getFFNCookie = () => {
  return getCookie("lp_ffn")
}

const hideInsuranceByExecptions = (flightSelected, roundtrip) => {
  return flightSelected.length > 1 && !roundtrip
}

const calculateTotalPriceLock = (paxNumber, price) => paxNumber * price

const calculateTotalInsurance = (insurance, passengerInformationLength) => {
  if (insurance.offer) {
    const price = insurance.offer.prices[0].totalAmount
    return parseFloat(price) * passengerInformationLength
  }
  return 0
}

const getHeaderCardInfo = (
  modifiedItinerary,
  pnr,
  passengerInformation,
  passengerInformationLength,
  paymentsEmail,
  hasPayment
) => {
  const modiItinerary = modifiedItinerary === null ? [] : modifiedItinerary
  return {
    destinationImage: "",
    destinationCity: modiItinerary[0]?.destinationName,
    pnr,
    firstDate: formatDate(modiItinerary[0]?.flights[0].departure?.date) || "-",
    lastDate: formatDate(modiItinerary[1]?.flights[0].departure?.date) || "-",
    segments: modiItinerary.length,
    passengers:
      passengerInformationLength > 0 ? passengerInformationLength - 1 : 0,
    firstPassenger:
      passengerInformationLength > 0
        ? `${passengerInformation[0].firstName}${" "}${
            passengerInformation[0].surname
          }`
        : "",
    email: hasPayment
      ? paymentsEmail
      : (passengerInformationLength > 0 && passengerInformation[0].email) || "",
    isOneWay: modiItinerary.length === 1,
  }
}

const getChangedItinerary = (flightsData) => {
  const modifiedFlights = new Set([])

  try {
    const modifiedItinerary = flightsData?.modifiedItinerary || null
    const originalItinerary = flightsData?.originalItinerary || null

    if (modifiedItinerary && originalItinerary) {
      originalItinerary.forEach((originalFlight) => {
        const allModifiedFlights = modifiedItinerary.reduce(
          (sum, od) => sum.concat(od.flights),
          []
        )
        const currentFlight = allModifiedFlights.find(
          (mf) => mf.key === originalFlight.key
        )

        if (
          currentFlight &&
          (originalFlight.flightNumber !== currentFlight.flightNumber ||
            originalFlight.locationDepart !==
              currentFlight.departure.locationDepart ||
            originalFlight.locationArrive !==
              currentFlight.arrival.locationArrive ||
            originalFlight.selectedFareFamily !==
              currentFlight.selectedFareFamily.code ||
            originalFlight.scheduledDepart.date !==
              currentFlight.departure.date ||
            originalFlight.scheduledArrive.date !==
              currentFlight.arrival.date ||
            originalFlight.scheduledDepart.time !==
              currentFlight.departure.time ||
            originalFlight.scheduledArrive.time !==
              currentFlight.arrival.time ||
            originalFlight.classOfService !== currentFlight.classOfService)
        ) {
          modifiedFlights.add(`OD${currentFlight.odKey}`)
        }
      })
    }

    return modifiedFlights
  } catch (error) {
    console.error(error)
    return modifiedFlights
  }
}

const getItineraryData = (flightsData) => {
  if (flightsData.modifiedItinerary) {
    return (
      flightsData?.modifiedItinerary?.map((item) => {
        return {
          destination: {
            code: item.destinationCode,
            city: item.destinationName,
            destinationImageURL: item.destinationImageURL,
            departureDate: "2020-01-01",
            operationDate: "2020-02-01",
          },
          origin: {
            code: item.originCode,
            city: item.originName,
            departureDate: "2020-02-01",
            operationDate: "2020-03-01",
          },
          originDestinationKey: item.originDestinationKey,
          disable: getChangedItinerary(flightsData).has(
            item.originDestinationKey
          ),
          solutions: {
            classOfService: item.flights[0].classOfService,
            fareFamily: item.flights[0].selectedFareFamily.description,
            journeyTime: item.journeyTime,
            numberOfLayovers: item.numberOfLayovers,
            flights: item.flights?.map((flight) => {
              return {
                aircraftName: flight.aircraftName,
                arrival: {
                  airportCode: flight.arrival.locationArrive,
                  flightDate: flight.arrival.date,
                  flightTime: flight.arrival.time,
                },
                departure: {
                  airportCode: flight.departure.locationDepart,
                  flightDate: flight.departure.date,
                  flightTime: flight.departure.time,
                },
                layoverTime: flight.layoverTime,
                marketingCarrier: {
                  airlineCode: flight.airlineMarketing,
                  airlineName: flight.airlineName,
                  flightNumber: flight.flightNumber,
                },
                operatingCarrier: flight.airlineOperating,
              }
            }),
          },
          // This will be necessary when we get the solution and segment keys in the session service.
          // This will be necessary to show the flights attributes correctly for every leg.
          // travelerFaresDetails: flightsData?.travelerFaresDetails,
        }
      }) || []
    )
  }
  return []
}

const getConfRespCookie = ({ pnr }) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  const cookieData = getCookie("c_confRes")
  if (cookieData) {
    const confirmationResponse = parseStringObject(cookieData, date)
    deleteCookiesMatchingArrays(["c_confRes"])
    return confirmationResponse
  }
  return {}
}

const getAprovalNumber = ({ pnr }) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  if (getCookie("c_nn")) {
    const aprovalNumberCookie = parseStringObject(getCookie("c_nn"), date)
    localStorage.setItem("c_nn", aprovalNumberCookie || "-")
    deleteCookiesMatchingArrays(["c_nn"])
    return aprovalNumberCookie || ""
  }
  return null
}
const getInstallmentsCookie = (pnr) => {
  const date = SHA256(`${pnr}${getDate()}`).toString()
  if (getCookie("c_pr")) {
    const installmentInformationCookie = parseStringObject(
      getCookie("c_pr"),
      date
    )
    return {
      paymentsEmail: installmentInformationCookie?.e || "",
      installments: installmentInformationCookie?.i || null,
      valueEMD: installmentInformationCookie?.m || 0,
      paymentOption: installmentInformationCookie?.po || "NA",
    }
  }
  return null
}

export {
  getCreditCardError,
  hideInsuranceByExecptions,
  calculateTotalPriceLock,
  calculateTotalInsurance,
  getDevideIdCookie,
  getOriginFromApp,
  getHeaderCardInfo,
  getFFNCookie,
  setCookieForRedirection,
  getItineraryData,
  getConfRespCookie,
  getAprovalNumber,
  getInstallmentsCookie,
}
