import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "80px 30px",
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    height: 298,
    [theme.breakpoints.up("sm")]: {
      padding: "88px 52px 40px 37px",
      height: 270,
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      padding: "104px 82px",
      height: 280,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "104px 102px",
      height: 256,
    },
    "& .MuiTypography-h5": {
      [theme.breakpoints.up("md")]: {
        width: 400,
      },
      [theme.breakpoints.up("lg")]: {
        width: 530,
      },
    },
    "& .contentContainer": {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 5,
        justifyContent: "center",
        width: "60%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: 15,
        width: "50%",
      },
      "& .demoView": {
        display: "inline-flex",
        margin: "5px 20px 0 -40px",
        top: 120,
        listStyle: "none",
        "& li": {
          cursor: "pointer",
          marginRight: 30,
          color: theme.palette.primary.main,
        },
      },
    },
    "& p": {
      color: theme.palette.grey["600"],
      fontSize: 16,
    },
    "& a": {
      cursor: "pointer",
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  segmentContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 32px 0 32px",
    [theme.breakpoints.up("sm")]: {
      padding: "19px 40px 20px 37px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "19px 82px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "23px 102px 0",
    },
    "& .fareContent": {
      display: "flex",
      flexDirection: "column",
      width: 378,
      [theme.breakpoints.up("sm")]: {
        width: "80%",
      },
      "& .flightTitle": {
        fontSize: 20,
        color: theme.palette.grey["700"],
        fontWeight: "inherit",
      },
      "& .flight": {
        fontSize: 16,
      },

      "& .fare": {
        fontSize: 16,
        [theme.breakpoints.up("sm")]: {
          marginLeft: 5,
        },
      },
      "& .fareAndFlight": {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          display: "flex",
          flexDirection: "row",
        },
        "& .pointDivider": {
          color: theme.palette.grey["700"],
          fontSize: 16,
          marginLeft: 5,
          [theme.breakpoints.up("xs")]: {
            display: "none",
          },
        },
      },
    },
    "& .navContent": {
      width: "50%",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        width: "20%",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
      "& .navButton": {
        height: 48,
        minWidth: 48,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: 0,
        borderRadius: "50%",
        [theme.breakpoints.up("md")]: {
          height: 64,
          minWidth: 64,
        },
        "&:first-of-type": {
          marginRight: 8,
        },
        "&.disabledNav": {
          pointerEvents: "none",
          background: theme.palette.grey["300"],
          opacity: "0.5",
        },
      },
    },
  },
  fareDetails: {
    background: theme.palette.grey["100"],
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    minHeight: 360,
    [theme.breakpoints.up("sm")]: {
      marginTop: "-16px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-16px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 20,
    },
    "& p": {
      fontFamily: "monospace",
      color: theme.palette.grey["700"],
      fontSize: 14,
      height: 343,
      overflow: "scroll",
      padding: "0 33px 0 33px",
      overflowX: "hidden",
      whiteSpace: "pre-wrap",
      [theme.breakpoints.up("sm")]: {
        padding: "0 35px 0 35px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "0 82px 0 82px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "23px 124px 0 102px",
      },
    },
  },
  blurEffect: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    height: 84,
    background: `
    linear-gradient(
      to bottom,
      rgba(239,239,238,0) 0%,
      ${theme.palette.common.white} 100%
    )
  `,
  },
  flightMessage: {
    background: theme.palette.grey["100"],
    padding: "110px 48px 101px 48px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: 339,
    marginTop: 16,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      padding: "101px 70px 128px 70px",
      height: 309,
    },
    [theme.breakpoints.up("md")]: {
      padding: "76px 264px 89px 264px",
      height: 255,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 20,
      padding: "98px 357px 142px 357px",
      height: 318,
    },
    "& .message": {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      textAlign: "center",
      margin: "auto",
      alignContent: "flex-end",
      alignItems: "center",
      color: theme.palette.grey["600"],
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        width: 486,
      },
      "& .outlineIcon": {
        color: theme.palette.grey["400"],
        marginBottom: 11,
      },
    },
  },
  fareDetailsLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.grey["100"],
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    minHeight: 360,
  },
  modalWidth: {
    [theme.breakpoints.up("lg")]: {
      width: 1200,
    },
    [theme.breakpoints.down("md")]: {
      width: 900,
    },
    [theme.breakpoints.down("sm")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: 339,
    },
    [`@media (width:${theme.breakpoints.values.phoneSE}px)`]: {
      width: 283,
    },
  },
}))

export default useStyles
