import { defineMessages } from "react-intl"

const definedMessages = defineMessages({
  title: {
    id: "modifyLanguageModal.title",
  },
  subText: {
    id: "modifyLanguageModal.subText",
  },
  continueCTA: {
    id: "modifyLanguageModal.continueCTA",
  },
  stayCTA: {
    id: "modifyLanguageModal.stayCTA",
  },
  titleWCAG: {
    id: "modifyLanguageModalWCAG.title",
  },
  containerWCAG: {
    id: "modifyLanguageModalWCAG.container",
  },
  continueCTAWCAG: {
    id: "modifyLanguageModalWCAG.continueCTA",
  },
  stayCTAWCAG: {
    id: "modifyLanguageModalWCAG.stayCTA",
  },
  closeWCAG: {
    id: "modifyLanguageModalWCAG.closeButton",
  },
})
export default definedMessages
