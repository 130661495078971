import { takeEvery, call, put } from "redux-saga/effects"

import api from "../../api"
import TYPES from "./authTypes"
import { authActions } from "../../store/auth/authSlice"
import {
  setCookiesMatchingArrays,
  encryptInfo,
  passengerProfileTransform,
  deleteCookiesMatchingArrays,
  getCookie,
} from "../../utils/auth"
import { generalActions } from "../../store/general/generalSlice"

const setCookies = ({
  data: {
    token,
    profile: { firstName, lastName, level, miles },
    username,
  },
}) =>
  setCookiesMatchingArrays(
    [
      "masterSessionId",
      "lp_ffn",
      "lp_firstname",
      "lp_lastname",
      "lp_miles",
      "lp_level",
    ],
    [token, username, firstName, lastName, miles, level]
  )

function* fetchConnectMilesLogin({ payload: { password, userName } }) {
  try {
    const getPasswordEncypted = yield call(encryptInfo, password)

    const { data } = yield call(api.connectMiles.logIn, {
      payload: {
        userName,
        password: getPasswordEncypted,
      },
    })
    const { sessionToken: token, username: membership, profile } = data
    yield put(authActions.loginSuccess({ profile, token, membership }))
    yield call(setCookies, { data })
    yield put(generalActions.setModalMemberLogin(false))
    yield put(
      authActions.passengerProfileExtendedRequest({
        token,
        membership,
      })
    )
  } catch (err) {
    yield put(generalActions.logError(err))
    let errorMessage
    switch (err?.response?.data?.code || "") {
      case "4503":
        errorMessage = "errorbannerBlockedAccount"
        break
      default:
        errorMessage = "errorbannerWrongData"
    }
    yield put(authActions.loginFailure(errorMessage))
  }
}

const cleanLoginCookies = () =>
  deleteCookiesMatchingArrays([
    "masterSessionId",
    "lp_ffn",
    "lp_firstname",
    "lp_lastname",
    "lp_miles",
    "lp_level",
  ])

function* logOutConnectMiles({ payload = true }) {
  try {
    const getTokenFromCookies = getCookie("masterSessionId")
    const getUserNameFromCookies = getCookie("lp_ffn")
    yield call(api.connectMiles.logOut, {
      token: getTokenFromCookies,
      username: getUserNameFromCookies,
    })
    yield call(cleanLoginCookies)
    yield put(authActions.catchLogoutEvent(true))
    if (payload) window.location.reload(false)
  } catch (error) {
    yield put(generalActions.logError(error))
    yield call(cleanLoginCookies)
    yield put(authActions.catchLogoutEvent(false))
    if (payload) window.location.reload(false)
  }
}

function* fetchPassengerProfileExtended({ payload }) {
  try {
    const { data } = yield call(api.connectMiles.getProfile, payload)
    const prueba = yield call(passengerProfileTransform, data)
    yield put(authActions.passengerProfileExtendedSuccess(prueba))
  } catch (e) {
    yield put(generalActions.logError(e))
    yield call(cleanLoginCookies)
    yield put(
      authActions.passengerProfileExtendedFailure({
        code: "CONNECT_MILES_GET_PROFILE_FAILURE",
        message: "Unexpected server error",
      })
    )
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_AUTH_REQUEST, fetchConnectMilesLogin)
  yield takeEvery(
    TYPES.PASSENGER_PROFILE_EXTENDED_REQUEST,
    fetchPassengerProfileExtended
  )
  yield takeEvery(TYPES.LOGOUT_REQUEST, logOutConnectMiles)
}

export default authSaga
