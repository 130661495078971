const EVENT_ACTION_TYPES = {
  SET_LANG: "general/setLang",
  SET_CHANGE_FARE_MODAL_OPTION_SELECTED:
    "flight/setChangeFareModalOptionSelected",
  SHOW_NOT_AVAILABLE_PAGE: "reservation/showNotAvailablePage",
  AUTH_LOGIN_FAILURE: "auth/loginFailure",
  AUTH_LOGIN_SUCCESS: "auth/loginSuccess",
  SET_FLIGHTS_SELECTED: "flight/setFlightsSelected",
  GENERAL_LOG_ERROR: "general/logError",
}

const CLASS_OF_SERVICES = {
  A: "Alpha",
  B: "Bravo",
  C: "Charlie",
  D: "Delta",
  E: "Echo",
  F: "Foxtrot",
  G: "Golf",
  H: "Hotel",
  I: "India",
  J: "Juliet",
  K: "Kilo",
  L: "Lima",
  M: "Mike",
  N: "November",
  O: "Oscar",
  P: "Papa",
  Q: "Quebec",
  R: "Romeo",
  S: "Sierra",
  T: "Tango",
  U: "Uniform",
  V: "Victor",
  W: "Whiskey",
  X: "X-ray",
  Y: "Yankee",
  Z: "Zulu",
}

const FARE_FAMILY_LETTER = {
  C: "BF",
  J: "BF",
  D: "BP",
  R: "BP",
  Y: "EF",
  B: "EF",
  M: "EF",
  H: "EC",
  Q: "EC",
  K: "EC",
  V: "EC",
  U: "EC",
  S: "EC",
  O: "EC",
  W: "EC",
  E: "EB",
  L: "EB",
  T: "EB",
  A: "EB",
}

const FARE_FAMILY_CODE_CATEGORY = {
  EB: "BAS",
  EC: "CLS",
  CF: "CLF",
  BF: "BFU",
  BP: "PRO",
  EF: "EFU",
}

const CABIN_CATEGORY = {
  BAS: "Economy",
  CLS: "Economy",
  CLF: "Economy",
  EFU: "Economy",
  PRO: "Business",
  BFU: "Business",
}

const FARE_FAMILY_CATEGORY = {
  BAS: "Basic",
  CLS: "Classic",
  CLF: "Flex",
  EFU: "Full",
  PRO: "Promo",
  BFU: "Full",
}

const FARE_FAMILY_POSITION_BY_CATEGORY = {
  BAS: 1,
  CLS: 2,
  CLF: 3,
  EFU: 4,
  PRO: 1,
  BFU: 2,
}

const FARE_FAMILY_MAP = {
  BAS: "EB",
  CLS: "EC",
  CLF: "CF",
  BFU: "BF",
  PRO: "BP",
  EFU: "EF",
}

const ROUTE_TYPE = {
  ROUNDTRIP: "RT",
  ONEWAY: "OW",
  MULTICITY: "MC",
  STOPOVER: "SO",
}

export {
  CLASS_OF_SERVICES,
  FARE_FAMILY_LETTER,
  FARE_FAMILY_CATEGORY,
  FARE_FAMILY_CODE_CATEGORY,
  CABIN_CATEGORY,
  FARE_FAMILY_POSITION_BY_CATEGORY,
  FARE_FAMILY_MAP,
  ROUTE_TYPE,
  EVENT_ACTION_TYPES,
}
