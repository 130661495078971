import React from "react"
import PropTypes from "prop-types"
import { Divider } from "@material-ui/core"
import { injectIntl } from "react-intl"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import Typography from "@bit/vibenitez.copa-components-library.typography"
import ModalCo from "../../../../../ToLibrary/modal/modalCo"
import useStyles, { ButtonOutlined as Button } from "./styles"

import { useObserveWindowResize } from "./customHooks"
import definedMessages from "./message"

const ConfirmLanguageChangeModal = ({
  isOpen,
  onClose,
  handleOnApply,
  intl,
}) => {
  const { formatMessage } = intl
  const styles = useStyles()
  useObserveWindowResize(onClose)
  return (
    <ModalCo
      onClose={onClose}
      open={isOpen}
      showClose
      classes={{ paper: styles.modal }}
      aria-labelledby="confirm-dialog-title"
      data-cy="container-change-lang"
      aria-describedby="confirm-dialog-description"
      aria-modal="true"
      wcagObject={[
        { message: "", id: "change-language" },
        { message: formatMessage(definedMessages.closeWCAG) },
      ]}
    >
      <DialogContent data-cy="content-inf-change-lang">
        <DialogTitle className="row-content" disableTypography>
          <Typography variant="h5" color="primary" id="confirm-dialog-title">
            {formatMessage(definedMessages.title)}
          </Typography>
        </DialogTitle>
        <DialogContentText
          className="row-content"
          id="confirm-dialog-description"
          variant="body1"
          color="inherit"
        >
          {formatMessage(definedMessages.subText)}
        </DialogContentText>
      </DialogContent>

      <Divider aria-hidden />
      <DialogActions className={styles.footer}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          data-cy="btn-confirm-change-lang"
          onClick={handleOnApply}
          aria-label={formatMessage(definedMessages.continueCTAWCAG)}
        >
          {formatMessage(definedMessages.continueCTA)}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          data-cy="btn-cancel-change-lang"
          onClick={onClose}
          autoFocus
          aria-label={formatMessage(definedMessages.stayCTAWCAG)}
        >
          {formatMessage(definedMessages.stayCTA)}
        </Button>
      </DialogActions>
    </ModalCo>
  )
}

ConfirmLanguageChangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOnApply: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(ConfirmLanguageChangeModal)
