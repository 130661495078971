import React, { Component, lazy } from "react"
import { connect } from "react-redux"
import { Switch } from "react-router-dom"
import PropTypes from "prop-types"
import { IntlProvider, FormattedMessage } from "react-intl"
import isEmpty from "lodash/isEmpty"
import { ErrorBoundary } from "react-error-boundary"

import localeData from "./translations"
import { configCurrency } from "./translations/config"

// @component general
import FeatureFlags from "./featureFlags"
import UserRoute from "./components/commons/components/UserRoute"
import LoadingCo from "./components/UI/Loading/loadingCo"

import SearchPage from "./components/pages/SearchPage"

import { authActions } from "./store/auth/authSlice"
import { getCookie, deleteCookiesMatchingArrays } from "./utils/auth"
import AlertFull from "./components/UI/alertFull/alertFullCo"
import { generalActions } from "./store/general/generalSlice"
import ErrorFallBack from "./components/errorBoundary"

const MainLayout = lazy(() => import("./components/layouts/main"))

const ResultPage = lazy(() => import("./components/pages/resultPage"))
const SummaryPage = lazy(() => import("./components/pages/summaryPage"))
const ConfirmationPage = lazy(() =>
  import("./components/pages/confirmationPage")
)
const NotEligible = lazy(() => import("./components/pages/notEligiblePage"))
const NotFound = lazy(() => import("./components/pages/notFound"))
const CriticalError = lazy(() => import("./components/pages/criticalError"))

const InterstitialLoading = lazy(() =>
  import("./components/commons/interstitial/interstitial")
)

class App extends Component {
  componentDidMount = () => {
    const { retrieveSession, history } = this.props
    const getTokenFromCookies = getCookie("masterSessionId")
    const getUserNameFromCookies = getCookie("lp_ffn")
    const shouldRedirectAfterCriticalError = getCookie("critical-error")

    if (shouldRedirectAfterCriticalError) {
      deleteCookiesMatchingArrays(["critical-error"])
      history.push("/booking-panel")
    }

    if (!isEmpty(getTokenFromCookies) && FeatureFlags.TURN_ON_LOGIN) {
      retrieveSession({
        token: getTokenFromCookies,
        membership: getUserNameFromCookies,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { currency } = this.props
    if (prevProps.currency !== currency) {
      configCurrency(currency)
    }
  }

  renderLoading = (loading, isInterstitialLoading) => {
    if (loading) {
      if (isInterstitialLoading) {
        return (
          <InterstitialLoading
            loadingText={<FormattedMessage id="interstitial.loading" />}
            titleText={<FormattedMessage id="interstitial.title" />}
            decriptionText={<FormattedMessage id="interstitial.description" />}
            wcagDescription={
              <FormattedMessage id="interstitial.wcagDescription" />
            }
          />
        )
      }
      return <LoadingCo />
    }
    return false
  }

  render() {
    const {
      location,
      lang,
      isLoading,
      isInterstitialLoading,
      fullAlert,
      alertMessage,
      displayFullAlert,
      hideIconClose,
    } = this.props
    const messages = localeData[lang] || localeData.en

    return (
      <IntlProvider locale={lang} messages={messages}>
        <ErrorBoundary fallback={<ErrorFallBack />}>
          <AlertFull
            visible={fullAlert}
            closeIcon
            data-cy="alertFull"
            type={alertMessage.type}
            message={alertMessage.message}
            roleAlert={alertMessage.role}
            onClose={displayFullAlert}
            hideClose={hideIconClose}
          />
          {this.renderLoading(isLoading, isInterstitialLoading)}
          <Switch>
            <UserRoute
              location={location}
              path="/"
              exact
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: false,
                showPrice: false,
              }}
              ariaText="searchPageSEO.description"
              idContainer="searchPageId"
              pageTitle="searchPageSEO.titlePage"
              metaTypeSEO="searchPageSEO.description"
              keywords={["searchPageSEO.keywords"]}
              component={<SearchPage />}
            />
            <UserRoute
              location={location}
              path="/resultPage"
              exact
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: true,
                showPrice: false,
              }}
              ariaText="resultPageSEO.description"
              idContainer="resultPageId"
              pageTitle="resultPageSEO.titlePage"
              metaTypeSEO="resultPageSEO.description"
              keywords={["resultPageSEO.keywords"]}
              component={<ResultPage />}
            />
            <UserRoute
              location={location}
              path="/summary"
              exact
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: true,
                showPrice: false,
              }}
              ariaText="summarySEO.description"
              idContainer="summaryID"
              pageTitle="summarySEO.title"
              metaTypeSEO="summarySEO.description"
              keywords={["summarySEO.keywords"]}
              component={<SummaryPage />}
            />
            <UserRoute
              location={location}
              path="/confirmation"
              exact
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: false,
                showPrice: false,
              }}
              ariaText="confirmation.heading.title"
              idContainer="confirmationID"
              pageTitle="confirmationSEO.titlePage"
              metaTypeSEO="confirmationSEO.description"
              keywords={["confirmationSEO.keywords"]}
              component={<ConfirmationPage />}
            />
            <UserRoute
              location={location}
              path="/notEligible/:pnr?/:lastName?"
              exact
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: false,
                showPrice: false,
              }}
              ariaText="notEligiblePageWCAG.container"
              idContainer="notEligibleId"
              pageTitle="notEligiblePageWCAG.title"
              metaTypeSEO="notEligiblePageWCAG.container"
              keywords={["notEligiblePage.keywords"]}
              component={<NotEligible />}
            />
            <UserRoute
              location={location}
              path="/critical-error"
              exact
              layout={MainLayout}
              layoutProps={{
                showEditButton: false,
                showPrice: false,
                showInfo: false,
              }}
              ariaText="criticalErrorPage.description"
              idContainer="criticalErrorPageID"
              pageTitle="criticalErrorPage.titlePage"
              metaTypeSEO="criticalErrorPage.description"
              keywords={["notFoundPage.keywords"]}
              component={<CriticalError />}
            />
            <UserRoute
              location={location}
              layout={MainLayout}
              layoutProps={{
                headerVariant: "gradient700",
                showEditButton: false,
                showPrice: false,
              }}
              ariaText="notFoundPageWCAG.content"
              idContainer="notFoundId"
              pageTitle="notFoundPageWCAG.title"
              metaTypeSEO="notFoundPage.description"
              keywords={["notFoundPage.keywords"]}
              component={<NotFound />}
            />
          </Switch>
        </ErrorBoundary>
      </IntlProvider>
    )
  }
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  retrieveSession: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInterstitialLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  alertMessage: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    role: PropTypes.string,
  }).isRequired,
  fullAlert: PropTypes.bool.isRequired,
  hideIconClose: PropTypes.bool.isRequired,
  displayFullAlert: PropTypes.func.isRequired,
}

const mapStateToProps = ({ general }) => ({
  lang: general.lang,
  hideIconClose: general.hideIconClose,
  isLoading: !!general.loading,
  isInterstitialLoading: !!general.isInterstitialLoading,
  currency: general.currency,
  fullAlert: general.displayFullAlert,
  alertMessage: general.fullAlertMessage,
})

const mapDispatchToProps = (dispatch) => ({
  retrieveSession: (credentials) =>
    dispatch(authActions.passengerProfileExtendedRequest(credentials)),
  displayFullAlert: () => dispatch(generalActions.displayFullAlert()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
