import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import clsx from "clsx"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import DividerCo from "@bit/vibenitez.copa-components-library.divider"
import ModalColumnsCo from "../../../ToLibrary/modal/modalColumnsCo"
import ModalCo from "../../../ToLibrary/modal/modalCo"
import useStyles from "./styles"
import { deleteCookiesMatchingArrays } from "../../../utils/auth"
import { authActions } from "../../../store/auth/authSlice"
import { redirectToMyTrips } from "../../../utils/redirectFlow"

const EndSessionModal = ({ isOpen, execLogout }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)
  const {
    location: { search: locationSearch },
  } = useHistory()

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  const handleRedirectTo = () => {
    const params = new URLSearchParams(locationSearch)
    const pnr = params.get("pnr")
    const lastName = params.get("lastName")

    setOpen(false)
    deleteCookiesMatchingArrays(["exp_time"])
    execLogout()
    redirectToMyTrips(pnr, lastName).runWith(window.location)
  }
  return (
    <ModalCo
      data-cy="endSessionModal"
      classes={{ paper: classes.modal }}
      onClose={handleRedirectTo}
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      wcagObject={[
        {
          id: "container",
          message: formatMessage({
            id: "endSession.endSessionWCAG",
          }),
        },
      ]}
    >
      <ModalColumnsCo>
        <div
          data-cy="endSessionM-contentTitle"
          className={clsx(classes.contentInfo, classes.contentLeft)}
        >
          <TypographyCo
            data-cy="endSessionM-title"
            color="primary"
            variant="h5"
          >
            {formatMessage({ id: "endSession.endSessionTitle" })}
          </TypographyCo>
        </div>
        <div
          data-cy="endSessionM-contentDescription"
          className={clsx(classes.contentInfo, classes.contentRight)}
        >
          <TypographyCo data-cy="endSessionM-description" variant="body1">
            {formatMessage({ id: "endSession.endSessionBoddy" })}
          </TypographyCo>
        </div>
      </ModalColumnsCo>
      <DividerCo aria-hidden />
      <div data-cy="endSessionM-contentBtn" className={classes.contentButton}>
        <ButtonCo
          data-cy="endSessionM- button"
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleRedirectTo}
          aria-label={formatMessage({
            id: "endSession.endSessionBtnWCAG",
          })}
        >
          {formatMessage({ id: "endSession.endSessionBtn" })}
        </ButtonCo>
      </div>
    </ModalCo>
  )
}

EndSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  execLogout: PropTypes.func.isRequired,
}

const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch) => ({
  execLogout: () => dispatch(authActions.logoOutRequest(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EndSessionModal)
