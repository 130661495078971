import React from "react"
import { Container, Dialog, Grid, SvgIcon } from "@material-ui/core"
import PropTypes from "prop-types"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import { ReactComponent as CopaLogo } from "../../assets/images/copaairlines-logo-blue.svg"
import bannerImage from "../../assets/images/Interstitial-banner-biosecurity.png"
import AnimationDisplayer from "../animationDisplayer/animationDisplayer"
import SpinnerCo from "../Spinner/spinnerCo"
import * as AnimationData from "./animationSrc.json"

import useStyles from "./styles"

function Interstitial({
  loadingText,
  titleText,
  decriptionText,
  wcagDescription,
}) {
  const classes = useStyles()
  return (
    <Dialog
      data-cy="interstitial-backdrop"
      className={classes.root}
      open
      fullScreen
    >
      <Container
        data-cy="interstitial-container"
        className={classes.interstitial}
      >
        <div
          data-cy="interstitial-animationDisplayer"
          className={classes.animationContainer}
        >
          <AnimationDisplayer
            config={AnimationData.default}
            wcagDescription={wcagDescription}
          />
        </div>
        <Grid
          data-cy="interstitial-loadingContainer"
          className={classes.loadingContainer}
        >
          <Grid item xs={12} container justifyContent="center">
            <SpinnerCo color={theme.palette.grey["500"]} />
          </Grid>
          {loadingText && (
            <Grid item xs={12} container justifyContent="center">
              <TypographyCo
                data-cy="interstitial-loadingText"
                variant="body2"
                className={classes.loading}
              >
                {loadingText}
              </TypographyCo>
            </Grid>
          )}
        </Grid>
        {titleText && (
          <Grid
            container
            data-cy="interstitial-gridContaniner"
            direction="row"
            className={classes.box}
            alignItems="center"
          >
            <Grid className={classes.imageBanner} item xs={12} sm="auto">
              <img
                src={bannerImage}
                alt={titleText}
                className={classes.imageBannerContent}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <TypographyCo
                data-cy="interstitial-titleText"
                variant="h4"
                color="primary"
                className={classes.title}
              >
                {titleText}
              </TypographyCo>

              {decriptionText && (
                <TypographyCo
                  data-cy="interstitial-description"
                  variant="body2"
                  className={classes.description}
                >
                  {decriptionText}
                </TypographyCo>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <SvgIcon
            viewBox="0 0 177 30"
            classes={{
              root: classes.logo,
            }}
          >
            <CopaLogo />
          </SvgIcon>
        </Grid>
      </Container>
    </Dialog>
  )
}

Interstitial.propTypes = {
  loadingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  titleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  decriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  wcagDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
}

Interstitial.defaultProps = {
  loadingText: "",
  titleText: "",
  decriptionText: "",
  wcagDescription: "",
}

export default Interstitial
