import React from "react"
import PropTypes from "prop-types"
import FormHelperText from "@material-ui/core/FormHelperText"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: "0.75rem",
    marginTop: 0,
    display: "flex",
    alignItems: "center",
  },

  helperTextContainer: ({ helperTextContainer }) => ({
    height: 20,
    helperTextContainer,
  }),
  text: (props) => ({
    ...props,
  }),
  icon: {
    marginTop: 4,
    marginRight: 10,
    fontSize: 14,
  },
}))

const InlineHelperText = ({
  helperText,
  WCAGHelperText,
  isError,
  showMessage,
  icon,
  styleTextInline,
  id,
  helperTextContainer,
}) => {
  const classes = useStyles(styleTextInline)
  return (
    <div
      data-cy={`inline-${id}`}
      className={clsx(classes.helperTextContainer, helperTextContainer)}
    >
      {showMessage && (
        <FormHelperText
          id={id}
          aria-label={WCAGHelperText}
          className={classes.helperText}
          error={isError}
        >
          {icon && <span>{icon}</span>}
          <span className={classes.text}>{helperText}</span>
        </FormHelperText>
      )}
    </div>
  )
}

InlineHelperText.propTypes = {
  id: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  isError: PropTypes.bool,
  showMessage: PropTypes.bool,
  icon: PropTypes.element,
  helperTextContainer: PropTypes.string,
  styleTextInline: PropTypes.oneOfType([PropTypes.object]),
  WCAGHelperText: PropTypes.string,
}

InlineHelperText.defaultProps = {
  isError: false,
  showMessage: false,
  icon: null,
  styleTextInline: {},
  helperText: "",
  helperTextContainer: null,
  WCAGHelperText: null,
}

export default InlineHelperText
