export default {
  topBar: {
    modifySearch: "New search",
    description: "Modify trip",
    reservation: "Reservation code: {reservationCode}",
    reservationXS: "Reservation: {reservationCode}",
    passengers:
      "{numberOfPassengers, plural, one {# Passenger} other {# Passengers} }",
    loginCTA: "Log In",
    miles: "miles",
    close: "To Close",
    backToMytrips: "Return to My Trips",
    membersMenu: {
      personalizedGreeting: "Hello,",
      LogoutCTA: "Log out",
      accountCTA: "My account",
      connectmilesLabel: "# CONNECTMILES",
      statusLabel: "STATUS",
      milesLabel: "MILES BALANCE",
      myAccountExternalLink: "/en/web/profile",
    },
    loginBox: {
      credentialsLabel: "Email, ConnectMiles number or username",
      required: "This field is required",
      credentialsPlaceholder: "Enter your username",
      inlineErrorEmptyField: "This field is required",
      passwordLabel: "Password",
      passwordfieldPlaceholder: "Enter your password",
      forgotLink: "Forgot Password?",
      forgotExternalLink: "https://www.copaair.com/en/web/gs/forgot",
      createaccountlink: `Don't have an account? <a> Join now </a>`,
      createaccountlinkExternal: "https://www.copaair.com/en/web/gs/enrollment",
      emailMatch: "E-mails do not match",
      errorbannerWrongData:
        "The information you provided doesn't match our records. Please check your entries and try again.",
      errorbannerBlockedAccount:
        "Your account has been temporarily locked for security reasons. Please contact the ConnectMiles Service Center for assistance. <a> Learn more </a>",
    },
  },
  topBarWCAG: {
    logo: "Copa Airlines Logo. Press Enter to go to Copa Airlines main website.",
    description:
      "Modify trip section. You are going to do a change in your reservation {reservationCode} the change will apply to all {numberOfPassengers} passenger.",
    languageSelector:
      "Language selection button. The current language is {language}. Press Enter to change the current language selected.",
    loggedIn:
      "You've logged in with the user [Nombre]. Press Enter to open the Member's Menu",
    login:
      "ConnectMiles Login button, allows you to get access to your member profile. Press Enter to go to Login form.",
    editButton:
      "Edit button Flight change parameters. Press Enter to modify your change parameters.",
    backToMytrips:
      "Return button. Press enter to return to My Trips details page.",
    membersMenu: {
      connectMilesLogoAlt: "Logo de ConnectMiles",
      logoutButton:
        "Sign out from your ConnectMiles account, button. Press enter to sign out of your ConnectMiles account.",
      myAccountButton:
        "My account, button. Press enter to open your ConnectMiles profile page, this page opens in a new tab of your browser",
      container:
        "ConnectMiles number {number} ConnectMiles menu. Hello {name} your connectMiles number is {number} and your current status is {status}. To exit press the (ESC) key",
    },
    loginBox: {
      CredentialsLabel: "Enter your ConnectMiles email, number or username",
      PasswordLabel: "Enter your ConnectMiles password",
      loginButton: "Login button. Press enter to login with your credentials",
      fortgotPassword:
        "Forgot Password link. Press enter to go to ConnectMiles and recover your password",
      Close: "Close buttom, press enter to close the pop up",
      InlineErrorMandatoryFiled: "Error. This field is mandatory",
      CreateAccountlink:
        "Link to create account. Press enter if you don't have an account and want to create an account now. The connectmiles page will open in a new tab on your browser.",
      ErrorBannerAlertWrongInformation:
        "Error. The information you provided doesn't match our records. Please check your entries and try again.Er",
      ErrorBannerAlertBlockedAccount:
        "Error. Your account has been temporarily locked for security reasons. Please contact the ConnectMiles Service Center for assistance. <link to ConnectMiles call center number>",
    },
  },
  searchPage: {
    heading: {
      subtitle: "Your trip to {city}",
      title: "How do you want to modify your trip?",
      description: "You can modify dates or travel time",
      containerWCAG:
        "Modify Flight section, here you can select the type of change you want to make.",
    },
    editHeading: {
      title: "New search",
      ctaEdit: "Return",
      wcagContent:
        "Modify flight change section, here you can change the type of change you want to make.",
      wcagBton: "Return button. Press enter to return to the page you were on.",
    },
    notResultsDisclaimer:
      "There is no availability on the selected dates and / or cities. Please modify your search and try again.",
    fatalErrorDisclaimer:
      "Sorry, due to technical difficulties, we're not able to continue with your trip modification. Please try again later. ",
    ctaSearchFlight: "Search flight options",
    ctaSearchFlightWCAG:
      "Search button. Press Enter to proceed with the search for flight options.",
    modeSelector: {
      title: "What do you want to modify?",
      option1Text: "Modify dates and/or time",
      option3Info: "Current date: {departureDate}",
      option1Info: "Current dates: {departureDate} - {arrivalDate}",
      option2Text: "Change cities and dates",
      option4Info: "{departureCode} · {arrivalCode}, {departureDate}",
      option2Info:
        "{departureCode} · {arrivalCode}, {departureDate} - {arrivalDate}",
    },
    modeSelectorWCAG: {
      changeDates:
        "Option to change the date and / or hours of your trip. Press enter to select this change option",
      changeRoutes:
        "Option to change the city and date of your trip. Press enter to select this change option",
    },
    datesSelector: {
      title: "Select the flights you want to modify",
      infoSegment:
        "Changes will apply to all passengers on this reservation. If you want to advance your trip, some dates may not be available due to applicable restrictions. Your current flights will not change until you confirm your new itinerary.",
      flightNumber: "Flight {index}",
      originTitle: "From",
      originPlaceholder: "Enter origin",
      originFocusPlaceholder: "City, country or airport",
      destinationTitle: "To",
      destinationPlaceholder: "Enter destination",
      destinationFocusPlaceholder: "City, country or airport",
      noResultsAutocomplete: "Oops, no results found",
      noResultsAutocompleteHelperText: "Please modify your search",
      noResultsHelperTextOrigin: "Select a valid origin",
      noResultsHelperTextDestination: "Select a valid destination",
      emptyOrigin: "Select an origin",
      emptyDestination: "Select a destination",
      datesTitle: "Travel dates",
      emptyDate: "Select travel date",
      emptyOriginWhenDestination: "You must select an origin first",
      airportListFail: "Oops, we couldn't load the airports.",
      retryButton: "Retry",
      datePicker: {
        dateRoundTrip: "Travel dates",
        dateRoundTripPlaceHolder: "Enter travel dates",
        dateOneWay: "Travel date",
        dateOneWayPlaceholder: "Enter travel date",
        flexibleDates: "Flexible dates",
        confirmCTA: "Confirm",
        departure: "Departure",
        return: "Return",
      },
      keyboardShortcuts: {
        title:
          "Information panel where you can learn more about keyboard navigation for selecting dates. Continue to learn keyboard shortcuts.",
        enter: "Confirm the selected date (Enter)",
        leftAndRight:
          "Change day backward (left arrow) or forward (right arrow)",
        upAndDown: "Previous week (up arrow) or next week (down arrow)",
        pageUpAndPageDn:
          "Previous month (Page up key) or to the next month (Page down key)",
        homeAndEnd: "Week's first day (Home key) or last day (End key)",
        escape: "Return date entry field (Escape key)",
        info: "Open Keyboard Navigation information panel",
        botoni: "Open Keyboard Navigation information panel (i key)",
        closeBtn:
          "Close button. Press Enter to close this informational panel and return to date selection",
      },
      sameDay:
        "Modifications within the same flight day can only be requested at the airport. Select another date to continue.",
    },
    changeRouteSelector: {
      changeRoute:
        "Changes available within the country of your original reservation.",
      notAvailableTitle: "City unavailable on this platform.",
      errorMesaje:
        "To change your country of origin, please contact our <a>Reservation Center</a>.",
      link: "https://www.copaair.com/en/web/gs/reservation-center",
    },
    datesSelectorWCAG: {
      originField:
        "Origin. Enter the city, country or airport of origin of your trip.",
      airportLoadingFail:
        "Load error. We couldn't load the airports. Press Tab to retry and continue your search.",
      swapButton:
        "Swap button. Origin {origin}, Destination {destination} Press Enter to swap, or continue to select travel dates.",
      destinationField:
        "Destination. Enter your destination city, country or airport.",
      checkBoxWCAG:
        "Flight {numFlight} of your trip. Press enter to confirm that you want to change this flight, which is originally from {origin} to {destination} on {date}.",
      checkBoxWCAGNextFlight: "Otherwise press tab to go to the next flight",
      datePicker: {
        dateRoundTrip:
          "Dates Selection. You can select the date of your flight. You can browse by month and day to make your selection.",
        dateOneWayTrip:
          "Search for one-way flights, press enter to advance to Origin selection.",
        travelDateField:
          "Dates Selection. You can select the date of your flight. You can browse by month and day to make your selection.",
        monthSelection: "{month} {year}. Press enter to go to {month} days. ",
        daySelection: "{weekday} {day}. Press enter to select this day.",
        roundTripSelection:
          "You have selected {month}, {weekday} {day} for the outbound flight, please select the return date.",
        oneWaySelection: "You have selected {month}, {weekday} {day}",
        flexibleDatesSelection:
          "Flexible dates. Press enter to activate flexible dates on your search results. {active}",
        roundTripConfirmationCTA:
          "Confirm search dates. Press enter to confirm your search travel dates are: Departing {departureMonth} {departureDay}, returning {returnMonth} {returnDay}",
        oneWayConfirmationCTA:
          "Confirm search date. Press enter to confirm your search travel date is: {departureMonth} {departureDay}",
      },
    },
    disclaimer: {
      title: "When making a change to your trip you should know this:",
      disc1:
        "The cost of the exchange may vary depending on the fare family originally purchased. If the cost of your new itinerary is lower, you will receive a credit for a future flight. Some previously paid taxes may not be refundable. This platform will allow you to make a single change to your booking. If a second change is required, you should do so through our Reservation Center.",
      disc2:
        'When you modifying your reservation, you will lose your original seat selection and should re-select them in our "My Trips" section once you have completed the change. Previously selected or purchased seats may no longer be available on the new flight. If the seat was purchased by another passenger, you will have a credit associated with the reservation to select your new seat. If you do not use the credit, it will not be refundable.',
      disc3:
        "The fare family you select for your new flights (outbound or return), will be applied for the entire itinerary, including the flights you are not modifying. Be sure to review the fare attributes before making the change.",
      link: "Read our exchanges policy",
      webLink:
        "https://copaair.com/en-gs/legal/terms-and-conditions/voluntary-exchanges",
    },
    disclaimerWCAG: {
      contentDisclaimer:
        "Important messages section. Remember that {disc1}, {disc2}, {disc3}",
      contentDisclaimerMobil:
        "Sección de mensajes importantes. Presione enter para conocer los mensajes",
      link: "Change policy link, press enter to see the details of the change policy.",
    },
  },
  searchPageSEO: {
    description:
      "Choose the type of change you want to make to your trip. You can changes the hours, dates or cities.",
    titlePage: "Change Trip - Copa Airlines",
    keywords:
      "Changes, Flights, Change of flights, change of date, change of hours, change reservations, modify flights, change trip, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  resultPageSEO: {
    description:
      "List of all available flights to make your change, select the flight that suits you the best.",
    titlePage: "Flights Results  | Copa Airlines",
    keywords:
      "Changes, Flights, Change of flights, change of date, change of hours, change reservations, modify flights, change trip, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  confirmationSEO: {
    description:
      "Confirmation of the change to your trip. Review the detailed summary of your change.",
    titlePage: "Change Confirmation | Copa Airlines",
    keywords:
      "Changes, Flights, Change of flights, change of date, change of hours, change reservations, modify flights, change trip, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fullWidthAlert: {
    timeLeft: "Your session will expire in {time}.",
  },
  resultPage: {
    heading: {
      title: "Select new itinerary",
      description: "New selection: ",
    },
    fareChangeModal: {
      modalTitle: "Your whole trip will change to {fareFamily}",
      unchangedODModalTitle: "Fare families in new itinerary",
      upgradeDescriptionRT:
        "This action will change all flights on your trip to the {fareFamily} fare, even those flights you did not select for modification. You will now enjoy more benefits. ",
      downgradeDescriptionRT:
        "This action will change all flights on your trip to the {fareFamily} fare, even those flights you did not select for modification. Your travel experience and benefits could be affected. ",
      upgradeDescriptionOW:
        "This action will change all flights on your trip to the {fareFamily} fare. You will now enjoy more benefits.",
      downgradeDescriptionOW:
        "This action will change all flights on your trip to the {fareFamily} fare. Your travel experience and benefits could be affected. ",
      ctaCancel: "Cancel",
      ctaAccept: "Accept",
      fareFamilyDetails: {
        text: "View more details on the fare families",
        link: "https://www.copaair.com/en-gs/travel-information/fare-families/",
      },
      table: {
        flightHead: "Flight",
        oldFareHead: "Original Fare",
        newFareHead: "Will change to",
        warningText: "Cabin Change",
        dateText: "Flight {numberOfFlight} · {date}",
      },
      unchangedODPill: "No changes",
      unchangedODAlert:
        "You have selected different fare families for your flights. For your convenience, you can always choose different fare families for your outbound and return flights. However, some benefits such as refunds or itinerary changes may be restricted.",
    },
    fareChangeModalWCAG: {
      containerUpgrade:
        "Important message. Your whole trip will change to {newFareFamily}. This action will change all the flights of your trip to the {newFareFamily} fare, even the flights that you are not changing. You will now enjoy more benefits. These are the details of your changes: your flight from {origin1} to {destination1} that was in {fareFamily1} will change to {newFareFamily} {alerts1} and your flight from {origin2} to {destination2} that was in {fareFamily2} it will change to {newFareFamily} {alerts2}",
      containerDowngrade:
        "Important message. Your whole trip will change to {newFareFamily}. This action will change all the flights of your trip to the {newFareFamily} fare, even the flights that you are not changing. Your travel experience and benefits could be affected. These are the details of your changes: your flight from {origin1} to {destination1} that was in {fareFamily1} will change to {newFareFamily} {alerts1} and your flight from {origin2} to {destination2} that was in {fareFamily2} it will change to {newFareFamily} {alerts2}",
      ctaCancel:
        "Cancel button. Press enter to go back and select other option.",
      ctaAccept: "Accept Button. Press enter to accept the change.",
      fareFamilyDetailsLink:
        "Fare Families link. Press enter to learn more about our fare families.",
      closeButton:
        "Close modal button. Press the button to close the modal and select another option.",
    },
    fares: {
      BFU: "Business Full",
      PRO: "Business Promo",
      EFU: "Economy Full",
      BAS: "Economy Basic",
      CLS: "Economy Classic",
      CLF: "Economy Classic Flex",
      NONE: " ",
    },
    cardsResult: {
      rountripSearchLabel: "Round Trip",
      oneWaySearchLabel: "One way",
      totalAmount: "Total amount to pay",
      changeItineraty: "Change itinerary for",
      changeItineratyPlural: "Change itinerary from",
      optionText: "View option",
      optionTextPlural: "View options",
      header: {
        buttonFilter: "Sort & Filter",
        economyCabin: "Economy",
        businessCabin: "Business",
      },
      headerWCAG: {
        languageAndStorefrontSelector: {
          countryDropdown:
            "Use the up and down arrow keys to select the country according to your location and the currency of your preference. Press tab to continue into language selection. Press escape to return to the navigation.",
          languageDropdown:
            "Use the up and down arrow keys to select  your preferred language. Press escape to return to the navigation.",
          modifyCTA:
            "Modify Button. Press Enter to modify the country to {currencyCountry} and language to {currencyLanguage}",
          closeCTA:
            "Close button. Press Enter to keep country and language, return to main navigation.",
        },
        loginBox: {
          CredentialsLabel: "Enter your ConnectMiles email, number or username",
          PasswordLabel: "Enter your ConnectMiles password",
          loginButton:
            "Login button. Press enter to login with your credentials",
          fortgotPassword:
            "Forgot Password link. Press enter to go to ConnectMiles and recover your password",
          Close: "Close buttom, press enter to close the pop up",
          InlineErrorMandatoryFiled: "Error. This field is mandatory",
          CreateAccountlink:
            "Link to create account. Press enter if you don't have an account and want to create an account now. The connectmiles page will open in a new tab on your browser.",
          ErrorBannerAlertWrongInformation:
            "Error. The information you provided doesn't match our records. Please check your entries and try again.Er",
          ErrorBannerAlertBlockedAccount:
            "Error. Your account has been temporarily locked for security reasons. Please contact the ConnectMiles Service Center for assistance. <link to ConnectMiles call center number>",
        },
        buttonFilter:
          "Button. According to your search your return flight is on {day}",
      },
      tripCard: {
        viewDetails: "View details",
        flightDetailLayover: "Layover in",
        flightDetailLayovers: "Layovers in",
        flightDetailDirect: "Nonstop",
      },
      tripCardWCAG: {
        viewDetailsDirect:
          "Flight result information. Direct flight from {origin} to {destination} with departure time {departure} and arrival at {arrival}; from {day}, {month}, {year}, operated by {airlines}. Total flight duration is {flightTime}.",
        viewDetailsLayovers:
          "Flight result information. Flight from {origin} to {destination} with departure time {departure} and arrival at {arrival}; from {day}, {month}, {year}, with {layoverLength} {layoverTime} scale operated by {airlines}. Total flight duration is {flightTime}.",
        viewDetails:
          "View details Link. Press enter to see more flight details.",
        hours: "hours",
        hour: "hour",
        minutes: "minutes",
      },
      cabin: {
        cabinText1: "From",
        cabinText2: "Per adult",
        cabinText3: "Sold Out",
        economy: "Economy",
        business: "Business",
        unavailable: "Not available",
        helperTextMobile: "Select a fare",
        oneWay: "One way",
      },
      cabinWCAG: {
        economyCabin: "Economy cabin. Price from {price} per adult",
        economySoldOut: "No availability in economy cabin",
        businessCabin: "Business Cabin. Price from {price} per adult",
        businessSoldOut: "No availability in business cabin",
        economyCabinPromo:
          "Economy Cabin. Price from {price} and with the discount it is  {originalPrice} per adult",
        businessCabinPromo:
          "Business Cabin. Price from {price} and with the discount it is {originalPrice} per adult",
        cabinHelperMobileText: "{type} cabin. Select a fare",
      },
      fareFamilies: {
        basicPill: "Most restrictive fare",
        selectedPill: "You chose this fare",
        restrictivePill: "Restrictive Fare",
        purchased: "Purchased fare",
        ctaBasic: "Select Economy Basic",
        ctaClassic: "Select Economy Classic",
        ctaFull: "Select Economy Full",
        ctaFlex: "Select Classic Flex",
        ctaExtra: "Select",
        ctaBusinessPromo: "Select Business Promo",
        ctaBusinessFull: "Select Business Full",
        ctaBack: "Keep this fare",
        linkText: "Fare details",
        link: "https://www.copaair.com/en-gs/travel-information/fare-families/",
        changePenaltyDisclaimer:
          "The first change without penalty fees is applicable if made at least eight days prior to the departure date*.",
        fareFamilyDisclaimer:
          "Prices shown include fuel charges, taxes, and fees. Check our <link>baggage policy</link> for flights operated by Copa Airlines. Additional charges may apply to overweight, oversized, or extra bags, as well as certain special items.",
        baggagePolicyLink:
          "https://www.copaair.com/en-gs/travel-information/baggage-information/",
        faresPolicyLink:
          "https://www.copaair.com/en-gs/travel-information/fare-families/",
        preferMemberDisclaimer:
          "PreferMember benefits vary depending on member's status.",
        priceIncludeDisclaimer: "Prices include fuel charges, taxes, and fees.",
        baggageDisclaimer:
          "Check our <link>baggage policy</link> for flights operated by Copa Airlines. Additional charges may apply for overweight, oversized, or extra bags, as well as certain special items.",
        mixedFareFamiliesDisclaimer:
          "When selecting mixed <link>fare families</link>, the most restrictive attributes from both fares will be applied.",
        tooltipPillRestrictive:
          "One of the flights has a fare with luggage, seating and change restrictions. Click on 'see details' to know more.",
        baggagePolicyLinkWCAG:
          "Check our baggage policy. Press 'Enter' for more details.",
        faresPolicyLinkWCAG:
          "Explore our fare families and their benefits. Press 'Enter' for more details.",
      },
      fareFamiliesWCAG: {
        basicContainer:
          "Economic Basic fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        classicContainer:
          "EconomicClassic fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        fullContainer:
          "EconomicFull fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        extraContainer:
          "Extra fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        businessPromoContainer:
          "Business Promo fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        businessFullContainer:
          "Business Full fare family, this fare is priced at {price} and has the following attributes {attributeList}",
        cta: "Select Fare button. Press the button to select this fare or click Tab to go to the next fare.",
      },
    },
  },
  summaryPage: {
    title: "Your new trip itinerary",
    subtitle: "Please review your itinerary carefully before continuing.",
    changeFlight: "Modify selection",
    summaryPageCTA: "Continue",
    CTAButton:
      "Continue button. Press the button to continue with the purchase flow.",
    titleCostChange: "Cost of change",
    textCollapsible: "Change without additional cost",
    flightTypeForCookie1: "Round Trip",
    flightTypeForCookie2: "One Way",
    flightTypeForCookie3: "Multi-city",
    flightTypeForCookie4: "Stopover Trip",
    adults: "Adults",
    adult: "Adult",
    children: "Children",
    child: "Child",
    infants: "Lap Infants",
    infant: "Lap Infant",
    itinerary: {
      businessFull: "Business Full",
      businessPromo: "Business Promo",
      economyFull: "Economy Full",
      economyBasic: "Economy Basic",
      economyClassic: "Economy Classic",
      viewDetails: "View details",
      outboundFlight: "Outbound flight",
      returnFlight: "Return flight",
      changeFlight: "Change Flight",
      fareDetails: "Fare rules",
      layover: "Layover in",
      layovers: "Layovers in",
      leg: "Flight {legNumber}",
      pillText: "New flight",
      noChangePillText: "No changes",
    },
    priceBreakdown: {
      confirmationCode: "Authorization code",
      newFare: "New fare",
      oldFare: "Original fare",
      credit: "Credit",
      changePenalty: "Trip Modication penalty",
      subTotalSubTitle: "Subtotal",
      differenceFare: "Fare difference",
      taxSubtitle: "Taxes and fees",
      upliftMessage:
        "You completed this purchase using Uplift. <a>Learn more.</a>",
      upliftUrl: "https://www.copaair.com/en/web/gs/payment-options-uplift",
      disclaimer:
        "By confirming this change, you will receive a travel certificate for {price} valid for future ticket purchases with Copa Airlines.",
      disclamerConfirmation:
        "You will receive a travel certificate for {price}, valid to purchase future tickets with Copa Airlines.",
      chargedAmountSuccesfully:
        "We are processing your payment. You will receive an email with the confirmation of your booking",
      chargedAmountInsuranceSuccesfully:
        "You will receive an email with the confirmation of your purchase. The travel insurance charge will be reflected as a separate charge from the rest of the cost of the reservation.",
      disclaimerBton:
        "By clicking on confirm change, you will be modifying your reservation, and you will not be able to return or make additional modifications on this platform.",
      presidentialAlert:
        "{name}, being a PreferMember Presidential gives you more benefits. To exempt you from the penalty for changes fee please contact our Reservation Center.",
    },
    itineraryNotAvailableModal: {
      title: "We're sorry, the itinerary selected is no longer available",
      description:
        "One or more of the flights you have selected is no longer available, and we cannot proceed with the modification of your trip. Please, restart your search and choose a new itinerary.",
      ctaButton: "Restart search",
    },
    taxesModal: {
      title: "Taxes and fees detail",
      subtitle: "Reservation cost",
      description: "Description",
      amount: "Amount",
      total: "Total taxes and fees",
      payedTaxes: "Taxes and fees previously paid",
    },
  },
  summaryWCAG: {
    itinerary: {
      linkViewDetails:
        "View details Link. Press enter to see more flight details.",
      linkViewRules:
        "Link to see airfare details. Press enter to see the details of the selected fare.",
      linkModify:
        "Change flight link, press enter to return to the flight results page and change the flight selection.",
      containerOutboundWithOneScale:
        "Information of the outbound flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; with {numLayover} scale of {timeLayover} in {codCountry}; the total duration of the flights is {duration} with {fare} fare family.",
      containerInboundWithOneScale:
        "Information of the returning flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; with {numLayover} scale of {timeLayover} in {codCountry}; the total duration of the flights is {duration} with {fare} fare family.",
      containerOutboundWithMultipleScales:
        "Information of the outbound flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; with {numLayover} scales of {timeLayover} in {codCountry}; the total duration of the flights is {duration} with {fare} fare family.",
      containerInboundWithMultipleScales:
        "Information of the returning flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; with {numLayover} scales of {timeLayover} in {codCountry}; the total duration of the flights is {duration} with {fare} fare family.",
      containerOutboundWithoutScale:
        "Information of the outbound flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; the total duration of the flight is {duration} with {fare} fare family.",
      containerInboundWithoutScale:
        "Information of the returning flight selected. The flight is on {dateFlight}, from {startFlight} to {endFlight} with departure time {hourDeparture} and arrival at {hourArrival}; the total duration of the flights is {duration} with {fare} fare family.",
    },
    itineraryNotAvailableModal: {
      container:
        "Important message. Some of the flights you chose are no longer available and we are unable to proceed with changing your reservation. Please select a new itinerary.",
      ctaButton:
        "Change flight button. Press the button to select another itinerary.",
      closeButton: "Press the button to close the modal.",
    },
    taxesModal: {
      container:
        "Taxes and fees modal. From here you can see what taxes and fees apply to your reservation.This are the taxes and fees that apply to you.",
      closeButton:
        "Fees and taxes modal close button. Press the button to close the modal and continue with the purchase.",
      openPayedTaxes:
        "Detail of fees and taxes already paid. Press enter to see the details of the fees and taxes that you originally paid.",
    },
    priceBreakdown: {
      container:
        "Your trip modification summary with a subtotal of {subTotal}, taxes {taxTotal} and Total of {total}",
      linkViewDetails:
        "View taxes details Link. Press enter to see more taxes details.",
    },
    presidentialAlertContainer:
      "Important message. Benefits for passengers with status PreferMember Presidential.",
    presidentialAlertDescription:
      "{name},being a PreferMember Presidential gives you more benefits. To be exempted from the trip modification penalty fee please contact our Reservation Center.",
  },
  summarySEO: {
    title: "Trip Summary - Copa Airlines",
    description:
      "Summary of your trip change, carefully review the information of your new flights. Once you confirm the information, the change will be made to all passengers.",
    keywords:
      "Changes, Flights, Change of flights, change of date, change of hours, change reservations, modify flights, change trip, Copa Airlines, Copaair, copa.com, copaair.com.",
  },
  fareRulesModal: {
    title: "Fare Rules",
    fare: "Fare",
    headingDescription:
      "Here, you will be able to read the fare rules that apply to each flight in this reservation.",
    headingCaption: "Learn more about our fare families.",
    headingLink:
      "https://www.copaair.com/en-gs/travel-information/fare-families/",
    lineMessage:
      "The information on the fare rules is not available for this flight since it is not operated by Copa Airlines.",
    errorMessage:
      "Sorry, the fare rules information is not available at this time. Please try again later.",
    WCAG: {
      modalDescription:
        "Fare Rules modal. Here you can see what rules apply to the fare you have slected.",
      fareContent:
        "Flight from {iataOrigin} to {iataDestination}, Flight number {airlineCode} {flightNumber}, has the following rules: {fareFamilyName}",
      backwardArrow:
        "Previous FLight button. Press enter to view the fare rules that apply to the previous flight.",
      forwardArrow:
        "Next FLight button. Use this button to view the fare rules that apply to the next flight.",
      errorLineMsg:
        "Flight from [Origin IATA CODE] to [destination IATA code], Flight number [Airline code + number], [all text]",
      closeBotton:
        "Fare rules modal close button. Press the button to close the modal.",
    },
  },
  notEligiblePage: {
    heading: {
      flightCaption: "Trip Dates",
      fareCaption: "Fare Family",
      reservationCaption: "Reservation code",
    },
    withCancel: {
      title: "To modify this reservation, please contact our Call Center",
      description:
        "We are currently unable to modify this reservation online. Please contact our <a>Call Center</a> or visit one of our <b>sales offices</b> to manage your booking.",
      linkReservation:
        "https://www.copaair.com/en-gs/customer-service/call-center/",
      linkOffices:
        "https://www.copaair.com/en-gs/customer-service/sales-offices/",
      items: {
        title: "Some reasons may be:",
        item1: "Your reservation has less than 24 hours since it was issued.",
        item2:
          "Your reservation was not purchased through Copa.com, the Call Center, or our Sales Offices.",
        item3: "Your reservation has a special discount applied.",
        item4: "Your itinerary includes flights operated by another airline.",
        item5: "Your reservation was previously modified.",
        item6:
          "Your itinerary has multiple destinations or includes a stay in Panama.",
        item7:
          "You purchased additional services, such as sports equipment, pets, among others.",
        item8:
          "You received an upgrade to Business Class on one or more of the segments of your reservation.",
        item9: "You’ve already completed part of your travel itinerary.",
      },
    },
    cancelTitle: "Other available options",
    cancelButtonText: "Cancel my reservation",
    helpSection: {
      message: "Need Help?",
      reservationOption: {
        text: "Call Center",
        link: "https://www.copaair.com/en-gs/customer-service/call-center/",
      },
      salesOption: {
        text: "Sales Offices",
        link: "https://www.copaair.com/en-gs/customer-service/sales-offices/",
      },
      helpOption: {
        text: "Information Center",
        link: "https://help.copaair.com/hc/en-us",
      },
    },
  },
  notEligiblePageWCAG: {
    title: "Rebooking not eligible - Copa Airlines",
    container:
      "Important message. Reservation is not eligible to make changes.",
    cancelButton:
      "Cancel my reservation button. Press enter to go to the cancel reservation options page.",
    helpSection: {
      reservationOption:
        "Call Center link. Need Help? Press enter to go to the Call Center page.",
      salesOption:
        "Sales Offices link. Need Help? Press enter to go to the Sales Offices page.",
      helpOption:
        "Help Center link. Need Help? Press enter to go to the Help Center page.",
    },
  },
  confirmation: {
    heading: {
      title: "All set! You have completed your change",
      titleWithPricelock: "Ready! Your reservation was saved successfully",
      description:
        "You will soon receive a confirmation of your modification to the email <b></b>. ",
      descriptionWithPay:
        "We are currently processing your payment. You will soon receive a confirmation with the electronic ticket of your reservation to the email <b></b>.",
      codeText: "Reservation Code",
      btnReservation: "Go back to My trips",
      btnPrint: "Print itinerary",
      alert:
        "To prevent your reservation from being automatically canceled, remember to complete your purchase no later than",
      pseAlert:
        "We are processing your trip modification, which may take a few minutes. During this period, you may not be able to access your reservation information. Once completed, you will receive an email with your new e-ticket.",
    },
    itineratyTitle: "Your new trip itinerary",
    passengerInfo: {
      title: "Passengers in this reservation",
      subtitle:
        'These are the passengers who will be traveling. Please verify their information before proceeding. Once your booking is completed you can select or change your seat at "My Trips".',
      adult: "Adult",
      child: "Child",
      infant: "Lap Infant",
      notSeatSelected: "Remember to select seats ",
    },
    multiCityText: "Multi-City Flight",
    errorCreditCards:
      "Sorry, it was not possible to save the credit card in your profile. For your next purchase you will have to re-enter your card information.",
    errorFQTV:
      "Sorry, it was not possible to save {passagers} frequent flyer number in your reservation. To add it, please go to My Trips and add it to the passenger information section.",
    insuranceTitle: "Important information about your travel insurance",
    insurancePaymentAlert:
      "You purchased travel insurance with our strategic ally CHUBB. To process the modification of this service, you must contact CHUBB directly at <a> these contact points</a>, using your reservation code as a reference.",
    insuranceContactLink:
      "https://copa.chubbtravelinsurance.com/copa/pa-en/home.html#",
    description:
      "Travel reservation confirmation page. We detail the entire summary of your purchase.",
    keywords:
      "Flights, Travel, Booking, travel ticket, travel ticket, secure purchases, secure transactions,, Copaair, copa.com, copaair.com, Copa Airlines.",
    descriptionPage: "Confirmation",
    titlePage: "Confirmation Page",
    disclaimers: {
      retract: {
        title: "Important information for passengers traveling from Chile",
        body: "This Purchase does not apply for the right to retract established in the Chilean Consumer Law.",
      },
      bankTransfer: {
        title: "Information related to bank transfer as form of payment",
        body: "The alternative form of payment of bank transfer is only available if you make your purchase with a minimum of 14 days before your trip.",
      },
      bankTicket: {
        title: "Information related to boleto bancario as form of payment",
        body: "The alternative form of payment of boleto bancario is only available if you make your purchase with a minimum of 14 days before your trip.",
      },
      OTP: {
        title: "On-Time performance about your flights",
        body: 'If you are traveling to/from Brazil, you can check the percentage of flights delays and cancellations by clicking on  "View more details"  link for each flight.',
      },
    },
    pse: {
      title: "Payment Information (PSE Colombia)",
      subtitle:
        "Important message: We are processing your payment. You will receive an email with the confirmation of your booking",
      info: {
        nit: "NIT",
        bank: "Bank",
        company: "Company",
        refNumber: "Reference number",
        paymentDesc: "Description of the payment",
      },
    },
    aditionalServices: {
      title: "Additional services",
      aditionalText:
        "Your travel insurance purchase with our strategic ally for the sum of {price} is being processed. Soon they will send you the payment confirmation and the policy corresponding to the service you purchased.",
      aditionalText2:
        "For any exchange, refund, query or related claim, contact CHUBB directly at these <link>contact points</link>, using your reservation code as a reference.",
      contactPointsLink:
        "https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=en",
    },
    whereToPaySection: {
      whereToPayTitle: "How to pay for your reservation:",
      webText: "Trough “My Trips” on",
      webLink: "https://www.copaair.com/",
      webLinkText: "copa.com",
      callingTo: "Calling our ",
      callingToLink: "https://www.copaair.com/en/web/gs/reservation-center",
      callingToLinkText: "Reservation Center",
      callingToText2: "(only payments with credit card)*",
      anotherWayOfPayment: "You can also pay at our",
      anotherWayOfPaymentLink:
        "https://www.copaair.com/en/web/gs/sales-offices",
      anotherWayOfPaymentLinkText: "sales offices*",
      anotherWayOfPayment2: "or at the airport counter*",
      disclaimer1:
        "*If you make a reservation through copa.com and then wish to pay through our Reservation Center, sales offices or at the airport, a non-refundable administrative fee and / or other additional non-refundable charges will be applied to the total cost of the ticket. Read our ",
      disclaimerLink:
        "https://www.copaair.com/en/web/gs/terms-conditions-book-and-hold-fee",
      disclaimerLinkText: "PriceLock terms and conditions",
      disclaimer2:
        "for more information. These rates are not valid for issuance or payment in our offices in Argentina.",
    },
    priceBreakownPriceLock: "You paid today",
    priceBreakdownDisclaimerText:
      "You have until {date} to complete your purchase.",
  },
  confirmationWCAG: {
    descriptionPage:
      "Your trip modification was completed and your payment is being processed. Your reservation code is {codeReservation}. Once your payment has been processed, you will receive a confirmation email to {email}",
    heading: {
      btnReservation:
        "Go to My Trips button. Press enter to go to My Trips and see the details of your flight.",
      btnPrint:
        "Print page button. Press enter to print this page as proof that your reservation is being processed.",
    },
    passengersWCAG: {
      linkSeat:
        "Seat selection link. Press enter to go to My Trips and select your seats.",
      withSeat:
        "These are the passengers to travel on this reservation: {TypePax} Passenger {namePax} {surnamePax}, with date of birth {birthdate}, with seat {seat}.",
      withOutSeat:
        "These are the passengers to travel on this reservation: {TypePax} Passenger {namePax} {surnamePax}, with date of birth {birthdate}, does not have a seat selected. Yo can select a seat after completing the purchase in the My Trips.",
    },
  },
  viewMoreDetailsModal: {
    title: "Trip details",
    subtitlesCards: "Flight segment {currentflight} of {total}",
    date: "Date",
    Flight: "Flight",
    subtitleTo: "to",
    aircraftType: "Aircraft type",
    operatedBy: "Operated by",
    button: "See available seats",
    layover: "LAYOVER",
    fareFamilyText: "You are traveling in {fare}:",
    viewMoreText: "View more details on the fare families",
    viewMoreLink:
      "https://www.copaair.com/en-gs/travel-information/fare-families/",
    OTPTitle: "Percentage of Cancellations and Delays",
    OTPCancellations: "Cancellations",
    OTPDelays: "Delays greater than 30 min",
    OTPDelays2: "Delays greater than 60 min",
    mixedFareFamilies:
      "You selected flights with different fare families, some of these benefits may be restricted.",
    navigationText: "Flight {flightNumber} of {numberOfFlights}",
    restrictiveFare:
      "This result is Basic fare, which is our most restrictive fare and has the following restrictions: Baggage with additional cost and preselection of seats with cost (or free assignment at the airport).",
    WCAG: {
      modalDescription: "Flight itinerary section",
      itinerarySummary:
        "Flight result information. Flight from {origin} to {destination} with departure time {departureTime} and arrival at {arrivalTime}; from {dateFormat}, with {numberOfLayovers} {timeLayover} scale operated by {airlineName}. Total flight duration is {journeyTime}",
      segmentSummary:
        "Flight information {currentflight} from {total} from {origin} at {departureTime} arriving in {destination} at {arrivalTime} on {date}, with flight number {airlineCode}, with airplane model {aircraftName} and finally operated by {airlineName}",
      segmentSummaryWithOTP:
        "Flight information {currentflight} from {total} from {origin} at {departureTime} arriving in {destination} at {arrivalTime} on {date}, with flight number {airlineCode}, with airplane model {aircraftName} and finally operated by {airlineName}. Information about the Percentage of Cancellations and Delays of this flight. Information about Cancellations. This flight has a percentage of {percent1} of cancellations. Information about Delays greater than 30 min. This flight has a percentage of {percent2} delays greater than 30 min. Information about Delays greater than 60 min. This flight has a percentage of {percent3} delays greater than 60 min.",
      availaibleSeats:
        "Button to see available seats. Press the button to see seat availability on this flight.",
      // From the following line to OTPText3 they are read in the segment id SummaryWithOTP
      OTPTitle:
        "Information about the Percentage of Cancellations and Delays of this flight.",
      OTPText1:
        "Information about Cancellations. This flight has an percentage of {percentage} of cancellations.",
      OTPText2:
        "Information about Delays greater than 30 min. This flight has a percentage of {percentage} delays greater than 30 min.",
      OTPText3:
        "Information about Delays greater than 60 min. This flight has a percentage of {percentage} delays greater than 60 min.",
      fareFamilyText:
        "You are traveling in {fare}:  These are the benefits that correspond to this fare family {list}.",
      viewMoreLink:
        "Link to see more details of the fare family. Press enter to see the details of the fare family.",
      mixedFareFamilies:
        "Alert. You selected flights with different fare families, some of these benefits may be restricted.",
      closeBotton:
        "Close button modal to view flight details. Press the button to close the modal.",
      backwardArrow:
        "Go to the previous flight button. Press this button to see the details of the previous flight.",
      forwardArrow:
        "Go to next flight button. Press enter to see the details of the next flight.",
    },
  },
  interstitial: {
    loading: "Loading",
    title: "Your safety is our priority",
    description:
      "We have strengthened our biosecurity protocols so that you can travel worry-free.",
    wcagDescription: "Your page is loading",
  },
  endSession: {
    endSessionTitle: "Your search has expired",
    endSessionBoddy:
      "The time to complete this reservation has run out. You may begin a new search.",
    endSessionBtn: "Start over",
    endSessionWCAG:
      "Session expired message. The time to complete the change has expired, therefore your change has not completed, please start over.",
    endSessionBtnWCAG: "Back to start",
  },
  paymentErrorModal: {
    title: "We were unable to process your payment.",
    errorMessage: "You have exhausted all attempts to process the payment.",
    body: "To complete this change, please contact us through the <a> Reservation Center. </a>",
    bodyLink:
      "To complete this change, please contact us through the Reservation Center.",
    link: "https://www.copaair.com/en/web/us/reservation-center",
    btnText: "Return to My Trips",
    btnDecriptionWCAG:
      "Go to My Trips button. Press enter to go to My Trips and see the details of your flight.",
    descriptionWCAG:
      "Error message. Sorry, you have exhausted all attempts to process the payment. To complete this change, please contact us through the Reservation Center.",
  },
  criticalErrorPage: {
    titlePage: "Error",
    title: "We're sorry",
    description:
      "We were unable to process your change, please contact our Reservation Center to perform this process.",
    retryButton: "Contact Reservation Center ",
    helpText: "Need help?",
    reservationCenter: "Reservation Center",
    salesOffice: "Sales Offices",
    helpDesk: "Information Center",
    reservationCenterLink:
      "https://www.copaair.com/en/web/gs/centro-de-reservaciones",
    salesOfficeLink: "https://www.copaair.com/en/web/gs/oficinas-de-ventas",
    helpDeskLink: "https://help.copaair.com/hc/en-us",
  },
  criticalErrorPageWCAG: {
    cta: "Error. We were unable to process your change, please contact our Reservation Center to perform this process.",
    reservationCenter:
      "Reservation Center link. Press enter to go to the Reservation Center page.",
    salesOffice:
      "Sales Offices link. Press enter to go to the Sales Offices page.",
    helpDesk: "Help Center link. Press enter to go to the Help Center page.",
  },
  modifyLanguageModal: {
    title: "This action will reset your search",
    subText:
      "This action will reset your search. Any selection of flights will be lost. Are you sure you want to continue?",
    continueCTA: "Continue with changes",
    stayCTA: "Stay in this search",
  },
  modifyLanguageModalWCAG: {
    title: "This action will reset your search",
    container:
      "This action will reset your search. Any selection of flights will be lost. Are you sure you want to continue?",
    continueCTA: "Continue button. Press enter to continue with the change",
    stayCTA:
      "Stay in this search. Button. Press Enter to keep country and language, return to main navigation",
    closeButton:
      "Close button. Press Enter to keep country and language, return to main navigation",
  },
}
