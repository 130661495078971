import React, { memo, useMemo } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { useIntl, FormattedMessage } from "react-intl"
import { isEmpty } from "lodash"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"

// import { useLocation } from "react-router-dom"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"
import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import ModalCo from "../../../../ToLibrary/modal/modalCo"
// import SingleAlertCo from "../../../../ToLibrary/Alert/SingleAlertCo"
// import InfoIcon from "../../UI/Icons/IconInfo"
import CardDetails from "./cardDetails"
import ItineraryCard from "../itineraryCard/itineraryCard"
import SegmentNav from "./segmentNav"
import theme from "../../../../theme"

import { ReactComponent as CheckSVG } from "../../../../ToLibrary/icons/Check.svg"
import { ReactComponent as CrossSVG } from "../../../../ToLibrary/icons/Cross.svg"

import { getFlightsDataForItinerary } from "../../../pages/summaryPage/utilsSummary"
import { setIconCode } from "../../../../utils/iconUtils"

import useStyles from "./styles"

import {
  journeyTimeFormat,
  from24To12Time,
  dateCommaDDmmYYY,
} from "../../../../store/flight/flightUtils"

import { flightsActions } from "../../../../store/flight/flightSlice"

const ViewMoreDetails = ({
  isOpen,
  handleClose,
  flights,
  flightsSelected,
  setFlightDetailsData,
  saveSearch,
  ticket,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const flightsSelectedData = window.location.pathname.includes("/summary")
    ? getFlightsDataForItinerary(flightsSelected, {
        saveSearch,
        ticket,
        formatMessage,
      })
    : flightsSelected

  const { dataFlights, itinenaryData } = flights
  const { origin, destination, solutions } = itinenaryData || {}
  const showNavigation = flightsSelectedData.length > 1
  const flightIndex = itinenaryData
    ? itinenaryData.originDestinationKey.charAt(
        itinenaryData.originDestinationKey.length - 1
      ) - 1
    : 0

  const flightList = useMemo(() => {
    if (flights?.itinenaryData?.travelerFaresDetails) {
      return itinenaryData?.travelerFaresDetails[0].fareComponents.find(
        ({ solutionKey }) => solutionKey === itinenaryData.solutions.key
      )?.fareFamilyDescriptions
    }

    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights])

  const departureArrivalTime = (flightsData) => {
    return from24To12Time(
      moment(`${flightsData?.flightDate} ${flightsData?.flightTime}`)
    )
  }

  const setIcon = (code) => {
    let logo
    switch (setIconCode(code)) {
      case "price":
        logo = (
          <AttachMoneyIcon style={{ fill: theme.palette.alternative.orange }} />
        )
        break
      case "check":
        logo = (
          <CheckSVG className={classes.fareIcon} style={{ fill: "#2c985c" }} />
        )
        break
      default:
        logo = (
          <CrossSVG
            className={classes.fareIcon}
            style={{ fill: theme.palette.error.main }}
          />
        )
    }
    return logo
  }

  const changeFlightDataToShow = (indexFlight) => {
    const flightsData = flightsSelectedData[indexFlight]
    setFlightDetailsData({
      itinenaryData: flightsData,
      dataFlights: flightsData?.solutions?.flights,
    })
  }

  // const verifyRestrictiveFare = (index) => {
  //   if (
  //     pathname === "/multicity" ||
  //     pathname === "/" ||
  //     pathname === "/summary"
  //   ) {
  //     return mcFlightsSelected[index]?.restrictiveFare
  //   }

  //   return flightsSelected[index]?.solutions?.offers?.fareFamily?.code === "BAS"
  // }

  return (
    <>
      <ModalCo
        data-cy="viewMoreDetailsFlights"
        wcagObject={[
          {
            message: formatMessage({
              id: "viewMoreDetailsModal.WCAG.modalDescription",
            }),
          },
          {
            id: "closeBotton",
            message: formatMessage({
              id: "viewMoreDetailsModal.WCAG.closeBotton",
            }),
          },
        ]}
        classes={{
          paper: classes.modalWidth,
        }}
        onClose={() => handleClose(!isOpen)}
        open={isOpen}
        showClose
      >
        <>
          <div className={classes.root}>
            <TypographyCo
              data-cy="viewMoreDetailsFlights-title"
              variant="h5"
              color="primary"
            >
              <FormattedMessage id="viewMoreDetailsModal.title" />
            </TypographyCo>
            <div className={classes.headerContainer}>
              <div className="itineraryCardContainer">
                <ItineraryCard
                  data={itinenaryData || {}}
                  isOutboundFlight={
                    itinenaryData &&
                    itinenaryData.originDestinationKey === "OD1"
                  }
                  aria-label={formatMessage(
                    {
                      id: "viewMoreDetailsModal.WCAG.itinerarySummary",
                    },
                    {
                      origin: itinenaryData && origin?.city,
                      destination: itinenaryData && destination?.city,
                      departureTime:
                        itinenaryData &&
                        departureArrivalTime(solutions?.flights[0]?.departure),
                      arrivalTime:
                        itinenaryData &&
                        departureArrivalTime(solutions?.flights[0]?.arrival),
                      dateFormat:
                        itinenaryData &&
                        dateCommaDDmmYYY(solutions?.flights[0]?.flightDate),
                      timeLayover:
                        itinenaryData && solutions?.flights[0]?.layoverTime,
                      numberOfLayovers:
                        itinenaryData && solutions?.numberOfLayovers,
                      airlineName:
                        itinenaryData &&
                        solutions?.flights[0]?.marketingCarrier?.airlineName,
                      journeyTime:
                        itinenaryData && solutions?.journeyTime
                          ? journeyTimeFormat(solutions?.journeyTime)
                          : "-",
                    }
                  )}
                  tabIndex="0"
                  maxWidthContainer="inherit"
                  notAction
                  noBorderBottom
                />
              </div>
              {showNavigation && (
                <SegmentNav
                  flightIndex={flightIndex}
                  handleClick={changeFlightDataToShow}
                  maxValue={flightsSelectedData.length}
                />
              )}
            </div>
            {/* {verifyIfFlightHasMixedFareFamilies(flightsSelectedData) && (
              <SingleAlertCo
                severity="info"
                fontWeight={500}
                fontSize="0.875rem"
                aria-label={formatMessage({
                  id: "viewMoreDetailsModal.WCAG.mixedFareFamilies",
                })}
                icon={
                  <InfoIcon
                    data-cy="InfoIcon-MixedFareFamilies"
                    fontSize="inherit"
                  />
                }
                data-cy="MixedFareFamilies"
              >
                {formatMessage({
                  id: "viewMoreDetailsModal.mixedFareFamilies",
                })}
              </SingleAlertCo>
            )} */}
            {/* {verifyRestrictiveFare(flightIndex) && (
              <SingleAlertCo
                severity="info"
                fontWeight={500}
                fontSize="0.875rem"
                icon={
                  <InfoIcon
                    data-cy="InfoIcon-RestrictiveFare"
                    fontSize="inherit"
                  />
                }
                data-cy="RestrictiveFare"
              >
                {formatMessage({
                  id: "viewMoreDetailsModal.restrictiveFare",
                })}
              </SingleAlertCo>
            )} */}
            {!isEmpty(flightList) && (
              <div className={classes.fareDetailsContianer}>
                <TypographyCo
                  color="primary"
                  className={classes.fareDetailsTitle}
                  data-cy="viewMoreDetailsFlightsFareDetails-title"
                  variant="body1"
                >
                  {formatMessage(
                    { id: "viewMoreDetailsModal.fareFamilyText" },
                    { fare: flights?.itinenaryData?.solutions?.fareFamily }
                  )}
                </TypographyCo>
                <div
                  className={classes.fareListContianer}
                  aria-label={formatMessage(
                    { id: "viewMoreDetailsModal.WCAG.fareFamilyText" },
                    {
                      fare: flights?.itinenaryData?.solutions?.fareFamily,
                      list: flightList?.map(({ description }) => description),
                    }
                  )}
                  role="grid"
                  tabIndex="0"
                >
                  {flightList?.map(({ code, description }) => {
                    return (
                      <div className={classes.fareDescription}>
                        <div>{setIcon(code)}</div>
                        <TypographyCo
                          data-cy="viewMoreDetailsFlightsFareDetails-description"
                          variant="body2"
                          className={classes.fareDetailsDescription}
                        >
                          {description}
                        </TypographyCo>
                      </div>
                    )
                  })}
                </div>
                <ButtonCo
                  color="primary"
                  size="medium"
                  variant="link"
                  className={classes.checkFareRules}
                  href={formatMessage({
                    id: "viewMoreDetailsModal.viewMoreLink",
                  })}
                  target="_blank"
                  aria-label={formatMessage({
                    id: "viewMoreDetailsModal.WCAG.viewMoreLink",
                  })}
                >
                  <FormattedMessage id="viewMoreDetailsModal.viewMoreText" />
                </ButtonCo>
              </div>
            )}
          </div>
          {dataFlights &&
            dataFlights.map((data, idx) => (
              <CardDetails
                data-cy={`viewDetailsCardDetails-${idx}`}
                key={data.flightKey}
                solutionKey={itinenaryData.solutions?.key}
                flightDetails={data}
                indexFlight={idx}
                flightsQty={dataFlights.length}
                isLayover={dataFlights.length > 1 && true}
              />
            ))}
        </>
      </ModalCo>
    </>
  )
}

ViewMoreDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  flights: PropTypes.shape(),
  flightsSelected: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  setFlightDetailsData: PropTypes.func.isRequired,
  ticket: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  saveSearch: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
}

ViewMoreDetails.defaultProps = {
  flights: {},
  flightsSelected: [],
}

const mapStateToProps = ({ flight, reservation }) => ({
  flights: flight.flightDetailsModal.data,
  flightsSelected: window.location.pathname.includes("/summary")
    ? flight.flightsSelected
    : flight.flightsDataModal,
  saveSearch: flight.saveSearch,
  ticket: reservation.ticket,
})

const mapDispatchToProps = (dispatch) => ({
  setFlightDetailsData: (data) =>
    dispatch(flightsActions.setFlightDetailsData(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ViewMoreDetails))
