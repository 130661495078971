import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import isEmpty from "lodash/isEmpty"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import { authActions } from "../../../store/auth/authSlice"
import logoConnectMiles from "../../../assets/images/logo_connectMiles.svg"
import ToastCo from "../../toast/toastCo"
import MembersMenuModal from "../index"
import useContentStyle from "./style"
import LogInForm from "./loginBoxForm"
import definedMessages from "./message"

const MembersLoginContent = ({ intl, initiateAuth, pending, logInError }) => {
  const { formatMessage } = intl
  const classes = useContentStyle()
  return (
    <div className={classes.root} role="contentinfo">
      <div data-cy="loginboxform-container" className="loginboxform-container">
        <img src={logoConnectMiles} alt="" className="logo-connect-miles" />
        {!isEmpty(logInError) && (
          <ToastCo id="message-alert" type="error" closebutton={false}>
            {formatMessage(definedMessages[logInError], {
              a: (...chunks) => (
                <a
                  href={formatMessage(
                    definedMessages.connectMilesCallCenterLink
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  key="error-toast"
                  data-cy="login-error-toast"
                >
                  {chunks.join("")}
                </a>
              ),
            })}
          </ToastCo>
        )}
        <LogInForm
          formatMessage={formatMessage}
          initiateAuth={initiateAuth}
          pending={pending}
          logInError={logInError}
        />
        <TypographyCo variant="body2" className="gray--text">
          {formatMessage(definedMessages.createaccountlink, {
            a: (...chunks) => (
              <a
                href={formatMessage(definedMessages.createaccountlinkExternal)}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={formatMessage(
                  definedMessages.createAccountlinkWCAG
                )}
                key="create-account"
                data-cy="login-enrollment-link"
              >
                {chunks.join("")}
              </a>
            ),
          })}
        </TypographyCo>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  pending: auth.pending,
  logInError: auth.error,
})

const mapDispatchToProps = (dispatch) => ({
  initiateAuth: (credential) => {
    return dispatch(authActions.loginRequest(credential))
  },
})

const InfoContentWithIntl = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MembersLoginContent))

MembersLoginContent.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userName: PropTypes.string,
    miles: PropTypes.string,
  }),
  initiateAuth: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  logInError: PropTypes.string.isRequired,
}

MembersLoginContent.defaultProps = {
  userData: {
    userName: "",
    miles: "",
  },
}

const Modal = ({ children, refElement }) => {
  return (
    <MembersMenuModal
      modalContent={<InfoContentWithIntl />}
      refElement={refElement}
      isLoginBox
    >
      {children}
    </MembersMenuModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  refElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
}

export default Modal
