const initialState = {
  fetching: false,
  error: "",
  sessionInfo: {
    passengers: [],
    modifiedItinerary: [],
    selectedOffer: {
      newFare: 0,
      taxesCharges: 0,
      originalFare: 0,
      subTotal: 0,
      total: 0,
      currency: "",
      travelerFaresDetails: [],
    },
  },
  successful: false,
  confResp: {},
  paymentsInfo: {},
  aprovalNumber: 0,
}

export default initialState
