import { defineMessages } from "react-intl"

const definedMessages = defineMessages({
  credentialsLabel: {
    id: "topBar.loginBox.credentialsLabel",
  },
  credentialsPlaceholder: {
    id: "topBar.loginBox.credentialsPlaceholder",
  },
  inlineErrorEmptyField: {
    id: "topBar.loginBox.inlineErrorEmptyField",
  },
  passwordLabel: {
    id: "topBar.loginBox.passwordLabel",
  },
  passwordfieldPlaceholder: {
    id: "topBar.loginBox.passwordfieldPlaceholder",
  },
  forgotLink: {
    id: "topBar.loginBox.forgotLink",
  },
  createaccountlink: {
    id: "topBar.loginBox.createaccountlink",
  },
  errorbannerWrongData: {
    id: "topBar.loginBox.errorbannerWrongData",
  },
  errorbannerBlockedAccount: {
    id: "topBar.loginBox.errorbannerBlockedAccount",
  },
  loginCTA: {
    id: "topBar.loginCTA",
  },
  emailMatch: {
    id: "topBar.loginBox.emailMatch",
  },
  forgotExternalLink: {
    id: "topBar.loginBox.forgotExternalLink",
  },
  createaccountlinkExternal: {
    id: "topBar.loginBox.createaccountlinkExternal",
  },
  connectMilesCallCenterLink: {
    id: "common.ConnectMilesCallCenterLink",
  },
  inlineErrorRequiredField: {
    id: "topBar.loginBox.required",
  },
  credentialsLabelWCAG: {
    id: "topBarWCAG.loginBox.CredentialsLabel",
  },
  passwordLabelWCAG: {
    id: "topBarWCAG.loginBox.PasswordLabel",
  },
  loginButtonWCAG: {
    id: "topBarWCAG.loginBox.loginButton",
  },
  fortgotPasswordWCAG: {
    id: "topBarWCAG.loginBox.fortgotPassword",
  },
  closeWCAG: {
    id: "topBarWCAG.loginBox.Close",
  },
  inlineErrorMandatoryFiledWCAG: {
    id: "topBarWCAG.loginBox.InlineErrorMandatoryFiled",
  },
  createAccountlinkWCAG: {
    id: "topBarWCAG.loginBox.CreateAccountlink",
  },
  errorBannerAlertWrongInformationWCAG: {
    id: "topBarWCAG.loginBox.ErrorBannerAlertWrongInformation",
  },
  errorBannerAlertBlockedAccountWCAG: {
    id: "topBarWCAG.loginBox.ErrorBannerAlertBlockedAccount",
  },
})
export default definedMessages
