import React, { useRef, useState, cloneElement, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Backdrop from "@material-ui/core/Backdrop"
import Box from "@material-ui/core/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useIntl } from "react-intl"
import { connect } from "react-redux"
import { useLocation, useHistory } from "react-router-dom"

import TypographyCo from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import PopperCo from "../../../../../ToLibrary/Popper/popperCo"
import ConfirmLanguageChangeModal from "../confirmLanguageChangeModal"
import { ReactComponent as Close } from "../../../../../ToLibrary/icons/Cross.svg"
import { setCookiesMatchingArrays, getCookie } from "../../../../../utils/auth"
import { generalActions } from "../../../../../store/general/generalSlice"

import useStyles from "./styles"

const CountryModal = ({
  language,
  languages,
  children,
  setLanguage,
  startLoading,
  stopLoading,
}) => {
  const { formatMessage } = useIntl()
  const {
    push: historyPush,
    location: { search: locationSearch },
  } = useHistory()
  const buttonRef = useRef()
  const changeLangButtonRef = useRef()
  const classes = useStyles()
  const isSelectOpen = useRef(false)
  const [open, setOpen] = useState(false)
  const [languageConfirm, setLanguageConfirm] = useState()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const location = useLocation()
  const params = new URLSearchParams(locationSearch)
  const pnr = params.get("pnr")
  const lastName = params.get("lastName")
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down("xs")} and (orientation: landscape)`
  )

  const isTrapEnabled = () => !isSelectOpen.current

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const validateForSummary = () => location.pathname.match(/summary/g)

  const validateForBasicPage = () => location.pathname.match(/critical-error/g)

  // const updQueryParamValue = (newValue, queryParam) => {
  //   try {
  //     const href = new window.URL(currentURL)
  //     href.searchParams.set(newValue, queryParam)
  //     return href.toString()
  //   } catch (e) {
  //     return currentURL
  //   }
  // }

  const updPageLanguage = (lang) => {
    if (lang !== language) {
      startLoading(true)
      setLanguage(lang)
      setCookiesMatchingArrays(["currentLang", "lang"], [lang, lang])
      if (validateForSummary()) {
        setOpenConfirmModal(false)
        historyPush(`/?pnr=${pnr}&lastName=${lastName}`)
        window.location.reload()
        setTimeout(() => {
          stopLoading()
        }, 100)
      } else {
        if (validateForBasicPage()) {
          stopLoading()
          return
        }
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    const cookieLenguage = getCookie("lang")
    if (cookieLenguage !== "" && language.id !== cookieLenguage) {
      updPageLanguage(cookieLenguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
    if (changeLangButtonRef) {
      // TODO: Delete setTimeout but nvm i don't know how to handle this mondaa
      setTimeout(() => {
        changeLangButtonRef.current.focus()
      }, 0)
    }
  }

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true)
    handleClose()
  }

  const handleClick = (lang) => {
    if (validateForSummary()) {
      handleOpenConfirmModal()
      setLanguageConfirm(lang)
      return
    }
    if (language.id !== lang) {
      updPageLanguage(lang)
    }
    handleClose()
  }

  const onKeyPress = (e, lang) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault()
      handleClick(lang)
    }
  }

  const closeModal = (e) => {
    if (e.key === "Escape") {
      e.preventDefault()
      handleClose()
    }
  }

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        className={classes.backDrop}
      />
      <Box ref={buttonRef} className={classes.modalContainer}>
        {cloneElement(children, {
          onClick: handleOpen,
          ref: changeLangButtonRef,
        })}
        {openConfirmModal && (
          <ConfirmLanguageChangeModal
            onClose={handleCloseConfirmModal}
            isOpen={openConfirmModal}
            handleOnApply={() => {
              updPageLanguage(languageConfirm)
            }}
          />
        )}
        <PopperCo
          open={open}
          flip={isMobileLandscape}
          anchorEl={buttonRef.current}
          placement="bottom-end"
          isTrapEnabled={isTrapEnabled}
          arrow={!isMobileLandscape}
          disablePortal
          className={classes.popper}
          ariaLabel="Idioma Language Língua"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              padding: 60,
              boundariesElement: "scrollParent",
            },
          }}
          arrowClassName={classes.popperArrow}
          onKeyDown={(e) => closeModal(e)}
        >
          <div className={classes.modal}>
            <div className={classes.containerClose}>
              <Box hidden id="closeCTA">
                {formatMessage({
                  id: "resultPage.cardsResult.headerWCAG.languageAndStorefrontSelector.closeCTA",
                })}
              </Box>
              <button
                aria-describedby="closeCTA"
                data-cy="countryModal-close"
                type="button"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <Close
                  className={classes.iconClose}
                  aria-label={formatMessage({ id: "topBar.close" })}
                />
              </button>
            </div>

            <div className={classes.modalTitle}>
              <TypographyCo variant="overline" gutterBottom>
                Idioma / Language / Língua
              </TypographyCo>
            </div>
            {languages.map(({ id, option }) => (
              <div
                key={id}
                className={clsx(
                  classes.option,
                  id === language.id ? "active" : ""
                )}
                role="button"
                tabIndex="0"
                onClick={() => handleClick(id)}
                onKeyDown={(e) => onKeyPress(e, id)}
                data-cy={`language-${id}-button`}
              >
                <TypographyCo>{option}</TypographyCo>
              </div>
            ))}
          </div>
        </PopperCo>
      </Box>
    </>
  )
}

CountryModal.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
    })
  ),
  language: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  setLanguage: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
}

CountryModal.defaultProps = {
  languages: [],
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(generalActions.setLang(language)),
  startLoading: (set) => dispatch(generalActions.startLoading(set)),
  stopLoading: () => dispatch(generalActions.stopLoading()),
})

export default connect(null, mapDispatchToProps)(CountryModal)
