import React from "react"
import PropTypes from "prop-types"
import SvgIcon from "@material-ui/core/SvgIcon"
import { useIntl } from "react-intl"

import ButtonCo from "@bit/vibenitez.copa-components-library.button"

import { ReactComponent as ArrowRight } from "../../../../ToLibrary/icons/Arrow-Right.svg"
import { ReactComponent as ArrowLeft } from "../../../../ToLibrary/icons/Arrow-Left.svg"
import useStyles from "./styles"

const SegmentNav = ({
  flightInfo,
  fareFamilyName,
  nextSegment,
  prevSegment,
  isPrevDisabled,
  isNextDisabled,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const { flightKey, arrival, departure, marketingCarrier } = flightInfo

  return (
    <div
      data-cy="fareSegmentNav"
      className={classes.segmentContainer}
      aria-label={formatMessage(
        {
          id: "fareRulesModal.WCAG.fareContent",
        },
        {
          iataOrigin: departure?.airportCode,
          iataDestination: arrival?.airportCode,
          airlineCode: marketingCarrier?.airlineCode,
          flightNumber: marketingCarrier?.flightNumber,
          fareFamilyName,
        }
      )}
      role="grid"
      tabIndex="0"
    >
      <div data-cy="fareSegmentNav-fareContent" className="fareContent">
        <strong data-cy="fareSegmentNav-flightTitle" className="flightTitle">
          {departure?.airportCode} - {arrival?.airportCode}
        </strong>
        <div data-cy="fareSegmentNav-fareAndFlight" className="fareAndFlight">
          <strong className="flight">
            {`${marketingCarrier?.airlineCode} ${marketingCarrier?.flightNumber}`}
          </strong>
          <span className="pointDivider">·</span>
          <span className="fare">
            {formatMessage({
              id: "fareRulesModal.fare",
            })}
            {": "}
            {fareFamilyName}
          </span>
        </div>
      </div>
      {(!isPrevDisabled || !isNextDisabled) && (
        <div data-cy="fareSegmentNav-navContent" className="navContent">
          <ButtonCo
            data-cy="fareSegmentNav-backwardArrow"
            className={`navButton ${isPrevDisabled ? "disabledNav" : ""}`}
            aria-label={formatMessage({
              id: "fareRulesModal.WCAG.backwardArrow",
            })}
            color="inherit"
            onClick={(e) => prevSegment(e, flightKey)}
          >
            <SvgIcon component={ArrowLeft} />
          </ButtonCo>
          <ButtonCo
            data-cy="fareSegmentNav-forwardArrow"
            className={`navButton ${isNextDisabled ? "disabledNav" : ""}`}
            aria-label={formatMessage({
              id: "fareRulesModal.WCAG.forwardArrow",
            })}
            color="inherit"
            onClick={(e) => nextSegment(e, flightKey)}
          >
            <SvgIcon component={ArrowRight} />
          </ButtonCo>
        </div>
      )}
    </div>
  )
}

SegmentNav.propTypes = {
  flightInfo: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any)]),
  fareFamilyName: PropTypes.string,
  nextSegment: PropTypes.func.isRequired,
  prevSegment: PropTypes.func.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
}

SegmentNav.defaultProps = {
  flightInfo: {},
  fareFamilyName: "",
}

export default SegmentNav
