import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import PropTypes from "prop-types"
import Close from "@material-ui/icons/Close"
import Info from "@material-ui/icons/Info"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  collapse: {
    top: 0,
    zIndex: 999,
    position: "sticky",
  },
  root: (state) => ({
    padding: state ? "17px 24px 17px 24px" : "12px 24px 12px 24px",
    borderRadius: 0,
    width: "100%",
    display: "flex",
  }),
  contentInfo: {
    padding: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    margin: "0 18px",
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  iconButton: {
    padding: 5,
    borderRadius: "50%",
    border: 0,
    backgroundColor: theme.palette.transparent.alertIcons,
    alignSelf: "flex-start",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.4)",
    },
  },
  iconMessage: {
    margin: 0,
    padding: 0,
  },
  icon: {
    alignSelf: "center",
    size: 16,
    color: theme.palette.common.white,
  },
}))

/**
 * AlertFull is a special alert that covers the entire width of the screen
 */
const AlertFull = ({
  visible,
  message,
  type,
  roleAlert,
  customIcon,
  onClose,
  hideClose,
}) => {
  const classes = useStyles(hideClose)
  return (
    <Collapse
      classes={{ container: classes.collapse }}
      in={visible}
      timeout={600}
    >
      <Alert
        color={type}
        variant="filled"
        classes={{
          root: classes.root,
          message: classes.contentInfo,
          icon: classes.iconMessage,
        }}
        role={roleAlert || "alert"}
        icon={customIcon || <Info className={classes.icon} />}
      >
        <Typography className={classes.text} variant="body2">
          {message}
        </Typography>
        {!hideClose && (
          <IconButton
            className={classes.iconButton}
            onClick={onClose}
            aria-label="close full alert"
          >
            {!hideClose && <Close className={classes.icon} />}
          </IconButton>
        )}
      </Alert>
    </Collapse>
  )
}

AlertFull.propTypes = {
  visible: PropTypes.bool,
  hideClose: PropTypes.bool,
  type: PropTypes.oneOf(["info", "success", "error"]),
  message: PropTypes.string.isRequired,
  roleAlert: PropTypes.string,
  customIcon: PropTypes.node,
  onClose: PropTypes.func,
}

AlertFull.defaultProps = {
  visible: false,
  hideClose: false,
  type: "info",
  customIcon: null,
  onClose: null,
  roleAlert: "",
}

export default AlertFull
