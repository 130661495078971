import { makeStyles } from "@material-ui/core/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles({
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "257px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "136px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "151px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "195px",
    },
  },
  title: {
    marginTop: 32,
  },
  subtitle: {
    marginTop: 16,
    marginBottom: 56,
    width: "517px",
    color: theme.palette.grey[600],
    [theme.breakpoints.down("md")]: {
      width: "515px",
      marginBottom: 48,
    },
    [theme.breakpoints.down("sm")]: {
      width: "529px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "92%",
    },
  },
  retryButton: {
    height: 48,
    [theme.breakpoints.down("md")]: {
      height: 40,
    },
  },
  helpContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexWrap: "wrap",
    marginTop: 273,
    paddingBottom: 56,
    [theme.breakpoints.down("md")]: {
      marginTop: 169,
      paddingBottom: 48,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 153,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 113,
      textAlign: "left",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  helpText: {
    color: theme.palette.grey[700],
    fontWeight: 500,
    padding: "8px 24px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px 24px",
    },
  },
  icons: {
    width: 17,
    fill: theme.palette.primary.light,
  },
})

export default useStyles
